import React, { useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import { Modal } from 'reactstrap'

function Taxi () {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  // const [isloginOn, setIsLogin] = useState(false)
  // const Toggle = () => setIsLogin(!isloginOn)

  return (
    <>
      <Navbar />
      <div className='main-taxi-insurance'>
        <div className='taxi-insurance'>
          <div className='taxi-insurance-info'>
            <h1>
              Taxi insurance starting from <br /> <span>₹ 6/day*</span>
            </h1>
            <input
              type='text'
              maxLength={15}
              className=' textuppercase'
              placeholder='Enter car number: (eg AP-31-cc-1986)'
            />{' '}
            <br />
            <button onClick={() => setModal(true)}>
              <a href='#'>Get Details</a>
            </button>
            <div class='text'>Don't know your car number?</div>
          </div>
          <div className='taxi-insurance-img'>
            <img src='./assets/img/Texi-insurance-1.png' alt='' />
          </div>
        </div>
      </div>
      <div className='about-taxi-insurance'>
        <div className='taxi-heading'>
          <h1>
            Know More about <b> Taxi Insurance</b>
          </h1>
          <div className='taxi-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Specialized Coverage: Taxi insurance offers coverage specifically
              designed for the unique risks associated with taxi services,
              including liability protection for passengers.
            </p>
          </div>
          <div className='taxi-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Public Liability: Addresses risks related to third-party injuries
              or property damage during taxi operations, providing financial
              protection and legal compliance.Passenger Liability: Provides
              coverage for injuries sustained by passengers while in the taxi,
              ensuring financial support for medical expenses.
            </p>
          </div>
          <div className='taxi-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Comprehensive Vehicle Coverage: Protects the taxi vehicle against
              damages from accidents, theft, vandalism, and natural disasters.
            </p>
          </div>
          <div className='taxi-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Uninsured/Underinsured Motorist Coverage: Safeguards against
              accidents involving drivers with insufficient or no insurance,
              offering additional protection for passengers and the taxi.
            </p>
          </div>
        </div>
        <div className='taxi-insurance-img'>
          <img src='./assets/img/Texi-insurance-2.png' alt='' />
        </div>
      </div>
      <div className='about-taxi-insurance-2'>
        <div className='taxi-insurance-img'>
          <img src='./assets/img/Texi-insurance-3.png' alt='' />
        </div>
        <div className='taxi-heading'>
          <h1>
            Benefits of <b> Taxi Insurance</b>
          </h1>
          <div className='taxi-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Specialized Coverage: Tailored insurance designed to address the
              unique risks associated with taxi services.
            </p>
          </div>
          <div className='taxi-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Passenger Protection: Coverage for injuries sustained by
              passengers during taxi rides, providing financial support for
              medical expenses.Public Liability: Protects against third-party
              injuries or property damage during taxi operations, ensuring legal
              compliance and financial security.
            </p>
          </div>
          <div className='taxi-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Comprehensive Vehicle Coverage: Safeguards taxi vehicles against
              damages from accidents, theft, vandalism, and natural disasters.
            </p>
          </div>
          <div className='taxi-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Income Protection: Coverage for lost income in case the taxi is
              temporarily out of service due to covered
              damages.Uninsured/Underinsured Motorist Coverage: Additional
              protection for passengers and the taxi in accidents involving
              drivers with insufficient or no insurance.
            </p>
          </div>
        </div>
      </div>
      <Footer />

      <Modal isOpen={modal} toggle={toggle} size='md'>

      <nav className=''>
          <div
            className='nav nav-tabs border-0 justify-content-center px-2 py-2 mt-0'
            id='nav-tab'
            style={{
              backgroundColor: '#354c89',
              width: '100%',
              color: 'white',
              fontWeight: 'bold'
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p className='fs-5'>Details Submitted</p>
              
            </div>
          </div>
        </nav>
        <div
          className='px-4 py-5'
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <h2 className='modal-page'>
            Your Details has been Submitted.You will Recieve a call Shortly
          </h2>
          <button
            onClick={() => setModal(false)}
            style={{
              width: '50%',
              margin: 20,
              border: 'none',
              borderRadius: '3px',
              color: 'white',
              backgroundColor: '#354c89'
            }}
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  )
}

export default Taxi
