import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import HowWork from "./HowWork";

function Age() {
  return (
    <>
      <Navbar />
      <div className="age-main-div">
        <div className="age-inf">
        <div className="select-age">
          <div className="self-age">
            <div className="self-age-img">
              <img src="./assets/img/age-img.png" alt="" />
            </div>
            <h1>Self</h1>
          </div>
          <h1>select Age</h1>
          <div className="loder">
            <p> 48%</p>
          </div>
        </div>
        <div className="age-div">
          <select>
            <option>Select Age</option>
            <option value="">20 yr</option>
            <option value="">23 yr</option>
            <option value="">25 yr</option>
            <option value="">26 yr</option>
            <option value="">28 yr</option>
            <option value="">29 yr</option>
            <option value="">30 yr</option>
          </select>
        </div>
        <div className="btn-age">
          <button className="btn-1 ">20 yr</button>
          <button className="btn-1 ">23 yr</button>
          <button className="btn-1 ">25 yr</button>
          <button className="btn-1 ">26 yr</button>
          <button className="btn-1 ">28 yr</button>
          <button className="btn-1 ">29 yr</button>
          <button className="btn-1 ">30 yr</button>
        </div>
        <div className="submit-age">
          <button>
            <Link to="/city">Continue</Link>
          </button>
        </div>
        </div>
      </div>
      {/* <HowWork/> */}
      <Footer />
    </>
  );
}

export default Age;
