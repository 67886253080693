import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import HowWork from "./HowWork";

function Wheeler() {
  return (
    <>
      <Navbar />
      <div className="main-wheeler-page">
        <div className="wheeler-inf">
          <div className="select-wheeler">
            <div className="new-wheeler">
              <div className="new-wheeler-img">
                <img src="./assets/img/new-product-img.png" alt="" />
              </div>
              <h1>New</h1>
            </div>
            <h1>Select City / RTO</h1>
            <div className="loder">
              <p> 50%</p>
            </div>
          </div>
          <div className="wheeler-name">
            <div className="wheeler-logo">
              <Link>
                <div className="wheeler-box">
                  <img src="./assets/img/honda-img.png" alt="" />
                </div>
              </Link>
              <Link>
                <div className="wheeler-box">
                  <img src="./assets/img/hero-img.png" alt="" />
                </div>
              </Link>
              <Link>
                {" "}
                <div className="wheeler-box">
                  <img src="./assets/img/honda-img-2.png" alt="" />
                </div>
              </Link>
              <Link>
                <div className="wheeler-box">
                  <img src="./assets/img/hero-img-2.png" alt="" />
                </div>
              </Link>
              <Link>
                <div className="wheeler-box">
                  <img src="./assets/img/suzuki-img.png" alt="" />
                </div>
              </Link>
              <Link>
                {" "}
                <div className="wheeler-box">
                  <img src="./assets/img/honda-img-3.png" alt="" />
                </div>
              </Link>
              <Link>
                {" "}
                <div className="wheeler-box">
                  <img src="./assets/img/honda-img-4.png" alt="" />
                </div>
              </Link>
              <Link>
                {" "}
                <div className="wheeler-box">
                  <img src="./assets/img/royal-img.png" alt="" />
                </div>
              </Link>
            </div>
          </div>

          <div className="more-wheeler">
            <select name="" id="">
              <option value=""> View More</option>
            </select>
          </div>
          <div className="submit-wheeler">
            <button>
              <Link to="/model">Continue</Link>
            </button>
          </div>
        </div>
      </div>
      <div className="wheeler-name">
        <div className="wheeler-logo">
          <Link>
            <div className="wheeler-box">
              <img src="./assets/img/honda-img.png" alt="" />
            </div>
          </Link>
          <Link>
            <div className="wheeler-box">
              <img src="./assets/img/hero-img.png" alt="" />
            </div>
          </Link>
          <Link>
            {" "}
            <div className="wheeler-box">
              <img src="./assets/img/honda-img-2.png" alt="" />
            </div>
          </Link>
          <Link>
            <div className="wheeler-box">
              <img src="./assets/img/hero-img-2.png" alt="" />
            </div>
          </Link>
          <Link>
            <div className="wheeler-box">
              <img src="./assets/img/suzuki-img.png" alt="" />
            </div>
          </Link>
          <Link>
            {" "}
            <div className="wheeler-box">
              <img src="./assets/img/honda-img-3.png" alt="" />
            </div>
          </Link>
          <Link>
            {" "}
            <div className="wheeler-box">
              <img src="./assets/img/honda-img-4.png" alt="" />
            </div>
          </Link>
          <Link>
            {" "}
            <div className="wheeler-box">
              <img src="./assets/img/royal-img.png" alt="" />
            </div>
          </Link>
        </div>
      </div>

      <div className="more-wheeler">
        <select name="" id="">
          <option value=""> View More</option>
        </select>
      </div>
      <div className="submit-wheeler">
        <button>
          <Link to="/model">Continue</Link>
        </button>
      </div>

      <HowWork />
      <Footer />
    </>
  );
}

export default Wheeler;
