import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Modal, ModalBody, TabPane } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { handleLoginPos } from "./Services/PosService";
import cookie from "react-cookies";
import {
  dispatchQuickQuote,
  dispatchResetQuickQuote,
  resetQuickQuoteResults,
  saveUserDetails,
} from "../store/actions/userAction";
import { toast } from "react-toastify";
import {
  GetDataWithToken,
  PostData,
  PostDataWithToken,
} from "../api/apiHelper";
import Cookies from "js-cookie";
import { useCookies } from "react-cookie";
function Navbar() {
  const [findAdvisor, setFindAdvisor] = useState(false);
  const toggle1 = () => setFindAdvisor(!findAdvisor);

  const [isloginOn, setIsLogin] = useState(false);
  const toggle = () => setIsLogin(!isloginOn);

  const [isForget, setIsForget] = useState(false);
  const toggleForget = () => setIsForget(!isForget);

  const [isReset, setIsReset] = useState(false);
  const toggleReset = () => setIsReset(!isReset);

  const [isCustomer, setIsCustomer] = useState(false);
  const toggleCustomer = () => setIsCustomer(!isCustomer);

  // For Login Form
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset: resetPosForm,
  } = useForm({ mode: "onBlur" });

  // For Forget Password
  const {
    register: register1,
    formState: { errors: errors1 },
    handleSubmit: handleSubmit1,
    reset: reset1,
  } = useForm({ mode: "onBlur" });

  // For Reset Password
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    reset: reset2,
    watch,
  } = useForm({ mode: "onBlur" });

  // For Find An Advisor
  const {
    register: register3,
    formState: { errors: errors3 },
    handleSubmit: handleSubmit3,
    reset: reset3,
  } = useForm({ mode: "onBlur" });
  const userDetails = useSelector((state) => state.root.userDetails);
  const apiRequestQQ = useSelector((state) => state.root.apiRequestQQ);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modal, setModal] = React.useState(true);
  const [OTP, setOTP] = useState("");
  const [loginAs, setLoginAs] = React.useState("Pos");
  const [userError, setUserError] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [showLoginDropdown, setShowLoginDropdown] = useState(false);
  const [email, setEmail] = useState("");
  const password = watch("password");
  const [posDetails, setPosDetails] = useState({});
  const [posId, setPosId] = useState("");
  const searchParams = new URLSearchParams(window.location.search);
  const vehicleType = searchParams.get("vehicleType");
  const userId = searchParams.get("posId");
  const [logout, setLogout] = useState(userId ? false : true);
  const [cookies, setCookie, removeCookie] = useCookies([
    "Data",
    "userDetails",
    "posId",
  ]);

  function myFunction() {
    var x = document.getElementById("myTopnav");
    if (x.classList.contains("show")) {
      x.classList.remove("show");
    } else {
      x.classList.add("show");
    }
  }
  function navigateToSelectedOption(value) {
    // Get the selected value from the dropdown
    const selectedValue = document.getElementById("insuranceDropdown").value;

    // Navigate to the selected option (you can replace this with your navigation logic)
    if (selectedValue !== "value") {
      window.location.href = selectedValue;
    }
  }

  // POS Login
  const onSubmit = (data) => {
    handleLoginPos(data).then((response) => {
      if (response.status === true) {
        cookie.save("access_token", response.data.access_token, { path: "/" });
        dispatch(saveUserDetails(response.data));
        if (loginAs === "Pos") {
          if (response.data.isVerified === false) {
            // navigate("/postraining");
            window.location.href =
              "https://uatbpm.retroinsurance.in/?id=" +
              response.data.access_token;
          } else {
            window.location.href =
              "https://uatbpm.retroinsurance.in/?id=" +
              response.data.access_token;
          }
        }
        // if (loginAs !== "Pos") {
        //   if (response.data.isVerified === true) {
        //     navigate("/crm/dashboard");
        //   } else {
        //     navigate("/postraining");
        //   }
        // }
      } else {
        setErrorResponse(response?.data?.message);
        toast(response?.data?.message, { type: "error" });
        setUserError(true);
      }
    });
  };

  // Customer Login
  // const customerLogin = data => {
  //   if (OTP == '') {
  //     let postData = {
  //       phone: data.MobileNumber
  //     }
  //     PostDataWithToken('auth/customer-login', postData).then(response => {
  //       if (response.status === true) {
  //         setOTP(response.data)
  //         reset1({ otp: response.data })
  //       }
  //     })
  //   } else {
  //     let postData = {
  //       phone: data.MobileNumber,
  //       otp: data.otp
  //     }
  //     PostDataWithToken('auth/verify-otp', postData).then(response => {
  //       if (response.status === true) {
  //         cookie.save('access_token', response.data.access_token, {
  //           path: '/'
  //         })
  //         dispatch(saveUserDetails(response.data))
  //         navigate('/')
  //       }
  //     })
  //   }
  // }

  const handleForgetModal = () => {
    setIsForget(true);
    setIsLogin(false);
  };
  const handleCustomerModal = () => {
    setIsCustomer(true);
    setIsLogin(false);
  };

  const handleForget = (data) => {
    PostData("auth/forget", data).then((response) => {
      if (response.status === true) {
        // navigate("/resetpassword", { state: { email: data.email } });
        setEmail(data?.email);
        setIsForget(false);
        setIsReset(true);
      } else {
        toast(response?.data?.message, { type: "error" });
      }
    });
  };

  const handleReset = (data) => {
    let Data = {
      email: email,
      password: data.passwordRepeat,
      otp: data.otp,
    };
    PostData("auth/reset", Data).then((response) => {
      if (response.status === true) {
        // navigate("/Login");
        setIsReset(false);
        setIsLogin(true);
        toast(response?.message, { type: "success" });
      } else {
        toast(response?.message, { type: "error" });
      }
    });
  };

  const handleAdvisor = (data) => {
    let Data = {
      name: data.name,
      contact_number: data.contact_number,
    };
    PostData("admin/addleads", Data).then((response) => {
      if (response?.success === true) {
        setFindAdvisor(false);
        toast(response?.message, { type: "success" });
      } else {
        toast(response?.message, { type: "error" });
      }
    });
  };

  const handleLoginRedirectModal = () => {
    setIsForget(false);
    setIsLogin(true);
  };
  const handleVehicleType = (type) => {
    dispatchResetQuickQuote();
    dispatch(resetQuickQuoteResults());
    dispatchQuickQuote("VehicleType", type);

    let vType = {
      vehicleType: "",
      vehicleModel: "",
    };
    if (type === "4w") {
      vType.vehicleType = type;
      vType.vehicleModel = "Pvt Car";
    } else if (type === "2w") {
      vType.vehicleType = type;
      vType.vehicleModel = "Two Wheeler";
    } else if (type === "gcv") {
      vType.vehicleType = type;
      vType.vehicleModel = "GCV";
    } else if (type === "gcv") {
      vType.vehicleType = type;
      vType.vehicleModel = "Taxi";
    }

    navigate("/motor-process", { state: vType });
  };

  useEffect(() => {
    const cookieValue = Cookies.get("userDetails");
    if (cookieValue) {
      setPosDetails(JSON.parse(cookieValue));
      dispatch(saveUserDetails(JSON.parse(cookieValue)));
    }

    const cookieValue1 = Cookies.get("posId");
    setPosId(cookieValue1);
    dispatchQuickQuote("posId", cookieValue1);
  }, []);

  const posRedirect = (page) => {
    if (page === "login") {
      window.location.href = "https://uatbpm.retroinsurance.in/";
    } else if (page === "profile") {
      window.location.href = "https://uatbpm.retroinsurance.in/profile/posp";
    } else {
      window.location.href = "https://uatbpm.retroinsurance.in/dashboard";
    }
  };

  const logoutUser = () => {
    removeCookie("Data", { path: "/", domain: ".retroinsurance.in" });
    removeCookie("posId", { path: "/", domain: ".retroinsurance.in" });
    removeCookie("userDetails", { path: "/", domain: ".retroinsurance.in" });
    dispatchQuickQuote("Login", true);
    dispatch(saveUserDetails({}));
    setLogout(true);
    navigate("/");
  };

  const handleLogintMouseEnter = () => {
    setShowLoginDropdown(true);
  };

  const handleLoginMouseLeave = () => {
    setShowLoginDropdown(false);
  };

  return (
    <>
      <header className="fixed">
        <div className="navbar-retro">
          <Link to="/">
            <div>
              <img src="/assets/logo.svg" alt="" className="nav-logo-image" />
            </div>
          </Link>
          <div className="btn-box1" id="myTopnav">
            <div className="nav-retro-info m">
              <ul className="nav-retro-link" id="myTopnav">
                <li>
                  <div className="dropdown">
                    <span>Insurance Product</span>

                    <div className="dropdown-content">
                      <div
                        style={{
                          textDecoration: "none",
                          color: "#000",
                          cursor: "pointer",
                          fontSize: "1rem",
                          fontWeight: "600",
                        }}
                        onClick={() => handleVehicleType("4w")}
                      >
                        <p>Car</p>
                      </div>
                      <div
                        style={{
                          textDecoration: "none",
                          color: "#000",
                          cursor: "pointer",
                          fontSize: "1rem",
                          fontWeight: "600",
                        }}
                        onClick={() => handleVehicleType("2w")}
                      >
                        <p> Bike</p>
                      </div>
                      <Link to="/Taxi">
                        <p>Taxi</p>
                      </Link>
                      <Link to="/Health">
                        <p>Health</p>
                      </Link>
                      <Link to="#">
                        <p>GCV</p>
                      </Link>
                    </div>
                  </div>
                </li>
                <li>
                  {/* <a href="#">Raise a Claim</a> */}

                  <a href="/about"> About Us</a>
                </li>
                <li>
                  {/* <a href="#">Support</a> */}
                  <a href="/ContactUs">Contact Us</a>
                </li>
                <div className="btn-block">
                  <div className="btnss">
                    <Link to="/ContactUs">
                      <p>Contact Us</p>
                    </Link>
                  </div>
                  <div className="btnss">
                    <Link to="/about">
                      <p>About Us</p>
                    </Link>
                  </div>
                  {!userDetails?.username && (
                    <>
                      <button
                        onClick={() => setFindAdvisor(true)}
                        className="nav-text-btn"
                      >
                        Find an Advisor
                      </button>
                      <div className="btn-box2">
                        <Link to="/becomepos">
                          <button className="nav-text-btn">Become A POS</button>
                        </Link>
                      </div>
                    </>
                  )}
                  {userDetails?.username ? (
                    <li className="nav-item-1 dropdown d-block">
                      <a
                        className="nav-link-1 dropdown-toggle"
                        href="#"
                        id="navbarDropdown1"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span
                          onMouseEnter={handleLogintMouseEnter}
                          onMouseLeave={handleLoginMouseLeave}
                        ></span>
                        {/* <span className='d-inline-block'>POS Name -</span> */}
                        {userDetails?.name && userDetails?.name.split(" ")[0]}
                      </a>

                      <ul
                        className={`dropdown-menu ${
                          showLoginDropdown ? "show" : ""
                        }`}
                        aria-labelledby="navbarDropdown1"
                      >
                        {userDetails?.type === "2" && (
                          <li className="position-relative py-2">
                            <a
                              href="javascript:void(0)"
                              onClick={() => posRedirect("profile")}
                              className="dropdown-item"
                            >
                              My Profile
                            </a>
                          </li>
                        )}

                        <li className="position-relative py-2">
                          <span
                            className="dropdown-item"
                            onClick={() => logoutUser()}
                          >
                            Logout
                          </span>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    <div className="btn-box3" onClick={() => setIsLogin(true)}>
                      <button className="nav-text-btn">Login</button>
                    </div>
                  )}
                </div>
              </ul>
            </div>
          </div>
          <>
            <div className="nav-menu">
              <a className="icon" onClick={() => myFunction()}>
                <i className="fa fa-bars"></i>
              </a>
            </div>
          </>
        </div>
      </header>

      {/* Login Dialog */}
      <div className="main-login-modal">
        <Modal
          isOpen={isloginOn}
          toggle={toggle}
          // style={{ width: '35%' }}
          className="ee"
        >
          <div className="modal-login">
            <nav className="">
              <div className="nav nav-tabs border-0 justify-content-center px-2 py-2">
                <div
                  className="v3_close false"
                  onClick={() => setIsLogin(false)}
                >
                  <img src="./assets/img/close.png" title="Close" alt="close" />
                </div>
              </div>
            </nav>

            {/* <TabContent activeTab={loginAs}> */}
            <TabPane tabId="Pos">
              <form>
                <div className="card shadow-lg border-0">
                  <div className=" justify-content-center p-5">
                    <div className="main-posp-from">
                      <div>
                        <img src="./assets/img/posp-img.png" />
                      </div>
                      <div className="posp-heading">
                        <h1>
                          Getting In Touch <br />
                          <b>With Us Is Easy</b>
                        </h1>
                      </div>
                    </div>
                    <div style={{ alignContent: "center", display: "flex" }}>
                      <div
                        type="submit"
                        className=" log-btn"
                        onClick={() => handleCustomerModal()}
                      >
                        Customer
                      </div>
                      <Link
                        className=" log-btn"
                        to="https://pos.retroinsurance.in/"
                      >
                        POSP
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </TabPane>
          </div>
        </Modal>
      </div>
      {/* Forget Password */}
      <Modal
        isOpen={isForget}
        toggle={toggleForget}
        //  style={{ width: '35%' }}
      >
        <nav className="">
          <div
            className="nav nav-tabs border-0 justify-content-center px-2 py-2"
            id="nav-tab"
            style={{
              backgroundColor: "#35508a",
              width: "100%",
              color: "white",
              fontWeight: "bold",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <i className='fas fa-user-plus me-3' /> */}
              <p className="fs-5">Forget Password</p>
            </div>
            <span
              className="close-button col-2"
              style={{ cursor: "pointer", alignItems: "center" }}
              onClick={() => setIsForget(false)}
            >
              ×
            </span>
          </div>
        </nav>
        <form onSubmit={handleSubmit1(handleForget)}>
          <div className="card shadow-lg border-0">
            <div className="card-body p-5">
              <div className="login-form">
                {/* <i className="fa-solid fa-envelope"></i> */}
                <label for="Email">
                  <img
                    src="assets/envelope.png"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "12px",
                    }}
                  />

                  <input
                    type="email"
                    className="login-input-div"
                    style={{ borderBottom: "1px solid lightgrey" }}
                    id="Email"
                    {...register1("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Invalid email address",
                      },
                    })}
                    placeholder="Enter Email"
                    onChange={(e) => setUserError(false)}
                  />
                </label>
                {/* <label htmlFor='Email'></label> */}

                <p className="f-error" style={{ fontSize: "12px" }}>
                  {errors1?.email?.message}
                </p>
              </div>
              <div style={{ alignContent: "center", display: "flex" }}>
                <button type="submit" className=" log-btn">
                  Submit
                </button>
              </div>

              <Link
                to={"#"}
                onClick={() => handleLoginRedirectModal()}
                className="text-decoration-none fs-6 fw-bold text-dark mt-4 d-block text-center"
              >
                Already Have an account? Login
              </Link>
            </div>
          </div>
        </form>
      </Modal>

      {/* Reset Password */}
      <Modal
        isOpen={isReset}
        toggle={toggleReset}
        //  style={{ width: '35%' }}
      >
        <nav className="">
          <div
            className="nav nav-tabs border-0 justify-content-center px-2 py-2"
            id="nav-tab"
            style={{
              backgroundColor: "#35508a",
              width: "100%",
              color: "white",
              fontWeight: "bold",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <i className='fas fa-user-plus me-3' /> */}
              <p className="fs-5">Reset Password</p>
            </div>
            <span
              className="close-button col-2"
              style={{ cursor: "pointer", alignItems: "center" }}
              onClick={() => setIsReset(false)}
            >
              ×
            </span>
          </div>
        </nav>
        <form onSubmit={handleSubmit2(handleReset)}>
          <div className="card shadow-lg border-0">
            <div className=" justify-content-center p-5">
              <div className="login-form">
                {/* <i className="fa-solid fa-envelope"></i> */}
                <label for="Email">
                  <img
                    src="assets/envelope.png"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "12px",
                    }}
                  />

                  <input
                    type="number"
                    className="login-input-div"
                    style={{ borderBottom: "1px solid lightgrey" }}
                    id="upass3"
                    {...register2("otp", {
                      required: "otp is required",
                    })}
                    placeholder="Enter OTP"
                  />
                </label>

                <p className="f-error" style={{ fontSize: "12px" }}>
                  {errors2?.otp?.message}
                </p>
              </div>

              <div className=" login-form mb-4  ">
                <label for="Email">
                  <img
                    src="assets/lock-solid.svg"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "12px",
                    }}
                  />

                  <input
                    type="password"
                    {...register2("password", {
                      required: "Password is required",
                    })}
                    className="login-input-div "
                    style={{ borderBottom: "1px solid lightgrey" }}
                    id="upass"
                    placeholder="Enter Password"
                  />
                </label>
                {/* <label htmlFor='PSsword'></label> */}

                <p className="f-error" style={{ fontSize: "12px" }}>
                  {errors2?.password?.message}
                </p>
              </div>

              <div className=" login-form mb-4  ">
                <label for="Email">
                  <img
                    src="assets/lock-solid.svg"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "12px",
                    }}
                  />

                  <input
                    type="password"
                    {...register2("passwordRepeat", {
                      required: `Password is required`,
                      validate: (value) =>
                        value === password || `The password do not match`,
                      minLength: {
                        value: 6,
                        message: `Password length must be at least 6 characters long`,
                      },
                    })}
                    className="login-input-div "
                    style={{ borderBottom: "1px solid lightgrey" }}
                    id="upass2"
                    placeholder="Confirm Password"
                  />
                </label>
                {/* <label htmlFor='PSsword'></label> */}

                <p className="f-error" style={{ fontSize: "12px" }}>
                  {errors2?.passwordRepeat?.message}
                </p>
              </div>

              <div style={{ alignContent: "center", display: "flex" }}>
                <button type="submit" className=" log-btn">
                  Submit
                </button>
              </div>

              <Link
                to={"#"}
                onClick={() => handleReset()}
                className="text-decoration-none fs-6 fw-bold text-dark mt-4 d-block text-center"
              >
                Submit
              </Link>
            </div>
          </div>
        </form>
      </Modal>

      {/* Find An Advisor Dialog */}
      <Modal
        isOpen={findAdvisor}
        toggle={toggle1}
        // style={{ width: '35%' }}
      >
        <nav className="">
          <div
            className="nav nav-tabs border-0 justify-content-center px-2 py-2 mt-0"
            id="nav-tab"
            style={{
              backgroundColor: "#35508a",
              width: "100%",
              color: "white",
              fontWeight: "bold",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="fs-5 find-advisor">Find An Advisor</p>
              <span
                className="close-button col-2"
                style={{ cursor: "pointer", alignItems: "center" }}
                onClick={() => setFindAdvisor(false)}
              >
                ×
              </span>
            </div>
          </div>
        </nav>
        <form onSubmit={handleSubmit3(handleAdvisor)}>
          <div className="card shadow-lg border-0">
            <div className=" justify-content-center p-5">
              {/* Name */}
              <div className="login-form">
                <label for="name">
                  <img
                    src="assets/user-solid.svg"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "12px",
                    }}
                  />
                  <input
                    type="name"
                    className="login-input-div"
                    style={{ borderBottom: "1px solid lightgrey" }}
                    id="name"
                    {...register3("name", {
                      required: "Username is required",
                    })}
                    placeholder="Enter Username"
                  />
                </label>
                <p className="f-error" style={{ fontSize: "12px" }}>
                  {errors?.name?.message}
                </p>
              </div>

              {/* Email */}
              {/* <div className='login-form'>
                <label for='Email'>
                  <img
                    src='assets/envelope.png'
                    style={{
                      height: '20px',
                      width: '20px',
                      marginRight: '12px'
                    }}
                  />
                  <input
                    type='email'
                    className='login-input-div'
                    style={{ borderBottom: '1px solid lightgrey' }}
                    id='Email'
                    {...register3('email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'Invalid email address'
                      }
                    })}
                    placeholder='Enter Email'
                  />
                </label>
                <p className='f-error' style={{ fontSize: '12px' }}>
                  {errors?.email?.message}
                </p>
              </div> */}

              {/* Mobile */}
              <div className=" login-form mb-4  ">
                <label for="mobile">
                  <img
                    src="assets/mobile-solid.svg"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "12px",
                    }}
                  />
                  <input
                    type="number"
                    {...register3("contact_number", {
                      required: "Mobile Number is required",
                    })}
                    className="login-input-div "
                    style={{ borderBottom: "1px solid lightgrey" }}
                    id="contact_number"
                    placeholder="Enter Mobile Number"
                  />
                </label>
                <p className="f-error" style={{ fontSize: "12px" }}>
                  {errors?.contact_number?.message}
                </p>
              </div>

              <div style={{ alignContent: "center", display: "flex" }}>
                <button type="submit" className=" log-btn">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
      {/* Customer Login */}
      <Modal isOpen={isCustomer} toggle={toggleCustomer}>
        <nav className="">
          <div
            className="nav nav-tabs border-0 justify-content-center px-2 py-2"
            id="nav-tab"
            style={{
              backgroundColor: "#354c89",
              width: "100%",
              color: "white",
              fontWeight: "bold",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <i className="fas fa-user-plus me-3" />
              <p className="fs-5">Customer</p>
            </div>
            <span
              className="close-button col-2"
              style={{ cursor: "pointer", alignItems: "center" }}
              onClick={() => setIsCustomer(false)}
            >
              ×
            </span>
          </div>
        </nav>

        {/* <TabContent activeTab={loginAs}> */}
        <TabPane tabId="Pos">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card shadow-lg border-0">
              <div className=" justify-content-center p-5">
                <div className="login-form">
                  {/* <i className="fa-solid fa-envelope"></i> */}
                  <label for="Email">
                    <img
                      src="assets/envelope.png"
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "12px",
                      }}
                    />

                    <input
                      type="email"
                      className="login-input-div"
                      style={{ borderBottom: "1px solid lightgrey" }}
                      id="Email"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Invalid email address",
                        },
                      })}
                      placeholder="Enter Email"
                      onChange={(e) => setUserError(false)}
                    />
                  </label>
                  {/* <label htmlFor='Email'></label> */}

                  <p className="f-error" style={{ fontSize: "12px" }}>
                    {errors?.email?.message}
                  </p>
                </div>

                <div className=" login-form mb-4  ">
                  <label for="Email">
                    <img
                      src="assets/lock-solid.svg"
                      style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "12px",
                      }}
                    />

                    <input
                      type="password"
                      {...register("password", {
                        required: "Password is required",
                      })}
                      className="login-input-div "
                      style={{ borderBottom: "1px solid lightgrey" }}
                      id="PSsword"
                      placeholder="Enter Password"
                      onChange={(e) => setUserError(false)}
                    />
                  </label>
                  {/* <label htmlFor='PSsword'></label> */}

                  <p className="f-error" style={{ fontSize: "12px" }}>
                    {errors?.password?.message}
                  </p>
                </div>

                {userError && (
                  <p
                    className="f-error fs-6 text-danger px-1 "
                    style={{ position: "relative", bottom: "1rem" }}
                  >
                    {errorResponse}
                  </p>
                )}

                <div style={{ alignContent: "center", display: "flex" }}>
                  <button type="submit" className=" log-btn">
                    Login
                  </button>
                </div>

                <Link
                  to={"#"}
                  onClick={() => {
                    handleForgetModal();
                  }}
                  className="text-decoration-none fs-6 fw-bold text-dark mt-4 d-block text-center"
                >
                  Forgot password
                </Link>
              </div>
            </div>
          </form>
        </TabPane>
      </Modal>
    </>
  );
}

export default Navbar;
