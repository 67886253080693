import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import HowWork from "./HowWork";
import { Link } from "react-router-dom";

function ModelYear() {
  return (
    <>
      <Navbar />
      <div className="main-model-page">
        <div className="modal-inf">
          <div className="select-model">
            <div className="new-model">
              <div className="new-model-img">
                <img src="./assets/img/new-product-img.png" alt="" />
              </div>
              <h1>New</h1>
            </div>
            <h1>Model </h1>
            <div className="loder">
              <p> 75%</p>
            </div>
          </div>
          <div className="model-year">
            <div className="model-btn">
              <Link>
                <button>2023</button>
              </Link>
              <Link>
                <button>2022</button>
              </Link>
              <Link>
                <button>2021</button>
              </Link>
              <Link>
                <button>2020</button>
              </Link>
              <Link>
                <button>2019</button>
              </Link>
              <Link>
                <button>2018</button>
              </Link>
              <Link>
                <button>2017</button>
              </Link>
              <Link>
                <button>2016</button>
              </Link>
              <Link>
                {" "}
                <button>2015</button>
              </Link>
              <Link>
                <button>2014</button>
              </Link>
              <Link>
                <button>2013</button>
              </Link>
              <Link>
                <button>2012</button>
              </Link>
              <Link>
                <button>2011</button>
              </Link>
              <Link>
                <button>2010</button>
              </Link>
              <Link>
                <button>2009</button>
              </Link>
            </div>
          </div>

          <div className="more-model">
            <select name="" id="">
              <option value=""> View More</option>
            </select>
          </div>
          <div className="submit-model">
            <Link>
              {" "}
              <button>Continue</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="model-year">
        <div className="model-btn">
          <Link>
            <button>2023</button>
          </Link>
          <Link>
            <button>2022</button>
          </Link>
          <Link>
            <button>2021</button>
          </Link>
          <Link>
            <button>2020</button>
          </Link>
          <Link>
            <button>2019</button>
          </Link>
          <Link>
            <button>2018</button>
          </Link>
          <Link>
            <button>2017</button>
          </Link>
          <Link>
            <button>2016</button>
          </Link>
          <Link>
            {" "}
            <button>2015</button>
          </Link>
          <Link>
            <button>2014</button>
          </Link>
          <Link>
            <button>2013</button>
          </Link>
          <Link>
            <button>2012</button>
          </Link>
          <Link>
            <button>2011</button>
          </Link>
          <Link>
            <button>2010</button>
          </Link>
          <Link>
            <button>2009</button>
          </Link>
        </div>
      </div>

      <div className="more-model">
        <select name="" id="">
          <option value=""> View More</option>
        </select>
      </div>
      <div className="submit-model">
        <Link to="/QuoteBike">
          {" "}
          <button>Continue</button>
        </Link>
      </div>

      <HowWork />
      <Footer />
    </>
  );
}

export default ModelYear;
