import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Blog = () => {
  return (
    <div>
      {/* <!-- Bread Crumb --> */}
      <Navbar />
      <div className="breadcrumb">
        <h2>Blog</h2>
      </div>

      {/* <!-- Blog List --> */}
      <div className="bloglist">
        <div className="container">
          <ul>
            <li>
              <div className="bloglistimg">
                <Link to="/BlogDetails">
                  <img
                    src={"/assets/img/blogimg01.jpg"}
                    alt="img"
                    className="img-fluid"
                  />
                </Link>
              </div>
              <div className="posttime">17 July, 2023</div>
              <div className="postby">
                <img src={"/assets/img/admin.png"} alt="img" /> Posted by Admin
              </div>
              <div className="postTitle">
                <a href="#">
                  Understanding and Addressing Teen Depression and Recognizing
                  Its Danger
                </a>
              </div>
            </li>

            <li>
              <div className="bloglistimg">
                <a href="/BlogDeails">
                  <Link to="/BlogDetails">
                    <img
                      src={"/assets/img/blogimg03.jpg"}
                      alt="img"
                      className="img-fluid"
                    />
                  </Link>
                </a>
              </div>
              <div className="posttime">17 July, 2023</div>
              <div className="postby">
                <img src={"/assets/img/admin.png"} alt="img" /> Posted by Admin
              </div>
              <div className="postTitle">
                <a href="./BlogDeails">
                  Understanding and Addressing Teen Depression and Recognizing
                  Its Danger
                </a>
              </div>
            </li>

            <li>
              <div className="bloglistimg">
                <a href="./BlogDeails">
                  <Link to="/BlogDetails">
                    <img
                      src={"/assets/img/blogimg02.jpg"}
                      alt="img"
                      className="img-fluid"
                    />
                  </Link>
                </a>
              </div>
              <div className="posttime">17 July, 2023</div>
              <div className="postby">
                <img src={"/assets/img/admin.png"} alt="img" /> Posted by Admin
              </div>
              <div className="postTitle">
                <a href="./BlogDeails">
                  Understanding and Addressing Teen Depression and Recognizing
                  Its Danger
                </a>
              </div>
            </li>

            <li>
              <div className="bloglistimg">
                <a href="./BlogDeails">
                  <Link to="/BlogDetails">
                    <img
                      src={"/assets/img/blogimg03.jpg"}
                      alt="img"
                      className="img-fluid"
                    />
                  </Link>
                </a>
              </div>
              <div className="posttime">17 July, 2023</div>
              <div className="postby">
                <img src={"/assets/img/admin.png"} alt="img" /> Posted by Admin
              </div>
              <div className="postTitle">
                <a href="./BlogDeails">
                  Understanding and Addressing Teen Depression and Recognizing
                  Its Danger
                </a>
              </div>
            </li>

            <li>
              <div className="bloglistimg">
                <a href="./BlogDeails">
                  <Link to="/BlogDetails">
                    <img
                      src={"/assets/img/blogimg02.jpg"}
                      alt="img"
                      className="img-fluid"
                    />
                  </Link>
                </a>
              </div>
              <div className="posttime">17 July, 2023</div>
              <div className="postby">
                <img src={"/assets/img/admin.png"} alt="img" /> Posted by Admin
              </div>
              <div className="postTitle">
                <a href="./BlogDeails">
                  Understanding and Addressing Teen Depression and Recognizing
                  Its Danger
                </a>
              </div>
            </li>

            <li>
              <div className="bloglistimg">
                <a href="./BlogDeails">
                  <Link to="/BlogDetails">
                    <img
                      src={"/assets/img/blogimg03.jpg"}
                      alt="img"
                      className="img-fluid"
                    />
                  </Link>
                </a>
              </div>
              <div className="posttime">17 July, 2023</div>
              <div className="postby">
                <img src={"/assets/img/admin.png"} alt="img" /> Posted by Admin
              </div>
              <div className="postTitle">
                <a href="./BlogDeails">
                  Understanding and Addressing Teen Depression and Recognizing
                  Its Danger
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
