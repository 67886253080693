import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const BlogDetails = () => {
  return (
    <div>
      <Navbar />
      {/* <!-- Bread Crumb --> */}
      <div className="breadcrumb">
        <h2>Blog Detail</h2>
      </div>

      {/* <!-- Blog Detail --> */}
      <div className="blogdetail">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="postdetailimg">
                <img
                  src={"/assets/img/blogimg01.jpg"}
                  alt="img"
                  className="img-fluid"
                />
              </div>
              <h1>
                Understanding and Addressing Teen Depression and Recognizing Its
                Danger
              </h1>
              <div className="postby">
                <img src={"/assets/img/admin.png"} alt="img" /> Posted by Admin
              </div>
              <div className="postdetaildes">
                <p>
                  Depression, often described as a “silent killer,” is a
                  prevalent mental health disorder that affects millions of
                  individuals worldwide. It transcends cultural, economic, and
                  social boundaries, impacting people of all ages and
                  backgrounds. While occasional sadness or feeling down is
                  normal, depression is different. It is a complex illness that
                  goes far beyond temporary emotional fluctuations.
                </p>
              </div>

              <div className="postdetaildes">
                <h2>Defining Depression</h2>
                <p>
                  Depression, often described as a “silent killer,” is a
                  prevalent mental health disorder that affects millions of
                  individuals worldwide. It transcends cultural, economic, and
                  social boundaries, impacting people of all ages and
                  backgrounds. While occasional sadness or feeling down is
                  normal, depression is different. It is a complex illness that
                  goes far beyond temporary emotional fluctuations.
                </p>
              </div>

              <div className="postdetaildes">
                <h2>Symptoms and Manifestations</h2>
                <p>
                  Depression can manifest in various ways, and its symptoms may
                  vary from person to person. Common signs of depression
                  include:
                </p>
                <ul>
                  <li>Persistent sadness or feelings of emptiness.</li>
                  <li>Loss of interest or pleasure in activities.</li>
                  <li>Fatigue, lethargy, or low energy levels.</li>
                  <li>Changes in appetite</li>
                  <li>weight changes (either increased or decreased).</li>
                  <li>Sleep disturbances and changes in sleep cycle.</li>
                  <li>
                    Difficulty concentrating, making decisions, or experiencing
                    memory problems.
                  </li>
                  <li>Irritability, restlessness, or agitation.</li>
                  <li>Feelings of worthlessness, guilt, or self-blame.</li>
                  <li>Recurrent thoughts of death or suicide.</li>
                </ul>
              </div>

              <div className="postdetaildes">
                <h2>What are the causes and risk factors of depression?</h2>
                <p>
                  Depression is a multifactorial disorder with various
                  contributing factors. While the exact cause is often elusive,
                  it can result from various factors including a combination of
                  genetic, biological, environmental, and psychological factors.
                  Some common risk factors for depression include:
                </p>
              </div>
              <div className="prepost">
                <a href="#">Previous Post</a>
              </div>
            </div>

            <div className="col-md-5">
              <div className="recentpost">
                <h2>Recent Posts</h2>
                <ul>
                  <li>
                    <img src="/assets/img/demo1.png" alt="" />
                    <a href="#">
                      Understanding and Addressing Teen Depression and
                      Recognizing Its Danger
                    </a>
                  </li>
                  <li>
                    <img src="/assets/img/demo2.png" alt="" />
                    <a href="#">
                      9 Helpful Tips for parents to help your child beat exam
                      stress
                    </a>
                  </li>
                  <li>
                    <img src="/assets/img/demo2.png" alt="" />
                    <a href="#">
                      Understanding and Addressing Teen Depression and
                      Recognizing Its Danger
                    </a>
                  </li>
                  <li>
                    <img src="/assets/img/demo3.png" alt="" />
                    <a href="#">
                      10 Natural Ways to Deworm Your Body Without Chemicals
                    </a>
                  </li>
                  <li>
                    <img src="/assets/img/demo1.png" alt="" />
                    <a href="#">
                      Top 10 Vitamin A Foods That Helps to Boost Your Immunity
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogDetails;
