import React from 'react'
import Navbar from './Navbar'
import HowWork from './HowWork'

import Footer from './Footer'

function Existing() {
  return (
  <>
  <Navbar/>
  <div className="main-Existing-page">

      <div className='existing-inf'>
      <div className="Existing">
            <div className="self-Existing">
                 <div className="self-Existing-img">
                  <img src="./assets/img/age-img.png" alt=""/>
                 </div>
                 <h1>Self</h1>
            </div>
            <h1>Existing illness</h1>
           

      </div>
      <div className="Existing-text">
           <p>Do you have an existinG To help us find plan that covers your condition illness or medical history?</p>
      </div>
      <div className="Existing-details">
            <button><a href="#">Diabates</a></button>
            <button><a href="#">Hypertension</a></button>
            <button><a href="#">Heart Ailments</a></button>
            <button><a href="#">BP</a></button>
            <button><a href="#">None of these</a></button>
                  
      </div>

    
    
<div className="submit-Existing">
      <button>
            Continue
            </button>
</div> 
</div>
</div>
{/* <HowWork/> */}

<Footer/>
  </>
  )
}

export default Existing