import React from 'react'
import './becomepos.css'
// import "./becomepos1.css";
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import ReactSelect from 'react-select'
import { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useEffect } from 'react'
import Navbar from '../../Navbar'
import Footer from '../../Footer'
const BecomePos = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    clearErrors
  } = useForm({ mode: 'onChange' })
  const {
    register: register1,
    formState: { errors: errors1 },
    handleSubmit: handleSubmit1,
    reset: reset1
  } = useForm({ mode: 'onChange' })

  const [modal, setModal] = useState(false)
  const toggleModal = () => setModal(!modal)
  const [activeStep, setActiveStep] = useState(1)
  const [stateData, setStateData] = useState([])
  const [districtData, setDistrictData] = useState([])
  const [cityData, setCityData] = useState([])
  const [pincodeData, setPincodeData] = useState([])
  const [bankData, setBankData] = useState([])
  const [rmData, setRmData] = useState()
  const [state, setState] = useState()
  const [district, setDistrict] = useState('')
  const [city, setCity] = useState('')
  const [pincode, setPincode] = useState('')
  const [bank, setBank] = useState('')
  const [rm, setRm] = useState('')
  const navigate = useNavigate()
  const [photo, setPhoto] = useState()
  const [signature, setSignature] = useState()
  const [aadharFront, setAadharFront] = useState()
  const [aadharBack, setAadharBack] = useState()
  const [panCard, setPanCard] = useState()
  const [qualificationImg, setQualificationImg] = useState()
  const [cheque, setCheque] = useState()
  const [other, setOther] = useState()
  const data = new FormData()
  const [allData, setAllData] = useState({})
  const [OTPModal, setOTPModal] = useState(false)
  const [otp, setOTP] = useState('')

  const getStates = async () => {
    const stateRes = await axios.get(
      'https://bpm.retroinsurance.in/api/v1/employee/Pincode/States',
      ''
    )

    if (stateRes?.data?.status) {
      let i = 0
      let data = stateRes?.data?.data
      let arr = []
      while (i < data.length) {
        let item = data[i]
        arr.push({
          label: item?.name,
          value: item?.id
        })
        i++
      }
      setStateData(arr)
    }
  }

  const getDistrict = async val => {
    setState(val)

    const districtRes = await axios.get(
      `https://bpm.retroinsurance.in/api/v1/employee/Pincode/District?state_id=${val}`,
      ''
    )

    if (districtRes?.data?.status) {
      let i = 0
      let data = districtRes?.data?.data
      let arr = []
      while (i < data.length) {
        let item = data[i]
        arr.push({
          label: item?.name,
          value: item?.id
        })
        i++
      }
      setDistrictData(arr)
    }
  }

  const getCity = async val => {
    setDistrict(val)

    const cityRes = await axios.get(
      `https://bpm.retroinsurance.in/api/v1/employee/Pincode/City?district_id=${val}`,
      ''
    )

    if (cityRes?.data?.status) {
      let i = 0
      let data = cityRes?.data?.data
      let arr = []
      while (i < data.length) {
        let item = data[i]
        arr.push({
          label: item?.name,
          value: item?.id
        })
        i++
      }
      setCityData(arr)
    }
  }

  const getPincode = async val => {
    setCity(val)

    const pincodeRes = await axios.get(
      `https://bpm.retroinsurance.in/api/v1/employee/Pincode/Pincode?city_id=${val}`,
      ''
    )

    if (pincodeRes?.data?.status) {
      let i = 0
      let data = pincodeRes?.data?.data
      let arr = []
      while (i < data.length) {
        let item = data[i]
        arr.push({
          label: item?.name,
          value: item?.id
        })
        i++
      }
      setPincodeData(arr)
    }
  }

  const getBank = async val => {
    const bankData = await axios.get(
      `https://bpm.retroinsurance.in/api/v1/employee/Banks`,
      ''
    )

    if (bankData?.data?.status) {
      let i = 0
      let data = bankData?.data?.data
      let arr = []
      while (i < data.length) {
        let item = data[i]
        arr.push({
          label: item?.name,
          value: item?.id
        })
        i++
      }
      setBankData(arr)
    }
  }

  const getRM = async val => {
    const rmRes = await axios.get(
      `https://bpm.retroinsurance.in/api/v1/my-posp/POSP/getAllRMs?id=0`,
      ''
    )

    if (rmRes?.data?.status) {
      let i = 0
      let data = rmRes?.data?.data
      let arr = []
      while (i < data.length) {
        let item = data[i]
        arr.push({
          label: item?.name,
          value: item?.id
        })
        i++
      }
      setRmData(arr)
    }
  }

  useEffect(() => {
    getStates()
    getBank()
    getRM()
  }, [])

  const handleValidation = async val => {
    data.append('name', val?.name)
    data.append('dob', val?.dob)
    data.append('gender', val?.gender)
    data.append('mobile_no', val?.mobile_no)
    data.append('qualification_name', val?.qualification_name)
    data.append('email', val?.email)
    data.append('aadhar_no', val?.aadhar_no)
    data.append('pan_no', val?.pan_no)
    data.append('id', 0)
    data.append('user_id', 0)
    data.append('current_uri', '')
    setAllData(val)

    const validationRes = await axios.post(
      'https://bpm.retroinsurance.in/api/v1/my-posp/POSP/validation',
      data
    )
    if (validationRes?.data?.status) {
      setActiveStep(activeStep + 1)
    } else {
      toast.error(validationRes?.data?.message)
    }
  }

  const handleSecondStepSubmit = val => {
    let addressDetails = {
      address_line_1: val?.address_line_1,
      address_line_2: val?.address_line_2,
      address_line_3: val?.address_line_3,
      state_id: state,
      district_id: district,
      city_id: city,
      pincode: pincode
    }
    setAllData({ ...allData, addressDetails })
    setActiveStep(3)
  }

  const handleThirdStepSubmit = val => {
    let bankingDetails = {
      bank_id: bank,
      branch_name: val?.branch_name,
      account_type: val?.account_type,
      account_no: val?.account_no,
      ifsc_code: val?.ifsc_code
    }
    setAllData({ ...allData, bankingDetails })
    setActiveStep(4)
  }

  const finalSubmit = async () => {
    data.append('name', allData?.name)
    data.append('dob', allData?.dob)
    data.append('gender', allData?.gender)
    data.append('mobile_no', allData?.mobile_no)
    data.append('qualification_name', allData?.qualification_name)
    data.append('email', allData?.email)
    data.append('aadhar_no', allData?.aadhar_no)
    data.append('pan_no', allData?.pan_no)
    data.append('id', 0)
    data.append('user_id', 0)
    data.append('current_uri', '')

    data.append('address_line_1', allData?.addressDetails?.address_line_1)
    data.append('address_line_2', allData?.addressDetails?.address_line_2)
    data.append('address_line_3', allData?.addressDetails?.address_line_3)
    data.append('state_id', state)
    data.append('district_id', district)
    data.append('city_id', city)
    data.append('pincode', pincode)

    data.append('bank_id', bank)
    data.append('branch_name', allData?.bankingDetails?.branch_name)
    data.append('account_type', allData?.bankingDetails?.account_type)
    data.append('account_no', allData?.bankingDetails?.account_no)
    data.append('ifsc_code', allData?.bankingDetails?.ifsc_code)

    data.append('photo', photo)
    data.append('signature', signature)
    data.append('aadhar_card_front', aadharFront)
    data.append('aadhar_card_back', aadharBack)
    data.append('pancard', panCard)
    data.append('qualification', qualificationImg)
    data.append('cheque', cheque)
    data.append('other', other)
    data.append('rm_id', rm)

    const finalRes = await axios.post(
      'https://bpm.retroinsurance.in/api/v1/my-posp/POSP/index',
      data
    )

    if (finalRes?.data?.status) {
      toast.success(finalRes?.data?.message)
      navigate('/')
    } else {
      toast.error(finalRes?.data?.message)
    }
  }

  const handleStepSelection = step => {
    setActiveStep(step)
  }

  const handleNextClick = () => {
    const currentTabId = `step${activeStep}`
    const currentTabPane = document.getElementById(currentTabId)
    if (currentTabPane) {
      currentTabPane.classList.remove('show', 'active')
    }

    setActiveStep(activeStep + 1)

    const nextTabId = `step${activeStep + 1}`
    const nextTabPane = document.getElementById(nextTabId)
    if (nextTabPane) {
      nextTabPane.classList.add('show', 'active')
    }
  }

  const handleOTP = async val => {
    let headers = {
      'Content-Type': 'multipart/form-data'
    }
    let obj = {
      username: val,
      password: '',
      otp: '',
      captcha_code: '',
      flag: 1,
      user_id: ''
    }
    if (val?.length === 10) {
      setOTPModal(true)
      const sendOtp = await axios.post('https://bpm.retroinsurance.in/api/v1/employee/User', obj, { headers: headers })
   
      if(sendOtp?.data?.status){

      }
    }
  }

  const submitOTP = () => {

  }
  return (
    <div>
      <Navbar />
      <section className='about-us-section'>
        {/*----- Blur set effect -----*/}
        <div className='breadcrumb'>
          <h2>Become a POS</h2>
          {/* <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <span>Become a POS</span>
            </li>
          </ul> */}
        </div>

        <div className='posreg mb-5'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <h2 className='posheading mt-5'>POS Registration</h2>
                <div className='posreg'>
                  <ul
                    className='nav nav-tabs formsteps formstepspos'
                    id='myTab1'
                    role='tablist'
                  >
                    <li className='nav-item' role='presentation'>
                      <button
                        className={`nav-link ${
                          activeStep === 1 ? 'active' : ''
                        }`}
                        onClick={() => handleStepSelection(1)}
                        id='step1-tab'
                        // data-bs-toggle="tab"
                        // data-bs-target="#step1"
                        type=''
                        role=''
                        aria-controls={'step1'}
                        aria-selected='activeStep === 1'
                      >
                        1
                      </button>
                      <span className='posnamemobile'>Personal Details</span>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <button
                        className={`nav-link ${
                          activeStep === 2 ? 'active' : ''
                        }`}
                        onClick={() => handleStepSelection(2)}
                        id='step2-tab'
                        data-bs-toggle='tab'
                        data-bs-target='#step2'
                        type=''
                        role=''
                        aria-controls='step2'
                        aria-selected={activeStep === 2}
                      >
                        2
                      </button>
                      <span className='addressposmobile'>Address Details</span>
                    </li>

                    <li className='nav-item' role='presentation'>
                      <button
                        className={`nav-link ${
                          activeStep === 3 ? 'active' : ''
                        }`}
                        onClick={() => handleStepSelection(3)}
                        id='step3-tab'
                        data-bs-toggle='tab'
                        data-bs-target='#step3'
                        type=''
                        role=''
                        aria-controls='step2'
                        aria-selected={activeStep === 3}
                      >
                        3
                      </button>
                      <span className='addressposmobile'>Banking Details</span>
                    </li>

                    <li className='nav-item' role='presentation'>
                      <button
                        className={`nav-link ${
                          activeStep === 4 ? 'active' : ''
                        }`}
                        onClick={() => handleStepSelection(4)}
                        id='step4-tab'
                        data-bs-toggle='tab'
                        data-bs-target='#step4'
                        type=''
                        role=''
                        aria-controls='step2'
                        aria-selected={activeStep === 4}
                      >
                        4
                      </button>
                      <span className='addressposmobile'>Documents</span>
                    </li>
                  </ul>

                  <div className='tab-content' id='myTabContent1'>
                    {/* First Step */}
                    {/* First Step */}
                    {activeStep === 1 && (
                      <form onSubmit={handleSubmit(handleValidation)}>
                        <div
                          className={`tab-pane fade ${
                            activeStep === 1 ? 'show active' : ''
                          }`}
                          id='step1'
                          role='tabpanel'
                          aria-labelledby='step1-tab'
                        >
                          <div className='posformbox'>
                            <ul>
                              {/* Fullname */}
                              <div className='adress_filed mt-4'>
                                <li>
                                  <label className='my-1'>
                                    Full Name
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='text'
                                    id='name'
                                    name='name'
                                    {...register('name', {
                                      required: 'Full Name is required',
                                      pattern: {
                                        value: /^[^\d]+$/,
                                        message:
                                          'Name should not contain numeric characters'
                                      }
                                    })}
                                    className='form-control fs-2'
                                    placeholder='Full Name'
                                  />
                                  {errors.name && (
                                    <p className='f-error fs-6 mx-2 text-danger'>
                                      {errors?.name?.message}
                                    </p>
                                  )}
                                </li>
                              </div>

                              {/* Date Of Birth */}

                              <div className='adress_filed mt-4'>
                                <li>
                                  <label className='my-1'>
                                    Date Of Birth
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='date'
                                    id='dob'
                                    name='dob'
                                    {...register('dob', {
                                      required: 'Date of Birth is required'
                                    })}
                                    className='form-control fs-2'
                                    placeholder='Date Of Birth'
                                  />
                                  <p className='f-error fs-6 mx-2 text-danger'>
                                    {errors?.dob && errors?.dob?.message}
                                  </p>
                                </li>
                              </div>

                              {/* Gender */}
                              <div className='adress_filed mt-4'>
                                <li>
                                  <label className='my-1'>
                                    Gender
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <select
                                    className='form-control fs-2'
                                    id='gender'
                                    name='gender'
                                    {...register('gender', {
                                      required: 'Gender is Required'
                                    })}
                                  >
                                    <option key='Male' value='Male'>
                                      Male
                                    </option>
                                    <option key='Female' value='Female'>
                                      Female
                                    </option>
                                  </select>
                                  <p className='f-error fs-6 mx-2 text-danger'>
                                    {errors?.gender && errors?.gender?.message}
                                  </p>
                                </li>
                              </div>

                              {/* Mobile */}

                              <div className='adress_filed mt-4'>
                                <li>
                                  <label className='my-1 mx-1'>
                                    Mobile No
                                    <span
                                      className='text-danger'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='number'
                                    id='mobile_no'
                                    name='mobile_no'
                                    {...register('mobile_no', {
                                      required: 'Mobile Number is required',
                                      pattern: {
                                        value:
                                          /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/,
                                        message: 'Invalid Mobile Number'
                                      },
                                      onChange: e => handleOTP(e?.target?.value)
                                    })}
                                    maxLength='10'
                                    className='form-control fs-2'
                                    placeholder='Mobile No.'
                                  />
                                  {
                                    <p className='f-error fs-6 mx-2 text-danger'>
                                      {errors?.mobile_no &&
                                        errors?.mobile_no?.message}
                                    </p>
                                  }
                                </li>
                              </div>

                              {/* Qualifications */}
                              <div className='adress_filed mt-4'>
                                <li>
                                  <label className='my-1'>
                                    Qualification
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <select
                                    className='form-control fs-2'
                                    id='qualification_name'
                                    name='qualification_name'
                                    {...register('qualification_name', {
                                      required: 'Qualification is Required'
                                    })}
                                  >
                                    <option key='10th' value='10th'>
                                      10th
                                    </option>
                                    <option key='12th' value='12th'>
                                      12th
                                    </option>
                                    <option key='Graduation' value='Graduation'>
                                      Graduation
                                    </option>
                                    <option
                                      key='Post Graduation'
                                      value='Post Graduation'
                                    >
                                      Post Graduation
                                    </option>
                                  </select>
                                  <p className='f-error fs-6 mx-2 text-danger'>
                                    {errors?.qualification_name &&
                                      errors?.qualification_name?.message}
                                  </p>
                                </li>
                              </div>

                              {/* Email */}
                              <div className='adress_filed mt-4'>
                                <li>
                                  <label className='my-1'>
                                    Email
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='text'
                                    id='email'
                                    name='email'
                                    {...register('email', {
                                      required: 'Email is required',
                                      pattern: {
                                        value:
                                          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: 'Invalid email address'
                                      }
                                    })}
                                    className='form-control fs-2'
                                    placeholder='Email Id'
                                  />
                                  {errors.email && (
                                    <p className='f-error fs-6 mx-2 text-danger'>
                                      {errors?.email && errors?.email?.message}
                                    </p>
                                  )}
                                </li>
                              </div>

                              {/* Adhar Card */}
                              <div className='adress_filed mt-4'>
                                <li>
                                  <label className='my-1'>
                                    Aadhar
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='number'
                                    id='aadhar_no'
                                    name='aadhar_no'
                                    maxLength={12}
                                    {...register('aadhar_no', {
                                      required: 'Aadhar is required'
                                    })}
                                    className='form-control fs-2'
                                    placeholder='Aadhar'
                                  />
                                  {errors.aadhar_no && (
                                    <p className='f-error fs-6 mx-2 text-danger'>
                                      {errors?.aadhar_no &&
                                        errors?.aadhar_no?.message}
                                    </p>
                                  )}
                                </li>
                              </div>

                              {/* Pan Card */}
                              <div className='adress_filed mt-4'>
                                <li>
                                  <label className='my-1'>
                                    PAN
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='text'
                                    id='pan_no'
                                    name='pan_no'
                                    maxLength={10}
                                    {...register('pan_no', {
                                      required: 'Pan no is required',
                                      pattern: {
                                        value: /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
                                        message: 'Invalid Pan Number'
                                      }
                                    })}
                                    className='form-control fs-2'
                                    placeholder='PAN'
                                  />
                                  {errors.pan_no && (
                                    <p className='f-error fs-6 mx-2 text-danger'>
                                      {errors?.pan_no &&
                                        errors?.pan_no?.message}
                                    </p>
                                  )}
                                </li>
                              </div>
                            </ul>
                          </div>
                          <div className='text-center mt-5'>
                            <button type='submit' className='btn btn-primary'>
                              Next
                            </button>
                          </div>
                        </div>
                      </form>
                    )}

                    {/* Second Step */}
                    {activeStep === 2 && (
                      <form onSubmit={handleSubmit(handleSecondStepSubmit)}>
                        <div
                          className={`tab-pane fade ${
                            activeStep === 2 ? 'show active' : ''
                          }`}
                          id='step2'
                          role='tabpanel'
                          aria-labelledby='step1-tab'
                        >
                          <div className='posformbox'>
                            <ul>
                              {/* Addreess line 1 */}
                              <div className='adress_filed mt-4'>
                                <li>
                                  <label className='my-1'>
                                    Address Line 1
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='text'
                                    id='address_line_1'
                                    name='address_line_1'
                                    {...register('address_line_1', {
                                      required: 'Address Line 1 is required'
                                    })}
                                    className='form-control fs-2'
                                    placeholder='Address Line 1'
                                  />
                                  {errors.address_line_1 && (
                                    <p className='f-error fs-6 mx-2 text-danger'>
                                      {errors?.address_line_1?.message}
                                    </p>
                                  )}
                                </li>
                              </div>

                              {/* Addreess line 2 */}
                              <div className='adress_filed mt-4'>
                                <li>
                                  <label className='my-1'>
                                    Address Line 2
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='text'
                                    id='address_line_2'
                                    name='address_line_2'
                                    {...register('address_line_2', {
                                      required: 'Address Line 2 is required'
                                    })}
                                    className='form-control fs-2'
                                    placeholder='Address Line 2'
                                  />
                                  {errors.address_line_2 && (
                                    <p className='f-error fs-6 mx-2 text-danger'>
                                      {errors?.address_line_2?.message}
                                    </p>
                                  )}
                                </li>
                              </div>

                              {/* Addreess line 3 */}
                              <div className='adress_filed mt-4'>
                                <li>
                                  <label className='my-1'>
                                    Address Line 3
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='text'
                                    id='address_line_3'
                                    name='address_line_3'
                                    {...register('address_line_3', {
                                      required: 'Address Line 3 is required'
                                    })}
                                    className='form-control fs-2'
                                    placeholder='Address Line 3'
                                  />
                                  {errors.address_line_3 && (
                                    <p className='f-error fs-6 mx-2 text-danger'>
                                      {errors?.address_line_3?.message}
                                    </p>
                                  )}
                                </li>
                              </div>

                              {/* State */}
                              <div className='adress_filed mt-4'>
                                <li>
                                  {/* <label className='my-1'>
                                    State
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label> */}
                                  <ReactSelect
                                    options={stateData}
                                    name='state'
                                    placeholder='Select State'
                                    onChange={val => getDistrict(val.value)}
                                  />
                                  {/* <p className='f-error fs-6 mx-2 text-danger'>
                                    {errors?.qualification_name &&
                                      errors?.qualification_name?.message}
                                  </p> */}
                                </li>
                              </div>

                              {/* City */}
                              <div className='adress_filed mt-4'>
                                <li>
                                  {/* <label className='my-1'>
                                    District
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label> */}
                                  <ReactSelect
                                    options={districtData}
                                    name='district'
                                    placeholder='Select District'
                                    onChange={val => getCity(val.value)}
                                  />
                                  {/* <p className='f-error fs-6 mx-2 text-danger'>
                                    {errors?.qualification_name &&
                                      errors?.qualification_name?.message}
                                  </p> */}
                                </li>
                              </div>

                              {/* District */}
                              <div className='adress_filed mt-4'>
                                <li>
                                  {/* <label className='my-1'>
                                    City
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label> */}
                                  <ReactSelect
                                    options={cityData}
                                    name='city'
                                    placeholder='Select City'
                                    onChange={val => getPincode(val.value)}
                                  />
                                  {/* <p className='f-error fs-6 mx-2 text-danger'>
                                    {errors?.qualification_name &&
                                      errors?.qualification_name?.message}
                                  </p> */}
                                </li>
                              </div>

                              {/* Pincode */}
                              <div className='adress_filed mt-4'>
                                <li>
                                  {/* <label className='my-1'>
                                    Pincode
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label> */}
                                  <ReactSelect
                                    options={pincodeData}
                                    name='pincode'
                                    placeholder='Select Pincode'
                                    onChange={val => setPincode(val.value)}
                                  />
                                  {/* <p className='f-error fs-6 mx-2 text-danger'>
                                    {errors?.qualification_name &&
                                      errors?.qualification_name?.message}
                                  </p> */}
                                </li>
                              </div>
                            </ul>
                          </div>
                          <div className='text-center mt-5'>
                            <button type='submit' className='btn btn-primary'>
                              Next
                            </button>
                          </div>
                        </div>
                      </form>
                    )}

                    {/* Third Step */}
                    {activeStep === 3 && (
                      <form onSubmit={handleSubmit(handleThirdStepSubmit)}>
                        <div
                          className={`tab-pane fade ${
                            activeStep === 3 ? 'show active' : ''
                          }`}
                          id='step3'
                          role='tabpanel'
                          aria-labelledby='step3-tab'
                        >
                          <div className='posformbox'>
                            <ul>
                              {/* Bank */}
                              <div className='adress_filed mt-4'>
                                <li>
                                  {/* <label className='my-1'>
                                    Select Bank
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label> */}
                                  <ReactSelect
                                    options={bankData}
                                    name='bank'
                                    placeholder='Select Bank'
                                    onChange={val => setBank(val.value)}
                                  />
                                  {/* <p className='f-error fs-6 mx-2 text-danger'>
                                    {errors?.qualification_name &&
                                      errors?.qualification_name?.message}
                                  </p> */}
                                </li>
                              </div>

                              {/* Branch Name */}
                              <div className='adress_filed mt-4'>
                                <li>
                                  <label className='my-1'>
                                    Branch Name
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='text'
                                    id='branch_name'
                                    name='branch_name'
                                    {...register('branch_name', {
                                      required: 'Branch Name is required'
                                    })}
                                    className='form-control fs-2'
                                    placeholder='Branch Name'
                                  />
                                  {errors.branch_name && (
                                    <p className='f-error fs-6 mx-2 text-danger'>
                                      {errors?.branch_name?.message}
                                    </p>
                                  )}
                                </li>
                              </div>

                              {/* Account Type */}
                              <div className='adress_filed mt-4'>
                                <li>
                                  <label className='my-1'>
                                    Account Type
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <select
                                    className='form-control fs-2'
                                    id='account_type'
                                    name='account_type'
                                    {...register('account_type', {
                                      required: 'Account Type is Required'
                                    })}
                                  >
                                    <option key='Saving' value='Saving'>
                                      Saving
                                    </option>
                                    <option key='Current' value='Current'>
                                      Current
                                    </option>
                                  </select>
                                  <p className='f-error fs-6 mx-2 text-danger'>
                                    {errors?.account_type &&
                                      errors?.account_type?.message}
                                  </p>
                                </li>
                              </div>

                              {/* Account Number */}
                              <div className='adress_filed mt-4'>
                                <li>
                                  <label className='my-1'>
                                    Account Number
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='number'
                                    id='account_no'
                                    name='account_no'
                                    {...register('account_no', {
                                      required: 'Account Number is required'
                                    })}
                                    className='form-control fs-2'
                                    placeholder='Account Number'
                                  />
                                  <p className='f-error fs-6 mx-2 text-danger'>
                                    {errors?.account_no &&
                                      errors?.account_no?.message}
                                  </p>
                                </li>
                              </div>

                              {/* IFSC */}
                              <div className='adress_filed mt-4'>
                                <li>
                                  <label className='my-1'>
                                    IFSC
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='text'
                                    id='ifsc_code'
                                    name='ifsc_code'
                                    {...register('ifsc_code', {
                                      required: 'IFSC is required'
                                    })}
                                    className='form-control fs-2'
                                    placeholder='IFSC'
                                  />
                                  <p className='f-error fs-6 mx-2 text-danger'>
                                    {errors?.ifsc_code &&
                                      errors?.ifsc_code?.message}
                                  </p>
                                </li>
                              </div>
                            </ul>
                          </div>
                          <div className='text-center mt-5'>
                            <button type='submit' className='btn btn-primary'>
                              Next
                            </button>
                          </div>
                        </div>
                      </form>
                    )}

                    {/* Fourth Step */}
                    {activeStep === 4 && (
                      <form onSubmit={handleSubmit(finalSubmit)}>
                        <div
                          className={`tab-pane fade ${
                            activeStep === 4 ? 'show active' : ''
                          }`}
                          id='step4'
                          role='tabpanel'
                          aria-labelledby='step3-tab'
                        >
                          <div className='posformbox'>
                            <ul>
                              {/* Photo */}
                              <div className='adress_filed mt-5'>
                                <li>
                                  <label className='my-1'>
                                    Photo
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='file'
                                    id='photo'
                                    name='photo'
                                    {...register('photo', {
                                      onChange: e =>
                                        setPhoto(e?.target?.files[0])
                                    })}
                                    className='form-control fs-2'
                                    placeholder=''
                                  />
                                  <p className='f-error fs-6 mx-2 text-danger'>
                                    {errors?.photo && errors?.photo?.message}
                                  </p>
                                </li>
                              </div>

                              {/* Signature */}
                              <div className='adress_filed mt-5'>
                                <li>
                                  <label className='my-1'>
                                    Signature
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='file'
                                    id='signature'
                                    name='signature'
                                    {...register('signature', {
                                      onChange: e =>
                                        setSignature(e?.target?.files[0])
                                    })}
                                    className='form-control fs-2'
                                    placeholder=''
                                  />
                                  <p className='f-error fs-6 mx-2 text-danger'>
                                    {errors?.signature &&
                                      errors?.signature?.message}
                                  </p>
                                </li>
                              </div>

                              {/* Aadhar Card Front */}
                              <div className='adress_filed mt-5'>
                                <li>
                                  <label className='my-1'>
                                    Aadhar Card Front
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='file'
                                    id='aadhar_card_front'
                                    name='aadhar_card_front'
                                    {...register('aadhar_card_front', {
                                      onChange: e =>
                                        setAadharFront(e?.target?.files[0])
                                    })}
                                    className='form-control fs-2'
                                    placeholder=''
                                  />
                                  <p className='f-error fs-6 mx-2 text-danger'>
                                    {errors?.aadhar_card_front &&
                                      errors?.aadhar_card_front?.message}
                                  </p>
                                </li>
                              </div>

                              {/* Aadhar Card Back */}
                              <div className='adress_filed mt-5'>
                                <li>
                                  <label className='my-1'>
                                    Aadhar Card Back
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='file'
                                    id='aadhar_card_back'
                                    name='aadhar_card_back'
                                    {...register('aadhar_card_back', {
                                      onChange: e =>
                                        setAadharBack(e?.target?.files[0])
                                    })}
                                    className='form-control fs-2'
                                    placeholder=''
                                  />
                                  <p className='f-error fs-6 mx-2 text-danger'>
                                    {errors?.aadhar_card_back &&
                                      errors?.aadhar_card_back?.message}
                                  </p>
                                </li>
                              </div>

                              {/* Pan Card */}
                              <div className='adress_filed mt-5'>
                                <li>
                                  <label className='my-1'>
                                    PAN Card
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='file'
                                    id='pancard'
                                    name='pancard'
                                    {...register('pancard', {
                                      onChange: e =>
                                        setPanCard(e?.target?.files[0])
                                    })}
                                    className='form-control fs-2'
                                    placeholder=''
                                  />
                                  <p className='f-error fs-6 mx-2 text-danger'>
                                    {errors?.pancard &&
                                      errors?.pancard?.message}
                                  </p>
                                </li>
                              </div>

                              {/* 10th / 12th / Graduation */}
                              <div className='adress_filed mt-5'>
                                <li>
                                  <label className='my-1'>
                                    10th/12th/Graduation
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='file'
                                    id='qualification'
                                    name='qualification'
                                    {...register('qualification', {
                                      onChange: e =>
                                        setQualificationImg(e?.target?.files[0])
                                    })}
                                    className='form-control fs-2'
                                    placeholder=''
                                  />
                                  <p className='f-error fs-6 mx-2 text-danger'>
                                    {errors?.qualification &&
                                      errors?.qualification?.message}
                                  </p>
                                </li>
                              </div>

                              {/* Cheque */}
                              <div className='adress_filed mt-5'>
                                <li>
                                  <label className='my-1'>
                                    Cheque
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='file'
                                    id='cheque'
                                    name='cheque'
                                    {...register('cheque', {
                                      onChange: e =>
                                        setCheque(e?.target?.files[0])
                                    })}
                                    className='form-control fs-2'
                                    placeholder=''
                                  />
                                  <p className='f-error fs-6 mx-2 text-danger'>
                                    {errors?.cheque && errors?.cheque?.message}
                                  </p>
                                </li>
                              </div>

                              {/* Other */}
                              <div className='adress_filed mt-5'>
                                <li>
                                  <label className='my-1'>
                                    Other
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='file'
                                    id='other'
                                    name='other'
                                    {...register('other', {
                                      onChange: e =>
                                        setOther(e?.target?.files[0])
                                    })}
                                    className='form-control fs-2'
                                    placeholder=''
                                  />
                                  <p className='f-error fs-6 mx-2 text-danger'>
                                    {errors?.other && errors?.other?.message}
                                  </p>
                                </li>
                              </div>

                              {/* RM */}
                              <div className='adress_filed mt-5'>
                                <li>
                                  {/* <label className='my-1'>
                                    Select RM
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label> */}
                                  <ReactSelect
                                    options={rmData}
                                    name='rm'
                                    placeholder='Select RM'
                                    onChange={val => setRm(val.value)}
                                  />
                                  {/* <p className='f-error fs-6 mx-2 text-danger'>
                                    {errors?.qualification_name &&
                                      errors?.qualification_name?.message}
                                  </p> */}
                                </li>
                              </div>
                            </ul>
                          </div>

                          <div className='text-center mt-5'>
                            <button type='submit' className='btn btn-primary'>
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    )}

                    {/* <form onSubmit={handleSubmit1(onSubmitPos)}>
                      {activeStep === 2 && (
                        <div
                          className={`tab-pane fade ${
                            activeStep === 2 ? 'show active' : ''
                          }`}
                          id='step2'
                          role='tabpanel'
                          aria-labelledby='step2-tab'
                        >
                          <div className='posformbox mx-auto'>
                            <ul className='twoinsuranceform row m-0'>
                              <div className='col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4'>
                                <li className='position-relative mb-1'>
                                  <label>Full Name</label>
                                  <input
                                    type='text'
                                    value={userData?.name}
                                    readOnly
                                    name='Fullname'
                                    className='form-control fs-2'
                                  />
                                </li>
                              </div>
                              <div className='col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4'>
                                <li className='position-relative mb-1'>
                                  <label>Mobile Number</label>
                                  <input
                                    type='text'
                                    value={userData?.phone}
                                    readOnly
                                    name='MobileNumber'
                                    className='form-control fs-2 '
                                  />
                                </li>
                              </div>
                              <div className='col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4'>
                                <li className='position-relative mb-1'>
                                  <label>Email</label>
                                  <input
                                    type='text'
                                    value={userData?.email}
                                    readOnly
                                    name='Email'
                                    className='form-control fs-2'
                                  />
                                </li>
                              </div>
                              <div className='col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4'>
                                <li className='position-relative mb-1'>
                                  <label className='my-1'>
                                    Aadhar Number
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='text'
                                    name='AadharNumber'
                                    {...register1('aadhar_number', {
                                      required: 'Aadhar is required',
                                      pattern: {
                                        value:
                                          /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
                                        message: 'Invalid Aadhar Number'
                                      }
                                    })}
                                    maxLength={12}
                                    className='form-control fs-2'
                                    placeholder='Aadhar Number'
                                  />
                                  <span
                                    className='text-danger fs-4 mx-1'
                                    style={{
                                      position: 'relative',
                                      bottom: '2rem'
                                    }}
                                  >
                                    {errors1?.aadhar_number?.message}
                                  </span>
                                </li>
                              </div>

                              <div className='col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4'>
                                <li className='position-relative mb-1'>
                                  <label className='my-1'>
                                    Pan Number
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='text'
                                    name='PanNumber'
                                    {...register1('pan_number', {
                                      required: 'Pan is required',
                                      pattern: {
                                        value: /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/,
                                        message: 'Invalid Pan Number'
                                      }
                                    })}
                                    maxLength={10}
                                    className='form-control fs-2 text-uppercase'
                                    placeholder='Pan Number'
                                  />
                                  <span
                                    className='text-danger fs-4 mx-1'
                                    style={{
                                      position: 'relative',
                                      bottom: '2rem'
                                    }}
                                  >
                                    {errors1?.pan_number?.message}
                                  </span>
                                </li>
                              </div>

                              <div className='col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4'>
                                <li className='position-relative mb-1'>
                                  <label className='my-1'>
                                    Address{' '}
                                    <span
                                      className='text-danger'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='text'
                                    name='Address'
                                    {...register1('address', {
                                      required: 'Address is required'
                                    })}
                                    // style={{ height: "100px" }}
                                    className='form-control fs-2'
                                    placeholder='Address'
                                  />
                                  <span
                                    className='text-danger fs-4 mx-1'
                                    style={{
                                      position: 'relative',
                                      bottom: '2rem'
                                    }}
                                  >
                                    {errors1?.address?.message}
                                  </span>
                                </li>
                              </div>

                              <div
                                className='col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4'
                                style={{ marginBottom: '20px' }}
                              >
                                <li className='position-relative mb-1'>
                                  <label className='my-1'>
                                    State
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <select
                                    className='form-modal fs-2 text-secondary'
                                    {...register1('stateId', {
                                      required: 'State is required'
                                    })}
                                    onChange={e => {
                                      getDistricts(e.target.value)
                                      setValue('stateId', e.target.value, {
                                        shouldValidate: true
                                      })
                                    }}
                                    id='casetype1as'
                                  >
                                    <option
                                      className='d-none'
                                      value=''
                                      defaultValue
                                    >
                                      Select State
                                    </option>
                                    {stateData &&
                                      stateData.length > 0 &&
                                      stateData.map((item, i) => (
                                        <option value={item.id} key={i}>
                                          {item.state}
                                        </option>
                                      ))}
                                  </select>

                                  <span className='text-danger fs-4 mx-1'>
                                    {errors1?.stateId?.message}
                                  </span>
                                </li>
                              </div>

                              <div
                                className='col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4'
                                style={{ marginBottom: '20px' }}
                              >
                                <li className='position-relative mb-1'>
                                  <label className='my-1'>
                                    District{' '}
                                    <span
                                      className='text-danger'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <select
                                    className='form-modal fs-2 text-secondary'
                                    {...register1('districtId', {
                                      required: 'District is required'
                                    })}
                                    id='casetype1as'
                                    onChange={e => {
                                      getBranches(e.target.value)
                                      setValue('districtId', e.target.value, {
                                        shouldValidate: true
                                      })
                                    }}
                                  >
                                    <option
                                      className='d-none'
                                      value=''
                                      selected
                                    >
                                      Select District
                                    </option>
                                    {districtData &&
                                      districtData.length > 0 &&
                                      districtData.map((item, i) => (
                                        <option value={item.id} key={i}>
                                          {item.district}
                                        </option>
                                      ))}
                                  </select>
                                  {errors1?.districtId && (
                                    <span className='text-danger fs-4 mx-1'>
                                      {errors1?.districtId?.message}
                                    </span>
                                  )}
                                </li>
                              </div>

                              <div
                                className='col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4'
                                style={{ marginBottom: '20px' }}
                              >
                                <li className='position-relative mb-1'>
                                  <label className='my-1'>
                                    Select City{' '}
                                    <span
                                      className='text-danger'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <select
                                    className='form-modal fs-2 text-secondary'
                                    {...register1('branchId', {
                                      required: 'Branch is required'
                                    })}
                                    id='casetype1as'
                                  >
                                    <option
                                      className='d-none'
                                      value=''
                                      selected
                                    >
                                      Select Branch
                                    </option>
                                    {branchData &&
                                      branchData.length > 0 &&
                                      branchData.map((item, i) => (
                                        <option value={item.id} key={i}>
                                          {item.name}
                                        </option>
                                      ))}
                                  </select>
                                  <span className='text-danger fs-4 mx-1'>
                                    {errors1?.branchId?.message}
                                  </span>
                                </li>
                              </div>

                              <div
                                className='col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4'
                                style={{ marginBottom: '20px' }}
                              >
                                <li className='position-relative mb-1'>
                                  <label className='my-1'>
                                    Bank Name
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <select
                                    className='form-modal fs-2 text-secondary'
                                    {...register1('bank_name', {
                                      required: 'Bank Name is required'
                                    })}
                                    id='casetype1as'
                                  >
                                    <option
                                      className='d-none'
                                      value=''
                                      selected
                                    >
                                      Select Bank Name
                                    </option>
                                    {BankNames.map((item, i) => (
                                      <option value={item} key={i}>
                                        {item}
                                      </option>
                                    ))}
                                  </select>
                                  <span className='text-danger fs-4 mx-1'>
                                    {errors1?.bank_name?.message}
                                  </span>
                                </li>
                              </div>

                              <div className='col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4'>
                                <li className='position-relative mb-1'>
                                  <label className='my-1'>
                                    Account Number{' '}
                                    <span
                                      className='text-danger'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='text'
                                    maxLength={19}
                                    {...register1('accountNumber', {
                                      required: 'Account Number is required',
                                      pattern: {
                                        value: /^[0-9]{9,18}$/,
                                        message: 'Invalid Account Number'
                                      }
                                    })}
                                    className='form-control fs-2'
                                    id='Account1'
                                    placeholder='Account Number'
                                  />
                                  <span
                                    className='text-danger fs-4 mx-1'
                                    style={{
                                      position: 'relative',
                                      bottom: '2rem'
                                    }}
                                  >
                                    {errors1?.accountNumber?.message}
                                  </span>
                                </li>
                              </div>

                              <div className='col-lg-6 col-md-6 mt-0 ps-lg-0 pb-4'>
                                <li className='position-relative mb-1'>
                                  <label className='my-1'>
                                    IFSC Code
                                    <span
                                      className='text-danger mx-1'
                                      style={{
                                        position: 'relative',
                                        top: '0.3rem'
                                      }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type='text'
                                    {...register1('ifsc', {
                                      required: 'IFSC Code is required',
                                      pattern: {
                                        value: /^[A-Za-z]{4}[0-9]{7}$/,
                                        message: 'Invalid IFSC Code'
                                      }
                                    })}
                                    className='form-control fs-2 text-uppercase'
                                    id='ifsc'
                                    placeholder='IFSC Code'
                                    maxLength={11}
                                  />
                                  <span
                                    className='text-danger fs-4 mx-1'
                                    style={{
                                      position: 'relative',
                                      bottom: '2rem'
                                    }}
                                  >
                                    {errors1?.ifsc?.message}
                                  </span>
                                </li>
                              </div>

                              <div className='d-flex flex-wrap gap-5'>
                                <li>
                                  <div className='file-wrapper pancard'>
                                    <span className='docimg'>
                                      {images.profile_picture ? (
                                        <>
                                          {images.profile_picture && (
                                            <>
                                              <img
                                                src={URL.createObjectURL(
                                                  images.profile_picture
                                                )}
                                                className='w-100 mt-3 mb-4 docimg'
                                              />
                                              <p
                                                className='remove-btn fs-4'
                                                onClick={() =>
                                                  removeImage('profile_picture')
                                                }
                                              >
                                                x
                                              </p>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <img
                                          src={
                                            '/assetss/images/profilephoto.png'
                                          }
                                          alt='img'
                                          className='img-fluid'
                                        />
                                      )}{' '}
                                      <strong>Profile Picture</strong>
                                    </span>
                                    <input
                                      type='file'
                                      name='profile_picture'
                                      {...register1('profile_picture', {
                                        required: 'Photo is requried',
                                        onChange: e => handleUploadFile(e)
                                      })}
                                      accept='image/*'
                                    />
                                    <div className='close-btn'>×</div>
                                  </div>
                                  <span className='text-danger fs-4 mx-1'>
                                    {errors1?.profile_picture?.message}
                                  </span>
                                </li>

                                <li>
                                  <div className='file-wrapper '>
                                    <span className='docimg'>
                                      {images.aadhar_front ? (
                                        <>
                                          {images.aadhar_front && (
                                            <>
                                              <img
                                                src={URL.createObjectURL(
                                                  images.aadhar_front
                                                )}
                                                className='w-100 mt-3 mb-4 docimg'
                                              />
                                              <p
                                                className='remove-btn-aadhar fs-4 '
                                                onClick={() =>
                                                  removeImage('aadhar_front')
                                                }
                                              >
                                                x
                                              </p>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <img
                                          src={
                                            '/assetss/images/adharcardimg.png'
                                          }
                                          alt='img'
                                          className='img-fluid'
                                        />
                                      )}{' '}
                                      <strong>
                                        Aadhaar Card <span>( Front )</span>
                                      </strong>
                                    </span>
                                    <input
                                      type='file'
                                      name='aadhar_front'
                                      {...register1('aadhar_front', {
                                        required: 'Aadhar Front is required',
                                        onChange: e => handleUploadFile(e)
                                      })}
                                      accept='image/*'
                                    />
                                    <div className='close-btn'>×</div>
                                  </div>
                                  <span className='text-danger fs-4 mx-1 '>
                                    {errors1?.aadhar_front?.message}
                                  </span>
                                </li>

                                <li>
                                  <div className='file-wrapper'>
                                    <span className='docimg'>
                                      {images.aadhar_back ? (
                                        <>
                                          {images.aadhar_back && (
                                            <>
                                              <img
                                                src={URL.createObjectURL(
                                                  images.aadhar_back
                                                )}
                                                className='w-100 mt-3 mb-4 docimg'
                                              />
                                              <p
                                                className='remove-btn-aadhar fs-4'
                                                onClick={() =>
                                                  removeImage('aadhar_back')
                                                }
                                              >
                                                x
                                              </p>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <img
                                          src={
                                            '/assetss/images/adharcardimg.png'
                                          }
                                          alt='img'
                                          className='img-fluid'
                                        />
                                      )}{' '}
                                      <strong>
                                        Aadhaar Card <span>( Back )</span>
                                      </strong>
                                    </span>
                                    <input
                                      type='file'
                                      name='aadhar_back'
                                      {...register1('aadhar_back', {
                                        required: 'Aadhar Back is required',
                                        onChange: e => handleUploadFile(e)
                                      })}
                                      accept='image/*'
                                    />
                                    <div className='close-btn'>×</div>
                                  </div>
                                  <span className='text-danger fs-4 mx-1'>
                                    {errors1?.aadhar_back?.message}
                                  </span>
                                </li>

                                <li>
                                  <div className='file-wrapper'>
                                    <span className='docimg'>
                                      {images.marksheet_image ? (
                                        <>
                                          {images.marksheet_image && (
                                            <>
                                              <img
                                                src={URL.createObjectURL(
                                                  images.marksheet_image
                                                )}
                                                className='w-100 mt-3 mb-4 docimg'
                                              />
                                              <p
                                                className='remove-btn fs-4'
                                                onClick={() =>
                                                  removeImage('marksheet_image')
                                                }
                                              >
                                                x
                                              </p>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <img
                                          src={
                                            '/assetss/images/qualification.png'
                                          }
                                          alt='img'
                                          className='img-fluid'
                                        />
                                      )}{' '}
                                      <strong>Qualification</strong>
                                    </span>
                                    <input
                                      type='file'
                                      {...register1('marksheet_image', {
                                        required: 'Marksheet is required',
                                        onChange: e => handleUploadFile(e)
                                      })}
                                      name='marksheet_image'
                                      accept='image/*'
                                    />
                                    <div className='close-btn'>×</div>
                                  </div>
                                  <span className='text-danger fs-4 mx-1'>
                                    {errors1?.marksheet_image?.message}
                                  </span>
                                </li>

                                <li>
                                  <div className='file-wrapper'>
                                    <span className='docimg'>
                                      {images.passbook_cheque ? (
                                        <>
                                          {images.passbook_cheque && (
                                            <>
                                              <img
                                                src={URL.createObjectURL(
                                                  images.passbook_cheque
                                                )}
                                                className='w-100 mt-3 mb-4 docimg'
                                              />
                                              <p
                                                className='remove-btn fs-4'
                                                onClick={() =>
                                                  removeImage('passbook_cheque')
                                                }
                                              >
                                                x
                                              </p>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <img
                                          src={'/assetss/images/cheque.png'}
                                          alt='img'
                                          className='img-fluid'
                                        />
                                      )}{' '}
                                      <strong>Cheque</strong>
                                    </span>
                                    <input
                                      type='file'
                                      name='passbook-cheque'
                                      {...register1('passbook_cheque', {
                                        required: 'Cheque Image is required',
                                        onChange: e => handleUploadFile(e)
                                      })}
                                      accept='image/*'
                                    />
                                    <div className='close-btn'>×</div>
                                  </div>
                                  <span className='text-danger fs-4 mx-1 '>
                                    {errors1?.passbook_cheque?.message}
                                  </span>
                                </li>

                                <li>
                                  <div className='file-wrapper'>
                                    <span className='docimg'>
                                      {images.pan_image ? (
                                        <>
                                          {images.pan_image && (
                                            <>
                                              <img
                                                src={URL.createObjectURL(
                                                  images.pan_image
                                                )}
                                                className='w-100 mt-3 mb-4 docimg'
                                              />
                                              <p
                                                className='remove-btn fs-4'
                                                onClick={() =>
                                                  removeImage('pan_image')
                                                }
                                              >
                                                x
                                              </p>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <img
                                          src={
                                            '/assetss/images/pancardicon.png'
                                          }
                                          alt='img'
                                          className='img-fluid'
                                        />
                                      )}{' '}
                                      <strong>Pan Card</strong>
                                    </span>
                                    <input
                                      type='file'
                                      name='pan-img'
                                      {...register1('pan_image', {
                                        required: 'Pan Card is required',
                                        onChange: e => handleUploadFile(e)
                                      })}
                                      accept='image/*'
                                    />
                                    <div className='close-btn'>×</div>
                                  </div>
                                  <span className='text-danger fs-4 mx-1'>
                                    {errors1?.pan_image?.message}
                                  </span>
                                </li>
                              </div>
                            </ul>
                            {checkboxError && (
                              <p
                                className='f-error fs-3  mx-2'
                                style={{ position: 'relative' }}
                              >
                                You must select the checkbox to proceed
                              </p>
                            )}
                            <div className='declare'>
                              <label className='declarebox fs-2'>
                                I hereby declare that the above information is
                                true and correct.
                                <input
                                  type='checkbox'
                                  checked={checkboxAccepted}
                                  onChange={() => {
                                    setCheckboxAccepted(!checkboxAccepted)
                                    setCheckboxError(false)
                                  }}
                                />
                                <span className='checkmark'></span>
                              </label>
                            </div>
                          </div>

                          <div className='text-center mt-5 mx-2'>
                            <button type='submit' className='btn btn-primary'>
                              Become a POS
                            </button>
                          </div>
                        </div>
                      )}
                    </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ----- container start ----- */}
        <div className='container-lg container-fluid'>
          <div className='row justify-content-between align-items-center'>
            <div className='col-12' />
          </div>
        </div>
        {/*----- container End -----*/}
      </section>

      {/* Success Modal */}

      <Modal
        isOpen={modal}
        toggle={toggleModal}
        size='md'
        // className='successModal'
        onMouseDown={e => e.stopPropagation()}
      >
        <ModalBody>
          <div className='cardModal'>
            <div className='checkMarkWrapper'>
              <i className='success-checkmark'>✓</i>
            </div>
            <div className='success-container'>
              <h1 className='success-header'>Success</h1>
              <p className='fs-6 text-center'>
                Thankyou for Registering with us. We will notify you through
                Mail if your request is Approved or Rejected
              </p>
              <Link className='' to='/'>
                <div className='success-btn'>
                  <button
                    className='homeButton'
                    id='modalBtn'
                    onClick={() => {
                      window.location.href = '/'
                    }}
                  >
                    Go to Home
                  </button>
                </div>
              </Link>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={OTPModal}
        // toggle={toggleModal}
        size='md'
        // className='successModal'
        onMouseDown={e => e.stopPropagation()}
      >
        <ModalBody>
          <div className='cardModal'>
           
            <div className='success-container'>
              <h1 className='success-header'>OTP Verification</h1>
              <div className='adress_filed mt-4'>
       
                 
                  <input
                    type='number'
                    id='otp'
                    name='otp'
                    maxLength='6'
                    className='form-control fs-2'
                    placeholder='XXXXXX'
                    onChange={e=> setOTP(e?.target?.value)}
                  />
                  
                
              </div>
             
                <div className='success-btn'>
                  <button
                    className='homeButton'
                    id='modalBtn'
                    onClick={() => submitOTP()}
                  >
                    Submit
                  </button>
                </div>
            
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Footer />
    </div>
  )
}

export default BecomePos
