export const posEndPoint = {
  becomePos: 'auth/become-a-pos',
  registerPos: 'auth/update-pos',
  posLogin: 'auth/trainee-login',
  trainingModule: 'exam/get-module',
  moduleComplete: 'exam/module-complete',
  startExam: 'exam/start-exam',
  postAnwer: 'exam/answer',
  endExam: 'exam/end-exam',
  examResult: 'exam/result',
  changePassword: 'auth/change-password',
  getStates: 'admin/states',
  getDistricts: 'admin/district',
  getBranches: 'admin/get-branch'
}

export const mastersEndPoint = {
  make: 'admin/make',
  model: 'admin/model',
  variant: 'admin/variant',
  rto: 'motor/rto',
  previousInsurer: 'motor/previous-insurer',
  pincode: 'motor/pincode',
  state: 'motor/states',
  bajajMMV: 'motor/getBajajMMV',
  hdfcChecksum: 'motor/hdfcChecksum',
  getFiancierBanks: 'motor/getFiancierBanks'
}
