import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import HowWork from "./HowWork";
import Footer from "./Footer";

function Rto() {
  return (
    <>
      <Navbar />
      <div className="main-rto-page">
        <div className="rto-inf">
          <div className="select-rto">
            <div className="new-rto">
              <div className="new-rto-img">
                <img src="./assets/img/new-product-img.png" alt="" />
              </div>
              <h1>New</h1>
            </div>
            <h1>Select City / RTO</h1>
            <div className="loder">
              <p> 30%</p>
            </div>
          </div>
          <div className="rto">
            <button>
              <img src="XMLID_223_.png" alt="" /> Select RTO
            </button>
          </div>

          <div className="btn-rto">
            <button>Delhi</button>
            <button>Hyderabad</button>
            <button>Mumbai</button>
          </div>
          <div className="btn-rto-2">
            <button>DSL 1</button>
            <button>DSL 2</button>
            <button>DSL 3</button>
            <button>DSL 4</button>
            <button>DSL 5</button>
            <button>DSL 6</button>
          </div>
          <div className="btn-rto">
            <button>Chennai</button>
            <button>Kolkata</button>
            <button>Bengalurui</button>
          </div>
          <div className="submit-rto">
            <button>
              <Link to="/policyoverview">Continue</Link>
            </button>
          </div>
        </div>
      </div>
      <HowWork />
      <Footer />
    </>
  );
}

export default Rto;
