import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../Footer'
import Navbar from '../Navbar'

function ErrorMessage() {
  return (
      <>
      <Navbar/>
      <div className='success_main_info'>
            <div className=' text-center success_msg'>           
            <div className="right_icon_img">
                <img src="/assets/img/incorrect_img.png"></img>
              </div>
              <h1 className='card-title' style={{color:"red"}}>Error</h1>
                <p className='poli_num pb-3' style={{fontWeight:"bolder"}}>Your policy is not done </p>
                <div className="card-footer">
                  <Link to ="/">
            <button
             className="btn btn-primary fs-5 px-3 py-2 mt-2">
                  Back to home
                </button>
                </Link>
            </div>
            </div>  
      </div>
      <Footer/>
      </>
  )
}

export default ErrorMessage