import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

function About() {
  return (
    <>
      <Navbar />
      <div className="About-maindiv">
        <div className="about-head">
          <h1>About Us</h1>
          <p>
            Home-<span>About Us</span>
          </p>
        </div>
        <div className="about-text">
          <div className="about-paragraph">
            <h1>Our Commitment to Modern Insurance Excellence</h1>
            <ul>
              <li>
                <p>
                  At the core of our identity lies a dedication to redefine the
                  insurance landscape. Inspired by visionaries like Richard
                  McClintock, our journey is a contemporary tale of evolution
                  and innovation.
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  Navigating the diverse passages of insurance, we prioritize
                  authenticity over humor, ensuring every interaction is both
                  credible and transparent. Our approach is free from hidden
                  surprises, offering a seamless experience tailored to your
                  unique needs.
                </p>
              </li>
              <li>
                <p>
                  In a dynamic industry, we steer clear of monotony, providing
                  personalized solutions that reflect the ever-changing nature
                  of insurance. Our commitment is to deliver a modern, reliable,
                  and transparent experience, avoiding the repetition found in
                  many online platforms.
                </p>
              </li>
              <li>
                <p>
                  Our journey is ongoing, rooted in authenticity and shaped by
                  the needs of today. Join us as we continue to redefine the
                  narrative of modern insurance—one that is reliable,
                  transparent, and designed for you.
                </p>
              </li>
            </ul>
          </div>
          <div className="about-img">
            <img src="./assets/img/about.png" alt="" />
          </div>
        </div>
        <div className="works-info">
          <div className="works-icon-img">
            <img src="./assets/img/money-bag-img.png" alt="" />
          </div>
          <h1>Affordable options</h1>
          <p style={{ textTransform: "capitalize" }}>
            Explore wide insurance choices. We offer good coverage without high
            costs, keeping you protected and worry-free.
          </p>
        </div>

        <div className="it-works">
          <div className="main-works-div">
            <div className="works-div-1">
              <div className="works-icon-img-2">
                <img src="./assets/img/customer-service11.png" alt="" />
              </div>
              <h1>Platinum Premium Insurance</h1>
              <p>
                Pick Platinum Premium insurance. It's the best. Gives top
                coverage, special perks for safety and peace of mind.
              </p>
            </div>
            <div className="works-div-2">
              <img src="./assets/img/aboutimg.png" alt="" />
            </div>
            <div className="works-div-3">
              <div className="works-icon-img-3">
                <img src="./assets/img/customer-service-img.png" alt="" />
              </div>
              <h1>30 minute claim support</h1>
              <p>
                Get fast aid in half an hour. Our support crew is ready to
                assist, ensuring prompt help and solutions.
              </p>
            </div>
          </div>
        </div>
        <div className="our-vision">
          <div className="our-visionBox">
            <div className="about-img">
              <img src="./assets/img/image1.png" alt="" />
            </div>
            <div className="about-paragraph">
              <h1>
                Our <b>Vision</b>
              </h1>
              <ul>
                <li>
                  <p></p>
                </li>
                <li>
                  <p>
                    At our organization, we envision a future where lives are
                    empowered through financial security. Our goal is to be a
                    leading force in the insurance industry, dedicated to
                    providing individuals and businesses with peace of mind.
                  </p>
                </li>
                <li>
                  <p>
                    Looking ahead, our vision revolves around navigating
                    tomorrow's challenges. We strive to redefine the insurance
                    landscape, ensuring our clients have access to tailored
                    coverage that anticipates and meets their evolving needs.
                    Our commitment lies in delivering innovative solutions for a
                    more secure future.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
