  import React, { useEffect, useMemo, useState } from 'react'
  import { useSelector } from 'react-redux'
  import {
    CUSTOMER_TYPE,
    POLICY_TYPE,
    kycDoc,
    kycDocName,
    kycType
  } from '../utility/constants'
  import { useForm } from 'react-hook-form'
  import {
    dispatchMotorQuote,
    dispatchProcessFlowMotor
  } from '../../store/actions/userAction'
  import { getKyc } from '../Services/TPApiCall'
  import { getVehiclePincode } from '../Services/masterServices'
  import ReactSelect from '../Tags/ReactSelect'
  import { toast } from 'react-toastify'

  const KYC = ({
    setCurrentStep,
    setActiveStep,
  //   kycStatusCheck
  }) => {
    const {
      handleSubmit,
      register,
      formState: { errors },
      reset,
      setValue,
      getValues,
      watch,
      clearErrors
    } = useForm({ mode: 'onBlur' })

    const {
      handleSubmit: handleSubmit1,
      register: register1,
      formState: { errors: errors1 },
      reset: reset1,
      setValue: setValue1,
      clearErrors: clearErrors1
    } = useForm({ mode: 'onBlur' })

    const motorRequest = useSelector(state => state?.root?.motorRequest)
    const kycConfig = useSelector(state => state?.root?.kycConfig)
    const selectedPlan = useSelector(state => state?.root?.selectedPlan)

    const [proceedSpinner, setProceedSpinner] = useState(false)
    const [kycMode, setKycMode] = useState('ckyc')
    const [kycStatus, setKycStatus] = useState(false)
    const [pincodeData, setPincodeData] = useState([])
    const [pincodeError, setPincodeError] = useState(false)
    const [kycFields, setKycFields] = useState('')
    const [address, setAddress] = useState({
      addressLine1: motorRequest?.addressLine1 || '',
      addressLine2: motorRequest?.addressLine2 || '',
      addressLine3: motorRequest?.addressLine3 || '',
      pincode: motorRequest?.pincode || ''
    })
    const [addressErrors, setAddressErrors] = useState({})

    // const kycFields =
    //   kycStatusCheck &&
    //   kycConfig &&
    //   kycConfig?.KycFieldConfig.filter(
    //     (kycType) => kycType.kycType === motorRequest?.kycType?.toLowerCase()
    //   );

    useEffect(() => {
      if ( kycConfig) {
        setKycFields(
          kycConfig?.KycFieldConfig.filter(
            kycType => kycType.kycType === motorRequest?.kycType?.toLowerCase()
          )
        )
      }
      setValue('docName', motorRequest?.docName)
    }, [kycConfig])

    const handleAddressChange = e => {
      const { name, value } = e.target
      if (name === 'addressLine1') {
        setAddressErrors(prev => ({
          ...prev,
          addressLine1: ''
        }))
      } else if (name === 'addressLine2') {
        setAddressErrors(prev => ({
          ...prev,
          addressLine2: ''
        }))
      } else if (name === 'addressLine3') {
        setAddressErrors(prev => ({
          ...prev,
          addressLine3: ''
        }))
      }
    
      setAddress({ ...address, [name]: value })
      dispatchMotorQuote(name, value)
    }

    const validateAddressFields = () => {
    
      let isValid = true
      const newErrors = {}

      if (!address.addressLine1) {
        newErrors.addressLine1 = 'Address 1 is required'
        isValid = false
      }
      if (!address.addressLine2) {
        newErrors.addressLine2 = 'Address 2 is required'
        isValid = false
      }
      if (!address.addressLine3) {
        newErrors.addressLine3 = 'Address 3 is required'
        isValid = false
      }

      setAddressErrors(newErrors)
      return isValid
    }

    useEffect(() => {
      
    }, [address])

    useEffect(() => {
    
    }, [])

    const handleNextClick = () => {

      if (motorRequest?.pincode === '') {
        setPincodeError(true)
      }
      if (validateAddressFields()) {
        selectedPlan?.PersonalAccident
          ? ( setActiveStep(2))
          :  (setActiveStep(3))
      }
    }

    useEffect(() => {
      getVehiclePincode()
        .then(response => {
          if (response.status === true) {
            let i = 0
            let arr = []
            let arr1 = []
            let j = 0
            while (i < response.data.length) {
              arr.push({
                value: response.data[i].Pin_Code,
                label:
                  response.data[i].Pin_Code + ' - ' + response.data[i].District,
                District: response.data[i].District,
                City: response.data[i].City,
                State: response.data[i].State,
                cityId: response.data[i].cityId,
                stateId: response.data[i].stateId
              })

              i++
            }
            // while (j < response.data.length) {
            //   arr1.push({
            //     value: response.data[j].Pin_Code,
            //     label: response.data[j].District,
            //     District: response.data[j].District,
            //     City: response.data[j].City,
            //     State: response.data[j].State,
            //   });

            //   j++;
            // }
            setPincodeData(arr)
            // setCityData(arr1);
          }
        })
        .catch(err => console.log(err))
    }, [])

    const submitKycDetails = async data => {
    
      setProceedSpinner(true)


      let newData = { ...motorRequest }

      if (!kycStatus) {
        for (let key in data) {
          dispatchMotorQuote(`${key}`, data[key])
          newData[key] = data[key]
        }

        try {
          const response = await getKyc(newData, selectedPlan)
      
      

          if (response?.data?.kycStatus === 'success') {
            const newData = {
              ...data,
              kycStatus: 'completed',
              kycType: motorRequest?.kycType
            }

            dispatchMotorQuote('kycStatus', 'completed')
            dispatchMotorQuote('kycData', response?.data?.kycData)
            toast.success('KYC Successful', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light'
            })
            setKycStatus(true)
            
            setProceedSpinner(false)
          } else if (response?.data?.kycStatus === 'failure') {
            dispatchMotorQuote('kycStatus', 'failure')
            toast.error(
              `${
                response?.data?.errors
                  ? response?.data?.errors[0]?.displayMessage
                  : 'KYC Failed. Please check your details and try again'
              }`,
              {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
              }
            )
            setProceedSpinner(false)
          } else {
            setProceedSpinner(false)
            toast.error(
              `${
                response?.data?.errors
                  ? response?.data?.errors[0]?.displayMessage
                  : 'KYC Failed. Please check your details and try again'
              }`,
              {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
              }
            )
          }
        } catch (error) {
          toast.error(
            `${'KYC Failed. Please check your details and try again'}`,
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light'
            }
          )
          console.error('Error occurred:', error)
          setProceedSpinner(false)
        }
      }

      if (kycStatus) {
        setActiveStep(true)
      }
    }

    const handleSelectPincode = data => {
      setPincodeError(false)

      dispatchMotorQuote('cityId', data?.cityId)
      dispatchMotorQuote('stateId', data?.stateId)
      dispatchMotorQuote('pincode', data?.value)
      dispatchMotorQuote('city', data?.City)
      dispatchMotorQuote('state', data?.State)
      reset({
        city: data?.City,
        state: data?.State,
        docName: getValues('docName')
      })

      // setValue("pincode", data?.Pin_Code);
    }

    const handleUploadFile = e => {
    
    }

    const handleInputChange = e => {
      const value = e.target.value.toUpperCase()
      setValue(e.target.name, value)
    }

    const handleMaritalStatus = e => {
      const value = e.target.value

      dispatchMotorQuote('maritalStatus', value)
    }



    return (
      <form onSubmit={handleSubmit(submitKycDetails)} className='w-100'>
        {/* {motorRequest?.kycStatus === "failure" && (
          <div
            className="nav nav-pills mb-3 mt-3 tab_owner_tab"
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
                onClick={() => {
                  setKycMode("ckyc");
                  dispatchMotorQuote("kycType", "ckyc");
                }}
              >
                CKYC
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
                onClick={() => {
                  setKycMode("ovd");
                  dispatchMotorQuote("kycType", "ovd");
                }}
              >
                OVD
              </button>
            </li>
          </div>
        )} */}
        <div className='onwer-personal-details'>
          <div className='row'>
            {/* Doc Type */}
            <div className='col-md-6 mb-2'>
              <div className='onwer-details'>
                <div
                  className='did-floating-label-content'
                  style={{ marginBottom: '0px' }}
                >
                  <>
                    <select
                      className='did-floating-input'
                      {...register('docName', {
                        required: 'Please Select Document Type to Verify'
                      })}
                      // defaultValue={kycStatusCheck ? motorRequest?.docName : ""}
                      disabled={kycStatus ? true : false}
                    >
                      <option value=''>Select Document Type</option>
                      {kycConfig?.KycFieldConfig.map(
                        item =>
                          item?.kycType === kycType?.ckyc && (
                            <option
                              value={item?.docName}
                              selected={
                                motorRequest?.docName === item?.docName
                                  ? true
                                  : false
                              }
                              onClick={() => reset('')}
                            >
                              {kycDocName[item.docName]}
                            </option>
                          )
                      )}
                    </select>
                    <label htmlFor='idxx4' className='did-floating-label'>
                      Select Document Type
                      <span className='text-danger'>*</span>
                    </label>
                  </>
                </div>
                <p className='f-error text-danger'>{errors?.docName?.message}</p>
              </div>
            </div>

            {kycConfig &&
              kycFields &&
              kycFields.map(
                field =>
                  watch('docName') === field?.docName &&
                  field?.requiredField?.map(input => (
                    <div className='col-md-6 mb-3 mt-3 mt-md-0' key={input}>
                      
                      {input === 'gender' ? (
                        <div className='did-floating-label-content'>
                          <select
                            name={input}
                            className='did-floating-input'
                            id={input}
                            {...register(input, {
                              required: `${input} is required`
                            })}
                            disabled={
                              motorRequest?.kycStatus === 'completed'
                                ? true
                                : false
                            }
                          >
                            <option value='' className='text-capitalize'>
                              {input}
                            </option>
                            <option
                              value='M'
                              // selected={
                              //   kycStatusCheck && motorRequest?.gender === 'M'
                              // }
                            >
                              Male
                            </option>
                            <option
                              value='F'
                              // selected={
                              //   kycStatusCheck && motorRequest?.gender === 'F'
                              // }
                            >
                              Female
                            </option>
                            <option
                              value='O'
                              // selected={
                              //   kycStatusCheck && motorRequest?.gender === 'O'
                              // }
                            >
                              Other
                            </option>
                          </select>
                          <label
                            htmlFor={input}
                            className='did-floating-label text-capitalize'
                          >
                            {input}
                            <span className='text-danger'>*</span>
                          </label>
                          <p className='f-error text-danger'>
                            {errors?.[input]?.message}
                          </p>
                        </div>
                      ) : (
                        <div className='did-floating-label-content'>
                          <input
                            type={
                              input === 'dob'
                                ? 'date'
                                : input === 'docLink'
                                ? 'file'
                                : input === 'DOI'
                                ? 'date'
                                : 'text'
                            }
                            className='did-floating-input'
                            {...register(input, {
                              required: `${input} is required`,
                              pattern:
                                input === 'docNumber'
                                  ? {
                                      value: new RegExp(field.regexPattern),
                                      message: 'Invalid document number format'
                                    }
                                  : undefined
                            })}
                            defaultValue={
                              input === 'firstName'
                                ? motorRequest?.kycData?.fullName
                                  ? motorRequest.kycData.fullName
                                  : motorRequest.middleName
                                  ? `${motorRequest.firstName} ${motorRequest.middleName} ${motorRequest.lastName}`
                                  : `${motorRequest.firstName} ${motorRequest.lastName}`
                                : input === 'dob'
                                ? motorRequest?.dob
                                : input === 'docNumber'
                                ? motorRequest?.docNumber
                                : null
                            }
                            maxLength={100}
                            disabled={
                              motorRequest?.kycStatus === 'completed'
                                ? true
                                : false
                            }
                            name={input}
                            onChange={handleInputChange}
                          />
                          <label
                            htmlFor={input}
                            className='did-floating-label text-capitalize'
                          >
                            {input === 'docNumber' ? field.label : input}
                          </label>
                          <p className='f-error text-danger text-capitalize'>
                            {errors?.[input]?.message}
                          </p>
                        </div>
                      )}
                    </div>
                  ))
              )}

            {motorRequest?.kycStatus !== 'completed' && (
              <div className='col-md-12'>
                <div className='submit-model1'>
                  <button
                    type='submit'
                    className=''
                    // onClick={() => handleNextStep()}
                  >
                    {proceedSpinner ? (
                      <div class='spinner-border text-light' role='status'></div>
                    ) : (
                      'Complete KYC'
                    )}
                  </button>
                </div>
              </div>
            )}

            {motorRequest?.kycStatus === 'completed' && (
              <>
                <div className='col-md-6 mb-2'>
                  <div className='onwer-details'>
                    <div className='did-floating-label-content'>
                      <select
                        name='maritalStatus'
                        className='did-floating-input'
                        id='maritalStatus'
                        // {...register("maritalStatus", {
                        //   required: "Marital Status is required",
                        // })}
                        onChange={handleMaritalStatus}
                      >
                        <option value=''>Marital Status</option>
                        <option value='married'>Married</option>
                        <option value='unmarried'>Unmarried</option>
                      </select>
                      <label htmlFor='idxx4' className='did-floating-label'>
                        Marital Status
                        <span className='text-danger'>*</span>
                      </label>
                      <p className='f-error text-danger'>
                        {errors?.maritalStatus?.message}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 mb-2'>
                  <div className='onwer-details'>
                    <div className='did-floating-label-content'>
                      <input
                        type='text'
                        className=' did-floating-input'
                        placeholder=''
                        // {...register("addressLine1", {
                        //   required: "Address 1 is required",
                        // })}
                        // value={address.addressLine1}
                        name='addressLine1'
                        onChange={handleAddressChange}
                        defaultValue={motorRequest?.addressLine1}
                        maxLength={30}

                        //   onChange={(e) => handleSelectAddress1(e.target.value)}
                      />
                      <label htmlFor='idxx4' className='did-floating-label'>
                        Address 1<span className='text-danger'>*</span>
                      </label>
                      <p className='f-error text-danger'>
                        {addressErrors.addressLine1}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 mb-2'>
                  <div className='onwer-details'>
                    <div className='did-floating-label-content'>
                      <input
                        type='text'
                        className=' did-floating-input'
                        placeholder=''
                        // {...register("addressLine2", {
                        //   required: "Address 2 is required",
                        // })}
                        // onChange={(e) =>
                        //   dispatchMotorQuote("addressLine2", e.target.value)
                        // }
                        name='addressLine2'
                        onChange={handleAddressChange}
                        defaultValue={motorRequest?.addressLine2}
                        maxLength={30}

                        //   onChange={(e) => handleSelectAddress1(e.target.value)}
                      />
                      <label htmlFor='idxx4' className='did-floating-label'>
                        Address 2<span className='text-danger'>*</span>
                      </label>
                      <p className='f-error text-danger'>
                        {addressErrors.addressLine2}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 mb-2'>
                  <div className='onwer-details'>
                    <div className='did-floating-label-content'>
                      <input
                        type='text'
                        className=' did-floating-input'
                        placeholder=''
                        // {...register("addressLine3", {
                        //   required: "Address 3 is required",
                        // })}
                        // onChange={(e) =>
                        //   dispatchMotorQuote("addressLine3", e.target.value)
                        // }
                        name='addressLine3'
                        onChange={handleAddressChange}
                        defaultValue={motorRequest?.addressLine3}
                        maxLength={30}

                        //   onChange={(e) => handleSelectAddress1(e.target.value)}
                      />
                      <label htmlFor='idxx4' className='did-floating-label'>
                        Address 3<span className='text-danger'>*</span>
                      </label>
                      <p className='f-error text-danger'>
                        {addressErrors.addressLine3}
                      </p>
                    </div>
                  </div>
                </div>

                <div className='col-md-6 mb-2'>
                  <div className='onwer-details'>
                    <div className='did-floating-label-content'>
                      <ReactSelect
                        options={pincodeData}
                        onChange={val => {
                          handleSelectPincode(val)
                        }}
                        placeholder='Select area pincode'
                        className='form-select did-floating-input'
                        defaultValue={motorRequest?.pincode}
                      />
                      <label htmlFor='idxx4' className='did-floating-label'>
                        Select Pincode
                        <span className='text-danger'>*</span>
                      </label>
                      {pincodeError && (
                        <p className='f-error text-danger'>Pincode is required</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className='col-md-6 mb-2'>
                  <div className='onwer-details'>
                    <div className='did-floating-label-content'>
                      <input
                        type='text'
                        placeholder=''
                        className='did-floating-input'
                        {...register('city', {
                          required: 'City is required'
                        })}
                        disabled
                        defaultValue={motorRequest?.city}
                      />
                      <label
                        htmlFor='idxx4'
                        className='did-floating-label uppercase'
                      >
                        Enter City
                        <span className='text-danger'>*</span>
                      </label>
                      <p className='f-error text-danger'>{addressErrors.city}</p>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 mb-2'>
                  <div className='onwer-details'>
                    <div className='did-floating-label-content'>
                      <input
                        list='text'
                        placeholder=''
                        name='State'
                        {...register('state', {
                          required: 'State is required'
                        })}
                        defaultValue={motorRequest?.state}
                        disabled
                        //   onChange={(e) => handleSelectState(e.target.value)}
                        className='did-floating-input uppercase'
                      />
                      <label htmlFor='idxx4' className='did-floating-label'>
                        Enter State
                        <span className='text-danger'>*</span>
                      </label>
                      <p className='f-error text-danger'>{addressErrors.state}</p>
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='next-btn-btn'>
                    <button
                      type='submit'
                      // onClick={() => {
                      //   setActiveStep2(true);
                      //   // motorRequest?.personalAccident
                      //   //   ? (setCurrentStep(2), setActiveStep2(true))
                      //   //   : setCurrentStep(3),
                      //   //   setActiveStep3(true);
                      //   setCurrentStep(2);
                      // }}
                      onClick={handleNextClick}
                      className='proceedBtn1'
                    >
                      Next
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>

          <></>
        </div>
      </form>
    )
  }

  export default KYC
