import React from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import HowWork from "./HowWork";
import Footer from "./Footer";

function Variant() {
  return (
    <>
      <Navbar />
      <div className="main-variant-page">
        <div className="modal-inf">
          <div className="select-variant">
            <div className="new-variant">
              <div className="new-variant-img">
                <img src="./assets/img/new-product-img.png" alt="" />
              </div>
              <h1>New</h1>
            </div>
            <h1>Model Variant</h1>
            <div className="loder">
              <p> 75%</p>
            </div>
          </div>
          <div className="model-variant">
            <div className="variant-btn">
              <Link to="#">
                <button>CB Unicorn</button>
              </Link>
              <Link>
                <button>SP Unicorn</button>
              </Link>
              <Link>
                <button>Unicorn 125</button>
              </Link>
              <Link>
                <button>CB Unicorn 350</button>
              </Link>
              <Link>
                <button>Unicorn 350</button>
              </Link>
              <Link>
                <button>Unicorn 250</button>
              </Link>
              <Link>
                <button>SP Unicorn 350</button>
              </Link>
            </div>
          </div>

          <div className="submit-variant">
            <button>
              <Link to="/ModelYear">Continue</Link>
            </button>
          </div>
        </div>
      </div>
      <HowWork />
      <Footer />
    </>
  );
}

export default Variant;
