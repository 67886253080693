import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Navbar from './Navbar'
import Footer from './Footer'
import { toast } from 'react-toastify'
import { PostDataWithToken } from '../api/apiHelper'

const ContactUs = () => {
  const [message, setMessage] = useState('')

  const {
    control,
    handleSubmit,
    formState: { errors },
    register
  } = useForm()

  const handleMessageChange = e => {
    const input = e.target.value

    if (input.length <= 200) {
      setMessage(input)
    }
  }

  const onSubmit = async data => {
    try {
      const response = await PostDataWithToken('admin/add-contactus', data)
      if (response?.success) {
        toast(response?.message, { type: 'success' })
      } else {
        toast(response?.message, { type: 'error' })
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      <Navbar />
      {/* <!-- Bread Crumb --> */}
      <div className='breadcrumb'>
        <h2>Contact Us</h2>
      </div>

      <div className='contactsec'>
        <div className='container'>
          <div className='row contact-page-info'>
            <div className='col-md-12 col-lg-6'>
              <div className='contactcnt'>
                <h2>Contact Info</h2>
                <p className='cont-info'>
                  Have a question, suggestion, or just want to say hello? We'd
                  love to hear from you! Our team is dedicated to providing
                  exceptional support and assistance. Feel free to reach out to
                  us via the contact form below or through the provided email
                  address. We strive to respond promptly to all inquiries and
                  will do our best to assist you with any queries you may have.
                </p>
                <ul>
                  <li>
                    <img
                      src={'/assets/img/mapp.png'}
                      alt='img'
                      className='contact-loction-info'
                    />
                    <div className='addressbox'>
                      <h3>Address</h3>
                      <p>
                        F-53, 1st Floor, Raheja Square Mall, Sec-2, IMT Manesar,
                        Gurgaon, Haryana - 122051
                      </p>
                    </div>
                  </li>

                  <li>
                    <img
                      src={'/assets/img/phone.png'}
                      alt='img'
                      className='contact-loction-info'
                    />
                    <div className='addressbox font-family'>
                      <h3>Mobile</h3>
                      <a
                        href='tel:+9717371800'
                        className=' text-decoration-none'
                      >
                        +91-97173 71800
                      </a>
                      <span className=''> / </span>
                      <a
                        href='tel:01244114493'
                        className=' text-decoration-none'
                      >
                        0124-4114493
                      </a>
                    </div>
                  </li>

                  <li>
                    <img
                      src={'/assets/img/msg.png'}
                      alt='img'
                      className='contact-loction-info'
                    />
                    <div className='addressbox'>
                      <h3>Email</h3>
                      <h4>
                        <a
                          href='mailto:help@retroinsurance.in'
                          className='text-lowercase'
                        >
                          help@retroinsurance.in
                        </a>
                      </h4>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className='col-md-12 col-lg-6'>
              <div className='gettouchform'>
                <h2>Get in Touch</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ul>
                    <li>
                      <label>Name</label>
                      <input
                        type='text'
                        name='Name'
                        className='form-control  name_info'
                        maxLength={31}
                        {...register('Name', {
                          required: 'Name is required',
                          maxLength: {
                            value: 30,
                            message: 'Name should not exceed 30 characters.'
                          },
                          pattern: {
                            value: /^[A-Za-z\s]+$/,
                            message:
                              'Name should only contain alphabetic characters and spaces.'
                          }
                        })}
                      />
                      {errors.Name && (
                        <p className='f-error fs-6 mx-2'>
                          {errors.Name.message}
                        </p>
                      )}
                    </li>
                    <div className='div-mobile-email'>
                      <li>
                        <label>Email</label>
                        <input
                          type='email'
                          name='Email'
                          className='mobile-info'
                          maxLength={31}
                          {...register('Email', {
                            required: 'Email is required',

                            maxLength: {
                              value: 30,
                              message: 'Email should not exceed 30 characters.'
                            },
                            pattern: {
                              value: /^\S+@\S+\.\S+$/,
                              message: 'Invalid email format.'
                            }
                          })}
                        />
                        {errors.Email && (
                          <p className='f-error fs-6 mx-2'>
                            {errors.Email.message}
                          </p>
                        )}
                      </li>

                      <li>
                        <label>Mobile</label>
                        <input
                          type='number'
                          name='Mobile'
                          className='mobile-info'
                          {...register('Mobile', {
                            required: 'Mobile is required',
                            pattern: {
                              value:
                                /((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}/,
                              message: 'Invalid Mobile Number'
                            }
                          })}
                          onInput={e => {
                            e.target.value = e.target.value.slice(0, 10)
                          }}
                        />
                        {errors.Mobile && (
                          <p className='f-error fs-6 mx-2'>
                            {errors.Mobile.message}
                          </p>
                        )}
                      </li>
                    </div>
                    <li>
                      <label>Message</label>
                      <textarea
                        name='Message'
                        className='form-control name_info'
                        maxLength={201}
                        onChange={handleMessageChange}
                        {...register('Message', {
                          required: 'Message is required',
                          maxLength: {
                            value: 200,
                            message: 'Message should not exceed 200 characters.'
                          }
                        })}
                      ></textarea>
                      {errors.Message && (
                        <p className='f-error fs-6 mx-2'>
                          {errors.Message.message}
                        </p>
                      )}
                    </li>
                  </ul>
                  <button type='submit' className='btn btn-primary fs-5'>
                    Submit Request
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default ContactUs
