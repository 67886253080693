import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'

function Bike () {
  const [modalKyc, setModalKyc] = useState(false)

  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  return (
    <>
      {/* <Navbar />
      <div className='main-page-bick-insurance'>
        <div id='bike-insurance'>
          <div className='bike-insureance-info'>
            <h1>
              Bike insurance starting from <br /> <span>₹ 6/day*</span>
            </h1>
            <input
              type='text'
              className=' text-uppercase'
              maxLength={15}
              placeholder='Enter bike number: (eg AP-31-cc-1986)'
            />{' '}
            <br />
            <Link to="/rto">
            <button
              // onClick={() => setModal(true)}
              >
              <a className="get-btn"> Get Details</a>
            </button>
            </Link>
            <div className='text'>Don't know your Bike number?</div>
          </div>
          <div className='bike-insurance-img'>
            <img src='/assets/img/Bike img.png' alt='' />
          </div>
        </div>
      </div> */}
      <div className='about-bike-insurance'>
        <div className='bike-heading-1'>
          <h1>
            Know More about <b> Bike Insurance</b>
          </h1>
          <div className='bike-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Third-Party Liability Coverage: Initial protection against legal
              and financial responsibilities for injuries or damages caused to
              third parties.
            </p>
          </div>
          <div className='bike-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Comprehensive Coverage: Offers a broader scope of protection,
              including coverage for own bike damages due to accidents, theft,
              vandalism, and natural disasters.Personal Accident Cover: Provides
              financial support for medical expenses and compensation in case of
              accidental injuries or fatalities to the bike owner or rider.
            </p>
          </div>
          <div className='bike-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              No Claim Bonus (NCB): Rewards policyholders for claim-free years
              with reduced premiums or other benefits during policy renewals.
              Policy Period Options: Flexibility to choose policy durations,
              typically ranging from one year to long-term policies for up to
              three years.
            </p>
          </div>
          <div className='bike-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Optional Add-Ons: Additional coverage options like zero
              depreciation, roadside assistance, and accessories coverage for a
              more comprehensive policy.
            </p>
          </div>
        </div>
        <div className='bike-insurance-img'>
          <img src='./assets/img/Bike-img-2.png' alt='' />
        </div>
      </div>
      <div className='about-bike-insurance-2'>
        <div className='bike-insurance-img-2'>
          <img src='./assets/img/Bike-img-3.png' alt='' />
        </div>
        <div className='bike-heading-1'>
          <h1>
            Benefits of <b> Bike Insurance</b>
          </h1>
          <div className='bike-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Financial Protection: Bike insurance provides financial coverage
              against repair or replacement costs in case of accidents, theft,
              vandalism, or natural disasters.
            </p>
          </div>
          <div className='bike-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Third-Party Liability Coverage: Safeguards the policyholder from legal and financial responsibilities arising from injuries or property damage caused to third parties. This coverage ensures that in the event of an accident, the policyholder is protected from potential legal and financial consequences.
            </p>
          </div>
          <div className='bike-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Peace of Mind: Assures policyholders that unexpected expenses
              related to bike damage or accidents will be covered, reducing
              stress and financial burden.
            </p>
          </div>
          <div className='bike-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Online Renewal and Management: Convenient online platforms for
              easy policy renewal, document submission, and policy
              management.Insured Declared Value (IDV): Ensures that the policy
              covers the bike's market value, determining the sum insured and
              influencing premium rates.
            </p>
          </div>
        </div>
      </div>
      {/* <Footer /> */}

      {/* <Modal isOpen={modalKyc}>
        <ModalBody>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h4 className='modal-title'>Did we get the right details?</h4>
              </div>

              <div className='modal-body'>
                <div className='model-box-info-1'>
                  <div className='model-text'>
                    <h2 className='text-modalbox'>
                      RJ02CD7873
                      <p>BAJAJ INDIA LTD </p>
                      <p>
                        {' '}
                        I20 <img
                          src='./assets/img/Ellipse 50.png'
                          alt=''
                        />{' '}
                        PULSAR 150
                      </p>
                      <p>
                        {' '}
                        2019 <img
                          src='./assets/img/Ellipse 50.png'
                          alt=''
                        />{' '}
                        PETROL <img src='Ellipse 50.png' alt='' /> Prakasam
                      </p>
                    </h2>
                  </div>
                  <div className='model-box-img'>
                    <img src='./assets/img/model-img.png' alt='' />
                  </div>
                </div>

                <div className='model-box-button-2'>
                  <Link to='/rto'>
                    {' '}
                    <button className='modal-btn active'>Yes,its My Bike</button>{' '}
                  </Link>
                </div>
                <div className='model-box-button-2'>
                  <button>Its NOT My Bike</button>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal> */}


{/* <Modal isOpen={modal} toggle={toggle} size='md'>

<nav className=''>
    <div
      className='nav nav-tabs border-0 justify-content-center px-2 py-2 mt-0'
      id='nav-tab'
      style={{
        backgroundColor: '#354c89',
        width: '100%',
        color: 'white',
        fontWeight: 'bold'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p className='fs-5'>Details Submitted</p>
        
      </div>
    </div>
  </nav>
  <div
    className='px-4 py-5'
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <h2 className='modal-page'>
      Your Details has been Submitted.You will Recieve a call Shortly
    </h2>
    <button
      onClick={() => setModal(false)}
      style={{
        width: '50%',
        margin: '20px',
        border: 'none',
        borderRadius: '3px',
        color: 'white',
        backgroundColor: '#354c89',
        padding:"5px 0px"
      }}
    >
      Close
    </button>
  </div>
</Modal> */}
     
    </>
  )
}

export default Bike
