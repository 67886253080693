import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  // useLocation
  useParams,
  useSearchParams,
} from "react-router-dom";
import { GetDataWithToken, PostDataWithToken } from "../../api/apiHelper";
import {
  dispatchQuickQuote,
  selectedPlanAction,
} from "../../store/actions/userAction";
import Navbar from "../Navbar";
import Footer from "../Footer";
// import { createQuotePayloadObj } from "react-select";
import {
  // downloadBajajPdf,
  // downloadBase64File,
  downloadDigitPdf,
  // changePolicyStatus,
} from "../Services/TPApiCall";
import { toast } from "react-toastify";
import {decryptAes256Text} from '../Services/TPApiCall'
// import Layout from '../Layout
// var FileSaver = require("file-saver");

const SuccessMessage = () => {
  const selectedPlan = useSelector((state) => state.root.selectedPlan);
  const apiRequestQQ = useSelector((state) => state.root.apiRequestQQ);
  const [loading, setLoading] = useState(true);

  const searchParams = new URLSearchParams(window.location.search);
  const bajajId = searchParams.get("p_policy_ref");
  const kotakId = searchParams.get("vPolicyNumber");
  const policyNumber = searchParams.get("policyNo");
  const ApiUniqueNumber = searchParams.get("UniqueNumber");
  const caseId = searchParams.get("caseId");
  const TransactionId = searchParams.get("TransactionId");
  const partner = searchParams.get("partner");
  const [magmaData, setMagmaData] = useState("");
  const [futureData, setFutureData] = useState(null);
  const [futurePdf, setFuturePdf] = useState(null);
  const [sompoPdf, setSompoPdf] = useState(null);
  const [spinner, setSpinner] = useState(false);

  const dispatch = useDispatch();

  const params = useParams();

  useEffect(() => {
    const currentURL = window.location.search;

    const startIndex =
      currentURL.indexOf("Payment%20successfully.|") +
      "Payment%20successfully.|".length;
    const linkAfterSuccess = currentURL.substring(startIndex);

    // Decode the URL component to handle encoded characters
    const decodedLink = decodeURIComponent(linkAfterSuccess);

    // Do something with the extracted value
    var finalUrl = decodedLink.split("|");
    setSompoPdf(finalUrl[0]);
    // const urlParams =  new URLSearchParams(currentURL)
    // const quoteID = urlParams.get('MSG')
  }, []);

  const handlePdf = () => {
    window.open(sompoPdf, "_blank");
  };

  const digitKYCStatusApi = () => {
    let postData = {
      UniqueNumber: ApiUniqueNumber,
    };

    PostDataWithToken("kyc/digit-kyc", postData).then((response) => {
      if (response.status === true) {

      //   if (response.data['Motor-KYC status search Api'].kycVerificationStatus !== 'DONE') {
      //     alert('You have to complete your digit kyc first')
      //     window.open(response.data['Motor-KYC status search Api'].link, '_blank')
      //   } else if (response.data['Motor-KYC status search Api'].kycVerificationStatus === 'DONE') {
      //     downloadDigitPdf(
      //       ApiUniqueNumber,
      //     )

      //   }
      // }
      if (response.data.kycVerificationStatus !== 'DONE') {
        alert('You have to complete your digit kyc first')
        window.open(response.data.link, '_blank')
      } else if (response.data.kycVerificationStatus === 'DONE') {
        downloadDigitPdf(
          ApiUniqueNumber,
        )

      }
    }
    });
  };

  const magmaPDFDownload = async (selectedPlan, apiRequestQQ) => {
     

    setSpinner(true);

    try {
      const postData = {
        UniqueNumber: ApiUniqueNumber
        // PolicyNumber: selectedPlan?.PolicyNumber,
        // CustomerID: apiRequestQQ.CustomerID,
        // IntermediaryCode: "BRC0000446",
        // ProposalNumber: apiRequestQQ.ProposalId,
        // ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber?.Magma,
        // VehicleType: apiRequestQQ?.VehicleType,
      };
      const response = await PostDataWithToken("motor/magma-pdf", postData);
      if (response?.data?.OutputResult?.PolicyBase64String) {
        
        downloadBase64File(
          response?.data?.OutputResult?.PolicyBase64String,
          "RETRO_Magma_PDF"
        );
      } else {
        setLoading(false);
        setSpinner(false);
      }
      setSpinner(false);
      return response?.data?.OutputResult?.PolicyBase64String;
    } catch (err) {
      setSpinner(false);
      console.log(err);
    }
  };

  function downloadBase64File(contentBase64, fileName) {

    const linkSource = `data:application/pdf;base64,${contentBase64}`;
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = "_self";
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const digitStatusOFAPI = () => {
    let postData = {
      UniqueNumber: ApiUniqueNumber,
    };

    PostDataWithToken("motor/search-digit", postData).then((response) => {
      if (response.status === true) {
        if (response.data.kycStatus.kycVerificationStatus != "DONE") {
          alert("You have to complete your digit kyc first");
          window.open(response.data?.kycStatus?.link, "_blank");
        } else if (response.data.kycStatus.kycVerificationStatus === "DONE") {
          downloadDigitPdf(ApiUniqueNumber);
        }
      }
    });
  };



  const sendPolicyPdfToEmail = (policyNumber, base64) => {
    let postData = {
      name: apiRequestQQ.FirstName,
      amount: selectedPlan.FinalPremium,
      quoteId: policyNumber,
      email: apiRequestQQ.Email,
      base64: base64,
    };
    PostDataWithToken("motor/send-policy", postData).then((response) => {});
  };

  const downloadFile = (fileUrl, fileName) => {
    if (fileUrl) {
      // Create an anchor element
      const link = document.createElement("a");
      link.href = fileUrl;

      // Check if the file is an image
      if (/\.(png|jpg|jpeg|gif)$/i.test(fileUrl)) {
        // If it's an image, convert it to a data URI and set it as the href
        fetch(fileUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const objectURL = URL.createObjectURL(blob);
            link.href = objectURL;
            link.setAttribute("download", fileName || "image"); // Set desired file name here
            // Trigger click event on the anchor element
            link.click();
            // Clean up
            URL.revokeObjectURL(objectURL);
          })
          .catch((error) => console.error("Error downloading image:", error));
      } else {
        // For non-image files, just set the download attribute
        link.setAttribute("download", fileName || "file"); // Set desired file name here
        document.body.appendChild(link);
        // Trigger click event on the anchor element
        link.click();
        // Clean up
        document.body.removeChild(link);
      }
    } else {
      console.error("File URL is empty");
    }
  };

  useEffect(() => {
    if(caseId !== ''){
      downloadPdfFile()
    }
    if(partner === "Digit"){
      digitKYCStatusApi()
    }
    if (partner === "Future") {
      let obj = {
        PolicyNo: policyNumber,
        ApiUniqueNumber: ApiUniqueNumber,
      };
      PostDataWithToken("motor/future-pdf-download", obj).then((res) => {
        if (res?.status) {
          setFuturePdf(res?.pdf);
          setLoading(false);
        } else {
          toast("Something Went Wrong", { type: "error" });
        }
      });
    }
    if (partner == 'Magma') {

      const postData = {
        UniqueNumber: ApiUniqueNumber
        // ProposalNumber: apiRequestQQ?.ProposalId,
        // ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber?.Magma,
        // VehicleType: apiRequestQQ?.VehicleType,
      };
      PostDataWithToken("motor/proposalStatus", postData).then((response) => {
        if (response?.data?.OutputResult?.PolicyNumber) {
          setMagmaData(response?.data?.OutputResult?.PolicyNumber);
          magmaPDFDownload()
          setLoading(false);
          // const PolicyNumber = response?.data?.OutputResult?.PolicyNumber;
          // const newData = { ...selectedPlan, PolicyNumber: PolicyNumber };
          // dispatch(selectedPlanAction(newData));
        }
      });
    } else {
      setLoading(false);
    }
  }, []);

  const downloadPdfFile = () => {
    GetDataWithToken(`motor/getPolicyDocument/${caseId}`).then((res)=> {
      if(res?.data?.caseStatus === 'Policy Document Requested' || res?.data?.caseStatus === 'Payment Success'){
       let d = decryptAes256Text(res?.data?.policyDocumentUrl, process.env.REACT_APP_DECRYPTION_KEY)
       downloadFile(d, 'HDFC')
      }else{
        toast.error('Something went wrong')
      }
    })
  }

  return (
    <>
      <Navbar />
      <div className="success_main_info">
        <div className="text-center success_msg">
          {loading === true ? (
            <div className="m-5">
              <h1 className="card-title">Loading.............</h1>
              <h6 className="card-subtitle mb-2 text-muted">
                Please Wait.....
              </h6>
            </div>
          ) : (
            <>
              <div className="m-2">
                <div className="right_icon_img">
                  <img src="/assets/img/right_img.png"></img>
                </div>
                <h1 className="card-title">Congratulations</h1>
                <p className="card-text">Your Policy purchased successfully.</p>

                {partner === "Digit" && (
                  <p className="poli_num">Policy Number : {policyNumber}</p>
                )}
                {partner === "Magma" && (
                  <p>Policy Number: {magmaData}</p>
                )}

                {partner === "Future" && (
                  <>
                    Policy Number: {policyNumber}
                    Transaction ID: {TransactionId}
                  </>
                )}
                <Link to="/" className="card-link poli_num">
                  Explore More
                </Link>
              </div>
              <div className="card-footer">
                {partner === "Digit" ? (
                  <button
                    className="btn btn-primary fs-5 px-3 py-2 mt-1"
                    onClick={() => {

                      digitKYCStatusApi()

                    }}
                  >
                    Download PDF
                  </button>
                ) : partner == "Magma" ? (
                  <button
                    className="btn btn-primary fs-3 px-3 py-2 mt-1"
                    onClick={() => magmaPDFDownload()}
                  >
                    {spinner ? (
                      <div class="spinner-border text-light" role="status">
                        <span class="sr-only"></span>
                      </div>
                    ) : (
                      "Download PDF"
                    )}
                  </button>
                ) : partner === "Future" ? (
                  <button
                    className="btn btn-primary fs-3 px-3 py-2 mt-1"
                    onClick={() => downloadFile(futurePdf, "Policy_PDF")}
                  >
                    Download PDF
                  </button>
                ) : (
                  <button
                    className="btn btn-primary fs-3 px-3 py-2 mt-1"
                    onClick={() => downloadPdfFile()}
                  >
                    Download PDF
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SuccessMessage;
