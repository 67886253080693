import {
  GetDataWithToken,
  PostDataWithToken,
  PostImageDataWithToken,
} from "../../api/apiHelper";
import { store } from "../../store";
import {
  apiErrorReponse,
  dispatchQuickQuote,
  quickQuoteResult,
  removeApiErrorMessage,
  saveApiErrorReponse,
} from "../../store/actions/userAction";
import {
  createOnlinePolicyObj,
  createQuotePayloadObj,
  kycObj,
  kycStatus,
  motorQuotationObj,
  quickQuotePayloadObj,
  submitProposalRequest,
} from "../../store/reducers/userReducers";
import { failureCaseArr, policyResultArr } from "../MakeModelArr";
import { insurers } from "../utility/constants";
import { sendErrorMessage } from "./PosService";
import CryptoJS from "crypto-js";

export const createCustomer = (postdata) => {
  let formData = new FormData();
  for (let key in postdata) {
    formData.append(key, postdata[key]);
  }
  PostImageDataWithToken("motor/create-customer", formData).then((response) => {
    if (response.status === true && response?.data !== null) {
      dispatchQuickQuote("customerId", response.data.id);
    } else {
      // sendErrorMessage(response);
    }
  });
};

export const digitAPICall = (apiRequestQQ) => {
  const min = 10000000000;
  const max = 99999999999;

  const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);

  const uniqueNumber = "Digit" + randomNumber;

  dispatchQuickQuote("ApiUniqueNumber.Digit", uniqueNumber);

  const newData = { ...apiRequestQQ, ApiUniqueNumber: uniqueNumber };

  PostDataWithToken("motor/digit", quickQuotePayloadObj(newData))
    .then((response) => {
      if (response.status === true) {
        store.dispatch(removeApiErrorMessage("digit"));

        let {
          enquiryId,
          contract,
          vehicle,
          netPremium,
          grossPremium,
          serviceTax,
          discounts,
        } = response?.data["Motor-Quick Quote"];

        let ncbDiscount = 0;
        if (discounts?.otherDiscounts && discounts.otherDiscounts.length > 0) {
          let index = discounts.otherDiscounts.findIndex(
            (item) => item.discountType === "NCB_DISCOUNT"
          );
          if (index > -1) {
            ncbDiscount =
              discounts.otherDiscounts &&
              discounts.otherDiscounts[index].discountAmount;
          }
        }
        store.dispatch(
          quickQuoteResult(
            policyResultArr(
              "assets/img/logos/partners/digit.png",
              "digit",
              "",
              enquiryId,
              vehicle?.licensePlateNumber,
              vehicle?.make,
              vehicle?.model,
              "",
              contract?.coverages?.ownDamage?.netPremium,
              contract?.coverages?.thirdPartyLiability?.netPremium
                ? contract?.coverages?.thirdPartyLiability?.netPremium
                : "",
              contract.coverages.personalAccident.selection === true
                ? contract.coverages.personalAccident.netPremium
                : false,
              netPremium,
              serviceTax.totalTax,
              grossPremium,
              vehicle.vehicleIDV.minimumIdv || 0,
              vehicle.vehicleIDV.maximumIdv || 0,
              vehicle.vehicleIDV.idv || 0,
              contract.startDate,
              contract.endDate,
              discounts.totalLoadingAmount,
              "Go Digit General Insurance Ltd",
              apiRequestQQ.NewPolicyType,
              contract.coverages.addons.roadSideAssistance.selection === true
                ? contract.coverages.addons.roadSideAssistance.netPremium
                : false,
              contract.coverages.addons.engineProtection.selection === true
                ? contract.coverages.addons.engineProtection?.netPremium
                : false,
              contract.coverages.addons.tyreProtection.selection === true
                ? contract.coverages.addons.tyreProtection?.netPremium
                : false,
              contract.coverages.addons.rimProtection.selection === true
                ? contract.coverages.addons.rimProtection?.netPremium
                : false,
              contract.coverages.addons.consumables.selection === true
                ? contract.coverages.addons.consumables?.netPremium
                : false,
              contract.coverages.accessories.electrical.selection,
              contract.coverages.accessories.nonElectrical.selection,
              contract.coverages.addons.returnToInvoice.selection === true
                ? contract.coverages.addons.returnToInvoice?.netPremium
                : false,
              false,
              false,
              false,
              contract.coverages.unnamedPA.unnamedPaidDriver.selection === true
                ? contract.coverages.unnamedPA.unnamedPaidDriver?.netPremium
                : false,
              contract.coverages.addons.personalBelonging.selection === true
                ? contract.coverages.addons.personalBelonging?.netPremium
                : false,
              contract.coverages.addons.partsDepreciation.selection === true
                ? contract.coverages.addons.partsDepreciation?.netPremium
                : false,
              contract.coverages.addons.keyAndLockProtect.selection === true
                ? contract.coverages.addons.keyAndLockProtect?.netPremium
                : false,
              contract.coverages.personalAccident.selection === true
                ? contract.coverages.personalAccident.netPremium
                : 0,
              contract.coverages.unnamedPA.unnamedPaidDriver.selection === true
                ? contract.coverages.unnamedPA.unnamedPaidDriver.netPremium
                : 0,
              contract.coverages.accessories.electrical.selection === true
                ? contract.coverages.accessories.electrical.insuredAmount
                : 0,
              contract.coverages.accessories.nonElectrical.selection === true
                ? contract.coverages.accessories.nonElectrical.insuredAmount
                : 0,
              0,
              ncbDiscount,
              contract.coverages.accessories.cng.selection,
              contract.coverages.legalLiability.paidDriverLL?.selection === true
                ? contract.coverages.legalLiability.paidDriverLL?.netPremium
                : false
            )
          )
        );
      } else {
        const error = response;
     
        store.dispatch(
          apiErrorReponse(
            failureCaseArr(
              insurers.Digit.Api_name,
              '',
              error?.data?.message,
              insurers.Digit.logo
            )
          )
        )
        // saveApiErrorReponse(
        //   insurers.Digit.Api_name,
        //   error,
        //   insurers.Digit.logo
        // );
        sendErrorMessage(response);
      }
    })
    .catch((err) => console.log(err));
};

export const digitCreateQuoteApiCall = async (apiRequestQQ) => {
  try {
    let response = await PostDataWithToken(
      "motor/digit-create-quote",
      createQuotePayloadObj(apiRequestQQ)
    );
    return response;
  } catch (err) {
    alert("something went wrong");
  }
};

export const digitPaymentApi = (applicationId, ApiUniqueNumber) => {
  let postData = {
    applicationId: applicationId,
    cancelReturnUrl: process.env.REACT_APP_ERROR_URL,
    successReturnUrl: process.env.REACT_APP_SUCCESS_URL,
    ApiUniqueNumber: ApiUniqueNumber,
  };
  PostDataWithToken("motor/digit-payment", postData).then((response) => {
    if (response.status === true) {
      window.location.href = response.data.paymentLink;
    }
  });
};

export const savePolicyToDatabase = (apiRequestQQ) => {
  let obj = createOnlinePolicyObj(apiRequestQQ);
  let formData = new FormData();
  for (let key in obj) {
    formData.append(key, obj[key]);
  }

  // formData.append("posId", store.getState().root.userDetails.id);

  formData.append("customerId", store.getState().root.apiRequestQQ.customerId);
  PostImageDataWithToken("motor/fill-policy-data", formData).then(
    (response) => {
      if (response.status === true) {
        dispatchQuickQuote("policyId", response.data.id);
      } else {
        sendErrorMessage(response);
      }
    }
  );
};

export const downloadDigitPdf = (UniqueNumber) => {
  let postData = {
    UniqueNumber: UniqueNumber,
  };
  PostDataWithToken("motor/digit-pdf-download", postData).then((res) => {
    if (res.status === true) {
      window.location.href = res?.data?.schedulePathHC;
    }
  });
};

// magma

export const magmaAPICall = async (apiRequestQQ) => {
  
  const min = 10000000000;
  const max = 99999999999;

  const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);
  const uniqueNumber = "MAGMA" + randomNumber;
  dispatchQuickQuote("ApiUniqueNumber.Magma", uniqueNumber);
  const newData = { ...apiRequestQQ, ApiUniqueNumber: uniqueNumber };
  const response = await PostDataWithToken(
    "motor/magma",
    quickQuotePayloadObj(newData)
  );
  if (
    response.status === true &&
    response.data.ServiceResult === "Success" &&
    apiRequestQQ.VehicleType === "4w"
  ) {
    store.dispatch(removeApiErrorMessage("Magma"));
    const data = response?.data;

    const premiumBreakUp =
      data.OutputResult.PremiumBreakUp.VehicleBaseValue.AddOnCover;

    const optionAddOn = data.OutputResult.PremiumBreakUp.OptionalAddOnCovers;

    const externalCNGkitTP = optionAddOn.find(
      (cover) => cover.OptionalAddOnCoverName === "ExternalCNGkit-TP"
    );

    const externalCNGkitOD = optionAddOn.find(
      (cover) => cover.OptionalAddOnCoverName === "ExternalCNGkit-OD"
    );

    const inbuiltCNGkitTP = optionAddOn.find(
      (cover) => cover.OptionalAddOnCoverName === "InbuiltCNGkit-TP"
    );

    const inbuiltCNGkitOD = optionAddOn.find(
      (cover) => cover.OptionalAddOnCoverName === "InbuiltCNGkit-OD"
    );

    const discount = data.OutputResult.PremiumBreakUp.Discount;

    store.dispatch(
      quickQuoteResult(
        policyResultArr(
          "assets/img/magma.png",

          "Magma",

          "policy Id",

          "ApiId",

          apiRequestQQ?.RegistrationNumber,

          apiRequestQQ?.MakeName,

          apiRequestQQ?.ModelName,

          apiRequestQQ?.VariantName,

          premiumBreakUp.find((cover) => cover.AddOnCoverType === "Basic OD")
            ?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "Basic OD"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find((cover) => cover.AddOnCoverType === "Basic TP")
            ?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "Basic TP"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "PAOwnerCover"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "PAOwnerCover"
              )?.AddOnCoverTypePremium
            : false,

          data.OutputResult.PremiumBreakUp.NetPremium,

          data.OutputResult.PremiumBreakUp.SGST +
            data.OutputResult.PremiumBreakUp.CGST,

          data.OutputResult.PremiumBreakUp.TotalPremium,

          parseInt(data.OutputResult.LowerIDV),

          parseInt(data.OutputResult.HigherIDV),

          parseInt(data.OutputResult.IDVofthevehicle),

          data.OutputResult.PolicyEffectiveFromDate,

          data.OutputResult.PolicyEffectiveToDate,

          discount.find(
            (cover) => cover.DiscountType === "Basic OD-Detariff Discount"
          )?.DiscountTypeAmount
            ? discount.find(
                (cover) => cover.DiscountType === "Basic OD-Detariff Discount"
              )?.DiscountTypeAmount
            : false,

          "Magma",

          apiRequestQQ.NewPolicyType,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "RoadSideAssistance"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "RoadSideAssistance"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "EngineProtector"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "EngineProtector"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find((cover) => cover.AddOnCoverType === "Tyre Guard")
            ?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "Tyre Guard"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "Rim Safeguard"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "Rim Safeguard"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find((cover) => cover.AddOnCoverType === "Consumables")
            ?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "Consumables"
              )?.AddOnCoverTypePremium
            : false,

          optionAddOn.find(
            (cover) => cover.OptionalAddOnCoverName === "Electrical"
          )?.OptionalAddOnCoverPremium
            ? optionAddOn.find(
                (cover) => cover.OptionalAddOnCoverName === "Electrical"
              )?.OptionalAddOnCoverPremium
            : false,

          optionAddOn.find(
            (cover) => cover.OptionalAddOnCoverName === "NonElectrical"
          )?.OptionalAddOnCoverPremium
            ? optionAddOn.find(
                (cover) => cover.OptionalAddOnCoverName === "NonElectrical"
              )?.OptionalAddOnCoverPremium
            : false,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "ReturnToInvoice"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "ReturnToInvoice"
              )?.AddOnCoverTypePremium
            : false,

          false,
          // "EngineGearBox",

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "NCB Protection"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "NCB Protection"
              )?.AddOnCoverTypePremium
            : false,

          discount.find(
            (cover) => cover.DiscountType === "Voluntary Excess Discount-OD"
          )?.DiscountTypeAmount
            ? discount.find(
                (cover) => cover.DiscountType === "Voluntary Excess Discount-OD"
              )?.DiscountTypeAmount
            : false,
          // "VoluntaryDeductive",

          optionAddOn.find(
            (cover) => cover?.OptionalAddOnCoverName === "UnnamedPACover"
          )?.OptionalAddOnCoverPremium
            ? optionAddOn.find(
                (cover) => cover?.OptionalAddOnCoverName === "UnnamedPACover"
              )?.OptionalAddOnCoverPremium
            : false,
          // 'PassengerCover',

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "LossOfPerBelongings"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "LossOfPerBelongings"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "ZeroDepreciation"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "ZeroDepreciation"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "KeyReplacement"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "KeyReplacement"
              )?.AddOnCoverTypePremium
            : false,

          "PaCoverAmount",

          "PassengerCoverAmount",

          discount.find(
            (cover) => cover.DiscountType === "Elecrical-Detariff Discount"
          )?.DiscountTypeAmount
            ? discount.find(
                (cover) => cover.DiscountType === "Elecrical-Detariff Discount"
              )?.DiscountTypeAmount
            : false,

          discount.find(
            (cover) => cover.DiscountType === "NonElecrical-Detariff Discount"
          )?.DiscountTypeAmount
            ? discount.find(
                (cover) =>
                  cover.DiscountType === "NonElecrical-Detariff Discount"
              )?.DiscountTypeAmount
            : false,

          "VoluntaryAmount",

          discount.find((cover) => cover.DiscountType === "Bonus Discount - OD")
            ?.DiscountTypeAmount
            ? discount.find(
                (cover) => cover.DiscountType === "Bonus Discount - OD"
              )?.DiscountTypeAmount
            : false,
          // 'NCBDiscount',

          externalCNGkitTP
            ? externalCNGkitTP.OptionalAddOnCoverPremium +
                (externalCNGkitOD?.OptionalAddOnCoverPremium || 0)
            : inbuiltCNGkitTP
            ? inbuiltCNGkitTP.OptionalAddOnCoverPremium +
              (inbuiltCNGkitOD?.OptionalAddOnCoverPremium || 0)
            : false,
          // CNGValue

          optionAddOn.find(
            (cover) => cover.OptionalAddOnCoverName === "LLPaidDriverCleaner-TP"
          )?.OptionalAddOnCoverPremium
            ? optionAddOn.find(
                (cover) =>
                  cover.OptionalAddOnCoverName === "LLPaidDriverCleaner-TP"
              )?.OptionalAddOnCoverPremium
            : false,
          // DriverLiability
          discount.find(
            (cover) => cover.DiscountType === "ExternalCNGkit-Detariff Discount"
          )?.DiscountTypeAmount
            ? discount.find(
                (cover) =>
                  cover.DiscountType === "ExternalCNGkit-Detariff Discount"
              )?.DiscountTypeAmount
            : false
        )
      )
    );
  } else if (
    response.status === true &&
    response.data.ServiceResult === "Success" &&
    apiRequestQQ.VehicleType === "2w"
  ) {
    
    store.dispatch(removeApiErrorMessage("Magma"));

    const data = response?.data;

    const premiumBreakUp = data.OutputResult.PremiumBreakUp.OptionalAddOnCovers;

    const optionAddOn = data.OutputResult.PremiumBreakUp.OptionalAddOnCovers;

    const discount = data.OutputResult.PremiumBreakUp.Discount;

    store.dispatch(
      quickQuoteResult(
        policyResultArr(
          "assets/images/logos/partners/magma.png",

          "Magma",

          "policy Id",

          "ApiId",

          apiRequestQQ?.RegistrationNumber,

          apiRequestQQ?.MakeName,

          apiRequestQQ?.ModelName,

          apiRequestQQ?.VariantName,

          premiumBreakUp.find((cover) => cover.AddOnCoverType === "Basic OD")
            ?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "Basic OD"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find((cover) => cover.AddOnCoverType === "Basic TP")
            ?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "Basic TP"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "PAOwnerCover"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "PAOwnerCover"
              )?.AddOnCoverTypePremium
            : false,

          data.OutputResult.PremiumBreakUp.NetPremium,

          data.OutputResult.PremiumBreakUp.SGST +
            data.OutputResult.PremiumBreakUp.CGST,

          data.OutputResult.PremiumBreakUp.TotalPremium,

          data.OutputResult.LowerIDV,

          data.OutputResult.HigherIDV,

          data.OutputResult.IDVofthevehicle,

          data.OutputResult.PolicyEffectiveFromDate,

          data.OutputResult.PolicyEffectiveToDate,

          discount.find(
            (cover) => cover.DiscountType === "Basic OD-Detariff Discount"
          )?.DiscountTypeAmount
            ? discount.find(
                (cover) => cover.DiscountType === "Basic OD-Detariff Discount"
              )?.DiscountTypeAmount
            : false,

          "Magma",

          apiRequestQQ.NewPolicyType,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "RoadSideAssistance"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "RoadSideAssistance"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "EngineProtector"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "EngineProtector"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find((cover) => cover.AddOnCoverType === "Tyre Guard")
            ?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "Tyre Guard"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "Rim Safeguard"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "Rim Safeguard"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find((cover) => cover.AddOnCoverType === "Consumables")
            ?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "Consumables"
              )?.AddOnCoverTypePremium
            : false,

          optionAddOn.find(
            (cover) => cover.OptionalAddOnCoverName === "Electrical"
          )?.OptionalAddOnCoverPremium
            ? optionAddOn.find(
                (cover) => cover.OptionalAddOnCoverName === "Electrical"
              )?.OptionalAddOnCoverPremium
            : false,

          optionAddOn.find(
            (cover) => cover.OptionalAddOnCoverName === "NonElectrical"
          )?.OptionalAddOnCoverPremium
            ? optionAddOn.find(
                (cover) => cover.OptionalAddOnCoverName === "NonElectrical"
              )?.OptionalAddOnCoverPremium
            : false,

          false,
          // "InvoiceCover",

          false,
          // "EngineGearBox",

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "NCB Protection"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "NCB Protection"
              )?.AddOnCoverTypePremium
            : false,

          discount.find(
            (cover) => cover.DiscountType === "Voluntary Excess Discount-OD"
          )?.DiscountTypeAmount
            ? discount.find(
                (cover) => cover.DiscountType === "Voluntary Excess Discount-OD"
              )?.DiscountTypeAmount
            : false,
          // "VoluntaryDeductive",

          optionAddOn.find(
            (cover) => cover?.OptionalAddOnCoverName === "UnnamedPACover"
          )?.OptionalAddOnCoverPremium
            ? optionAddOn.find(
                (cover) => cover?.OptionalAddOnCoverName === "UnnamedPACover"
              )?.OptionalAddOnCoverPremium
            : false,
          // 'PassengerCover',

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "LossOfPerBelongings"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "LossOfPerBelongings"
              )?.AddOnCoverTypePremium
            : false,

          premiumBreakUp.find(
            (cover) => cover.AddOnCoverType === "ZeroDepreciation"
          )?.AddOnCoverTypePremium
            ? premiumBreakUp.find(
                (cover) => cover.AddOnCoverType === "ZeroDepreciation"
              )?.AddOnCoverTypePremium
            : false,

          false,
          // 'KeyReplacement',

          "PaCoverAmount",

          "PassengerCoverAmount",

          discount.find(
            (cover) => cover.DiscountType === "Elecrical-Detariff Discount"
          )?.DiscountTypeAmount
            ? discount.find(
                (cover) => cover.DiscountType === "Elecrical-Detariff Discount"
              )?.DiscountTypeAmount
            : false,

          discount.find(
            (cover) => cover.DiscountType === "NonElecrical-Detariff Discount"
          )?.DiscountTypeAmount
            ? discount.find(
                (cover) =>
                  cover.DiscountType === "NonElecrical-Detariff Discount"
              )?.DiscountTypeAmount
            : false,

          "VoluntaryAmount",

          discount.find((cover) => cover.DiscountType === "Bonus Discount - OD")
            ?.DiscountTypeAmount
            ? discount.find(
                (cover) => cover.DiscountType === "Bonus Discount - OD"
              )?.DiscountTypeAmount
            : false,
          // 'NCBDiscount',

          optionAddOn.find(
            (cover) => cover.OptionalAddOnCoverName === "ExternalCNGkit-TP"
          )?.OptionalAddOnCoverPremium
            ? optionAddOn.find(
                (cover) => cover.OptionalAddOnCoverName === "ExternalCNGkit-TP"
              )?.OptionalAddOnCoverPremium +
                optionAddOn.find(
                  (cover) =>
                    cover.OptionalAddOnCoverName === "ExternalCNGkit-OD"
                )?.OptionalAddOnCoverPremium
            : false,
          // CNGValue

          optionAddOn.find(
            (cover) => cover.OptionalAddOnCoverName === "LLPaidDriverCleaner-TP"
          )?.OptionalAddOnCoverPremium
            ? optionAddOn.find(
                (cover) =>
                  cover.OptionalAddOnCoverName === "LLPaidDriverCleaner-TP"
              )?.OptionalAddOnCoverPremium
            : false,
          // DriverLiability
          discount.find(
            (cover) => cover.DiscountType === "ExternalCNGkit-Detariff Discount"
          )?.DiscountTypeAmount
            ? discount.find(
                (cover) =>
                  cover.DiscountType === "ExternalCNGkit-Detariff Discount"
              )?.DiscountTypeAmount
            : false
        )
      )
    );
  } else {
    dispatchQuickQuote("MAGMAStatus", false);
    const error = response;
    // saveApiErrorReponse("Magma", error, "assets/img/magma.png");
  }
};

export const magmaProposalCall = async (data) => {
  
  try {
    const response = await PostDataWithToken(
      "motor/magmaProposal",
      createQuotePayloadObj(data)
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const magmaProposalStatuscall = async (data) => {
  try {
    const response = await PostDataWithToken("motor/proposalStatus", data);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const magmaPaymentcall = async (apiRequestQQ) => {
  try {
    const postData = {
      ProposalNumber: apiRequestQQ?.ProposalId,
      CustomerID: apiRequestQQ.CustomerID,
      SuccessURL: process.env.REACT_APP_SUCCESS_URL,
      FailureURL: process.env.REACT_APP_ERROR_URL,
      CustomerEmailID: apiRequestQQ?.Email,
      BillingAmount: apiRequestQQ?.FinalPremium,
      CustomerMobileNumber: apiRequestQQ?.MobileNumber,
      PaymentGateway: "PAYU",
      ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber?.Magma,
      VehicleType: apiRequestQQ?.VehicleType,
    };
    const response = await PostDataWithToken("motor/generatePayment", postData);
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const magmaPDFDownload = async (selectedPlan, apiRequestQQ) => {
  try {
    const postData = {
      PolicyNumber: selectedPlan?.PolicyNumber,
      CustomerID: apiRequestQQ.CustomerID,
      IntermediaryCode: "BRC0000446",
      ProposalNumber: apiRequestQQ.ProposalId,
      ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber?.Magma,
      VehicleType: apiRequestQQ?.VehicleType,
    };
    const response = await PostDataWithToken("motor/magma-pdf", postData);
    if (response?.data?.OutputResult?.PolicyBase64String) {
      
      downloadBase64File(
        response?.data?.OutputResult?.PolicyBase64String,
        "RETRO_Magma_PDF"
      );
    } else {
      setLoading(false);
    }
    return response?.data?.OutputResult?.PolicyBase64String;
  } catch (err) {
    console.log(err);
  }
};

export function downloadBase64File(contentBase64, fileName) {
  
  const linkSource = `data:application/pdf;base64,${contentBase64}`;
  const downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);

  downloadLink.href = linkSource;
  downloadLink.target = "_self";
  downloadLink.download = fileName;
  downloadLink.click();
}

// Future
export const FutureGeneralQuickQuote = async (apiRequestQQ) => {
 
  const min = 10000000000;
  const max = 99999999999;

  const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);

  const uniqueNumber = "Future" + randomNumber;

  dispatchQuickQuote("ApiUniqueNumber.Future", uniqueNumber);

  const newData = { ...apiRequestQQ, ApiUniqueNumber: uniqueNumber };

  const response = await PostDataWithToken(
    "motor/futuregeneral",
    quickQuotePayloadObj(newData)
  );

  if (response.status === true) {
    if (
      response.data.GetQuoteResponse.GetQuoteResult.Root.Policy.Status[
        "_text"
      ] === "Successful"
    ) {
      const data = response.data.GetQuoteResponse.GetQuoteResult.Root.Policy;
      const table =
        response.data.GetQuoteResponse.GetQuoteResult.Root.Policy.NewDataSet
          .Table1;
      let dis = getFutureGenValue(table, "DISCPERC", "OD");
      let quoteNo =
        response.data.GetQuoteResponse.GetQuoteResult.Root.Client.QuotationNo[
          "_text"
        ];
      dispatchQuickQuote("FutureQuotationNumber", quoteNo);
      dispatchQuickQuote("FutureDiscount", dis);
      apiRequestQQ?.VehicleType === "4w"
        ? store.dispatch(
            quickQuoteResult(
              policyResultArr(
                "assets/img/furturegeneral.png",
                "Future",
                data.NewDataSet.Table.PolNo,
                data.ProductUINNo,
                apiRequestQQ.RegistrationNumber,
                apiRequestQQ.MakeName,
                apiRequestQQ.ModelName,
                apiRequestQQ.VariantName,
                parseFloat(
                  getFutureGenValue(table, "Total Basic OD Premium", "OD")
                ),
                parseFloat(
                  getFutureGenValue(table, "Total Basic TP  Premium (TP)", "TP")
                ),
                parseFloat(getFutureGenValue(table, "CPA", "TP")) > 0
                  ? parseFloat(getFutureGenValue(table, "CPA", "TP"))
                  : false,
                parseFloat(getFutureGenValue(table, "PrmDue", "OD")) +
                  parseFloat(getFutureGenValue(table, "PrmDue", "TP")) -
                  (parseFloat(getFutureGenValue(table, "ServTax", "OD")) +
                    parseFloat(getFutureGenValue(table, "ServTax", "TP"))),
                parseFloat(getFutureGenValue(table, "ServTax", "OD")) +
                  parseFloat(getFutureGenValue(table, "ServTax", "TP")),
                Math.round(
                  parseFloat(getFutureGenValue(table, "PrmDue", "OD")) +
                    parseFloat(getFutureGenValue(table, "PrmDue", "TP"))
                ),
                // apiRequestQQ?.idvRangeSet
                //   ? apiRequestQQ?.initialIdvData.Future.min
                //   : data.VehicleIDV['_text'] -
                //       (data.VehicleIDV['_text'] * 8) / 100,
                parseInt(data.VehicleIDV["_text"].replace(/,/g, "")) -
                  (parseInt(data.VehicleIDV["_text"].replace(/,/g, "")) * 20) /
                    100,
                // apiRequestQQ?.idvRangeSet
                //   ? apiRequestQQ?.initialIdvData.Future.max
                //   : data.VehicleIDV['_text'] +
                //       (data.VehicleIDV['_text'] * 30) / 100,
                parseInt(data.VehicleIDV["_text"].replace(/,/g, "")) +
                  (parseInt(data.VehicleIDV["_text"].replace(/,/g, "")) * 20) /
                    100,
                parseInt(data.VehicleIDV["_text"].replace(/,/g, "")),
                apiRequestQQ.PolicyStartDate,
                apiRequestQQ.PolicyEndDate,
                parseFloat(getFutureGenValue(table, "DISCPERC", "OD")),
                "FUTURE GENERALI INDIA INSURANCE COMPANY LTD.",
                apiRequestQQ.NewPolicyType,
                // parseFloat(getFutureGenValue(table, "RODSA", "OD")) > 0
                //   ? parseFloat(getFutureGenValue(table, "RODSA", "OD"))
                //   : false,
                table.find((item) =>
                  [
                    "STRSA",
                    "STZDP",
                    "ZDCNS",
                    "ZDCNE",
                    "ZDCNT",
                    "ZDCET",
                    "ZCETR",
                  ].includes(item?.Code?._text)
                )?.Code?._text || null,

                parseFloat(getFutureGenValue(table, "ENGPR", "OD")) > 0
                  ? parseFloat(getFutureGenValue(table, "ENGPR", "OD"))
                  : false,
                parseFloat(getFutureGenValue(table, 1, "OD")) > 0
                  ? parseFloat(getFutureGenValue(table, 1, "OD"))
                  : false,
                false,
                parseFloat(getFutureGenValue(table, "CONSM", "OD")) > 0
                  ? parseFloat(getFutureGenValue(table, "CONSM", "OD"))
                  : false,
                parseFloat(
                  getFutureGenValue(
                    table,
                    "Total Electrical Accessories Premium (IMT24)",
                    "OD"
                  )
                ) > 0
                  ? parseFloat(
                      getFutureGenValue(
                        table,
                        "Total Electrical Accessories Premium (IMT24)",
                        "OD"
                      )
                    )
                  : false,
                parseFloat(
                  getFutureGenValue(
                    table,
                    "Total Non-Electrical Accessories Premium (NEA)",
                    "OD"
                  )
                ) > 0
                  ? parseFloat(
                      getFutureGenValue(
                        table,
                        "Total Non-Electrical Accessories Premium (NEA)",
                        "OD"
                      )
                    )
                  : false,
                parseFloat(getFutureGenValue(table, 6, "OD")) > 0
                  ? parseFloat(getFutureGenValue(table, 6, "OD"))
                  : false,
                false,
                // parseFloat(getFutureGenValue(table, 4, "OD")) > 0
                //   ? parseFloat(getFutureGenValue(table, 4, "OD"))
                //   : false,
                table.find((item) => item?.Code?._text === "STNCB")?.Code
                  ?._text || null,
                parseFloat(getFutureGenValue(table, "VD", "OD")) > 0
                  ? parseFloat(getFutureGenValue(table, "VD", "OD"))
                  : false,
                parseFloat(
                  getFutureGenValue(
                    table,
                    "Add: Total Unnamed Passenger PA Premium (IMT16)",
                    "TP"
                  )
                ) > 0
                  ? parseFloat(
                      getFutureGenValue(
                        table,
                        "Add: Total Unnamed Passenger PA Premium (IMT16)",
                        "TP"
                      )
                    )
                  : false,
                false,
                parseFloat(getFutureGenValue(table, "ZODEP", "OD")) > 0
                  ? parseFloat(getFutureGenValue(table, "ZODEP", "OD"))
                  : false,
                false,
                parseFloat(getFutureGenValue(table, "CPA", "TP")) > 0
                  ? parseFloat(getFutureGenValue(table, "CPA", "TP"))
                  : false,
                parseFloat(getFutureGenValue(table, "APA", "TP")) > 0
                  ? parseFloat(getFutureGenValue(table, "APA", "TP"))
                  : false,
                parseFloat(getFutureGenValue(table, "EAV", "OD")) > 0
                  ? parseFloat(getFutureGenValue(table, "EAV", "OD"))
                  : false,
                parseFloat(getFutureGenValue(table, "NEA", "OD")) > 0
                  ? parseFloat(getFutureGenValue(table, "NEA", "OD"))
                  : false,
                parseFloat(getFutureGenValue(table, "VD", "OD")) > 0
                  ? parseFloat(getFutureGenValue(table, "VD", "OD"))
                  : false,
                parseFloat(
                  getFutureGenValue(
                    table,
                    "Less: Total No Claim Bonus Premium (NCB)",
                    "OD"
                  )
                )
                  ? parseFloat(
                      getFutureGenValue(
                        table,
                        "Less: Total No Claim Bonus Premium (NCB)",
                        "OD"
                      )
                    )
                  : false,
                parseFloat(
                  getFutureGenValue(
                    table,
                    "Total CNG Kit Fittings Premium (IMT25)",
                    "OD"
                  )
                ) > 0
                  ? parseFloat(
                      getFutureGenValue(
                        table,
                        "Total CNG Kit Fittings Premium (IMT25)",
                        "OD"
                      )
                    )
                  : false,
                parseFloat(
                  getFutureGenValue(
                    table,
                    "Add: Total LL for paid driver and / or cleaner Premium (IMT28)",
                    "TP"
                  )
                ) > 0
                  ? parseFloat(
                      getFutureGenValue(
                        table,
                        "Add: Total LL for paid driver and / or cleaner Premium (IMT28)",
                        "TP"
                      )
                    )
                  : false,
                parseFloat(
                  getFutureGenValue(table, "Total ADDON Premium", "OD")
                ) > 0
                  ? parseFloat(
                      getFutureGenValue(table, "Total ADDON Premium", "OD")
                    )
                  : false
              )
            )
          )
        : apiRequestQQ?.VehicleType === "2w"
        ? store.dispatch(
            quickQuoteResult(
              policyResultArr(
                "assets/img/furturegeneral.png",
                "Future",
                data.NewDataSet.Table.PolNo,
                data.ProductUINNo,
                apiRequestQQ.RegistrationNumber,
                apiRequestQQ.MakeName,
                apiRequestQQ.ModelName,
                apiRequestQQ.VariantName,
                parseFloat(
                  getFutureGenValue(table, "Total Basic OD Premium (OD)", "OD")
                ),
                parseFloat(
                  getFutureGenValue(table, "Total Basic TP  Premium (TP)", "TP")
                ),
                parseFloat(getFutureGenValue(table, "CPA", "TP")) > 0
                  ? parseFloat(getFutureGenValue(table, "CPA", "TP"))
                  : false,
                parseFloat(getFutureGenValue(table, "PrmDue", "OD")) +
                  parseFloat(getFutureGenValue(table, "PrmDue", "TP")) -
                  (parseFloat(getFutureGenValue(table, "ServTax", "OD")) +
                    parseFloat(getFutureGenValue(table, "ServTax", "TP"))),
                parseFloat(getFutureGenValue(table, "ServTax", "OD")) +
                  parseFloat(getFutureGenValue(table, "ServTax", "TP")),
                Math.round(
                  parseFloat(getFutureGenValue(table, "PrmDue", "OD")) +
                    parseFloat(getFutureGenValue(table, "PrmDue", "TP"))
                ),
                // apiRequestQQ?.idvRangeSet
                //   ? apiRequestQQ?.initialIdvData.Future.min
                //   : data.VehicleIDV['_text'] -
                //       (data.VehicleIDV['_text'] * 8) / 100,
                parseInt(data.VehicleIDV["_text"].replace(",", "")) -
                  (parseInt(data.VehicleIDV["_text"].replace(",", "")) * 20) /
                    100,
                // apiRequestQQ?.idvRangeSet
                //   ? apiRequestQQ?.initialIdvData.Future.max
                //   : data.VehicleIDV['_text'] +
                //       (data.VehicleIDV['_text'] * 30) / 100,
                parseInt(data.VehicleIDV["_text"].replace(",", "")) +
                  (parseInt(data.VehicleIDV["_text"].replace(",", "")) * 20) /
                    100,
                parseInt(data.VehicleIDV["_text"].replace(",", "")),
                apiRequestQQ.PolicyStartDate,
                apiRequestQQ.PolicyEndDate,
                parseFloat(getFutureGenValue(table, "DISCPERC", "OD")),
                "FUTURE GENERALI INDIA INSURANCE COMPANY LTD.",
                apiRequestQQ.NewPolicyType,
                // parseFloat(getFutureGenValue(table, "RODSA", "OD")) > 0
                //   ? parseFloat(getFutureGenValue(table, "RODSA", "OD"))
                //   : false,
                parseFloat(
                  getFutureGenValue(
                    table,
                    "Premium for Basic Road Side Assistance(CV3)",
                    "OD"
                  )
                ) > 0
                  ? parseFloat(
                      getFutureGenValue(
                        table,
                        "Premium for Basic Road Side Assistance(CV3)",
                        "OD"
                      )
                    )
                  : false,

                parseFloat(getFutureGenValue(table, "ENGPR", "OD")) > 0
                  ? parseFloat(getFutureGenValue(table, "ENGPR", "OD"))
                  : false,
                parseFloat(getFutureGenValue(table, 1, "OD")) > 0
                  ? parseFloat(getFutureGenValue(table, 1, "OD"))
                  : false,
                false,
                parseFloat(
                  getFutureGenValue(table, "Premium for Consumable(CV4)", "OD")
                ) > 0
                  ? parseFloat(
                      getFutureGenValue(
                        table,
                        "Premium for Consumable(CV4)",
                        "OD"
                      )
                    )
                  : false,
                parseFloat(getFutureGenValue(table, "EAV", "OD")) > 0
                  ? parseFloat(getFutureGenValue(table, "EAV", "OD"))
                  : false,
                parseFloat(getFutureGenValue(table, "NEA", "OD")) > 0
                  ? parseFloat(getFutureGenValue(table, "NEA", "OD"))
                  : false,
                parseFloat(getFutureGenValue(table, 6, "OD")) > 0
                  ? parseFloat(getFutureGenValue(table, 6, "OD"))
                  : false,
                false,
                // parseFloat(getFutureGenValue(table, 4, "OD")) > 0
                //   ? parseFloat(getFutureGenValue(table, 4, "OD"))
                //   : false,
                table.find((item) => item?.Code?._text === "STNCB")?.Code
                  ?._text || null,
                parseFloat(getFutureGenValue(table, "VD", "OD")) > 0
                  ? parseFloat(getFutureGenValue(table, "VD", "OD"))
                  : false,
                parseFloat(getFutureGenValue(table, "APA", "TP")) > 0
                  ? parseFloat(getFutureGenValue(table, "APA", "TP"))
                  : false,
                false,
                parseFloat(
                  getFutureGenValue(
                    table,
                    "Premium for Zero Depreciation(CV21)",
                    "OD"
                  )
                ) > 0
                  ? parseFloat(
                      getFutureGenValue(
                        table,
                        "Premium for Zero Depreciation(CV21)",
                        "OD"
                      )
                    )
                  : false,
                false,
                parseFloat(getFutureGenValue(table, "CPA", "TP")) > 0
                  ? parseFloat(getFutureGenValue(table, "CPA", "TP"))
                  : false,
                parseFloat(getFutureGenValue(table, "APA", "TP")) > 0
                  ? parseFloat(getFutureGenValue(table, "APA", "TP"))
                  : false,
                parseFloat(getFutureGenValue(table, "EAV", "OD")) > 0
                  ? parseFloat(getFutureGenValue(table, "EAV", "OD"))
                  : false,
                parseFloat(getFutureGenValue(table, "NEA", "OD")) > 0
                  ? parseFloat(getFutureGenValue(table, "NEA", "OD"))
                  : false,
                parseFloat(getFutureGenValue(table, "VD", "OD")) > 0
                  ? parseFloat(getFutureGenValue(table, "VD", "OD"))
                  : false,
                parseFloat(
                  getFutureGenValue(
                    table,
                    "Total No Claim Bonus Premium (NCB)",
                    "OD"
                  )
                )
                  ? parseFloat(
                      getFutureGenValue(
                        table,
                        "Total No Claim Bonus Premium (NCB)",
                        "OD"
                      )
                    )
                  : false,
                parseFloat(getFutureGenValue(table, "CNG", "TP")) > 0
                  ? parseFloat(getFutureGenValue(table, "CNG", "TP"))
                  : false,
                parseFloat(
                  getFutureGenValue(
                    table,
                    "Add: Total LL for paid driver and / or cleaner Premium (IMT28)",
                    "TP"
                  )
                ) > 0
                  ? parseFloat(
                      getFutureGenValue(
                        table,
                        "Add: Total LL for paid driver and / or cleaner Premium (IMT28)",
                        "TP"
                      )
                    )
                  : false,
                parseFloat(
                  getFutureGenValue(table, "Total ADDON Premium", "OD")
                ) > 0
                  ? parseFloat(
                      getFutureGenValue(table, "Total ADDON Premium", "OD")
                    ) +
                      parseFloat(getFutureGenValue(table, "CPA", "TP")) +
                      parseFloat(
                        getFutureGenValue(
                          table,
                          "Add: Total LL for paid driver and / or cleaner Premium (IMT28)",
                          "TP"
                        )
                      )
                  : false
              )
            )
          )
        : null;
    }
  } else {
    // sendErrorMessage(response)
  }
};

export const FutureGeneralInspection = (apiRequestQQ) => {
  try {
    const response = PostDataWithToken(
      "motor/future-inspection",
      createQuotePayloadObj(apiRequestQQ)
    );
    return response;
  } catch (err) {
    throw err;
  }
};

const getFutureGenValue = (arr, Description, Type) => {
  let index = arr.findIndex(
    (val) =>
      val.Description["_text"] === Description && val.Type["_text"] === Type
  );
  if (index > -1) {
    return arr[index].BOValue["_text"];
  } else {
    return 0;
  }
};

// New Structure
export const processFlow = async (data) => {
  try {
    const response = await PostDataWithToken("admin/flow", data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getMotorQuote = async (motorRequest) => {
  console.log('MotorRequestInTP', motorRequest)
  try {
    const response = await PostDataWithToken(
      "motor/getQuotesData",
      motorQuotationObj(motorRequest)
    );

    return response?.data;
  } catch (error) {
    return error;
  }
};

export const getKycConfig = async (insurerId, customerType) => {
  const postData = {
    insurerId: insurerId,
    customerType: customerType,
  };
  try {
    const response = await PostDataWithToken("kyc/getKycConfig", postData);
    return response;
  } catch (error) {
    return error;
  }
};

export const getKycStatus = async (data, planDetail) => {
  try {
    const response = await PostDataWithToken(
      "kyc/getKycStatus",
      kycStatus(data, planDetail)
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getKyc = async (data, selectedPlan) => {
  try {
    const response = await PostDataWithToken(
      "kyc/getKyc",
      kycObj(data, selectedPlan)
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const submitProposal = async (newData, selectedPlan) => {
  console.log('submitProposal:- ', newData)
  try {
    const response = await PostDataWithToken(
      "motor/submitProposal",
      submitProposalRequest(newData, selectedPlan)
    );

    return response;
  } catch (error) {
    1901261120;
    return error;
  }
};

export const decryptAes256Text = (encrypted, key) => {
  // Decode base64 encoded string to a WordArray
  const input = CryptoJS.enc.Base64.parse(encrypted);

  // Extract the IV (first 16 bytes) and the ciphertext (remaining bytes)
  const iv = CryptoJS.lib.WordArray.create(input.words.slice(0, 4), 16); // First 16 bytes (128 bits)
  const ciphertext = CryptoJS.lib.WordArray.create(
    input.words.slice(4),
    input.sigBytes - 16
  ); // Remaining bytes

  // Hash the key using SHA-256
  const hashedKey = CryptoJS.SHA256(key);

  // Decrypt the ciphertext using the hashed key and IV
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: ciphertext },
    hashedKey,
    {
      iv: iv,
      mode: CryptoJS.mode.CTR,
      padding: CryptoJS.pad.NoPadding, // NoPadding because CTR mode doesn't require padding
    }
  );

  // Convert decrypted WordArray to UTF-8 string
  return CryptoJS.enc.Utf8.stringify(decrypted);
};


export const getPreviousJourneyDetails = async (data) => {
  try {
    const response = await PostDataWithToken("admin/getFlow", data);
    return response;
  } catch (error) {
    return error;
  }
};