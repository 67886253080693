import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Footer from './Footer'
import Navbar from './Navbar'
import { QUICK_QUOTE_RESULTS } from '../store/types'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  dispatchQuickQuote,
  resetQuickQuoteResults,
  selectedPlanAction,
  dispatchMotorQuote,
  quickQuoteResult,
  dispatchKycConfig,
  apiErrorReponse
} from '../store/actions/userAction'
import {
  getFiancierBankData,
  getStates,
  getVehiclePincode,
  getVehiclePreviousInsurer,
  getVehicleRto
} from './Services/masterServices'
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Label,
  Input
} from 'reactstrap'
import { Link } from 'react-router-dom'
import {
  FutureGeneralQuickQuote,
  digitAPICall,
  getKyc,
  getKycConfig,
  getKycStatus,
  getMotorQuote,
  getPreviousJourneyDetails,
  magmaAPICall
} from './Services/TPApiCall'
import Skeleton from './Skeleton/Skeleton'
import EditDetailsModal from '../components/EditDetailsModal'
import { store } from '../store'
import {
  failureCaseArr,
  newPolicyResultArr,
  policyResultArr
} from './MakeModelArr'
import {
  POLICY_STATUS,
  POLICY_TYPE,
  extractCustomerLeadId,
  insurers,
  kycType
} from './utility/constants'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { BsDownload } from 'react-icons/bs'
import { IoMdShare } from 'react-icons/io'
import { PDFDocument } from 'pdf-lib'
// import {
//   getVehicleMake,
//   getVehicleModel,
//   getVehicleModel
// } from '../store/actions/userAction'
function QuoteBike () {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset
  } = useForm({ mode: 'onBlur' })
  const userDetails = useSelector(state => state?.root?.userDetails)
  const location = useLocation()
  const [disabled, setDisabled] = useState('')
  const [modalKyc, setModalKyc] = useState(false)
  const [planDetail, setPlanDetail] = useState(null)
  const [prevoisNCB, setPrevoiusNCB] = useState(0)
  const [NewPolicyType, SetNewPolicyType] = useState('')
  const [idvError, setIdvError] = useState(false)
  const ApiErrorArr = useSelector(state => state?.root?.ApiErrorArr)
  const [idvModal, setIdvModal] = useState(false)
  const [addOnFilter, setAddonFilter] = useState(false)
  const [onMouseLeave, setOnMouseLeave] = useState(true)
  const [changeAddonFilter, setChnageAddonFilter] = useState(false)
  const [additionalCover, setAdditionalCover] = useState(false)
  const [onMouseLeaveCover, setOnMouseLeaveCover] = useState(true)
  const [changeCoverFilter, setChangeCoverFilter] = useState(false)
  const [additionCoverOpen, setAddditionCoverOpen] = useState(false)
  const [editDetails, setEditDetails] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [ncbModel, setNcbModel] = useState(false)
  const [planModel, setPlanModel] = useState(false)
  const [planDropdown, setPlanDropdown] = useState(false)
  const [addCover, setAddCover] = useState(false)
  const [coverModel, setCoverModel] = useState(false)
  const [sortModel, setSortModel] = useState(false)
  const [insurer, setInsurerModel] = useState(false)
  const [planTypeModel, setPlanTypeModel] = useState(false)
  const [filterModel, setFilterModel] = useState(false)
  const [idvRangeError, setIdvRangeError] = useState(false)
  const [tooltipPosition, setTooltipPosition] = useState(0)
  const [modalOpen2, setModalOpen2] = useState(false)
  const [modalOpen3, setModalOpen3] = useState(false)
  const [modalOpen4, setModalOpen4] = useState(false)
  const [itemState, setItemState] = useState({})
  const [kycConfigData, setKycConfigData] = useState()

  const motorRequest = useSelector(state => state?.root?.motorRequest)

  const [dataMotorQuotation, setDataMotorQuotation] = useState({})

  const [validationError, setValidationError] = useState('')

  const [isPAChecked1, setIsPAChecked1] = useState(false)
  const [isPAChecked2, setIsPAChecked2] = useState(false)

  const [isRTIChecked1, setIsRTIChecked1] = useState(false)
  const [isRTIChecked2, setIsRTIChecked2] = useState(false)

  const [isZeroDepChecked1, setIsZeroDepChecked1] = useState(false)
  const [isZeroDepChecked2, setIsZeroDepChecked2] = useState(false)

  const [isEngineChecked1, setIsEngineChecked1] = useState(false)
  const [isEngineChecked2, setIsEngineChecked2] = useState(false)

  const [isTyreChecked1, setIsTyreChecked1] = useState(false)
  const [isTyreChecked2, setIsTyreChecked2] = useState(false)

  const [isFuelChecked1, setIsFuelChecked1] = useState(false)
  const [isFuelChecked2, setIsFuelChecked2] = useState(false)

  const [isElectricalChecked1, setIsElectricalChecked1] = useState(false)
  const [isElectricalChecked2, setIsElectricalChecked2] = useState(false)

  const [isNonElectricalChecked1, setIsNonElectricalChecked1] = useState(false)
  const [isNonElectricalChecked2, setIsNonElectricalChecked2] = useState(false)
  const [insurerData, setInsurerData] = useState([])

  const [showBox, setShowBox] = useState(false)
  const [modalTrue, setModalTrue] = useState(false)
  const [hidebtn, setHideBtn] = useState(false)
  const [minIdv, setMinIdv] = useState(null)
  const [maxIdv, setMaxIdv] = useState(null)
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false
  })
  const [spinner, setSpinner] = useState(false)

  const [magmaImg, setMagmaImg] = useState(null)

  const QuickQuoteResult = useSelector(state => state?.root?.QuickQouteResult)
  const apiRequestQQ = useSelector(state => state?.root?.apiRequestQQ)
  const QuickQouteResult = useSelector(state => state?.root?.QuickQouteResult)
  const [rangeValue, setRangeValue] = useState(QuickQuoteResult?.idv)
  const toggleModel = () => {
    setModalKyc(!modalKyc)
  }
  const [getMotorQuoteResponse, setGetMotorQuoteResponse] = useState(null)
  const [idv, setIdv] = useState(0)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [filter, setFilter] = useState({
    RoadSideAssistance: false,
    EngineProtection: false,
    TyreProtection: false,
    RimProtection: false,
    Consumables: false,
    IsElectricalAccessories: false,
    IsNonElectricalAccessories: false,
    PersonalAccident: false,
    InvoiceCover: false,
    EngineGearBox: false,
    NCBProtection: false,
    VoluntaryDeductive: false,
    PassengerCover: false,
    LossOfPersonalBelongings: false,
    ZeroDepriciationCover: false,
    KeyReplacement: false,
    LiabilitiesToPaidDriver: false,
    ElectricAmount: 0,
    NonElectricAmount: 0,
    PassengerCoverAmount: 0,
    VoluntaryDeductiveAmount: 0,
    NoOfLegelDrives: 0
  })

  const Covres = [
    {
      label: 'Personal Accident Cover',
      value: 'PersonalAccident',
      type: 'select'
    },
    {
      label: 'Electrical Accessories',
      value: 'IsElectricalAccessories',
      type: 'input',
      min: '1000',
      inputName: 'ElectricAmount'
    },
    {
      label: 'Non-Electrical Accessories',
      value: 'IsNonElectricalAccessories',
      type: 'input',
      min: '1000',
      inputName: 'NonElectricAmount'
    },
    {
      label: 'Is Bi-fuel Kit?',
      value: 'IsCngAccessories',
      type: 'input',
      min: 0,
      inputName: 'CNGValue'
    },
    {
      label: 'Passenger Cover',
      value: 'PassengerCover',
      type: 'input',
      min: '10000',
      inputName: 'PassengerCoverAmount'
    },
    {
      label: 'Legal Liability to Paid Driver?',
      value: 'LiabilitiesToPaidDriver',
      type: 'input',
      min: '10000',
      inputName: 'NoOfLegelDrives'
    }
  ]

  const addOnsData = [
    { label: 'Zero Dep Cover', value: 'ZeroDepriciationCover', type: 'select' },
    {
      label: 'Road Side Assistance',
      value: 'RoadSideAssistance',
      type: 'select'
    },
    { label: 'Consumables', value: 'Consumables', type: 'select' },
    { label: 'Key Replacement', value: 'KeyReplacement', type: 'select' },
    { label: 'Invoice Cover', value: 'InvoiceCover', type: 'select' },
    { label: 'Engine Gearbox Cover', value: 'EngineGearBox', type: 'select' },
    { label: 'NCB Protection', value: 'NCBProtection', type: 'select' },
    {
      label: 'Loss Of Personal Belongings',
      value: 'LossOfPersonalBelongings',
      type: 'select'
    },
    { value: 'EngineProtection', label: 'Engine Protection', type: 'select' },
    { value: 'TyreProtection', label: 'Tyre Protection', type: 'select' },
    { value: 'RimProtection', label: 'Rim Protection', type: 'select' },

    {
      label: 'Voluntary Deductive',
      value: 'VoluntaryDeductive',
      type: 'input',
      min: 0,
      inputName: 'VoluntaryDeductiveAmount'
    }
  ]

  // useEffect(() => {
  //   if (apiRequestQQ?.AddOns?.PersonalAccident) {
  //     setFilter({ ...filter, [PersonalAccident]: checked })
  //   }
  // }, [])

  const submitUserPlan = item => {
    const newData = { ...item, planType: item?.planType }
    if (item?.Api_name === 'Magma') {
      if (apiRequestQQ?.Idv < apiRequestQQ?.initialIdvData?.Magma?.min) {
        newData['Idv'] = apiRequestQQ?.initialIdvData?.Magma?.min
      } else if (apiRequestQQ?.Idv > apiRequestQQ?.initialIdvData?.Magma?.max) {
        newData['Idv'] = apiRequestQQ?.initialIdvData?.Magma?.max
      }
    } else if (item?.Api_name === 'Future') {
      if (apiRequestQQ?.Idv < apiRequestQQ?.initialIdvData?.Future?.min) {
        newData['Idv'] = apiRequestQQ?.initialIdvData?.Future?.min
      } else if (
        apiRequestQQ?.Idv > apiRequestQQ?.initialIdvData?.Future?.max
      ) {
        newData['Idv'] = apiRequestQQ?.initialIdvData?.Future?.max
      }
    } else if (item?.Api_name === 'digit') {
      if (apiRequestQQ?.Idv < apiRequestQQ?.initialIdvData?.digit?.min) {
        newData['Idv'] = apiRequestQQ?.initialIdvData?.digit?.min
      } else if (apiRequestQQ?.Idv > apiRequestQQ?.initialIdvData?.digit?.max) {
        newData['Idv'] = apiRequestQQ?.initialIdvData?.digit?.max
      }
    }

    dispatchQuickQuote('Idv', parseInt(newData?.idv))
    dispatch(selectedPlanAction(newData))
    dispatchQuickQuote('ApiId', item?.ApiId)
    dispatchQuickQuote('PaymentId', item?.ApiId)
    // if (idv === 0 && item?.Api_name == "digit") {
    //   dispatchQuickQuote("Idv", null);
    // } else {
    //   dispatchQuickQuote("Idv", item?.idv);
    // }
    if (item?.Api_name === 'Future') {
      dispatchQuickQuote('AddOns.' + 'discount', item?.discount)
      // dispatchQuickQuote("Idv", item?.idv);
    }
    dispatchQuickQuote('PolicyStartDate', item?.StartDate)
    dispatchQuickQuote('PolicyEndDate', item?.EndDate)
    navigate('/proposal')
  }

  let calculateYearDifference = (date1, date2) => {
    // Convert strings to Date objects if they are not already
    const startDate = new Date(date1)
    const endDate = new Date(date2)

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(startDate - endDate)

    // Convert milliseconds to years (assuming 365.25 days in a year for accuracy)
    const differenceYears = differenceMs / (1000 * 60 * 60 * 24 * 365.25)

    // Round down to the nearest integer to get the whole number of years
    // const yearsDifference = Math.floor(differenceYears);

    return differenceYears
    // setYearDiff[yearsDifference];
  }
  let yearDiff = calculateYearDifference(
    apiRequestQQ.RegistrationDate,
    new Date().toISOString().slice(0, 10)
  )

  useEffect(() => {
    // handleIDVFilter(rangeValue);
  }, [rangeValue])

  const handleChangeNCB = item => {
    setPrevoiusNCB(item)
    handleFilterOptions('', item)
  }

  const motorQuotation = async data => {
    console.log('motorQuotation addons', data)
    dispatch(resetQuickQuoteResults())
    if (data) {
      let response
      let isEqual
      let newData = { ...data }
      for (let key in data) {
        if (data[key] !== dataMotorQuotation[key]) {
          isEqual = false
          break
        } else {
          isEqual = true
        }
      }
      if (!isEqual) {
        dispatchMotorQuote('rId', '')
        newData = { ...newData, rId: '' }
      }
      setDataMotorQuotation(newData)
      console.log('newData updated for addon', newData)
      let updatedData = { ...newData }
      let executionCount = 0
      const fetchQuotesData = async () => {
        executionCount++
        try {
          if (
            updatedData?.rId &&
            response?.caseStatus === 'Quotes Generated' &&
            response?.isAllQuotesDone
          ) {
            response = await getMotorQuote(updatedData)
            setGetMotorQuoteResponse(response)
            if (executionCount >= 5) {
              handleQuotesData(response)
              clearInterval(interval)
            }

            if (response?.errors.length > 0) {
              if (response?.quotes.length > 0) {
                setGetMotorQuoteResponse(response)
                handleQuotesData(response)
              }
              response?.errors.map(error =>
                store.dispatch(
                  apiErrorReponse(
                    failureCaseArr(
                      error?.insurerName,
                      error?.insurerId,
                      error?.displayMessage,
                      error?.logo
                    )
                  )
                )
              )
              clearInterval(interval)
            }
            // if (response?.quotes?.length > 0) {
            //   if (executionCount >= 12) clearInterval(interval);

            //   response = await getMotorQuote(updatedData);
            //   setGetMotorQuoteResponse(response);
            //   handleQuotesData(response);
            // } else {
            //   response = await getMotorQuote(updatedData);
            // }
          } else if (
            updatedData?.rId &&
            response?.caseStatus === 'Quotes Requested'
          ) {
            response = await getMotorQuote(updatedData)
            setGetMotorQuoteResponse(response)
          } else if (response?.caseStatus === 'Quotes In Process') {
            if (response?.quotes?.length > 0) {
              if (executionCount >= 12) clearInterval(interval)
              response = await getMotorQuote(updatedData)
              setGetMotorQuoteResponse(response)
              handleQuotesData(response)
            } else {
              response = await getMotorQuote(updatedData)
            }
          } else {
            if (executionCount >= 12) {
              clearInterval(interval)
            } else {
              response = await getMotorQuote(updatedData)
            }
            if (
              response?.status === 'failure' &&
              response?.errors?.length > 0
            ) {
              response?.errors.map(error =>
                store.dispatch(
                  apiErrorReponse(
                    failureCaseArr(
                      error?.insurerName,
                      error?.insurerId,
                      error?.displayMessage,
                      error?.logo
                    )
                  )
                )
              )
              clearInterval(interval)
              throw new Error('API Error occurred')
            }
            setGetMotorQuoteResponse(response)
            updatedData = { ...updatedData, rId: response?.rId }
            dispatchMotorQuote('rId', response?.rId)
          }
        } catch (error) {
          console.error('Error occurred:', error)
          clearInterval(interval)
        }
      }

      const interval = setInterval(fetchQuotesData, 1000)
    }
  }

  const handleQuotesData = data => {
    try {
      data?.quotes?.map(item =>
        store.dispatch(
          quickQuoteResult(
            newPolicyResultArr(
              item?.insurerId === insurers.Digit.insurerId
                ? insurers.Digit.logo
                : item?.insurerId === insurers.Future.insurerId
                ? insurers.Future.logo
                : item?.insurerId === insurers.Bajaj.insurerId
                ? insurers.Bajaj.logo
                : item?.insurerId === insurers.HDFC.insurerId
                ? insurers.HDFC.logo
                : null,
              item?.insurer,
              '',
              '',
              motorRequest?.registrationNumber,
              item?.make,
              item?.model,
              item?.variant,
              item?.basicODPremium,
              item?.basicTPPremium,
              item?.netPremium,
              item?.gst,
              item?.finalPremium,
              item?.minIdv,
              item?.maxIdv,
              item?.idv,
              item?.policyStartDate,
              item?.policyEndDate,
              item?.discount,
              item?.insurer,
              item?.policyType,
              item?.roadSideAssistance?.isOpted,
              item?.roadSideAssistance?.isOpted
                ? item?.roadSideAssistance?.premium
                : item?.roadSideAssistance?.isOpted,
              item?.engineProtection?.isOpted,
              item?.engineProtection?.isOpted
                ? item?.engineProtection?.premium
                : item?.engineProtection?.isOpted,
              item?.tyreProtection?.isOpted,
              item?.tyreProtection?.isOpted
                ? item?.tyreProtection?.premium
                : item?.tyreProtection?.isOpted,
              item?.rimProtection?.isOpted,
              item?.rimProtection?.isOpted
                ? item?.rimProtection?.premium
                : item?.rimProtection?.isOpted,
              item?.consumables?.isOpted,
              item?.consumables?.isOpted
                ? item?.consumables?.premium
                : item?.consumables?.isOpted,
              item?.electricalAccessories?.isOpted,
              item?.electricalAccessories?.isOpted
                ? item?.electricalAccessories?.premium
                : item?.electricalAccessories?.isOpted,
              item?.nonElectricalAccessories?.isOpted,
              item?.nonElectricalAccessories?.isOpted
                ? item?.nonElectricalAccessories?.premium
                : item?.nonElectricalAccessories?.isOpted,
              item?.invoiceCover?.isOpted,
              item?.invoiceCover?.isOpted
                ? item?.invoiceCover?.premium
                : item?.invoiceCover?.isOpted,
              item?.ncbProtection?.isOpted,
              item?.ncbProtection?.isOpted
                ? item?.ncbProtection?.premium
                : item?.ncbProtection?.isOpted,
              item?.voluntaryDeductive?.isOpted,
              item?.voluntaryDeductive?.isOpted
                ? item?.voluntaryDeductive?.premium
                : item?.voluntaryDeductive?.isOpted,
              item?.passengerCover?.isOpted,
              item?.passengerCover?.isOpted
                ? item?.passengerCover?.premium
                : item?.passengerCover?.isOpted,
              item?.paCoverAmount?.isOpted,
              item?.paCoverAmount?.isOpted
                ? item?.paCoverAmount?.premium
                : item?.paCoverAmount?.isOpted,
              item?.lossOfPersonalBelongings?.isOpted,
              item?.lossOfPersonalBelongings?.isOpted
                ? item?.lossOfPersonalBelongings?.premium
                : item?.lossOfPersonalBelongings?.isOpted,
              item?.zeroDepriciationCover?.isOpted,
              item?.zeroDepriciationCover?.isOpted
                ? item?.zeroDepriciationCover?.premium
                : item?.zeroDepriciationCover?.isOpted,
              item?.keyReplacement?.isOpted,
              item?.keyReplacement?.isOpted
                ? item?.keyReplacement?.premium
                : item?.keyReplacement?.isOpted,

              item?.ncbDiscountAmount,
              item?.biFuelOd?.isOpted,
              item?.biFuelOd?.isOpted
                ? item?.biFuelOd?.premium
                : item?.biFuelOd?.isOpted,
              item?.biFuelTp?.isOpted,
              item?.biFuelTp?.isOpted
                ? item?.biFuelTp?.premium
                : item?.biFuelTp?.isOpted,
              item?.llToPaidDriver?.isOpted,
              item?.llToPaidDriver?.isOpted
                ? item?.llToPaidDriver?.premium
                : item?.llToPaidDriver?.isOpted,
              item?.caseId,
              item?.insurerId,
              item?.kycAdditionalData,
              item?.planId,
              item?.tppdDiscount ? item?.tppdDiscount : false,
              item?.loadingPremium ? item?.loadingPremium : false,
              item?.isAddonQuesAsk,
              item?.addonQuesArray,
              item?.isPanRequiredInProposal,
              item?.isAdharRequiredInProposal
            )
          )
        )
      )
    } catch (error) {
      console.log('first', error)
    }
  }

  const handleFilterOptions = (cover, item, idv, newStructureData) => {
    dispatchQuickQuote('isFiltered', true)
    console.log('newStructureData', newStructureData)
    let newData = { ...apiRequestQQ }
    let newStructData = newStructureData
      ? { ...newStructureData }
      : { ...motorRequest }
    if (cover) {
      if (cover === 'Comprehensive') {
        newStructData = {
          ...newStructData,
          newPolicyType: POLICY_TYPE.COMPREHENSIVE
        }
        dispatchMotorQuote('newPolicyType', POLICY_TYPE.COMPREHENSIVE)
      } else if (cover === 'ThirdParty') {
        newStructData = {
          ...newStructData,
          newPolicyType: POLICY_TYPE.THIRDPARTY
        }
        dispatchMotorQuote('newPolicyType', POLICY_TYPE.THIRDPARTY)
      } else {
        newStructData = { ...newStructData, newPolicyType: POLICY_TYPE.ODONLY }
        dispatchMotorQuote('newPolicyType', POLICY_TYPE.ODONLY)
      }
    }

    for (let key in filter) {
      newData[key] = filter[key]

      dispatchQuickQuote('AddOns.' + key, filter[key])
    }

    if (cover != '' && cover != undefined) {
      SetNewPolicyType(cover)
      newData['NewPolicyType'] = cover
      dispatchQuickQuote('NewPolicyType', cover)
    }
    let newItem = 0
    if (item && apiRequestQQ.IsVehicleNew === false) {
      newData['CurrentNoClaimBonus'] = item
      newItem =
        item === '0'
          ? '0'
          : item === '20'
          ? '0'
          : item === '25'
          ? '20'
          : item === '35'
          ? '25'
          : item === '45'
          ? '35'
          : item === '50'
          ? '45'
          : ''

      newData['PreviousNoClaimBonus'] = newItem
      dispatchQuickQuote('PreviousNoClaimBonus', newItem)
      dispatchMotorQuote('prevNoClaimBonus', newItem)
      dispatchQuickQuote('CurrentNoClaimBonus', item)
      newStructData = { ...newStructData, prevNoClaimBonus: newItem }
    }

    // if (idv >= range.minimumIdv && idv <= range.maximumIdv) {
    //   newData["Idv"] = idv;
    //   setIdvError(false);
    //   dispatchQuickQuote("Idv", idv);
    // } else {
    //   setIdvError(true);
    // }

    dispatch(resetQuickQuoteResults())

    // if (newStructureData?.idv >= 0) {
    // motorQuotation(newStructureData);
    // }

    setTimeout(() => {
      // Digit
      // digitAPICall(newData);
      // Future
      // FutureGeneralQuickQuote(newData);
      //  handleIDVFilter(newData?.Idv);
      // if (
      //   checkboxes?.checkbox1 ||
      //   checkboxes?.checkbox2 ||
      //   checkboxes?.checkbox3
      // ) {
      //   const newData2 = {
      //     ...newData,
      //     AddOns: { ...newData?.AddOns, PersonalAccident: false },
      //   };
      //   magmaAPICall(newData2);
      // } else {
      //   const newData2 = {
      //     ...newData,
      //     AddOns: { ...newData?.AddOns, PersonalAccident: true },
      //   };
      //   magmaAPICall(newData2);
      // }
      // Magma
      // magmaAPICall(newData)
    }, 500)
    console.log('newStructData addons', newStructData)
    motorQuotation(newStructData)
    setIdvModal(false)
  }

  const handleChangeFilter = e => {
    const { name, checked } = e.target
    setFilter({ ...filter, [name]: checked })
  }

  const handleChangeValue = e => {
    const { name, value } = e.target
    setFilter({ ...filter, [name]: parseFloat(value) })
  }

  useEffect(() => {
    if (additionCoverOpen) {
      setChangeCoverFilter(true)
    } else {
      setChnageAddonFilter(true)
    }
  }, [filter])

  useEffect(() => {
    if (changeAddonFilter === true && onMouseLeave === false) {
      let newStructureData = { ...motorRequest }
      for (let key in filter) {
        if (key === 'Consumables') {
          dispatchMotorQuote('consumables', filter[key])
          newStructureData['consumables'] = filter[key]
        } else if (key === 'ElectricAmount') {
          dispatchMotorQuote('electricAmount', filter[key])
          newStructureData['electricAmount'] = filter[key]
        } else if (key === 'EngineGearBox') {
          dispatchMotorQuote('engineGearBox', filter[key])
          newStructureData['engineGearBox'] = filter[key]
        } else if (key === 'EngineProtection') {
          dispatchMotorQuote('engineProtection', filter[key])
          newStructureData['engineProtection'] = filter[key]
        } else if (key === 'InvoiceCover') {
          dispatchMotorQuote('invoiceCover', filter[key])
          newStructureData['invoiceCover'] = filter[key]
        } else if (key === 'IsElectricalAccessories') {
          dispatchMotorQuote('isElectricalAccessories', filter[key])
          newStructureData['isElectricalAccessories'] = filter[key]
        } else if (key === 'IsNonElectricalAccessories') {
          dispatchMotorQuote('isNonElectricalAccessories', filter[key])
          newStructureData['isNonElectricalAccessories'] = filter[key]
        } else if (key === 'KeyReplacement') {
          dispatchMotorQuote('keyReplacement', filter[key])
          newStructureData['keyReplacement'] = filter[key]
        } else if (key === 'LiabilitiesToPaidDriver') {
          dispatchMotorQuote('liabilitiesToPaidDriver', filter[key])
          newStructureData['liabilitiesToPaidDriver'] = filter[key]
        } else if (key === 'LossOfPersonalBelongings') {
          dispatchMotorQuote('lossOfPersonalBelongings', filter[key])
          newStructureData['lossOfPersonalBelongings'] = filter[key]
        } else if (key === 'NCBProtection') {
          dispatchMotorQuote('ncbProtection', filter[key])
          newStructureData['ncbProtection'] = filter[key]
        } else if (key === 'NoOfLegelDrives') {
          if (filter?.LiabilitiesToPaidDriver === false) {
            dispatchMotorQuote('noOfPaidDrivers', '')
            newStructureData['noOfPaidDrivers'] = ''
          } else {
            dispatchMotorQuote('noOfPaidDrivers', filter[key])
            newStructureData['noOfPaidDrivers'] = filter[key]
          }
        } else if (key === 'NonElectricAmount') {
          dispatchMotorQuote('nonElectricAmount', filter[key])
          newStructureData['nonElectricAmount'] = filter[key]
        } else if (key === 'PassengerCover') {
          dispatchMotorQuote('isPassengerCover', filter[key])
          newStructureData['isPassengerCover'] = filter[key]
        } else if (key === 'PassengerCoverAmount') {
          if (filter?.PassengerCover === false) {
            dispatchMotorQuote('passengerCoverAmount', '')
            newStructureData['passengerCoverAmount'] = ''
          } else {
            dispatchMotorQuote('passengerCoverAmount', filter[key])
            newStructureData['passengerCoverAmount'] = filter[key]
          }
        } else if (key === 'PersonalAccident') {
          dispatchMotorQuote('personalAccident', filter[key])
          newStructureData['personalAccident'] = filter[key]
        } else if (key === 'RoadSideAssistance') {
          dispatchMotorQuote('roadSideAssistance', filter[key])
          newStructureData['roadSideAssistance'] = filter[key]
        } else if (key === 'TyreProtection') {
          dispatchMotorQuote('tyreProtection', filter[key])
          newStructureData['tyreProtection'] = filter[key]
        } else if (key === 'VoluntaryDeductive') {
          dispatchMotorQuote('isVoluntaryDeductable', filter[key])
          newStructureData['isVoluntaryDeductable'] = filter[key]
        } else if (key === 'VoluntaryDeductiveAmount') {
          dispatchMotorQuote('voluntaryDeductableAmount', filter[key])
          newStructureData['voluntaryDeductableAmount'] = filter[key]
        } else if (key === 'ZeroDepriciationCover') {
          dispatchMotorQuote('zeroDepriciationCover', filter[key])
          newStructureData['zeroDepriciationCover'] = filter[key]
        } else if (key === 'RimProtection') {
          dispatchMotorQuote('rimProtection', filter[key])
          newStructureData['rimProtection'] = filter[key]
        } else if (key === 'CNGValue') {
          dispatchMotorQuote('isCngAccessories', true)
          dispatchMotorQuote('cngValue', filter[key])
          newStructureData['cngValue'] = filter[key]
        }
      }
      console.log('newStructureData filter', newStructureData)
      handleFilterOptions('', '', '', newStructureData)
      setChnageAddonFilter(false)
    }
  }, [onMouseLeave])

  useEffect(() => {
    if (changeCoverFilter === true && onMouseLeaveCover === false) {
      let newStructureData = { ...motorRequest }
      for (let key in filter) {
        if (key === 'Consumables') {
          dispatchMotorQuote('consumables', filter[key])
          newStructureData['consumables'] = filter[key]
        } else if (key === 'ElectricAmount') {
          dispatchMotorQuote('electricAmount', filter[key])
          newStructureData['electricAmount'] = filter[key]
        } else if (key === 'EngineGearBox') {
          dispatchMotorQuote('engineGearBox', filter[key])
          newStructureData['engineGearBox'] = filter[key]
        } else if (key === 'EngineProtection') {
          dispatchMotorQuote('engineProtection', filter[key])
          newStructureData['engineProtection'] = filter[key]
        } else if (key === 'InvoiceCover') {
          dispatchMotorQuote('invoiceCover', filter[key])
          newStructureData['invoiceCover'] = filter[key]
        } else if (key === 'IsElectricalAccessories') {
          dispatchMotorQuote('isElectricalAccessories', filter[key])
          newStructureData['isElectricalAccessories'] = filter[key]
        } else if (key === 'IsNonElectricalAccessories') {
          dispatchMotorQuote('isNonElectricalAccessories', filter[key])
          newStructureData['isNonElectricalAccessories'] = filter[key]
        } else if (key === 'KeyReplacement') {
          dispatchMotorQuote('keyReplacement', filter[key])
          newStructureData['keyReplacement'] = filter[key]
        } else if (key === 'LiabilitiesToPaidDriver') {
          dispatchMotorQuote('liabilitiesToPaidDriver', filter[key])
          newStructureData['liabilitiesToPaidDriver'] = filter[key]
        } else if (key === 'LossOfPersonalBelongings') {
          dispatchMotorQuote('lossOfPersonalBelongings', filter[key])
          newStructureData['lossOfPersonalBelongings'] = filter[key]
        } else if (key === 'NCBProtection') {
          dispatchMotorQuote('ncbProtection', filter[key])
          newStructureData['ncbProtection'] = filter[key]
        } else if (key === 'NoOfLegelDrives') {
          if (filter?.LiabilitiesToPaidDriver === false) {
            dispatchMotorQuote('noOfPaidDrivers', '')
            newStructureData['noOfPaidDrivers'] = ''
          } else {
            dispatchMotorQuote('noOfPaidDrivers', filter[key])
            newStructureData['noOfPaidDrivers'] = filter[key]
          }
        } else if (key === 'NonElectricAmount') {
          dispatchMotorQuote('nonElectricAmount', filter[key])
          newStructureData['nonElectricAmount'] = filter[key]
        } else if (key === 'PassengerCover') {
          dispatchMotorQuote('isPassengerCover', filter[key])
          newStructureData['isPassengerCover'] = filter[key]
        } else if (key === 'PassengerCoverAmount') {
          if (filter?.PassengerCover === false) {
            dispatchMotorQuote('passengerCoverAmount', '')
            newStructureData['passengerCoverAmount'] = ''
          } else {
            dispatchMotorQuote('passengerCoverAmount', filter[key])
            newStructureData['passengerCoverAmount'] = filter[key]
          }
        } else if (key === 'PersonalAccident') {
          dispatchMotorQuote('personalAccident', filter[key])
          newStructureData['personalAccident'] = filter[key]
        } else if (key === 'RoadSideAssistance') {
          dispatchMotorQuote('roadSideAssistance', filter[key])
          newStructureData['roadSideAssistance'] = filter[key]
        } else if (key === 'TyreProtection') {
          dispatchMotorQuote('tyreProtection', filter[key])
          newStructureData['tyreProtection'] = filter[key]
        } else if (key === 'VoluntaryDeductive') {
          dispatchMotorQuote('isVoluntaryDeductable', filter[key])
          newStructureData['isVoluntaryDeductable'] = filter[key]
        } else if (key === 'VoluntaryDeductiveAmount') {
          dispatchMotorQuote('voluntaryDeductableAmount', filter[key])
          newStructureData['voluntaryDeductableAmount'] = filter[key]
        } else if (key === 'ZeroDepriciationCover') {
          dispatchMotorQuote('zeroDepriciationCover', filter[key])
          newStructureData['zeroDepriciationCover'] = filter[key]
        } else if (key === 'RimProtection') {
          dispatchMotorQuote('rimProtection', filter[key])
          newStructureData['rimProtection'] = filter[key]
        } else if (key === 'CNGValue') {
          dispatchMotorQuote('isCngAccessories', true)
          dispatchMotorQuote('cngValue', filter[key])
          newStructureData['cngValue'] = filter[key]
        }
      }

      // let newStructureData2 = { ...motorRequest };
      console.log('newStructureData filter cover', newStructureData)
      handleFilterOptions('', '', '', newStructureData)

      setChangeCoverFilter(false)
      setAddditionCoverOpen(false)
    }
  }, [onMouseLeaveCover])

  useEffect(() => {
    if (apiRequestQQ?.idvRangeSet === false) {
      getMinMaxIdvRange()
    }
  }, [QuickQouteResult, apiRequestQQ?.idvRangeSet])

  function debounce (func, delay) {
    let timeoutId
    return function () {
      const context = this
      const args = arguments
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => func.apply(context, args), delay)
    }
  }

  const handleIDVFilter = debounce(value => {
    dispatchMotorQuote('idv', value)
    const QuoteResult = [...QuickQouteResult]
    let data = { ...apiRequestQQ, Idv: value, userDetails: userDetails }
    let newData = { ...motorRequest, idv: value }
    dispatch(resetQuickQuoteResults())

    let insurerName = ['digit', 'Future', 'Magma']

    insurerName.map(insurer => {
      if (insurer === 'digit') {
        if (value < apiRequestQQ?.initialIdvData?.digit?.min) {
          data['Idv'] = apiRequestQQ?.initialIdvData?.digit?.min
          // digitAPICall(data)
        } else if (value > apiRequestQQ?.initialIdvData?.digit?.max) {
          data['Idv'] = apiRequestQQ?.initialIdvData?.digit?.max
          // digitAPICall(data)
        } else {
          data['Idv'] = value
          // digitAPICall(data)
        }
      } else if (insurer === 'Future') {
        if (value < apiRequestQQ?.initialIdvData?.Future?.min) {
          data['Idv'] = apiRequestQQ?.initialIdvData?.Future?.min
          // FutureGeneralQuickQuote(data)
        } else if (value > apiRequestQQ?.initialIdvData?.Future?.max) {
          data['Idv'] = apiRequestQQ?.initialIdvData?.Future?.max
          // FutureGeneralQuickQuote(data)
        } else {
          data['Idv'] = value
          // FutureGeneralQuickQuote(data)
        }
      } else if (insurer === 'Magma') {
        if (
          checkboxes?.checkbox1 ||
          checkboxes?.checkbox2 ||
          checkboxes?.checkbox3
        ) {
          data = {
            ...data,
            AddOns: { ...data?.AddOns, PersonalAccident: false }
          }
        } else {
          data = {
            ...data,
            AddOns: { ...data?.AddOns, PersonalAccident: true }
          }
        }
        if (value < apiRequestQQ?.initialIdvData?.Magma?.min) {
          data['Idv'] = apiRequestQQ?.initialIdvData?.Magma?.min
          // data = {
          //   ...data,
          //   AddOns: { ...data?.AddOns, PersonalAccident: true },
          // };

          // magmaAPICall(data)
        } else if (value > apiRequestQQ?.initialIdvData?.Magma?.max) {
          data['Idv'] = apiRequestQQ?.initialIdvData?.Magma?.max
          // data = {
          //   ...data,
          //   AddOns: { ...data?.AddOns, PersonalAccident: true },
          // };

          // magmaAPICall(data)
        } else {
          data['Idv'] = value
          // data = {
          //   ...data,
          //   AddOns: { ...data?.AddOns, PersonalAccident: true },
          // };
          // magmaAPICall(data)
        }
      }
    })

    if (newData?.idv > 0) {
      newData = {
        ...newData,
        sessionId: getMotorQuoteResponse?.sessionId || motorRequest?.sessionId
      }
      getMotorQuoteResponse &&
        dispatchMotorQuote('sessionId', getMotorQuoteResponse?.sessionId)
    } else {
      newData = { ...newData, sessionId: '' }
      dispatchMotorQuote('sessionId', '')
    }

    console.log('motorQuotation', newData)
    motorQuotation(newData)

    // digitAPICall(data);
    // FutureGeneralQuickQuote(data);
    // magmaAPICall(data);
    // if()
  }, 1500)

  useEffect(() => {
    getFiancierBankData()
      .then(response => {
        if (response.status === true) {
          let i = 0
          let arr = []
          while (i < response.data.length) {
            arr.push({
              value: response.data[i].bank_name,
              label: response.data[i].bank_name,
              HDFCId: response.data[i].HDFCId
            })

            i++
          }
          setFinancierData(arr)
        }
      })
      .catch(err => console.log(err))

    getVehiclePreviousInsurer().then(response => {
      if (response.status === true) {
        let i = 0
        let data = response.data
        let arr = []
        while (i < data.length) {
          let item = data[i]
          arr.push({
            option: item.Name,
            value: item.Digit_Code
          })
          i++
        }
        setInsurerData(arr)
      }
    })
  }, [])

  const handleInputChange = e => {
    handleInitialIdv()
    const newValue = parseInt(e.target.value)
    dispatchQuickQuote('Idv', newValue)
    setIdvRangeError(true)
    if (!isNaN(newValue) && newValue >= minIdv && newValue <= maxIdv) {
      setIdvRangeError(false)
      setRangeValue(newValue)
      handleIDVFilter(newValue)
    }
  }

  const handleSliderChange = e => {
    const newValue = parseInt(e.target.value)
    setRangeValue(newValue)
    handleIDVFilter(newValue)
  }

  const handleInitialIdv = () => {
    if (apiRequestQQ?.idvRangeSet === false && QuickQouteResult.length > 0) {
      const initialIdvData = {
        digit: {
          min: '',
          max: ''
        },
        Magma: {
          min: '',
          max: ''
        },
        Future: {
          min: '',
          max: ''
        }
      }

      QuickQouteResult.forEach(api => {
        const { Api_name, MinIdv, MaxIdv } = api

        if (Api_name === 'digit') {
          initialIdvData.digit.min = parseInt(MinIdv)
          initialIdvData.digit.max = parseInt(MaxIdv)
        } else if (Api_name === 'Future') {
          initialIdvData.Future.min = parseInt(MinIdv)
          initialIdvData.Future.max = parseInt(MaxIdv)
        } else if (Api_name === 'Magma') {
          initialIdvData.Magma.min = parseInt(MinIdv)
          initialIdvData.Magma.max = parseInt(MaxIdv)
        }
      })

      dispatchQuickQuote('initialIdvData', initialIdvData)
      dispatchQuickQuote('idvRangeSet', true)
    }
  }

  const magmaPaYesCall = () => {
    setShowBox(false)
    setCheckboxes({
      checkbox1: false,
      checkbox2: false,
      checkbox3: false
    })
    dispatchQuickQuote('AddOns.PersonalAccident', true)
    //  QuickQouteResult.map((item) =>setPlanDetail(item) )
  }

  const getMinMaxIdvRange = () => {
    const maxIdv = Math.max(...QuickQouteResult.map(insurer => insurer.MaxIdv))
    const minIdv = Math.min(...QuickQouteResult.map(insurer => insurer.MinIdv))
    setMaxIdv(maxIdv)
    setMinIdv(minIdv)
  }

  const magmaPaNoCall = () => {
    setShowBox(!showBox)
    setIsPAChecked1(false)
    setIsPAChecked2(false)
  }

  const handleCheckboxChange = event => {
    const { name, checked } = event.target
    const updatedCheckboxes = { ...checkboxes }

    // Uncheck all checkboxes except the one clicked
    Object.keys(updatedCheckboxes).forEach(key => {
      if (key !== name) {
        updatedCheckboxes[key] = false
      }
    })

    // Toggle the checkbox that was clicked
    updatedCheckboxes[name] = !updatedCheckboxes[name]

    setCheckboxes(updatedCheckboxes)
  }

  // const magmaSubmitCall = () => {

  //   setSpinner(true)

  //   if (apiRequestQQ.NewPolicyType !== "ODOnly" && (
  //     !isPAChecked1 &&
  //     !checkboxes.checkbox1 &&
  //     !checkboxes.checkbox2 &&
  //     !checkboxes.checkbox3)
  //   ) {
  //     setSpinner(false)
  //     setValidationError(
  //       "Please select at least one checkbox from PA Owner Cover"
  //     );

  //     return;
  //   }

  //   if (
  //     apiRequestQQ.NewPolicyType !== 'Bundled'
  //       ? apiRequestQQ.AddOns.ZeroDepriciationCover === true &&
  //         !isZeroDepChecked1 &&
  //         !isZeroDepChecked2
  //       : ''
  //   ) {
  //     setSpinner(false)
  //     setValidationError('Please select at least one checkbox from ZeroDep')

  //     return
  //   }

  //   if (
  //     apiRequestQQ.NewPolicyType !== 'Bundled'
  //       ? apiRequestQQ.AddOns.InvoiceCover === true &&
  //         !isRTIChecked1 &&
  //         !isRTIChecked2
  //       : ''
  //   ) {
  //     setSpinner(false)
  //     setValidationError('Please select at least one checkbox from RTI Cover')

  //     return
  //   }

  //   for (let key in checkboxes) {
  //     dispatchQuickQuote(`PAChecBox.${key}`, checkboxes[key])
  //   }

  //   dispatchQuickQuote('AddOns.PersonalAccident', false)

  //   if (Object.values(checkboxes).some(value => value === true)) {
  //     setSpinner(true)

  //     for (let key in checkboxes) {
  //       dispatchQuickQuote(`PAChecBox.${key}`, checkboxes[key])
  //     }

  //     dispatchQuickQuote('AddOns.PersonalAccident', false)

  //     const newObj = {
  //       ...apiRequestQQ,
  //       PAChecBox: {
  //         ...apiRequestQQ.PAChecBox,
  //         checkbox1: checkboxes.checkbox1,
  //         checkbox2: checkboxes.checkbox2,
  //         checkbox3: checkboxes.checkbox3
  //       },
  //       AddOns: { ...apiRequestQQ.AddOns, PersonalAccident: false },
  //       userDetails: userDetails
  //     }

  //     itemState.Api_name === 'Magma' &&
  //       magmaAPICall(newObj).then(res => {
  //         setModalOpen3(!modalOpen3)
  //         toggleModel(dispatchQuickQuote('AddOns.PersonalAccident', false))
  //         setSpinner(false)
  //         setCheckboxes({
  //           checkbox1: false,
  //           checkbox2: false,
  //           checkbox3: false
  //         })
  //       })
  //   } else {
  //     alert('Please select at least one checkbox.')
  //     setSpinner(false)
  //   }
  // }

  const validateForm = () => {
    let formIsValid = true

    if (
      checkboxes.checkbox2 &&
      apiRequestQQ.NewPolicyType !== 'ODOnly' &&
      apiRequestQQ.PrePAInsurerCode === ''
    ) {
      setValidationError('Insurer Name is required')
      formIsValid = false
    } else {
      setValidationError('')
    }

    if (
      checkboxes.checkbox2 &&
      apiRequestQQ.NewPolicyType !== 'ODOnly' &&
      apiRequestQQ.PrePAPolicyNumber === ''
    ) {
      setValidationError('Existing PA Policy Number is required')
      formIsValid = false
    } else {
      setValidationError('')
    }

    if (
      checkboxes.checkbox2 &&
      apiRequestQQ.NewPolicyType !== 'ODOnly' &&
      apiRequestQQ.PrePASumInsured === ''
    ) {
      setValidationError('Existing PA Sum Insured is required')
      formIsValid = false
    } else {
      setValidationError('')
    }

    if (
      checkboxes.checkbox2 &&
      apiRequestQQ.NewPolicyType !== 'ODOnly' &&
      apiRequestQQ.PAExpiryDate === ''
    ) {
      setValidationError('Alternate Policy Expiry Date is required')
      formIsValid = false
    } else {
      setValidationError('')
    }

    return formIsValid
  }

  const magmaSubmitCall = () => {
    if (itemState.Api_name === 'Future') {
      const formIsValid = validateForm()

      if (checkboxes.checkbox2) {
        if (!formIsValid) {
          return
        }
      }

      if (
        isRTIChecked2 ||
        isZeroDepChecked2 ||
        isElectricalChecked2 ||
        isNonElectricalChecked2 ||
        isTyreChecked2 ||
        isEngineChecked2 ||
        isFuelChecked2
      ) {
        setModalOpen2(false)

        setModalOpen4(true)
      } else {
        setSpinner(true)
        setHideBtn(true)

        if (
          apiRequestQQ.NewPolicyType !== 'ODOnly' &&
          !isPAChecked1 &&
          !checkboxes.checkbox1 &&
          !checkboxes.checkbox2 &&
          !checkboxes.checkbox3
        ) {
          setSpinner(false)
          setHideBtn(false)
          setValidationError(
            'Please select at least one checkbox from PA Owner Cover'
          )

          return
        }

        if (
          yearDiff <= 7 && apiRequestQQ.NewPolicyType !== 'Bundled'
            ? apiRequestQQ.AddOns.ZeroDepriciationCover === true &&
              !isZeroDepChecked1 &&
              !isZeroDepChecked2
            : ''
        ) {
          setSpinner(false)
          setHideBtn(false)
          setValidationError('Please select at least one checkbox from ZeroDep')

          return
        }

        if (
          yearDiff < 3 && apiRequestQQ.NewPolicyType !== 'Bundled'
            ? apiRequestQQ.AddOns.InvoiceCover === true &&
              !isRTIChecked1 &&
              !isRTIChecked2
            : ''
        ) {
          setSpinner(false)
          setHideBtn(false)
          setValidationError(
            'Please select at least one checkbox from RTI Cover'
          )

          return
        }

        if (
          apiRequestQQ.NewPolicyType !== 'Bundled'
            ? apiRequestQQ.AddOns.EngineProtection === true &&
              !isEngineChecked1 &&
              !isEngineChecked2
            : ''
        ) {
          setSpinner(false)
          setHideBtn(false)
          setValidationError(
            'Please select at least one checkbox from Engine Protection'
          )

          return
        }

        if (
          apiRequestQQ.NewPolicyType !== 'Bundled'
            ? apiRequestQQ.AddOns.TyreProtection === true &&
              !isTyreChecked1 &&
              !isTyreChecked2
            : ''
        ) {
          setSpinner(false)
          setHideBtn(false)
          setValidationError(
            'Please select at least one checkbox from Tyre Protection'
          )

          return
        }

        if (
          apiRequestQQ.NewPolicyType !== 'Bundled'
            ? apiRequestQQ.AddOns.IsCngAccessories === true &&
              !isFuelChecked1 &&
              !isFuelChecked2
            : ''
        ) {
          setSpinner(false)
          setHideBtn(false)
          setValidationError(
            'Please select at least one checkbox from Bi-Fuel Kit'
          )

          return
        }

        if (
          apiRequestQQ.NewPolicyType !== 'Bundled'
            ? apiRequestQQ.AddOns.IsElectricalAccessories === true &&
              !isElectricalChecked1 &&
              !isElectricalChecked2
            : ''
        ) {
          setSpinner(false)
          setHideBtn(false)
          setValidationError(
            'Please select at least one checkbox from Electrical Accessories'
          )

          return
        }

        if (
          apiRequestQQ.NewPolicyType !== 'Bundled'
            ? apiRequestQQ.AddOns.IsNonElectricalAccessories === true &&
              !isNonElectricalChecked1 &&
              !isNonElectricalChecked2
            : ''
        ) {
          setSpinner(false)
          setHideBtn(false)
          setValidationError(
            'Please select at least one checkbox from Non-Electrical Accessories'
          )

          return
        }

        if (showBox) {
          setSpinner(true)

          for (let key in checkboxes) {
            dispatchQuickQuote(`PAChecBox.${key}`, checkboxes[key])
          }

          dispatchQuickQuote('AddOns.PersonalAccident', false)

          if (Object.values(checkboxes).some(value => value === true)) {
            setSpinner(true)

            for (let key in checkboxes) {
              dispatchQuickQuote(`PAChecBox.${key}`, checkboxes[key])
            }

            dispatchQuickQuote('AddOns.PersonalAccident', false)
            dispatchQuickQuote('ISRTICoverInPreviousPolicy', isRTIChecked1)
            dispatchQuickQuote('ISZeroDepInPreviousPolicy', isZeroDepChecked1)

            const newObj = {
              ...apiRequestQQ,
              PAChecBox: {
                ...apiRequestQQ.PAChecBox,
                checkbox1: checkboxes.checkbox1,
                checkbox2: checkboxes.checkbox2,
                checkbox3: checkboxes.checkbox3
              },
              AddOns: { ...apiRequestQQ.AddOns, PersonalAccident: false },
              ISRTICoverInPreviousPolicy: isRTIChecked1,
              ISZeroDepInPreviousPolicy: isZeroDepChecked1
            }

            debounce

            if (itemState?.Api_name === 'Future') {
              if (
                apiRequestQQ?.Idv < apiRequestQQ?.initialIdvData?.Future?.min
              ) {
                newObj['Idv'] = apiRequestQQ?.initialIdvData?.Future?.min
              } else if (
                apiRequestQQ?.Idv > apiRequestQQ?.initialIdvData?.Future?.max
              ) {
                newObj['Idv'] = apiRequestQQ?.initialIdvData?.Future?.max
              }
            } else if (itemState?.Api_name === ' Magma') {
              if (
                apiRequestQQ?.Idv < apiRequestQQ?.initialIdvData?.Magma?.min
              ) {
                newObj['Idv'] = apiRequestQQ?.initialIdvData?.Magma?.min
              } else if (
                apiRequestQQ?.Idv > apiRequestQQ?.initialIdvData?.Magma?.max
              ) {
                newObj['Idv'] = apiRequestQQ?.initialIdvData?.Magma?.max
              }
            }

            dispatchQuickQuote('Idv', newObj?.Idv)
            // itemState.Api_name === 'Magma' &&
            //   magmaAPICall(newObj).then(res => {
            //     // setModalOpen2(!modalOpen2)
            //     // toggleModel()
            //     setModalTrue(true)
            //     setHideBtn(false)
            //     setSpinner(false)
            //     setCheckboxes({
            //       checkbox1: false,
            //       checkbox2: false,
            //       checkbox3: false
            //     })
            //   })

            // itemState.Api_name === 'Future' &&
            //   FutureGeneralQuickQuote(newObj).then(res => {
            //     // setModalOpen2(!modalOpen2)
            //     // toggleModel()
            //     setModalTrue(true)
            //     setSpinner(false)
            //     setHideBtn(false)
            //     setCheckboxes({
            //       checkbox1: false,
            //       checkbox2: false,
            //       checkbox3: false
            //     })
            //   })
          } else {
            if (
              !checkboxes.checkbox1 &&
              !checkboxes.checkbox2 &&
              !checkboxes.checkbox3
            ) {
              setSpinner(false)
              setHideBtn(false)
              apiRequestQQ.NewPolicyType !== 'ODOnly' &&
                setValidationError(
                  'Please select at least one checkbox from PA Owner Cover'
                )

              return
            }
            setSpinner(false)
            setHideBtn(false)
          }
        } else {
          setSpinner(true)
          dispatchQuickQuote('AddOns.PersonalAccident', true)
          dispatchQuickQuote('ISRTICoverInPreviousPolicy', isRTIChecked1)
          dispatchQuickQuote('ISZeroDepInPreviousPolicy', isZeroDepChecked1)

          const newObj = {
            ...apiRequestQQ,
            AddOns: { ...apiRequestQQ.AddOns, PersonalAccident: true },
            ISRTICoverInPreviousPolicy: isRTIChecked1,
            ISZeroDepInPreviousPolicy: isZeroDepChecked1
          }

          // itemState?.Api_name === 'Future' &&
          //   FutureGeneralQuickQuote(newObj).then(res => {
          //     setSpinner(true)
          //     // setModalOpen2(!modalOpen2)

          //     // toggleModel()
          //     setModalTrue(true)

          //     setSpinner(false)
          //     setHideBtn(false)
          //   })
          if (itemState?.Api_name === 'Future') {
            if (apiRequestQQ?.Idv < apiRequestQQ?.initialIdvData?.Future?.min) {
              newObj['Idv'] = apiRequestQQ?.initialIdvData?.Future?.min
            } else if (
              apiRequestQQ?.Idv > apiRequestQQ?.initialIdvData?.Future?.max
            ) {
              newObj['Idv'] = apiRequestQQ?.initialIdvData?.Future?.max
            }
          } else if (itemState?.Api_name === 'Magma') {
            if (apiRequestQQ?.Idv < apiRequestQQ?.initialIdvData?.Magma?.min) {
              newObj['Idv'] = apiRequestQQ?.initialIdvData?.Magma?.min
            } else if (
              apiRequestQQ?.Idv > apiRequestQQ?.initialIdvData?.Magma?.max
            ) {
              newObj['Idv'] = apiRequestQQ?.initialIdvData?.Magma?.max
            }
          }
          dispatchQuickQuote('Idv', newObj?.Idv)

          // itemState.Api_name === 'Magma' &&
          //   magmaAPICall(newObj).then(res => {
          //     setSpinner(true)
          //     // setModalOpen2(!modalOpen2)

          //     // toggleModel()

          //     setModalTrue(true)

          //     setSpinner(false)
          //     setHideBtn(false)
          //   })
        }
        setSpinner(true)
      }
    } else {
      setSpinner(true)
      setHideBtn(true)

      if (
        apiRequestQQ.NewPolicyType !== 'ODOnly' &&
        !isPAChecked1 &&
        !checkboxes.checkbox1 &&
        !checkboxes.checkbox2 &&
        !checkboxes.checkbox3
      ) {
        setSpinner(false)
        setHideBtn(false)
        setValidationError(
          'Please select at least one checkbox from PA Owner Cover'
        )

        return
      }

      if (
        apiRequestQQ.NewPolicyType !== 'Bundled'
          ? apiRequestQQ.AddOns.ZeroDepriciationCover === true &&
            !isZeroDepChecked1 &&
            !isZeroDepChecked2
          : ''
      ) {
        setSpinner(false)
        setHideBtn(false)
        setValidationError('Please select at least one checkbox from ZeroDep')

        return
      }

      if (
        yearDiff < 3 && apiRequestQQ.NewPolicyType !== 'Bundled'
          ? apiRequestQQ.AddOns.InvoiceCover === true &&
            !isRTIChecked1 &&
            !isRTIChecked2
          : ''
      ) {
        setSpinner(false)
        setHideBtn(false)
        setValidationError('Please select at least one checkbox from RTI Cover')

        return
      }

      if (showBox) {
        setSpinner(true)

        for (let key in checkboxes) {
          dispatchQuickQuote(`PAChecBox.${key}`, checkboxes[key])
        }

        dispatchQuickQuote('AddOns.PersonalAccident', false)

        if (Object.values(checkboxes).some(value => value === true)) {
          setSpinner(true)

          for (let key in checkboxes) {
            dispatchQuickQuote(`PAChecBox.${key}`, checkboxes[key])
          }

          dispatchQuickQuote('AddOns.PersonalAccident', false)
          dispatchQuickQuote('ISRTICoverInPreviousPolicy', isRTIChecked1)
          dispatchQuickQuote('ISZeroDepInPreviousPolicy', isZeroDepChecked1)

          const newObj = {
            ...apiRequestQQ,
            PAChecBox: {
              ...apiRequestQQ.PAChecBox,
              checkbox1: checkboxes.checkbox1,
              checkbox2: checkboxes.checkbox2,
              checkbox3: checkboxes.checkbox3
            },
            AddOns: { ...apiRequestQQ.AddOns, PersonalAccident: false },
            ISRTICoverInPreviousPolicy: isRTIChecked1,
            ISZeroDepInPreviousPolicy: isZeroDepChecked1
          }

          if (itemState?.Api_name === 'Future') {
            if (apiRequestQQ?.Idv < apiRequestQQ?.initialIdvData?.Future?.min) {
              newObj['Idv'] = apiRequestQQ?.initialIdvData?.Future?.min
            } else if (
              apiRequestQQ?.Idv > apiRequestQQ?.initialIdvData?.Future?.max
            ) {
              newObj['Idv'] = apiRequestQQ?.initialIdvData?.Future?.max
            }
          } else if (itemState?.Api_name === ' Magma') {
            if (apiRequestQQ?.Idv < apiRequestQQ?.initialIdvData?.Magma?.min) {
              newObj['Idv'] = apiRequestQQ?.initialIdvData?.Magma?.min
            } else if (
              apiRequestQQ?.Idv > apiRequestQQ?.initialIdvData?.Magma?.max
            ) {
              newObj['Idv'] = apiRequestQQ?.initialIdvData?.Magma?.max
            }
          }

          dispatchQuickQuote('Idv', newObj?.Idv)
          // itemState.Api_name === 'Magma' &&
          //   magmaAPICall(newObj).then(res => {
          //     // setModalOpen2(!modalOpen2)
          //     // toggleModel()
          //     setModalTrue(true)
          //     setHideBtn(false)
          //     setSpinner(false)
          //     setCheckboxes({
          //       checkbox1: false,
          //       checkbox2: false,
          //       checkbox3: false
          //     })
          //   })

          // itemState.Api_name === 'Future' &&
          //   FutureGeneralQuickQuote(newObj).then(res => {
          //     // setModalOpen2(!modalOpen2)
          //     // toggleModel()
          //     setModalTrue(true)
          //     setSpinner(false)
          //     setHideBtn(false)
          //     setCheckboxes({
          //       checkbox1: false,
          //       checkbox2: false,
          //       checkbox3: false
          //     })
          //   })
        } else {
          if (
            !checkboxes.checkbox1 &&
            !checkboxes.checkbox2 &&
            !checkboxes.checkbox3
          ) {
            setSpinner(false)
            setHideBtn(false)
            apiRequestQQ.NewPolicyType !== 'ODOnly' &&
              setValidationError(
                'Please select at least one checkbox from PA Owner Cover'
              )

            return
          }
          setSpinner(false)
          setHideBtn(false)
        }
      } else {
        setSpinner(true)
        dispatchQuickQuote('AddOns.PersonalAccident', true)
        dispatchQuickQuote('ISRTICoverInPreviousPolicy', isRTIChecked1)
        dispatchQuickQuote('ISZeroDepInPreviousPolicy', isZeroDepChecked1)

        const newObj = {
          ...apiRequestQQ,
          AddOns: { ...apiRequestQQ.AddOns, PersonalAccident: true },
          ISRTICoverInPreviousPolicy: isRTIChecked1,
          ISZeroDepInPreviousPolicy: isZeroDepChecked1
        }

        // itemState?.Api_name === 'Future' &&
        //   FutureGeneralQuickQuote(newObj).then(res => {
        //     setSpinner(true)
        //     // setModalOpen2(!modalOpen2)

        //     // toggleModel()
        //     setModalTrue(true)

        //     setSpinner(false)
        //     setHideBtn(false)
        //   })
        if (itemState?.Api_name === 'Future') {
          if (apiRequestQQ?.Idv < apiRequestQQ?.initialIdvData?.Future?.min) {
            newObj['Idv'] = apiRequestQQ?.initialIdvData?.Future?.min
          } else if (
            apiRequestQQ?.Idv > apiRequestQQ?.initialIdvData?.Future?.max
          ) {
            newObj['Idv'] = apiRequestQQ?.initialIdvData?.Future?.max
          }
        } else if (itemState?.Api_name === 'Magma') {
          if (apiRequestQQ?.Idv < apiRequestQQ?.initialIdvData?.Magma?.min) {
            newObj['Idv'] = apiRequestQQ?.initialIdvData?.Magma?.min
          } else if (
            apiRequestQQ?.Idv > apiRequestQQ?.initialIdvData?.Magma?.max
          ) {
            newObj['Idv'] = apiRequestQQ?.initialIdvData?.Magma?.max
          }
        }
        dispatchQuickQuote('Idv', newObj?.Idv)

        // itemState.Api_name === 'Magma' &&
        //   magmaAPICall(newObj).then(res => {
        //     setSpinner(true)
        //     // setModalOpen2(!modalOpen2)

        //     // toggleModel()

        //     setModalTrue(true)

        //     setSpinner(false)
        //     setHideBtn(false)
        //   })
      }
      setSpinner(true)
    }
  }

  useEffect(() => {
    if (modalTrue) {
      QuickQouteResult.map(item => {
        if (item.Api_name === 'Magma' && itemState.Api_name === 'Magma') {
          setPlanDetail(item)

          setMagmaImg(item.Logo)
          setTimeout(() => {
            setModalOpen2(!modalOpen2)
            toggleModel()
            setSpinner(false)
          }, [1000])
        } else if (
          item.Api_name === 'Future' &&
          itemState.Api_name === 'Future'
        ) {
          setPlanDetail(item)
          setMagmaImg(item.Logo)
          setTimeout(() => {
            setModalOpen2(!modalOpen2)
            toggleModel()
            setSpinner(false)
          }, [1000])
        }
      })
      setModalTrue(false)
    }
  }, [QuickQouteResult, modalTrue])

  // useEffect(() => {
  //   const slider = document.querySelector(".range-edit");
  //   const thumbSize = 20; // Adjust based on your slider thumb size
  //   const min = parseInt(slider.min);
  //   const max = parseInt(slider.max);
  //   const percent = ((rangeValue - min) / (max - min)) * 100;

  //   // Calculate tooltip position
  //   const position = (percent * (slider.offsetWidth - thumbSize)) / 100;
  //   setTooltipPosition(position);
  // }, [rangeValue]);

  const handleRTIChange1 = () => {
    setIsRTIChecked1(!isRTIChecked1)
    setIsRTIChecked2(false) // Uncheck checkbox 2
  }

  const handleRTIChange2 = () => {
    setIsRTIChecked2(!isRTIChecked2)
    setIsRTIChecked1(false) // Uncheck checkbox 1
  }

  const handleZeroDepChange1 = () => {
    setIsZeroDepChecked1(!isZeroDepChecked1)
    setIsZeroDepChecked2(false) // Uncheck checkbox 2
  }

  const handleZeroDepChange2 = () => {
    setIsZeroDepChecked2(!isZeroDepChecked2)
    setIsZeroDepChecked1(false) // Uncheck checkbox 1
  }

  const handleEngineChange1 = () => {
    setIsEngineChecked1(!isEngineChecked1)
    setIsEngineChecked2(false) // Uncheck checkbox 2
  }

  const handleEngineChange2 = () => {
    setIsEngineChecked2(!isEngineChecked2)
    setIsEngineChecked1(false) // Uncheck checkbox 1
  }

  const handleTyreChange1 = () => {
    setIsTyreChecked1(!isTyreChecked1)
    setIsTyreChecked2(false) // Uncheck checkbox 2
  }

  const handleTyreChange2 = () => {
    setIsTyreChecked2(!isTyreChecked2)
    setIsTyreChecked1(false) // Uncheck checkbox 1
  }

  const handleFuelChange1 = () => {
    setIsFuelChecked1(!isFuelChecked1)
    setIsFuelChecked2(false) // Uncheck checkbox 2
  }

  const handleFuelChange2 = () => {
    setIsFuelChecked2(!isFuelChecked2)
    setIsFuelChecked1(false) // Uncheck checkbox 1
  }

  const handleElectricalChange1 = () => {
    setIsElectricalChecked1(!isElectricalChecked1)
    setIsElectricalChecked2(false) // Uncheck checkbox 2
  }

  const handleElectricalChange2 = () => {
    setIsElectricalChecked2(!isElectricalChecked2)
    setIsElectricalChecked1(false) // Uncheck checkbox 1
  }

  const handleNonElectricalChange1 = () => {
    setIsNonElectricalChecked1(!isNonElectricalChecked1)
    setIsNonElectricalChecked2(false) // Uncheck checkbox 2
  }

  const handleNonElectricalChange2 = () => {
    setIsNonElectricalChecked2(!isNonElectricalChecked2)
    setIsNonElectricalChecked1(false) // Uncheck checkbox 1
  }

  const handlePAChange1 = () => {
    setIsPAChecked1(!isPAChecked1)
    setIsPAChecked2(false) // Uncheck checkbox 2
  }

  const handlePAChange2 = () => {
    setIsPAChecked2(!isPAChecked2)
    setIsPAChecked1(false) // Uncheck checkbox 1
  }

  const handlePAPolicyEndDate = val => {
    dispatchQuickQuote('PAExpiryDate', val)
  }

  const handlePAPolicyInsurer = val => {
    dispatchQuickQuote('PrePAInsurerCode', val)
  }

  const handlePAPolicyNumber = val => {
    dispatchQuickQuote('PrePAPolicyNumber', val)
  }
  const handlePASumInsred = val => {
    dispatchQuickQuote('PrePASumInsured', val)
  }

  const handlePAModal = item => {
    setPlanDetail(item)
    setItemState(item)

    if (
      item?.Api_name === 'Magma' &&
      apiRequestQQ?.CustomerType !== 'ORGANISATION'
    ) {
      if (apiRequestQQ.NewPolicyType === 'ODOnly') {
        if (filter.PersonalAccident !== true) {
          if (filter?.ZeroDepriciationCover || filter?.InvoiceCover) {
            setMagmaImg(item.Logo)
            setModalOpen2(true)
          } else {
            toggleModel()
            setPlanDetail(item)
            setItemState(item)
          }
        } else if (filter.PersonalAccident === true) {
          toggleModel()
          setPlanDetail(item)
          setItemState(item)
        }
      } else {
        setMagmaImg(item.Logo)
        setModalOpen2(true)
      }
    } else if (
      item?.Api_name === 'Future' &&
      apiRequestQQ?.CustomerType !== 'ORGANISATION'
    ) {
      if (apiRequestQQ.NewPolicyType === 'ODOnly') {
        if (filter.PersonalAccident !== true) {
          if (
            filter?.ZeroDepriciationCover ||
            filter?.InvoiceCover ||
            filter?.EngineProtection ||
            filter?.TyreProtection ||
            filter?.IsElectricalAccessories ||
            filter?.IsNonElectricalAccessories
          ) {
            setMagmaImg(item.Logo)
            setModalOpen2(true)
          } else {
            toggleModel()
            setPlanDetail(item)
            setItemState(item)
          }
        } else if (filter.PersonalAccident === true) {
          toggleModel()
          setPlanDetail(item)
          setItemState(item)
        }
      } else {
        setMagmaImg(item.Logo)
        setModalOpen2(true)
      }
    } else {
      toggleModel()
      setPlanDetail(item)
      setItemState(item)
      kycConfig(item)
      getKycStatus(motorRequest, item)
    }
  }

  // New Structure KYC Config
  const kycConfig = async item => {
    console.log('KYC Item', item)
    try {
      for (const insurer of QuickQouteResult) {
        console.log('KYC CONFIG', insurer)
        if (
          insurer?.Api_name !== 'Digit' ||
          insurer?.Api_name !== 'Magma' ||
          insurer?.Api_name !== 'Future'
        ) {
          try {
            const response = await getKycConfig(
              item?.insurerId,
              motorRequest?.customerType
            )

            if (!response) {
              throw new Error('No response from getKycConfig')
            }

            dispatchKycConfig(response?.data)
            setKycConfigData(true)

            if (response?.data?.kycConfig?.isCkycAllowed) {
              dispatchMotorQuote('kycType', kycType?.ckyc)
            } else if (response?.data?.kycConfig?.isOvdAllowed) {
              dispatchMotorQuote('kycType', kycType?.ovd)
            } else {
              dispatchMotorQuote('kycType', kycType?.ckyc)
            }
          } catch (error) {
            console.error(
              'Error fetching KYC config for insurer:',
              insurer?.Api_name,
              error
            )
          }
        }
      }
    } catch (error) {
      console.error('Error in kycConfig function:', error)
    }
  }

  const generatePdf = async () => {
    const cardElement = document.getElementById('model-height') // Replace with your cardRef if using React ref
    const scale = 1.5 // Lower scaling factor for smaller resolution
    const canvas = await html2canvas(cardElement, {
      useCORS: true,
      allowTaint: true,
      scale: scale
    })

    const imgData = canvas.toDataURL('image/png')
    const pdfDoc = await PDFDocument.create()
    const pdfWidth = canvas.width / 2 // Reduce width
    const pdfHeight = canvas.height / 2 // Reduce height

    const page = pdfDoc.addPage([pdfWidth, pdfHeight])
    const pngImage = await pdfDoc.embedPng(imgData)
    page.drawImage(pngImage, {
      x: 0,
      y: 0,
      width: pdfWidth,
      height: pdfHeight
    })

    const pdfBytes = await pdfDoc.save()
    const blob = new Blob([pdfBytes], { type: 'application/pdf' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = 'premiumBreakupDownload.pdf'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const generateQuotationPdf = async () => {
    const input = document.getElementById('quotelistPdf')
    const inputWidth = input.scrollWidth
    const inputHeight = input.scrollHeight
    const scale = 1.5
    const canvas = await html2canvas(input, {
      scale: scale,
      useCORS: true,
      allowTaint: true
    })

    const imgData = canvas.toDataURL('image/jpeg', 0.5)

    const pdf = new jsPDF('l', 'pt', [
      (inputWidth * scale) / 3.78,
      (inputHeight * scale) / 3.78
    ])
    pdf.addImage(
      imgData,
      'JPEG',
      0,
      0,
      (inputWidth * scale) / 3.78,
      (inputHeight * scale) / 3.78
    )

    pdf.save('quotelistPdf.pdf')
  }

  const [shareQuotationModal, setShareQuotationModal] = useState(false)
  const toggleShareQuotationModal = () =>
    setShareQuotationModal(!shareQuotationModal)

  const shareQuotation = () => {
    toggleShareQuotationModal()
  }

  // Fetching the Data for new structure in Flow API

  useEffect(() => {
    QuickQouteResult.length === 0 && fetchPreviousJourneyDetails()
  }, [])

  const fetchPreviousJourneyDetails = async () => {
    let newDataForMotorRequest = { ...motorRequest }
    try {
      const urlCustomerLeadId = extractCustomerLeadId(location?.pathname)
      const response = await getPreviousJourneyDetails({
        customerLeadId: urlCustomerLeadId
      })
      const stepsData = response?.data?.stepsData
      if (
        response?.data?.processStep === 'step13' &&
        response?.data?.processDiscription === 'pdfGeneration'
      ) {
        const caseId = stepsData?.step13?.motorRequest?.caseId
        for (let key in stepsData?.step13?.motorRequest) {
          dispatchMotorQuote(`${key}`, stepsData?.step13?.motorRequest[key])
        }
        navigate(`/success-page/${urlCustomerLeadId}?caseId=${caseId}`)
      } else if (
        (response?.data?.processStep === 'step7' &&
          response?.data?.processDiscription === 'quotation') ||
        (response?.data?.processStep === 'step6' &&
          response?.data?.processDiscription === 'pypSelection')
      ) {
        try {
          for (let key in stepsData?.step7?.motorRequest) {
            dispatchMotorQuote(`${key}`, stepsData?.step7?.motorRequest[key])
            newDataForMotorRequest[key] = stepsData?.step7?.motorRequest[key]
          }

          // const updatedFilter = { ...filter };

          // Addons.forEach((addon) => {
          //   const motorValue = stepsData?.step7?.motorRequest[addon.value];
          //   const motorValueAmount =
          //     stepsData?.step7?.motorRequest[addon.inputName];
          //   if (
          //     motorValue !== undefined &&
          //     motorValue !== filter[addon.value]
          //   ) {
          //     updatedFilter[addon.value] = motorValue;
          //     if (addon.type === "input" && addon.inputName) {
          //       updatedFilter[addon.inputName] = motorValueAmount;
          //     }
          //   }
          // });

          // setFilter(updatedFilter);
          // localStorage.setItem("filter", JSON.stringify(updatedFilter));

          for (let key in stepsData?.step7?.previousClaimData) {
            dispatchMotorQuote(
              `${key}`,
              stepsData?.step7?.previousClaimData[key]
            )
            newDataForMotorRequest[key] =
              stepsData?.step7?.previousClaimData[key]
          }
          dispatch(resetQuickQuoteResults())
          if (
            (motorRequest?.businessType === POLICY_STATUS.NOTEXPIRED ||
              motorRequest?.businessType === POLICY_STATUS.EXPIREDWITHIN90) &&
            !motorRequest?.isVehicleNew &&
            stepsData?.step6?.pypSelection?.newPolicyType !==
              POLICY_TYPE.THIRDPARTY &&
            !stepsData?.step5?.isOwnerChanged &&
            stepsData?.step6?.pypSelection?.businessType !==
              POLICY_STATUS.NOTEXPIRED &&
            motorRequest?.businessType !== 'new' &&
            !motorRequest?.isVehicleNew
          ) {
            // setNcbModel(true);
          }
          // navigate(`/quotelist/${previousProcessJourney?.customerLeadId}`);
        } catch (error) {
          console.error('Error processing motor request details:', error)
        }
      } else if (
        (response?.data?.processStep === 'step8' &&
          response?.data?.processDiscription === 'kycAndUserDetails') ||
        (response?.data?.processStep === 'step9' &&
          response?.data?.processDiscription === 'nomineeDetails') ||
        (response?.data?.processStep === 'step10' &&
          response?.data?.processDiscription === 'vehicleDetails')
      ) {
        navigate(`/proposal/${urlCustomerLeadId}`)
        updateJourneyData(
          stepsData,
          response?.data?.processStep,
          response?.data?.processDiscription
        )
        return
      }
      // else if (
      //   (response?.data?.processStep === "step9" &&
      //     response?.data?.processDiscription === "nomineeDetails") ||
      //   (response?.data?.processStep === "step10" &&
      //     response?.data?.processDiscription === "vehicleDetails") ||
      //   (response?.data?.processStep === "step8" &&
      //     response?.data?.processDiscription === "kycAndUserDetails")
      // ) {
      //   navigate(`/proposal/${response?.data?.customerLeadId}`);
      // }
    } catch (error) {
      console.error('Error fetching previous journey details:', error)
    }

    if (
      motorRequest?.isVehicleNew === false &&
      motorRequest?.vehicleType &&
      motorRequest?.newPolicyType !== POLICY_TYPE?.THIRDPARTY &&
      !motorRequest?.isOwnerChanged &&
      (motorRequest?.businessType === POLICY_STATUS.NOTEXPIRED ||
        motorRequest?.businessType === POLICY_STATUS.EXPIREDWITHIN90)
    ) {
      // setNcbModel(true);
    }

    if (
      motorRequest?.businessType !== POLICY_STATUS.NOTEXPIRED ||
      motorRequest?.newPolicyType === POLICY_TYPE?.THIRDPARTY ||
      motorRequest?.isOwnerChanged
    ) {
      newDataForMotorRequest && motorQuotation(newDataForMotorRequest)
    }

    const storedFilter = localStorage.getItem('filter')
    if (storedFilter) {
      setFilter(JSON.parse(storedFilter))
    }
  }

  return (
    <>
      <Navbar />
      <section className='plans_wrapper' id='quotelistPdf'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 col-lg-3'>
              <div className='invoice_sec'>
                <div className='row'>
                  <div className='col-9'>
                    <h2 className='sec_heading'>Your Vehicle Details</h2>
                  </div>
                  <div className='col-3 ps-0'>
                    <p
                      href='#!'
                      className='edit'
                      onClick={() => {
                        setEditDetails(true)
                        setEditModal(true)
                        handleInitialIdv()
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      Edit{' '}
                    </p>
                  </div>
                </div>

                <div className='invoice_list'>
                  <div className='row'>
                    <div className='col-7 pe-0'>
                      {apiRequestQQ?.VehicleType === '4w' ? (
                        <img
                          src={'/assets/img/car.png'}
                          height={35}
                          width={70}
                        />
                      ) : (
                        <img
                          src={'/assets/img/Bike_img.png'}
                          height={40}
                          width={70}
                        />
                      )}
                    </div>
                    <div className='col-5 ps-0'>
                      <span className='invoce_des'>
                        {apiRequestQQ?.MakeName + ' ' + apiRequestQQ?.ModelName}
                      </span>
                      <p className='invoce_des'>{apiRequestQQ?.VariantName}</p>
                    </div>
                  </div>
                  <div className='row plan_sum '>
                    <div className='col-7 pe-0'>
                      <p className=''>Year</p>
                    </div>
                    <div className='col-5 plan_sum_1 '>
                      <span className=''>
                        {apiRequestQQ.RegistrationDate.slice(0, 4)}
                      </span>
                    </div>
                  </div>
                  <div className='row plan_sum '>
                    <div className='col-7 pe-0'>
                      <p className=''>RTO</p>
                    </div>
                    <div className='col-5 plan_sum_1'>
                      <span className=''>
                        {apiRequestQQ?.IsVehicleNew === false
                          ? apiRequestQQ.RegistrationNumber.slice(0, 5).replace(
                              '-',
                              ''
                            )
                          : apiRequestQQ?.RtoCode}
                      </span>
                    </div>
                  </div>
                  <div className='row plan_sum '>
                    <div className='col-7 pe-0'>
                      <p className=''>Cubic Capacity</p>
                    </div>
                    <div className='col-5 plan_sum_1'>
                      <span className=''>{apiRequestQQ?.CubicCapacity}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='invoice_sec'>
                <div className='row'>
                  <div className='col-9'>
                    <h2 className='sec_heading'>Insured value (IDV)</h2>
                  </div>
                </div>
                {QuickQuoteResult && QuickQuoteResult.length > 0 && (
                  <div className='invoice_list'>
                    <div className='row range-edit'>
                      <div className='col-12 pe-0'>
                        <div className='value-show'>
                          <label htmlFor='idvInput'>
                            <b>Currently set for price:</b>
                          </label>
                          <input
                            type='number'
                            id='idvInput'
                            placeholder='Enter IDV'
                            defaultValue={apiRequestQQ?.Idv}
                            onChange={e => {
                              handleInputChange(e)
                              handleInitialIdv()
                            }}
                            min={minIdv}
                            max={maxIdv}
                            className='idv-input'
                          />
                        </div>

                        {idvRangeError && (
                          <p
                            className='text-danger'
                            style={{ fontSize: '0.8rem' }}
                          >
                            Please Enter the IDV within specified range
                          </p>
                        )}
                        <input
                          type='range'
                          min={minIdv}
                          max={maxIdv}
                          onChange={e => {
                            setRangeValue(e.target.value)
                            handleInitialIdv()
                          }}
                          onMouseUp={handleSliderChange}
                          defaultValue={minIdv}
                          className='range-edit'
                        />
                        <div className='range-Show_value'>
                          <span> {minIdv}</span>
                          <span>{maxIdv}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {apiRequestQQ?.PreviousInsuranceClaimed === 'false' &&
                apiRequestQQ?.NewPolicyType !== 'ThirdParty' && (
                  <>
                    {apiRequestQQ?.PolicyStatus !== 'expired above 90 day' && (
                      <div className='invoice_sec'>
                        <div className='row'>
                          <div className='col-9'>
                            <h2 className='sec_heading'>No claim bonus</h2>
                          </div>
                        </div>

                        <div className='invoice_list'>
                          <div className='row plan_sum'>
                            <div className='col-7 pe-0'>
                              <p className=''>Current applicable NCB :</p>
                            </div>
                            <div className='col-5 plan_sum_1'>
                              <p className=''>
                                {apiRequestQQ.PreviousNoClaimBonus === '0'
                                  ? '20'
                                  : apiRequestQQ.PreviousNoClaimBonus === '20'
                                  ? '25'
                                  : apiRequestQQ.PreviousNoClaimBonus === '25'
                                  ? '35'
                                  : apiRequestQQ.PreviousNoClaimBonus === '35'
                                  ? '45'
                                  : apiRequestQQ.PreviousNoClaimBonus === '45'
                                  ? '50'
                                  : apiRequestQQ.PreviousNoClaimBonus === '50'
                                  ? '50'
                                  : '0'}
                              </p>
                            </div>
                          </div>
                          <div className='row plan_sum'>
                            <div className='col-7 pe-0'>
                              <p className=''>Previous year NCB :</p>
                            </div>
                            <div className='col-5 plan_sum_1'>
                              <p className=''>
                                {apiRequestQQ.PreviousNoClaimBonus}
                              </p>
                            </div>
                          </div>
                          <div className='row plan_sum'>
                            <div className='col-7 pe-0'>
                              <p className=''>Claims in last policy :</p>
                            </div>
                            <div className='col-5 plan_sum_1'>
                              <p className=''>
                                {apiRequestQQ?.PreviousInsuranceClaimed ===
                                'true'
                                  ? 'Yes'
                                  : 'No'}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
            </div>
            <div className='col-md-12 col-lg-9'>
              <div className='plan_tabing'>
                <div className='tab-content' id='pills-tabContent'>
                  <div
                    className='tab-pane fade show active'
                    id='pills-home'
                    role='tabpanel'
                    aria-labelledby='pills-home-tab'
                    tabindex='0'
                  >
                    <div className=' Comprehensive-cover'>
                      <div className='Select-list-quote'>
                        <select
                          name='cars'
                          id='cars'
                          disabled={
                            apiRequestQQ?.PreviousInsuranceClaimed !==
                              'false' ||
                            apiRequestQQ?.IsVehicleNew === true ||
                            apiRequestQQ?.NewPolicyType === 'ThirdParty' ||
                            apiRequestQQ?.PolicyStatus ===
                              'expired above 90 day'
                              ? true
                              : false
                          }
                          className='select-option'
                          onChange={e => {
                            handleChangeNCB(e?.target?.value)
                            handleInitialIdv()
                          }}
                        >
                          <option key='' value=''>
                            Select NCB
                          </option>
                          <option key='0' value='0'>
                            0 %
                          </option>
                          <option key='20' value='20'>
                            20 %
                          </option>
                          <option key='25' value='25'>
                            25 %
                          </option>
                          <option key='35' value='35'>
                            35 %
                          </option>
                          <option key='45' value='45'>
                            45 %
                          </option>
                          <option key='50' value='50'>
                            50 %
                          </option>
                        </select>
                      </div>

                      <div className='Select-list-quote'>
                        <div
                          className={`${
                            apiRequestQQ?.NewPolicyType === 'ThirdParty'
                              ? 'disable_addon'
                              : 'position-relative'
                          }`}
                          onClick={() => {
                            setAddonFilter(!addOnFilter)
                            setOnMouseLeave(true)
                            setChnageAddonFilter(false)
                            handleInitialIdv()
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <p className='addon_sec'>
                            Addons
                            <i className='fa fa-caret-down fs-6 p-2' />
                          </p>
                        </div>
                        <div
                          id='addon'
                          className={`shadow  rounded pb-3 quote-addon ${
                            addOnFilter === false ? 'd-none' : ''
                          }`}
                          onMouseLeave={() => {
                            setAddonFilter(!addOnFilter)
                            setOnMouseLeave(false)
                          }}
                        >
                          <button
                            type='button'
                            className='btn-close position-absolute d-lg-none d-block'
                            style={{ top: 10, right: 10 }}
                          ></button>
                          {addOnsData.map((item, index) => (
                            <div
                              className='form-check mb-1 ms-2 mt-1 pt-2 addon_list_check'
                              style={{ zIndex: '10' }}
                            >
                              <input
                                type='checkbox'
                                value={item.value}
                                className='form-check-input'
                                name={item.value}
                                checked={
                                  filter[item.value] === true ? 'checked' : ''
                                }
                                id={'day' + index}
                                onChange={e => handleChangeFilter(e)}
                              />
                              <div
                                className='form-check-label addon_list mx-3'
                                htmlFor={'day' + index}
                              >
                                {item.label}
                              </div>
                              {item.type == 'input' &&
                                filter[item.value] === true && (
                                  <input
                                    type='number'
                                    min={item.min}
                                    name={item.inputName}
                                    value={filter[item.inputName]}
                                    onChange={e =>
                                      handleChangeValue(e, item.value)
                                    }
                                    className='addon_input_sec mt-1 ms-3'
                                  />
                                )}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className='Select-list-quote'>
                        <div
                          className={`${
                            apiRequestQQ?.NewPolicyType === 'ThirdParty'
                              ? 'disable_addon'
                              : ''
                          }`}
                          onClick={() => {
                            setAddditionCoverOpen(true)
                            setAdditionalCover(!additionalCover)
                            setOnMouseLeaveCover(true)
                            setChangeCoverFilter(false)
                            handleInitialIdv()
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <p className='addon_sec'>
                            Additional Covers
                            <i className='fa fa-caret-down fs-6 p-2' />
                          </p>
                        </div>
                        <div
                          id='addon'
                          className={`shadow rounded pb-3 quote-addon ${
                            additionalCover === false ? 'd-none' : ''
                          }`}
                          onMouseLeave={() => {
                            setAdditionalCover(!additionalCover)
                            setOnMouseLeaveCover(false)
                          }}
                        >
                          <button
                            type='button'
                            className='btn-close position-absolute d-lg-none d-block'
                            style={{ top: 10, right: 10 }}
                          ></button>
                          {Covres.filter(item =>
                            apiRequestQQ.VehicleType === '2w' ||
                            apiRequestQQ.FuelType == 'Diesel'
                              ? item.value !== 'IsCngAccessories' && item
                              : item
                          ).map(
                            (item, index) =>
                              !(
                                apiRequestQQ?.NewPolicyType === 'ThirdParty' &&
                                (item?.label === 'Electrical Accessories' ||
                                  item?.label ===
                                    'Non-Electrical Accessories' ||
                                  item?.label === 'Is Bi-fuel Kit?')
                              ) && (
                                <div
                                  className='form-check ms-2 mt-1 pt-2 fs-6'
                                  style={{ zIndex: '10' }}
                                >
                                  <input
                                    type='checkbox'
                                    className={`form-check-input addon_list_check ${
                                      apiRequestQQ?.NewPolicyType ===
                                        'ThirdParty' &&
                                      (item?.label ===
                                        'Electrical Accessories' ||
                                        item?.label ===
                                          'Non-Electrical Accessories' ||
                                        item?.label === 'Is Bi-fuel Kit?')
                                        ? 'disable_addon'
                                        : ''
                                    }`}
                                    name={item.value}
                                    checked={
                                      filter[item.value] === true
                                        ? 'checked'
                                        : ''
                                    }
                                    id={'day' + index}
                                    onChange={e => handleChangeFilter(e)}
                                  />
                                  <div
                                    className={`form-check-label  addon_list mx-3 ${
                                      apiRequestQQ?.NewPolicyType ===
                                        'ThirdParty' &&
                                      (item?.label ===
                                        'Electrical Accessories' ||
                                        item?.label ===
                                          'Non-Electrical Accessories' ||
                                        item?.label === 'Is Bi-fuel Kit?')
                                        ? 'disable_addon'
                                        : ''
                                    }`}
                                    htmlFor={'day' + index}
                                  >
                                    {item.label}
                                  </div>
                                  {item.type == 'input' &&
                                    filter[item.value] === true && (
                                      <>
                                        {item.value === 'PassengerCover' ? (
                                          <select
                                            className='addon_input_sec'
                                            style={{
                                              marginLeft: '10px',
                                              width: '160px',
                                              marginTop: '5px'
                                            }}
                                            name={item.inputName}
                                            onChange={e =>
                                              handleChangeValue(e, item.value)
                                            }
                                          >
                                            <option value='0'>
                                              Select Sum Insured
                                            </option>
                                            <option value='10000'>10000</option>
                                            <option value='20000'>20000</option>
                                            <option value='30000'>30000</option>
                                            <option value='40000'>40000</option>
                                            <option value='50000'>50000</option>
                                            <option value='100000'>
                                              100000
                                            </option>
                                            {apiRequestQQ.VehicleType ==
                                              '4w' && (
                                              <>
                                                <option value='120000'>
                                                  120000
                                                </option>
                                                <option value='150000'>
                                                  150000
                                                </option>
                                                <option value='200000'>
                                                  200000
                                                </option>
                                              </>
                                            )}
                                          </select>
                                        ) : item.value ==
                                          'LiabilitiesToPaidDriver' ? (
                                          <select
                                            className='addon_input_sec fs-6'
                                            style={{
                                              width: '160px',
                                              marginLeft: '10px',
                                              marginTop: '5px'
                                            }}
                                            name={item.inputName}
                                            onChange={e =>
                                              handleChangeValue(e, item.value)
                                            }
                                          >
                                            <option value='0'>
                                              Select No Of Drivers
                                            </option>
                                            <option value='1'>1</option>
                                            <option value='2'>2</option>
                                          </select>
                                        ) : (
                                          <input
                                            type='number'
                                            min={item.min}
                                            name={item.inputName}
                                            onChange={e =>
                                              handleChangeValue(e, item.value)
                                            }
                                            className='addon_input_sec'
                                            style={{
                                              marginLeft: '10px',
                                              marginTop: '5px'
                                            }}
                                          />
                                        )}
                                      </>
                                    )}
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                    {apiRequestQQ?.IsVehicleNew === false && (
                      <div className=' Comprehensive-cover mb-3 mt-4'>
                        {apiRequestQQ.VehicleType === '4w' ? (
                          yearDiff < 3 ? (
                            <div className='button-list-quote'>
                              <button className='btn-quoteList'>
                                Comprehensive cover
                              </button>
                            </div>
                          ) : (
                            <div className='button-list-quote'>
                              <button
                                className='btn-quoteList'
                                onClick={e => {
                                  e?.preventDefault()
                                  handleFilterOptions('Comprehensive')
                                  handleInitialIdv()
                                }}
                              >
                                Comprehensive cover
                              </button>
                            </div>
                          )
                        ) : yearDiff < 5 ? (
                          <div className='button-list-quote'>
                            <button className='btn-quoteList'>
                              Comprehensive cover
                            </button>
                          </div>
                        ) : (
                          <div className='button-list-quote'>
                            <button
                              className='btn-quoteList '
                              onClick={e => {
                                e?.preventDefault()
                                handleFilterOptions('Comprehensive')
                                handleInitialIdv()
                              }}
                            >
                              Comprehensive cover
                            </button>
                          </div>
                        )}
                        {apiRequestQQ.VehicleType === '4w' ? (
                          yearDiff < 3 ? (
                            <div className='button-list-quote'>
                              <button className='btn-quoteList active '>
                                Third party only cover
                              </button>
                            </div>
                          ) : (
                            <div className='button-list-quote'>
                              <button
                                className='btn-quoteList active'
                                onClick={e => {
                                  e?.preventDefault()
                                  handleFilterOptions('ThirdParty')
                                  handleInitialIdv()
                                }}
                              >
                                Third party only cover
                              </button>
                            </div>
                          )
                        ) : yearDiff < 5 ? (
                          <div className='button-list-quote'>
                            <button className='btn-quoteList active bg-light text-dark'>
                              Third party only cover
                            </button>
                          </div>
                        ) : (
                          <div className='button-list-quote'>
                            <button
                              className='btn-quoteList active'
                              onClick={e => {
                                e?.preventDefault()
                                handleFilterOptions('ThirdParty')
                                handleInitialIdv()
                              }}
                            >
                              Third party only cover
                            </button>
                          </div>
                        )}
                        {apiRequestQQ.VehicleType === '4w' ? (
                          yearDiff < 3 ? (
                            <div className='button-list-quote'>
                              <button
                                className='btn-quoteList '
                                onClick={e => {
                                  e?.preventDefault()
                                  handleFilterOptions('ODOnly')
                                  handleInitialIdv()
                                }}
                              >
                                OD only cover
                              </button>
                            </div>
                          ) : (
                            <div className='button-list-quote'>
                              <button className='btn-quoteList active bg-light text-dark'>
                                OD only cover
                              </button>
                            </div>
                          )
                        ) : yearDiff < 5 ? (
                          <div className='button-list-quote'>
                            <button
                              className='btn-quoteList '
                              onClick={e => {
                                e?.preventDefault()
                                handleFilterOptions('ODOnly')
                                handleInitialIdv()
                              }}
                            >
                              OD only cover
                            </button>
                          </div>
                        ) : (
                          <div className='button-list-quote'>
                            <button className='btn-quoteList active bg-light text-dark'>
                              OD only cover
                            </button>
                          </div>
                        )}
                      </div>
                    )}

                    <div className='plan_offer'>
                      <div className='d-flex justify-content-between mx-0 mx-md-4  gap-md-0 flex-wrap'>
                        <h2 className='plan_heading mt-2 mb-0'>
                          {QuickQuoteResult.length} {apiRequestQQ.NewPolicyType}{' '}
                          Plan
                        </h2>
                        <h3 className='plan_heading mt-2'>
                          Case No: {apiRequestQQ?.quotationNumber}
                        </h3>
                        <div
                          className='share-btn'
                          onClick={() => shareQuotation()}
                        >
                          <IoMdShare />
                        </div>
                      </div>
                      {QuickQuoteResult && QuickQuoteResult.length > 0 ? (
                        QuickQuoteResult.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <>
                                <div key={index} className='offer_box'>
                                  <div className='offer_list'>
                                    <div className='plan-logo'>
                                      <img src={item?.Logo} alt='' />
                                    </div>
                                    <div className='idv'>
                                      {apiRequestQQ?.NewPolicyType ===
                                      'ThirdParty' ? (
                                        <p>Third Party</p>
                                      ) : (
                                        <>
                                          {' '}
                                          <p>IDV</p>
                                          <span className='amount'>
                                            {item?.idv}
                                          </span>
                                        </>
                                      )}
                                    </div>
                                    <div
                                      className='plan_btn'
                                      onClick={() => {
                                        handlePAModal(item)
                                        dispatchQuickQuote('Idv', item?.idv)
                                      }}
                                    >
                                      <button>
                                        INR{' '}
                                        {Math.round(
                                          item?.FinalPremium?.toString().replace(
                                            'INR',
                                            ''
                                          )
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                  <div
                                    className='adons col-12 mt-2'
                                    style={{
                                      marginLeft: '14px',
                                      fontSize: '13px'
                                    }}
                                  >
                                    {apiRequestQQ?.NewPolicyType !==
                                    'ThirdParty' ? (
                                      <ul className='p-0 row  d-flex-column'>
                                        {item.PersonalAccident != false && (
                                          <li className='col-lg-3 ps-lg-0 mb-1'>
                                            <span>
                                              <span
                                                className={
                                                  item.PersonalAccident ===
                                                  false
                                                    ? 'text-danger'
                                                    : 'text-success'
                                                }
                                              >
                                                {' '}
                                                {item.PersonalAccident ===
                                                false ? (
                                                  <span className='fa '>
                                                    <img
                                                      style={{
                                                        width: '12px'
                                                      }}
                                                      src='/assets/img/cross.png'
                                                    />
                                                  </span>
                                                ) : (
                                                  <span>
                                                    <img
                                                      style={{
                                                        width: '12px'
                                                      }}
                                                      src='/assets/img/check.png'
                                                    />
                                                  </span>
                                                )}
                                              </span>{' '}
                                              Personal Accident
                                            </span>
                                          </li>
                                        )}
                                        {apiRequestQQ.isFiltered === true && (
                                          <>
                                            {item?.Api_name !== 'Future' &&
                                              filter.RoadSideAssistance ===
                                                true && (
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span
                                                      className={
                                                        item.RoadSideAssistance ===
                                                        false
                                                          ? 'text-danger'
                                                          : 'text-success'
                                                      }
                                                    >
                                                      {' '}
                                                      {item.RoadSideAssistance ===
                                                      false ? (
                                                        <span className='fa '>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/cross.png'
                                                          />
                                                        </span>
                                                      ) : (
                                                        <span>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/check.png'
                                                          />
                                                        </span>
                                                      )}
                                                    </span>{' '}
                                                    Road Side Assistance
                                                  </span>
                                                </li>
                                              )}

                                            {item?.Api_name !== 'Future' &&
                                              filter.EngineProtection ===
                                                true && (
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span
                                                      className={
                                                        item.EngineProtection ===
                                                        false
                                                          ? 'text-danger'
                                                          : 'text-success'
                                                      }
                                                    >
                                                      {' '}
                                                      {item.EngineProtection ===
                                                      false ? (
                                                        <span className='fa '>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/cross.png'
                                                          />
                                                        </span>
                                                      ) : (
                                                        <span>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/check.png'
                                                          />
                                                        </span>
                                                      )}
                                                    </span>{' '}
                                                    Engine Protection
                                                  </span>
                                                </li>
                                              )}

                                            {item?.Api_name !== 'Future' &&
                                              filter.TyreProtection ===
                                                true && (
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span
                                                      className={
                                                        item.TyreProtection ===
                                                        false
                                                          ? 'text-danger'
                                                          : 'text-success'
                                                      }
                                                    >
                                                      {' '}
                                                      {item.TyreProtection ===
                                                      false ? (
                                                        <span className='fa '>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/cross.png'
                                                          />
                                                        </span>
                                                      ) : (
                                                        <span>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/check.png'
                                                          />
                                                        </span>
                                                      )}
                                                    </span>{' '}
                                                    Tyre Protection
                                                  </span>
                                                </li>
                                              )}

                                            {item?.Api_name !== 'Future' &&
                                              filter.RimProtection === true && (
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span
                                                      className={
                                                        item.RimProtection ===
                                                        false
                                                          ? 'text-danger'
                                                          : 'text-success'
                                                      }
                                                    >
                                                      {' '}
                                                      {item.RimProtection ===
                                                      false ? (
                                                        <span className='fa '>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/cross.png'
                                                          />
                                                        </span>
                                                      ) : (
                                                        <span>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assetss/images/check.png'
                                                          />
                                                        </span>
                                                      )}
                                                    </span>{' '}
                                                    Rim Protection
                                                  </span>
                                                </li>
                                              )}

                                            {item?.Api_name !== 'Future' &&
                                              filter.Consumables === true && (
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span
                                                      className={
                                                        item.Consumables ===
                                                        false
                                                          ? 'text-danger'
                                                          : 'text-success'
                                                      }
                                                    >
                                                      {' '}
                                                      {item.Consumables ===
                                                      false ? (
                                                        <span className='fa '>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/cross.png'
                                                          />
                                                        </span>
                                                      ) : (
                                                        <span>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/check.png'
                                                          />
                                                        </span>
                                                      )}
                                                    </span>{' '}
                                                    Consumables
                                                  </span>
                                                </li>
                                              )}

                                            {filter.IsElectricalAccessories ===
                                              true && (
                                              <li className='col-lg-3 ps-lg-0 mb-1'>
                                                <span>
                                                  <span
                                                    className={
                                                      item.IsElectricalAccessories ===
                                                      false
                                                        ? 'text-danger'
                                                        : 'text-success'
                                                    }
                                                  >
                                                    {' '}
                                                    {item.IsElectricalAccessories ===
                                                    false ? (
                                                      <span className='fa '>
                                                        <img
                                                          style={{
                                                            width: '12px'
                                                          }}
                                                          src='/assets/img/cross.png'
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{
                                                            width: '12px'
                                                          }}
                                                          src='/assets/img/check.png'
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{' '}
                                                  Electrical Accessories
                                                </span>
                                              </li>
                                            )}

                                            {filter.IsNonElectricalAccessories ===
                                              true && (
                                              <li className='col-lg-3 ps-lg-0 mb-1'>
                                                <span>
                                                  <span
                                                    className={
                                                      item.IsNonElectricalAccessories ===
                                                      false
                                                        ? 'text-danger'
                                                        : 'text-success'
                                                    }
                                                  >
                                                    {' '}
                                                    {item.IsNonElectricalAccessories ===
                                                    false ? (
                                                      <span className='fa '>
                                                        <img
                                                          style={{
                                                            width: '12px'
                                                          }}
                                                          src='/assets/img/cross.png'
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{
                                                            width: '12px'
                                                          }}
                                                          src='/assets/img/check.png'
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{' '}
                                                  Non Electrical Accessories
                                                </span>
                                              </li>
                                            )}

                                            {item?.Api_name !== 'Future' &&
                                              filter.InvoiceCover === true && (
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span
                                                      className={
                                                        item.InvoiceCover ===
                                                        false
                                                          ? 'text-danger'
                                                          : 'text-success'
                                                      }
                                                    >
                                                      {' '}
                                                      {item.InvoiceCover ===
                                                      false ? (
                                                        <span className='fa'>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/cross.png'
                                                          />
                                                        </span>
                                                      ) : (
                                                        <span>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/check.png'
                                                          />
                                                        </span>
                                                      )}
                                                    </span>{' '}
                                                    Invoice Cover
                                                  </span>
                                                </li>
                                              )}

                                            {item?.Api_name !== 'Future' &&
                                              filter.EngineGearBox === true && (
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span
                                                      className={
                                                        item.EngineGearBox ===
                                                        false
                                                          ? 'text-danger'
                                                          : 'text-success'
                                                      }
                                                    >
                                                      {' '}
                                                      {item.EngineGearBox ===
                                                      false ? (
                                                        <span className='fa'>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/cross.png'
                                                          />
                                                        </span>
                                                      ) : (
                                                        <span>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/check.png'
                                                          />
                                                        </span>
                                                      )}
                                                    </span>{' '}
                                                    Engine GearBox Cover
                                                  </span>
                                                </li>
                                              )}
                                            {filter.IsCngAccessories ===
                                              true && (
                                              <li className='col-lg-3 ps-lg-0 mb-1'>
                                                <span>
                                                  <span
                                                    className={
                                                      item.IsCngAccessories ===
                                                      false
                                                        ? 'text-danger'
                                                        : 'text-success'
                                                    }
                                                  >
                                                    {' '}
                                                    {item.IsCngAccessories ===
                                                    false ? (
                                                      <span className='fa'>
                                                        <img
                                                          style={{
                                                            width: '12px'
                                                          }}
                                                          src='/assets/img/cross.png'
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{
                                                            width: '12px'
                                                          }}
                                                          src='/assets/img/check.png'
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{' '}
                                                  Is Bi-fuel Kit
                                                </span>
                                              </li>
                                            )}

                                            {item?.Api_name !== 'Future' &&
                                              filter.VoluntaryDeductive ===
                                                true && (
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span
                                                      className={
                                                        item.VoluntaryDeductive ===
                                                        false
                                                          ? 'text-danger'
                                                          : 'text-success'
                                                      }
                                                    >
                                                      {' '}
                                                      {item.VoluntaryDeductive ===
                                                      false ? (
                                                        <span className='fa '>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/cross.png'
                                                          />
                                                        </span>
                                                      ) : (
                                                        <span>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/check.png'
                                                          />
                                                        </span>
                                                      )}
                                                    </span>{' '}
                                                    Voluntary Deductive
                                                  </span>
                                                </li>
                                              )}
                                            {item?.Api_name !== 'Future' &&
                                              filter.NCBProtection === true && (
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span
                                                      className={
                                                        item.NCBProtection ===
                                                        false
                                                          ? 'text-danger'
                                                          : 'text-success'
                                                      }
                                                    >
                                                      {' '}
                                                      {item.NCBProtection ===
                                                      false ? (
                                                        <span className='fa '>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/cross.png'
                                                          />
                                                        </span>
                                                      ) : (
                                                        <span>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/check.png'
                                                          />
                                                        </span>
                                                      )}
                                                    </span>{' '}
                                                    NCB Protection
                                                  </span>
                                                </li>
                                              )}

                                            {filter.PassengerCover === true && (
                                              <li className='col-lg-3 ps-lg-0 mb-1'>
                                                <span>
                                                  <span
                                                    className={
                                                      item.PassengerCover ===
                                                      false
                                                        ? 'text-danger'
                                                        : 'text-success'
                                                    }
                                                  >
                                                    {' '}
                                                    {item.PassengerCover ===
                                                    false ? (
                                                      <span className='fa '>
                                                        <img
                                                          style={{
                                                            width: '12px'
                                                          }}
                                                          src='/assets/img/cross.png'
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{
                                                            width: '12px'
                                                          }}
                                                          src='/assets/img/check.png'
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{' '}
                                                  Passenger Cover
                                                </span>
                                              </li>
                                            )}

                                            {item?.Api_name !== 'Future' &&
                                              filter.LossOfPersonalBelongings ===
                                                true && (
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span
                                                      className={
                                                        item.LossOfPersonalBelongings ===
                                                        false
                                                          ? 'text-danger'
                                                          : 'text-success'
                                                      }
                                                    >
                                                      {' '}
                                                      {item.LossOfPersonalBelongings ===
                                                      false ? (
                                                        <span className='fa '>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/cross.png'
                                                          />
                                                        </span>
                                                      ) : (
                                                        <span>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/check.png'
                                                          />
                                                        </span>
                                                      )}
                                                    </span>{' '}
                                                    Loss Of Personal Belongings
                                                  </span>
                                                </li>
                                              )}

                                            {item?.Api_name !== 'Future' &&
                                              filter.ZeroDepriciationCover ===
                                                true && (
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span
                                                      className={
                                                        item.ZeroDepriciationCover ===
                                                        false
                                                          ? 'text-danger'
                                                          : 'text-success'
                                                      }
                                                    >
                                                      {' '}
                                                      {item.ZeroDepriciationCover ===
                                                      false ? (
                                                        <span>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/cross.png'
                                                          />
                                                        </span>
                                                      ) : (
                                                        <span>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/check.png'
                                                          />
                                                        </span>
                                                      )}
                                                    </span>{' '}
                                                    Zero Dep Cover
                                                  </span>
                                                </li>
                                              )}

                                            {item?.Api_name !== 'Future' &&
                                              filter.KeyReplacement ===
                                                true && (
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span
                                                      className={
                                                        item.KeyReplacement ===
                                                        false
                                                          ? 'text-danger'
                                                          : 'text-success'
                                                      }
                                                    >
                                                      {' '}
                                                      {item.KeyReplacement ===
                                                      false ? (
                                                        <span>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/cross.png'
                                                          />
                                                        </span>
                                                      ) : (
                                                        <span>
                                                          <img
                                                            style={{
                                                              width: '12px'
                                                            }}
                                                            src='/assets/img/check.png'
                                                          />
                                                        </span>
                                                      )}
                                                    </span>{' '}
                                                    KeyReplacement
                                                  </span>
                                                </li>
                                              )}

                                            {filter.LiabilitiesToPaidDriver ===
                                              true && (
                                              <li className='col-lg-3 ps-lg-0 mb-1'>
                                                <span>
                                                  <span
                                                    className={
                                                      item.DriverLiability ===
                                                      false
                                                        ? 'text-danger'
                                                        : 'text-success'
                                                    }
                                                  >
                                                    {' '}
                                                    {item.DriverLiability ===
                                                    false ? (
                                                      <span>
                                                        <img
                                                          style={{
                                                            width: '12px'
                                                          }}
                                                          src='/assets/img/cross.png'
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{
                                                            width: '12px'
                                                          }}
                                                          src='/assets/img/check.png'
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{' '}
                                                  Liabilities To PaidDriver
                                                </span>
                                              </li>
                                            )}

                                            {item?.Api_name === 'Future' &&
                                            apiRequestQQ?.VehicleType ===
                                              '4w' &&
                                            item?.RoadSideAssistance ===
                                              'STZDP' ? (
                                              <>
                                                {' '}
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Zero Dep Cover
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Personal Belongings
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Key Replacement
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Road Side Assistance
                                                  </span>
                                                </li>
                                              </>
                                            ) : item?.Api_name === 'Future' &&
                                              apiRequestQQ?.VehicleType ===
                                                '4w' &&
                                              item?.RoadSideAssistance ===
                                                'ZDCNS' ? (
                                              <>
                                                {' '}
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Zero Dep Cover
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Personal Belongings
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Key Replacement
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Consumables
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Road Side Assistance
                                                  </span>
                                                </li>
                                              </>
                                            ) : item?.Api_name === 'Future' &&
                                              apiRequestQQ?.VehicleType ===
                                                '4w' &&
                                              item?.RoadSideAssistance ===
                                                'ZDCNE' ? (
                                              <>
                                                {' '}
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Zero Dep Cover
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Personal Belongings
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Key Replacement
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Consumables
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Engine Protector
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Road Side Assistance
                                                  </span>
                                                </li>
                                              </>
                                            ) : item?.Api_name === 'Future' &&
                                              apiRequestQQ?.VehicleType ===
                                                '4w' &&
                                              item?.RoadSideAssistance ===
                                                'ZDCNT' ? (
                                              <>
                                                {' '}
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Zero Dep Cover
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Personal Belongings
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Key Replacement
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Consumables
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Tyre Protection
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Road Side Assistance
                                                  </span>
                                                </li>
                                              </>
                                            ) : item?.Api_name === 'Future' &&
                                              apiRequestQQ?.VehicleType ===
                                                '4w' &&
                                              item?.RoadSideAssistance ===
                                                'ZDCET' ? (
                                              <>
                                                {' '}
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Zero Dep Cover
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Personal Belongings
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Key Replacement
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Consumables
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Tyre Protection
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Engine Protection
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Road Side Assistance
                                                  </span>
                                                </li>
                                              </>
                                            ) : item?.Api_name === 'Future' &&
                                              apiRequestQQ?.VehicleType ===
                                                '4w' &&
                                              item?.RoadSideAssistance ===
                                                'ZCETR' ? (
                                              <>
                                                {' '}
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Zero Dep Cover
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Personal Belongings
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Key Replacement
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Consumables
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Tyre Protection
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Engine Protection
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Invoice Cover
                                                  </span>
                                                </li>
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Road Side Assistance
                                                  </span>
                                                </li>
                                              </>
                                            ) : item?.Api_name === 'Future' &&
                                              apiRequestQQ?.VehicleType ===
                                                '4w' &&
                                              item?.RoadSideAssistance ===
                                                'STRSA' ? (
                                              <li className='col-lg-3 ps-lg-0 mb-1'>
                                                <span>
                                                  <span>
                                                    <img
                                                      style={{
                                                        width: '12px'
                                                      }}
                                                      src='/assets/img/check.png'
                                                    />
                                                  </span>
                                                  Road Side Assistance
                                                </span>
                                              </li>
                                            ) : null}

                                            {item?.Api_name === 'Future' &&
                                              item?.NCBProtection ===
                                                'STNCB' && (
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    NCB Protection
                                                  </span>
                                                </li>
                                              )}

                                            {item?.Api_name === 'Future' &&
                                              apiRequestQQ?.VehicleType ===
                                                '2w' &&
                                              item?.RoadSideAssistance !==
                                                false && (
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Zero Dep Cover
                                                  </span>
                                                </li>
                                              )}
                                            {item?.Api_name === 'Future' &&
                                              apiRequestQQ?.VehicleType ===
                                                '2w' &&
                                              item?.ZeroDepriciationCover !==
                                                false && (
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Zero Dep Cover
                                                  </span>
                                                </li>
                                              )}
                                            {item?.Api_name === 'Future' &&
                                              apiRequestQQ?.VehicleType ===
                                                '2w' &&
                                              item?.Consumables !== false && (
                                                <li className='col-lg-3 ps-lg-0 mb-1'>
                                                  <span>
                                                    <span>
                                                      <img
                                                        style={{
                                                          width: '12px'
                                                        }}
                                                        src='/assets/img/check.png'
                                                      />
                                                    </span>
                                                    Consumables
                                                  </span>
                                                </li>
                                              )}
                                          </>
                                        )}
                                      </ul>
                                    ) : (
                                      <ul className='p-0 row  d-flex-column'>
                                        {item.PersonalAccident != false && (
                                          <li className='col-lg-3 ps-lg-0 mb-1'>
                                            <span>
                                              <span
                                                className={
                                                  item.PersonalAccident ===
                                                  false
                                                    ? 'text-danger'
                                                    : 'text-success'
                                                }
                                              >
                                                {' '}
                                                {item.PersonalAccident ===
                                                false ? (
                                                  <span className='fa '>
                                                    <img
                                                      style={{
                                                        width: '12px'
                                                      }}
                                                      src='/assets/img/cross.png'
                                                    />
                                                  </span>
                                                ) : (
                                                  <span>
                                                    <img
                                                      style={{
                                                        width: '12px'
                                                      }}
                                                      src='/assets/img/check.png'
                                                    />
                                                  </span>
                                                )}
                                              </span>{' '}
                                              Personal Accident
                                            </span>
                                          </li>
                                        )}
                                        {apiRequestQQ.isFiltered === true && (
                                          <>
                                            {filter.PassengerCover === true && (
                                              <li className='col-lg-3 ps-lg-0 mb-1'>
                                                <span>
                                                  <span
                                                    className={
                                                      item.PassengerCover ===
                                                      false
                                                        ? 'text-danger'
                                                        : 'text-success'
                                                    }
                                                  >
                                                    {' '}
                                                    {item.PassengerCover ===
                                                    false ? (
                                                      <span className='fa '>
                                                        <img
                                                          style={{
                                                            width: '12px'
                                                          }}
                                                          src='/assets/img/cross.png'
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{
                                                            width: '12px'
                                                          }}
                                                          src='/assets/img/check.png'
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{' '}
                                                  Passenger Cover
                                                </span>
                                              </li>
                                            )}
                                            {filter.LiabilitiesToPaidDriver ===
                                              true && (
                                              <li className='col-lg-3 ps-lg-0 mb-1'>
                                                <span>
                                                  <span
                                                    className={
                                                      item.DriverLiability ===
                                                      false
                                                        ? 'text-danger'
                                                        : 'text-success'
                                                    }
                                                  >
                                                    {' '}
                                                    {item.DriverLiability ===
                                                    false ? (
                                                      <span>
                                                        <img
                                                          style={{
                                                            width: '12px'
                                                          }}
                                                          src='/assets/img/cross.png'
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span>
                                                        <img
                                                          style={{
                                                            width: '12px'
                                                          }}
                                                          src='/assetss/img/check.png'
                                                        />
                                                      </span>
                                                    )}
                                                  </span>{' '}
                                                  Liabilities To PaidDriver
                                                </span>
                                              </li>
                                            )}
                                          </>
                                        )}
                                      </ul>
                                    )}
                                  </div>
                                </div>
                              </>
                              {index === QuickQouteResult.length - 1 &&
                                QuickQouteResult.length + ApiErrorArr.length <
                                  3 && <Skeleton />}
                            </React.Fragment>
                          )
                        })
                      ) : QuickQuoteResult.length === 0 &&
                        ApiErrorArr.length === 2 ? (
                        <div></div>
                      ) : (
                        <Skeleton />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal isOpen={modalKyc} id='model-height' size='md' backdrop='static'>
        <ModalHeader className='d-flex'>
          {/* <div className='d-flex raw'> */}
          <div className='modal_heading'>
            <h4 className='modal-title'>Plan Details</h4>
            <button
              class='v2_close false'
              type='button'
              onClick={() => toggleModel()}
            >
              &times;
            </button>
          </div>
          <div className='premiumBreakupLogo'>
            {/* <img src='/assets/logo.svg' width='100px' alt='logo' title='logo' /> */}
          </div>
          <button
            className='modal-pdf-btn active'
            onClick={() => generatePdf()}
          >
            <BsDownload />
          </button>
          {/* </div> */}
        </ModalHeader>
        <ModalBody>
          <div className='modal-cantainer'>
            <div className='modal-content'>
              <div className='modal-body'>
                <div className='model-quotelist-box'>
                  <div className='model-box-cantainer'>
                    <div className='quotlist-img'>
                      <img src={planDetail?.Logo} alt='' />
                    </div>
                    <div className='quotelist-text'>
                      <h6>{planDetail?.insurer}</h6>
                      <p>({planDetail?.policyType})</p>
                    </div>
                    <div className='model-textbtn'>
                      {' '}
                      <button
                        className='modal-btn active'
                        onClick={() => submitUserPlan(planDetail)}
                      >
                        Buy
                      </button>{' '}
                    </div>
                  </div>
                </div>
                <div className='idv-model-text'>
                  <div>
                    <h4>Cover Value (IDV)</h4>
                    <p>INR {planDetail?.idv}</p>
                  </div>

                  <div>
                    <h4>Policy Start Date</h4>
                    <p>{planDetail?.StartDate}</p>
                  </div>
                </div>
                <div className='idv-model-premium'>
                  <div className='premium-breakup'>
                    <h4>Premium Breakup</h4>
                  </div>
                </div>
                <div className='Basic-cover'>
                  <h4>Basic Covers</h4>
                  <div className='basic-cover-amount'>
                    <p>Basic Own Damage</p>
                    {typeof planDetail?.BasicODPremium === 'number'
                      ? Math.round(
                          planDetail?.BasicODPremium?.toString().replace(
                            'INR',
                            ''
                          )
                        )
                      : typeof planDetail?.BasicODPremium === 'string'
                      ? Math.round(
                          planDetail?.BasicODPremium.replace('INR', '')
                        )
                      : Math.round(planDetail?.BasicODPremium)}{' '}
                  </div>
                  <hr />
                  <div className='basic-cover-amount'>
                    <p>Basic Third Party Premium</p>
                    {typeof planDetail?.BasicTPPremium === 'number'
                      ? Math.round(
                          planDetail?.BasicTPPremium?.toString().replace(
                            'INR',
                            ''
                          )
                        )
                      : typeof planDetail?.BasicTPPremium === 'string'
                      ? Math.round(
                          planDetail?.BasicTPPremium.replace('INR', '')
                        )
                      : Math.round(planDetail?.BasicTPPremium)}
                  </div>
                  <hr />
                </div>
                <div className='Basic-cover'>
                  <h4>Add-on Covers</h4>

                  {/* Roadside Assistance */}
                  {planDetail?.Api_name !== 'Future' &&
                    planDetail?.RoadSideAssistance !== false && (
                      <>
                        <div className='basic-cover-amount'>
                          <p>Road Side Assistance</p>
                          <p>
                            {typeof planDetail?.RoadSideAssistance === 'number'
                              ? Math.round(planDetail?.RoadSideAssistance)
                              : typeof planDetail?.RoadSideAssistance ===
                                'string'
                              ? Math.round(planDetail?.RoadSideAssistance)
                              : Math.round(planDetail?.RoadSideAssistance)}
                          </p>
                        </div>

                        <hr />
                      </>
                    )}

                  {/* Engine Protection */}
                  {planDetail?.Api_name !== 'Future' &&
                    planDetail?.EngineProtection !== false && (
                      <>
                        <div className='basic-cover-amount'>
                          <p>Engine Protection</p>
                          <p>
                            {/* {Math.round(
                              planDetail?.EngineProtection?.toString().replace(
                                "INR",
                                ""
                              )
                            )} */}

                            {typeof planDetail?.EngineProtection === 'number'
                              ? Math.round(planDetail?.EngineProtection)
                              : typeof planDetail?.EngineProtection === 'string'
                              ? Math.round(planDetail?.EngineProtection)
                              : Math.round(planDetail?.EngineProtection)}
                          </p>
                        </div>
                        <hr />
                      </>
                    )}

                  {/* LL to Paid Driver */}
                  {planDetail?.Api_name !== 'Future' &&
                    planDetail?.liabilitiesToPaidDriver !== false && (
                      <>
                        <div className='basic-cover-amount'>
                          <p>Legal Liability to Paid Driver</p>
                          <p>
                            {/* {Math.round(
                              planDetail?.DriverLiability?.toString().replace(
                                "INR",
                                ""
                              )
                            )} */}

                            {typeof planDetail?.liabilitiesToPaidDriverAmount ===
                            'number'
                              ? Math.round(
                                  planDetail?.liabilitiesToPaidDriverAmount
                                )
                              : typeof planDetail?.liabilitiesToPaidDriverAmount ===
                                'string'
                              ? Math.round(
                                  planDetail?.liabilitiesToPaidDriverAmount
                                )
                              : Math.round(
                                  planDetail?.liabilitiesToPaidDriverAmount
                                )}
                          </p>
                        </div>
                        <hr />
                      </>
                    )}

                  {/* Tyre Protection */}
                  {planDetail?.Api_name !== 'Future' &&
                    planDetail?.TyreProtection !== false && (
                      <>
                        <div className='basic-cover-amount'>
                          <p>Tyre Protection</p>
                          <p>
                            {/* {Math.round(
                              planDetail?.TyreProtection?.toString().replace(
                                "INR",
                                ""
                              )
                            )} */}

                            {typeof planDetail?.TyreProtection === 'number'
                              ? Math.round(planDetail?.TyreProtection)
                              : typeof planDetail?.TyreProtection === 'string'
                              ? Math.round(planDetail?.TyreProtection)
                              : Math.round(planDetail?.TyreProtection)}
                          </p>
                        </div>
                        <hr />
                      </>
                    )}

                  {/* Rim Protection */}
                  {planDetail?.Api_name !== 'Future' &&
                    planDetail?.RimProtection !== false && (
                      <>
                        <div className='basic-cover-amount'>
                          <p>Rim Protection</p>
                          <p>
                            {/* {Math.round(
                              planDetail?.RimProtection?.toString().replace(
                                "INR",
                                ""
                              )
                            )} */}

                            {typeof planDetail?.RimProtection === 'number'
                              ? Math.round(planDetail?.RimProtection)
                              : typeof planDetail?.RimProtection === 'string'
                              ? Math.round(planDetail?.RimProtection)
                              : Math.round(planDetail?.RimProtection)}
                          </p>
                        </div>
                        <hr />
                      </>
                    )}

                  {/* Consumables */}
                  {planDetail?.Api_name !== 'Future' &&
                    planDetail?.Consumables !== false && (
                      <>
                        <div className='basic-cover-amount'>
                          <p>Consumables</p>
                          <p>
                            {/* {Math.round(
                              planDetail?.Consumables?.toString().replace(
                                "INR",
                                ""
                              )
                            )} */}

                            {typeof planDetail?.Consumables === 'number'
                              ? Math.round(planDetail?.Consumables)
                              : typeof planDetail?.Consumables === 'string'
                              ? Math.round(planDetail?.Consumables)
                              : Math.round(planDetail?.Consumables)}
                          </p>
                        </div>
                        <hr />
                      </>
                    )}

                  {/* Personal Accident */}
                  {planDetail?.Api_name !== 'Future' &&
                    planDetail?.PersonalAccident !== false && (
                      <>
                        <div className='basic-cover-amount'>
                          <p>Personal Accident</p>
                          <p>
                            {/* {typeof planDetail?.PaCoverAmount === String
                              ? Math.round(
                                  planDetail?.PaCoverAmount?.toString().replace(
                                    "INR",
                                    ""
                                  )
                                )
                              : planDetail?.PaCoverAmount} */}

                            {typeof planDetail?.PaCoverAmount === 'number'
                              ? Math.round(planDetail?.PaCoverAmount)
                              : typeof planDetail?.PaCoverAmount === 'string'
                              ? Math.round(planDetail?.PaCoverAmount)
                              : Math.round(planDetail?.PaCoverAmount)}
                          </p>
                        </div>
                        <hr />
                      </>
                    )}

                  {/* Invoice Cover */}
                  {planDetail?.Api_name !== 'Future' &&
                    planDetail?.InvoiceCover !== false && (
                      <>
                        <div className='basic-cover-amount'>
                          <p>Invoice Cover</p>
                          <p>
                            {/* {Math.round(
                              planDetail?.InvoiceCover?.toString().replace(
                                "INR",
                                ""
                              )
                            )} */}

                            {typeof planDetail?.InvoiceCover === 'number'
                              ? Math.round(planDetail?.InvoiceCover)
                              : typeof planDetail?.InvoiceCover === 'string'
                              ? Math.round(planDetail?.InvoiceCover)
                              : Math.round(planDetail?.InvoiceCover)}
                          </p>
                        </div>
                        <hr />
                      </>
                    )}

                  {/* Engine Gearbox */}
                  {planDetail?.Api_name !== 'Future' &&
                    planDetail?.EngineProtection !== false && (
                      <>
                        <div className='basic-cover-amount'>
                          <p>Engine Protection </p>
                          <p>
                            {/* {Math.round(
                              planDetail?.EngineGearBox?.toString().replace(
                                "INR",
                                ""
                              )
                            )} */}

                            {typeof planDetail?.EngineProtectionAmount ===
                            'number'
                              ? Math.round(planDetail?.EngineProtectionAmount)
                              : typeof planDetail?.EngineProtectionAmount ===
                                'string'
                              ? Math.round(planDetail?.EngineProtectionAmount)
                              : Math.round(planDetail?.EngineProtectionAmount)}
                          </p>
                        </div>
                        <hr />
                      </>
                    )}

                  {/* Passenger Cover */}
                  {planDetail?.Api_name !== 'Future' &&
                    planDetail?.PassengerCover !== false && (
                      <>
                        <div className='basic-cover-amount'>
                          <p>Passenger Cover </p>
                          <p>
                            {/* {Math.round(
                              planDetail?.PassengerCover?.toString().replace(
                                "INR",
                                ""
                              )
                            )} */}

                            {typeof planDetail?.PassengerCover === 'number'
                              ? Math.round(planDetail?.PassengerCover)
                              : typeof planDetail?.PassengerCover === 'string'
                              ? Math.round(planDetail?.PassengerCover)
                              : Math.round(planDetail?.PassengerCover)}
                          </p>
                        </div>
                        <hr />
                      </>
                    )}

                  {/* Loss of Personal Belongings */}
                  {planDetail?.Api_name !== 'Future' &&
                    planDetail?.LossOfPersonalBelongings !== false && (
                      <>
                        <div className='basic-cover-amount'>
                          <p>Loss Of PersonalBelongings </p>
                          <p>
                            {/* {Math.round(
                              planDetail?.LossOfPersonalBelongings?.toString().replace(
                                "INR",
                                ""
                              )
                            )} */}

                            {typeof planDetail?.LossOfPersonalBelongings ===
                            'number'
                              ? Math.round(planDetail?.LossOfPersonalBelongings)
                              : typeof planDetail?.LossOfPersonalBelongings ===
                                'string'
                              ? Math.round(planDetail?.LossOfPersonalBelongings)
                              : Math.round(
                                  planDetail?.LossOfPersonalBelongings
                                )}
                          </p>
                        </div>
                        <hr />
                      </>
                    )}

                  {/* Zero Depriciation */}
                  {planDetail?.Api_name !== 'Future' &&
                    planDetail?.ZeroDepriciationCover !== false && (
                      <>
                        <div className='basic-cover-amount'>
                          <p>Zero Depreciation Cover </p>
                          <p>
                            {/* {Math.round(
                              planDetail?.ZeroDepriciationCover?.toString().replace(
                                "INR",
                                ""
                              )
                            )} */}

                            {typeof planDetail?.ZeroDepriciationCover ===
                            'number'
                              ? Math.round(planDetail?.ZeroDepriciationCover)
                              : typeof planDetail?.ZeroDepriciationCover ===
                                'string'
                              ? Math.round(planDetail?.ZeroDepriciationCover)
                              : Math.round(planDetail?.ZeroDepriciationCover)}
                          </p>
                        </div>
                        <hr />
                      </>
                    )}

                  {/* Key Replacement */}
                  {planDetail?.Api_name !== 'Future' &&
                    planDetail?.KeyReplacement !== false && (
                      <>
                        <div className='basic-cover-amount'>
                          <p>Key Replacement </p>
                          <p>
                            {/* {Math.round(
                              planDetail?.KeyReplacement?.toString().replace(
                                "INR",
                                ""
                              )
                            )} */}

                            {typeof planDetail?.KeyReplacementAmount ===
                            'number'
                              ? Math.round(planDetail?.KeyReplacementAmount)
                              : typeof planDetail?.KeyReplacementAmount ===
                                'string'
                              ? Math.round(planDetail?.KeyReplacementAmount)
                              : Math.round(planDetail?.KeyReplacementAmount)}
                          </p>
                        </div>
                        <hr />
                      </>
                    )}

                  {/* Electrical Accessories */}
                  {planDetail?.IsElectricalAccessories != false && (
                    <>
                      <div className='basic-cover-amount'>
                        <p>Electrical Accessories Premium</p>

                        {planDetail?.Api_name === 'Future' ? (
                          <p>
                            {/* {typeof planDetail?.IsElectricalAccessories ===
                            "number"
                              ? Math.round(
                                  planDetail?.IsElectricalAccessories?.toString().replace(
                                    "INR",
                                    ""
                                  )
                                )
                              : typeof planDetail?.IsElectricalAccessories ===
                                "string"
                              ? Math.round(
                                  planDetail?.IsElectricalAccessories.replace(
                                    "INR",
                                    ""
                                  )
                                )
                              : Math.round(planDetail?.IsElectricalAccessories)} */}

                            {typeof planDetail?.IsElectricalAccessories ===
                            'number'
                              ? Math.round(planDetail?.IsElectricalAccessories)
                              : typeof planDetail?.IsElectricalAccessories ===
                                'string'
                              ? Math.round(planDetail?.IsElectricalAccessories)
                              : Math.round(planDetail?.IsElectricalAccessories)}
                          </p>
                        ) : (
                          <p>Included</p>
                        )}
                      </div>
                      <hr />
                    </>
                  )}

                  {/* Non Electrical Accessories */}
                  {planDetail?.IsNonElectricalAccessories != false && (
                    <>
                      <div className='basic-cover-amount'>
                        <p>Non Electrical Accessories Premium</p>

                        {planDetail?.Api_name === 'Future' ? (
                          <p>
                            {/* {typeof planDetail?.IsNonElectricalAccessories ===
                            "number"
                              ? Math.round(
                                  planDetail?.IsNonElectricalAccessories?.toString().replace(
                                    "INR",
                                    ""
                                  )
                                )
                              : typeof planDetail?.IsNonElectricalAccessories ===
                                "string"
                              ? Math.round(
                                  planDetail?.IsNonElectricalAccessories.replace(
                                    "INR",
                                    ""
                                  )
                                )
                              : Math.round(
                                  planDetail?.IsNonElectricalAccessories
                                )} */}

                            {typeof planDetail?.IsNonElectricalAccessories ===
                            'number'
                              ? Math.round(
                                  planDetail?.IsNonElectricalAccessories
                                )
                              : typeof planDetail?.IsNonElectricalAccessories ===
                                'string'
                              ? Math.round(
                                  planDetail?.IsNonElectricalAccessories
                                )
                              : Math.round(
                                  planDetail?.IsNonElectricalAccessories
                                )}
                          </p>
                        ) : (
                          <p>Included</p>
                        )}
                      </div>
                      <hr />
                    </>
                  )}

                  {/* Bi-Fuel OD / Inbuilt CNG OD */}
                  {planDetail?.BiFuelOd !== false && (
                    <>
                      <div className='basic-cover-amount'>
                        <p>
                          {apiRequestQQ.FuelType === 'CNG'
                            ? 'Inbuilt CNG Kit'
                            : 'Bi Fuel Kit (OD)'}
                        </p>

                        {planDetail?.Api_name !== 'Future' ? (
                          <p>
                            {!isNaN(planDetail?.BiFuelOdAmount) &&
                            typeof planDetail?.BiFuelOdAmount === 'number'
                              ? Math.round(planDetail?.BiFuelOdAmount)
                              : typeof planDetail?.BiFuelOdAmount === 'string'
                              ? Math.round(planDetail?.BiFuelOdAmount)
                              : Math.round(planDetail?.BiFuelOdAmount)}
                          </p>
                        ) : (
                          <p>Included</p>
                        )}
                      </div>
                      <hr />
                    </>
                  )}

                  {/* Bi-Fuel TP / Inbuilt CNG TP */}
                  {planDetail?.BiFuelTp !== false && (
                    <>
                      <div className='basic-cover-amount'>
                        <p>
                          {apiRequestQQ.FuelType === 'CNG'
                            ? 'Inbuilt CNG Kit'
                            : 'Bi Fuel Kit (TP)'}
                        </p>

                        {planDetail?.Api_name !== 'Future' ? (
                          <p>
                            {!isNaN(planDetail?.BiFuelTpAmount) &&
                            typeof planDetail?.BiFuelTpAmount === 'number'
                              ? Math.round(planDetail?.BiFuelTpAmount)
                              : typeof planDetail?.BiFuelTpAmount === 'string'
                              ? Math.round(planDetail?.BiFuelTpAmount)
                              : Math.round(planDetail?.BiFuelTpAmount)}
                          </p>
                        ) : (
                          <p>Included</p>
                        )}
                      </div>
                      <hr />
                    </>
                  )}

                  {/* NCB Protection */}
                  {!isNaN(planDetail?.NCBProtection) &&
                    planDetail?.Api_name !== 'Future' &&
                    planDetail?.NCBProtection !== false && (
                      <>
                        <div className='basic-cover-amount'>
                          <p>NCB Protection </p>
                          <p>{Math.round(planDetail?.NCBProtectionAmount)}</p>
                        </div>
                        <hr />
                      </>
                    )}

                  {/* Total Addons Premium */}
                  {planDetail?.Api_name === 'Future' && (
                    <>
                      <div className='basic-cover-amount'>
                        <p>Total AddOns Premium</p>
                        <p>
                          {planDetail?.planId
                            ? Math.round(planDetail?.planId)
                            : 0}
                        </p>
                      </div>

                      <hr />
                    </>
                  )}
                  <div>
                    <h4>Discounts</h4>
                  </div>
                  {motorRequest?.isPreviousInsuranceClaimed === false && (
                    <>
                      {' '}
                      {motorRequest?.newPolicyType !== 'ThirdParty' && (
                        <>
                          {apiRequestQQ?.PolicyStatus !==
                            'expired above 90 day' && (
                            <div className='basic-cover-amount'>
                              <p>
                                NCB Discounts (
                                {apiRequestQQ.PreviousNoClaimBonus === '0'
                                  ? '20'
                                  : apiRequestQQ.PreviousNoClaimBonus === '20'
                                  ? '25'
                                  : apiRequestQQ.PreviousNoClaimBonus === '25'
                                  ? '35'
                                  : apiRequestQQ.PreviousNoClaimBonus === '35'
                                  ? '45'
                                  : apiRequestQQ.PreviousNoClaimBonus === '45'
                                  ? '50'
                                  : apiRequestQQ.PreviousNoClaimBonus === '50'
                                  ? '50'
                                  : '50'}
                                %)
                              </p>
                              <p>{Math.round(planDetail?.NCBDiscount)}</p>
                            </div>
                          )}
                        </>
                      )}
                      <hr />
                    </>
                  )}
                  {planDetail?.discount !== false && (
                    <div className='basic-cover-amount'>
                      <p>OD Discount </p>

                      <td style={{ textAlign: 'end' }}>
                        {' '}
                        {
                          planDetail?.discount === true ? (
                            <span>(Included)</span>
                          ) : typeof planDetail?.discount === 'number' ? (
                            Math.round(planDetail?.discount)
                          ) : typeof planDetail?.discount === 'string' ? (
                            Math.round(planDetail?.discount)
                          ) : (
                            planDetail?.discount || '0'
                          )
                          // Math.round(planDetail?.discount)
                        }
                      </td>
                    </div>
                  )}
                  <hr />

                  {planDetail?.Api_name === 'Magma' &&
                    planDetail?.ElectricAmount != false && (
                      <>
                        <div className='basic-cover-amount'>
                          <p>Elecrical-Detariff Discount </p>

                          <td style={{ textAlign: 'end' }}>
                            {' '}
                            {
                              planDetail?.ElectricAmount === true ? (
                                <span>(Included)</span>
                              ) : typeof planDetail?.ElectricAmount ===
                                'number' ? (
                                Math.round(
                                  planDetail?.ElectricAmount?.toString().replace(
                                    'INR',
                                    ''
                                  )
                                )
                              ) : typeof planDetail?.ElectricAmount ===
                                'string' ? (
                                Math.round(
                                  planDetail?.ElectricAmount.replace('INR', '')
                                )
                              ) : (
                                planDetail?.ElectricAmount || '0'
                              )
                              // Math.round(planDetail?.discount)
                            }
                          </td>
                        </div>
                        <hr />
                      </>
                    )}

                  {planDetail?.Api_name === 'Magma' &&
                    planDetail?.nonElectricalAmount != false && (
                      <>
                        <div className='basic-cover-amount'>
                          <p>NonElecrical-Detariff Discount </p>

                          <td style={{ textAlign: 'end' }}>
                            {' '}
                            {
                              planDetail?.nonElectricalAmount === true ? (
                                <span>(Included)</span>
                              ) : typeof planDetail?.nonElectricalAmount ===
                                'number' ? (
                                Math.round(
                                  planDetail?.nonElectricalAmount
                                    ?.toString()
                                    .replace('INR', '')
                                )
                              ) : typeof planDetail?.nonElectricalAmount ===
                                'string' ? (
                                Math.round(
                                  planDetail?.nonElectricalAmount.replace(
                                    'INR',
                                    ''
                                  )
                                )
                              ) : (
                                planDetail?.nonElectricalAmount || '0'
                              )
                              // Math.round(planDetail?.discount)
                            }
                          </td>
                        </div>
                        <hr />
                      </>
                    )}

                  {planDetail?.Api_name === 'Magma' &&
                    planDetail?.SompoDiscountRate != false && (
                      <>
                        <div className='basic-cover-amount'>
                          <p>
                            {apiRequestQQ.FuelType === 'CNG'
                              ? 'Inbuilt CNGkit-Detariff Discount'
                              : 'ExternalCNGkit-Detariff Discount'}
                          </p>

                          <td style={{ textAlign: 'end' }}>
                            {' '}
                            {
                              planDetail?.SompoDiscountRate === true ? (
                                <span>(Included)</span>
                              ) : typeof planDetail?.SompoDiscountRate ===
                                'number' ? (
                                Math.round(
                                  planDetail?.SompoDiscountRate?.toString().replace(
                                    'INR',
                                    ''
                                  )
                                )
                              ) : typeof planDetail?.SompoDiscountRate ===
                                'string' ? (
                                Math.round(
                                  planDetail?.SompoDiscountRate.replace(
                                    'INR',
                                    ''
                                  )
                                )
                              ) : (
                                planDetail?.SompoDiscountRate || '0'
                              )
                              // Math.round(planDetail?.discount)
                            }
                          </td>
                        </div>
                        <hr />
                      </>
                    )}

                  <div className='basic-cover-amount'>
                    <p>Net Premium</p>
                    <p>
                      {Math.round(
                        planDetail?.NetPremium?.toString().replace('INR', '')
                      )}
                    </p>
                  </div>

                  <hr />
                  <div className='basic-cover-amount'>
                    <p>GST(18%)</p>
                    <p>
                      {Math.round(
                        planDetail?.GST?.toString().replace('INR', '')
                      )}
                    </p>
                  </div>

                  <hr />
                </div>
                {planDetail?.Api_name === 'Future' && (
                  <div className='Basic-cover'>
                    <h4>Additional Covers</h4>

                    {planDetail?.DriverLiability !== false && (
                      <>
                        <div className='basic-cover-amount'>
                          <p>Legal Liability to Paid Driver</p>
                          <p>
                            {Math.round(
                              planDetail?.DriverLiability?.toString().replace(
                                'INR',
                                ''
                              )
                            )}
                          </p>
                        </div>
                        <hr />
                      </>
                    )}

                    {planDetail?.PersonalAccident !== false && (
                      <>
                        <div className='basic-cover-amount'>
                          <p>Personal Accident</p>
                          <p>
                            {Math.round(
                              planDetail?.PersonalAccident?.toString().replace(
                                'INR',
                                ''
                              )
                            )}
                          </p>
                        </div>
                        <hr />
                      </>
                    )}

                    {planDetail?.PassengerCover !== false && (
                      <>
                        <div className='basic-cover-amount'>
                          <p>Passenger Cover </p>
                          <p>
                            {Math.round(
                              planDetail?.PassengerCover?.toString().replace(
                                'INR',
                                ''
                              )
                            )}
                          </p>
                        </div>
                        <hr />
                      </>
                    )}
                  </div>
                )}

                <div className='Basic-cover'>
                  <h4>Total Amount</h4>

                  <div className='basic-cover-total'>
                    <p>Payable</p>
                    <p>
                      {Math.round(
                        planDetail?.FinalPremium?.toString().replace('INR', '')
                      )}
                    </p>
                  </div>

                  {/* <hr/> */}
                </div>

                {/* <div className='model-box-button-2'>
                  <Link to='/plan_btn'>
                    {' '}
                    <button className='modal-btn active'>Yes,its My Bike</button>{' '}
                  </Link>
                </div>
                <div className='model-box-button-2'>
                  <button>Its NOT My Bike</button>
                </div> */}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={shareQuotationModal} size='md' backdrop='static'>
        <ModalHeader className='d-flex justify-content-center'>
          <div className=''>
            <img src='/assets/logo.svg' width='100px' alt='' className='' />
          </div>
          <button
            class='v2_close false'
            type='button'
            onClick={() => toggleShareQuotationModal()}
          >
            &times;
          </button>
        </ModalHeader>
        <ModalBody className='d-flex justify-content-center'>
          <div className='d-flex justify-content-center mt-3 mb-3'>
            <button class='mismatch-btn' onClick={() => generateQuotationPdf()}>
              Download Quotation
            </button>
          </div>
        </ModalBody>
      </Modal>
      {/* {ApiErrorArr && ApiErrorArr.length > 0 && (
        <div className='row'>
          <div className='col-12'>
      {ApiErrorArr && ApiErrorArr.length > 0 && (
        <div className="row">
          <div className="col-12">
            <div
              style={{
                marginTop: "15px",
                padding: "30px",
                textAlign: "center",
                background: "#fff",
                borderRadius: "16px",
                border: "1px solid rgba(37, 56, 88, 0.12)",
                fontSize: "17px",
              }}
            >
              <p style={{ marginBottom: "30px" }}>
                We did not get a quote form the following insurers
                <br />
                Might be possible reason at insurance company's end like MMV
                decline or RTO restricted or some technical issue.
                <br />
              </p>
              <ul style={{ display: "flex", justifyContent: "space-evenly" }}>
                {ApiErrorArr.map((item, key) => (
                  <li>
                    <a href="#!">
                      <img
                        style={{ height: "50px" }}
                        key={key}
                        src={
                          item === "Magma HDI General Insurance Co Ltd" ? 
                          '/assets/images/logos/partners/magma-hdi.png' : 
                          item === "Go Digit General Insurance Ltd" ? 
                          '/assets/images/logos/partners/digit.png' : 
                          item === "FUTURE GENERALI INDIA INSURANCE COMPANY LTD" ? 
                          "assets/img/furturegeneral.png" : 
                           "assets/img/furturegeneral.png"
                        }
                      />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )} */}
      <div className='api_errors'>
        {ApiErrorArr && ApiErrorArr.length > 0 && (
          <div className='quote_follower'>
            <div className='row'>
              {ApiErrorArr ? (
                ApiErrorArr.map((item, key) => (
                  <div className='col-12 col-lg-4 col-md-6' key={key}>
                    <div className='quote_follower_error'>
                      <ul className='insurer_list'>
                        <li className='insurer_img'>
                          <a className='plan-logo'>
                            <img
                              src={
                                item.Api_name === insurers.HDFC.Api_name
                                  ? insurers.HDFC.logo
                                  : item?.Api_name === insurers.Digit.Api_name
                                  ? insurers.Digit.logo
                                  : item?.Api_name === insurers?.Magma?.Api_name
                                  ? insurers?.Magma?.logo
                                  : item?.Api_name ===
                                    insurers?.Future?.Api_name
                                  ? insurers?.Future?.logo
                                  : null
                              }
                              alt={item.Api_name}
                            />
                          </a>
                        </li>
                        <li>
                          <p>
                            {item.displayMessage}
                            <br />
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                ))
              ) : (
                <p>
                  Quotes Not Generated
                  <br />
                </p>
              )}
            </div>
          </div>
        )}
      </div>

      <Footer />
      <Modal
        style={{ marginTop: '-10px' }}
        backdrop='static'
        isOpen={modalOpen2}
        toggle={() => setModalOpen2(!modalOpen2)}
        size='md'
        className='modal-dialog-centered'
      >
        <ModalBody>
          <div className='main-modal-sec-mag'>
            <div>
              <div className='modal-logo'>
                <img src={magmaImg}></img>
              </div>
              <div
                onClick={() => setModalOpen2(!modalOpen2)}
                class='v3_close false'
              >
                <img src='/assets/img/close.png' title='Close' alt='close' />
              </div>
            </div>

            {apiRequestQQ?.NewPolicyType !== 'ODOnly' && (
              <>
                <div className='modal_heading w-100 mb-3'>
                  <h4 className='text-start  mt-5'>
                    {' '}
                    Do you want io include PA Owner Cover in this policy?
                  </h4>
                </div>

                {/* <div className='d-flex justify-content-center mt-5 gap-5 plan_btn '>
                  <Button className='btn' onClick={() => magmaPaYesCall()}>
                    Yes
                  </Button>
                  <Button className='btn' onClick={() => magmaPaNoCall()}>
                    No
                  </Button>
                </div> */}

                <div className='d-flex justify-content mt-1 gap-5 plan_btn'>
                  <div className='box'>
                    <div class='item' onClick={() => magmaPaYesCall()}>
                      <div class='form-check'>
                        <input
                          type='checkbox'
                          checked={isPAChecked1}
                          onChange={handlePAChange1}
                          // id=""
                          // name="check"
                          className='form-check-input'
                          style={{
                            width: '18px',
                            height: '18px',
                            borderRadius: '3px'
                          }}
                        />
                        <label className='form-check-label'>Yes</label>
                      </div>
                    </div>
                  </div>
                  <div className='box'>
                    <div class='item' onClick={() => magmaPaNoCall()}>
                      <div class='form-check'>
                        <input
                          type='checkbox'
                          checked={isPAChecked2}
                          onChange={handlePAChange2}
                          // id=""
                          // name="check"
                          className='form-check-input'
                          style={{
                            width: '18px',
                            height: '18px',
                            borderRadius: '3px'
                          }}
                        />
                        <label className='form-check-label'>No</label>
                      </div>
                    </div>
                  </div>
                  {/* <Button className="btn" onClick={() => magmaPaYesCall()}>
              Yes
            </Button> */}
                  {/* <Button className="btn" onClick={() => magmaPaNoCall()}>
              No
            </Button> */}
                </div>
              </>
            )}

            {showBox && (
              <FormGroup check>
                <Label
                  className='form-check-label'
                  // style={{ background: "white", marginBottom: "10px" }}
                  htmlFor='aacche12'
                  style={{
                    borderBottom: '1px solid #ddd',
                    paddingBottom: '10px',
                    marginTop: '1rem'
                  }}
                  check
                >
                  <Input
                    type='checkbox'
                    name='checkbox1'
                    {...register('checkbox1')}
                    checked={checkboxes.checkbox1}
                    onChange={handleCheckboxChange}
                    style={{
                      // marginRight: '10px',
                      width: '15px',
                      height: '15px',
                      borderRadius: '3px'
                    }}
                    id='chec1'
                  />{' '}
                  <label htmlFor='chec1' className='px-1'>
                    Owner does not have valid driving license
                  </label>
                </Label>
                <br />

                <Label
                  className='form-check-label'
                  // style={{ background: "white", marginBottom: "10px" }}
                  htmlFor='aacche12'
                  style={{
                    borderBottom: '1px solid #ddd',
                    paddingBottom: '10px'
                  }}
                  check
                >
                  <Input
                    type='checkbox'
                    name='checkbox2'
                    {...register('checkbox2')}
                    checked={checkboxes.checkbox2}
                    onChange={handleCheckboxChange}
                    style={{
                      // marginRight: '10px',
                      width: '15px',
                      height: '15px',
                      borderRadius: '3px'
                    }}
                    id='chec2'
                  />{' '}
                  <label htmlFor='chec2' className='px-1'>
                    Driver having existing PA cover of Rs 15 lakhs
                  </label>
                </Label>
                <br />

                {itemState.Api_name !== 'Future' && (
                  <Label
                    className='form-check-label'
                    // style={{ background: "white", marginBottom: "10px" }}
                    htmlFor='aacche12'
                    style={{
                      borderBottom: '1px solid #ddd',
                      paddingBottom: '10px'
                    }}
                    check
                  >
                    <Input
                      type='checkbox'
                      name='checkbox3'
                      {...register('checkbox3')}
                      checked={checkboxes.checkbox3}
                      onChange={handleCheckboxChange}
                      style={{
                        width: '15px',
                        height: '15px',
                        borderRadius: '3px'
                      }}
                      id='chec3'
                    />
                    <label htmlFor='chec3' className='px-1'>
                      Own multiple vehicles and have opted for PA to Owner
                      Driver Cover in the another vehicle insurance policy
                    </label>
                  </Label>
                )}
              </FormGroup>
            )}

            {checkboxes.checkbox2 &&
              apiRequestQQ.NewPolicyType !== 'ODOnly' &&
              itemState.Api_name === 'Future' && (
                <form onSubmit={handleSubmit(magmaSubmitCall)}>
                  <FormGroup style={{ marginTop: '20px' }} check>
                    <Label>Insurer Name</Label>
                    <div className='error-div-box'>
                      <div className='Motor-deatil-2'>
                        <label>Previous PA Policy Insurer</label>
                        <select
                          {...register('PrePAInsurerCode', {
                            required: 'Field is required'
                          })}
                          className='owner-input-box'
                          onChange={e => handlePAPolicyInsurer(e.target.value)}
                        >
                          <option value=''>Select</option>
                          {insurerData &&
                            insurerData.length > 0 &&
                            insurerData.map((item, i) => (
                              <option key={i} value={item.value}>
                                {item.option}
                              </option>
                            ))}
                        </select>
                        <span className='text-danger error-input'>
                          {errors.PrePAInsurerCode &&
                            errors.PrePAInsurerCode.message}
                        </span>
                      </div>
                    </div>

                    <br />

                    <Label>Existing PA Policy Number</Label>
                    <div className='error-div-box'>
                      <div className='Motor-deatil-2'>
                        <label>Previous PA Policy Number</label>
                        <input
                          type='text'
                          placeholder='Previous PA Policy Number'
                          className='owner-input-box'
                          maxLength={25}
                          {...register('PrePAPolicyNumber', {
                            required: 'Field is required'
                          })}
                          onChange={e => handlePAPolicyNumber(e.target.value)}
                          onInput={e => e.target.value.replace(' ', '')}
                        />
                        <span className='text-danger error-input'>
                          {errors.PrePAPolicyNumber &&
                            errors.PrePAPolicyNumber.message}
                        </span>
                      </div>
                    </div>

                    <br />

                    <Label>Existing PA Sum Insured</Label>
                    <div className='error-div-box'>
                      <div className='Motor-deatil-2'>
                        <label>Existing PA Sum Insured</label>
                        <input
                          type='text'
                          placeholder='Existing PA Sum Insured'
                          className='owner-input-box'
                          maxLength={25}
                          {...register('PrePASumInsured', {
                            required: 'Field is required'
                          })}
                          onChange={e => handlePASumInsred(e.target.value)}
                          onInput={e => e.target.value.replace(' ', '')}
                        />
                        <span className='text-danger error-input'>
                          {errors.PrePASumInsured &&
                            errors.PrePASumInsured.message}
                        </span>
                      </div>
                    </div>

                    <br />

                    <Label>Alternate Policy Expiry Date</Label>
                    <div className='error-div-box'>
                      <div className='Motor-deatil-2'>
                        <label>Previous PA Policy End Date</label>
                        <input
                          type='date'
                          placeholder='Previous PA Policy End Date'
                          className='owner-input-box'
                          maxLength={18}
                          {...register('PAExpiryDate', {
                            required: 'Field is required'
                          })}
                          onChange={e => handlePAPolicyEndDate(e.target.value)}
                        />
                        <span className='text-danger error-input'>
                          {errors.PAExpiryDate && errors.PAExpiryDate.message}
                        </span>
                      </div>
                    </div>

                    <br />
                  </FormGroup>
                </form>
              )}

            {(Object.keys(itemState).length > 0 &&
              itemState.Api_name === 'Future' &&
              itemState?.RoadSideAssistance === 'ZDCNS' &&
              apiRequestQQ.NewPolicyType !== 'Bundled' && (
                <div>
                  {' '}
                  <div className='modal_heading w-100 mb-3 mt-4'>
                    <h4 className='text-start  mb-4'>
                      {' '}
                      Do you have Zero Depth in previous policy?
                    </h4>
                  </div>
                  <div className='d-flex justify-content-start gap-5 mb-4 check-input'>
                    <div className='box'>
                      <div class='item'>
                        <div class='form-check '>
                          <input
                            type='checkbox'
                            checked={isZeroDepChecked1}
                            onChange={handleZeroDepChange1}
                            id='checkbox-rect11'
                            className='form-check-input'
                          />
                          <label
                            htmlFor='checkbox-rect11'
                            className='form-check-label'
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='box'>
                      <div class='item'>
                        <div class='form-check'>
                          <input
                            type='checkbox'
                            checked={isZeroDepChecked2}
                            onChange={handleZeroDepChange2}
                            id='checkbox-rect12'
                            className='form-check-input'
                            style={{
                              width: '18px',
                              height: '18px',
                              borderRadius: '3px'
                            }}
                          />
                          <label
                            htmlFor='checkbox-rect12'
                            className='form-check-label'
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )) ||
              (itemState?.RoadSideAssistance === 'STZDP' &&
                apiRequestQQ.NewPolicyType !== 'Bundled' && (
                  <div>
                    {' '}
                    <div className='modal_heading w-100 mb-3 mt-4'>
                      <h4 className='text-start  mb-4'>
                        {' '}
                        Do you have Zero Depth in previous policy?
                      </h4>
                    </div>
                    <div className='d-flex justify-content-start gap-5 mb-4 check-input'>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check '>
                            <input
                              type='checkbox'
                              checked={isZeroDepChecked1}
                              onChange={handleZeroDepChange1}
                              id='checkbox-rect11'
                              className='form-check-input'
                            />
                            <label
                              htmlFor='checkbox-rect11'
                              className='form-check-label'
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check'>
                            <input
                              type='checkbox'
                              checked={isZeroDepChecked2}
                              onChange={handleZeroDepChange2}
                              id='checkbox-rect12'
                              className='form-check-input'
                              style={{
                                width: '18px',
                                height: '18px',
                                borderRadius: '3px'
                              }}
                            />
                            <label
                              htmlFor='checkbox-rect12'
                              className='form-check-label'
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

            {itemState.Api_name === 'Future' &&
              apiRequestQQ.AddOns.IsCngAccessories &&
              apiRequestQQ.NewPolicyType !== 'Bundled' && (
                <div>
                  {' '}
                  <div className='modal_heading w-100 mb-3 mt-4'>
                    <h4 className='text-start  mb-4'>
                      {' '}
                      Do you have Bi-Fuel Kit in previous policy?
                    </h4>
                  </div>
                  <div className='d-flex justify-content-start gap-5 mb-4 check-input'>
                    <div className='box'>
                      <div class='item'>
                        <div class='form-check '>
                          <input
                            type='checkbox'
                            checked={isFuelChecked1}
                            onChange={handleFuelChange1}
                            id='checkbox-rect11'
                            className='form-check-input'
                          />
                          <label
                            htmlFor='checkbox-rect11'
                            className='form-check-label'
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='box'>
                      <div class='item'>
                        <div class='form-check'>
                          <input
                            type='checkbox'
                            checked={isFuelChecked2}
                            onChange={handleFuelChange2}
                            id='checkbox-rect12'
                            className='form-check-input'
                            style={{
                              width: '18px',
                              height: '18px',
                              borderRadius: '3px'
                            }}
                          />
                          <label
                            htmlFor='checkbox-rect12'
                            className='form-check-label'
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {itemState.Api_name === 'Magma' &&
              yearDiff < 3 &&
              apiRequestQQ?.AddOns.InvoiceCover === true &&
              apiRequestQQ.NewPolicyType !== 'Bundled' && (
                <div>
                  <div className='modal_heading w-100 mb-3'>
                    <h4 className='text-start mb-4'>
                      {' '}
                      Do you have RTI in previous policy?
                    </h4>
                  </div>
                  <div className='d-flex justify-content-start gap-5 mb-4'>
                    <div className='box'>
                      <div class='item'>
                        <div class='form-check'>
                          <input
                            type='checkbox'
                            checked={isRTIChecked1}
                            onChange={handleRTIChange1}
                            id='checkbox-rect21'
                            className='form-check-input'
                          />
                          <label
                            htmlFor='checkbox-rect21'
                            className='form-check-label'
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='box'>
                      <div class='item'>
                        <div class='form-check'>
                          <input
                            type='checkbox'
                            id='checkbox-rect22'
                            checked={isRTIChecked2}
                            onChange={handleRTIChange2}
                            className='form-check-input'
                          />
                          <label
                            htmlFor='checkbox-rect22'
                            className='form-check-label'
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {itemState.Api_name === 'Magma' &&
              apiRequestQQ?.AddOns.ZeroDepriciationCover === true &&
              apiRequestQQ.NewPolicyType !== 'Bundled' && (
                <div>
                  {' '}
                  <div className='modal_heading w-100 mb-3 mt-4'>
                    <h4 className='text-start  mb-4'>
                      {' '}
                      Do you have Zero Depth in previous policy?
                    </h4>
                  </div>
                  <div className='d-flex justify-content-start gap-5 mb-4 check-input'>
                    <div className='box'>
                      <div class='item'>
                        <div class='form-check '>
                          <input
                            type='checkbox'
                            checked={isZeroDepChecked1}
                            onChange={handleZeroDepChange1}
                            id='checkbox-rect11'
                            className='form-check-input'
                          />
                          <label
                            htmlFor='checkbox-rect11'
                            className='form-check-label'
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='box'>
                      <div class='item'>
                        <div class='form-check'>
                          <input
                            type='checkbox'
                            checked={isZeroDepChecked2}
                            onChange={handleZeroDepChange2}
                            id='checkbox-rect12'
                            className='form-check-input'
                            style={{
                              width: '18px',
                              height: '18px',
                              borderRadius: '3px'
                            }}
                          />
                          <label
                            htmlFor='checkbox-rect12'
                            className='form-check-label'
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {Object.keys(itemState).length > 0 &&
              itemState.Api_name === 'Future' &&
              itemState?.RoadSideAssistance === 'ZCETR' &&
              apiRequestQQ.NewPolicyType !== 'Bundled' && (
                <>
                  <div>
                    <div className='modal_heading w-100 mb-3'>
                      <h4 className='text-start mb-4'>
                        {' '}
                        Do you have RTI in previous policy?
                      </h4>
                    </div>
                    <div className='d-flex justify-content-start gap-5 mb-4'>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check'>
                            <input
                              type='checkbox'
                              checked={isRTIChecked1}
                              onChange={handleRTIChange1}
                              id='checkbox-rect21'
                              className='form-check-input'
                            />
                            <label
                              htmlFor='checkbox-rect21'
                              className='form-check-label'
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check'>
                            <input
                              type='checkbox'
                              id='checkbox-rect22'
                              checked={isRTIChecked2}
                              onChange={handleRTIChange2}
                              className='form-check-input'
                            />
                            <label
                              htmlFor='checkbox-rect22'
                              className='form-check-label'
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='modal_heading w-100 mb-3'>
                      <h4 className='text-start mb-4'>
                        {' '}
                        Do you have Engine Protection in previous policy?
                      </h4>
                    </div>
                    <div className='d-flex justify-content-start gap-5 mb-4'>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check'>
                            <input
                              type='checkbox'
                              checked={isEngineChecked1}
                              onChange={handleEngineChange1}
                              id='checkbox-rect31'
                              className='form-check-input'
                            />
                            <label
                              htmlFor='checkbox-rect31'
                              className='form-check-label'
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check'>
                            <input
                              type='checkbox'
                              id='checkbox-rect32'
                              checked={isEngineChecked2}
                              onChange={handleEngineChange2}
                              className='form-check-input'
                            />
                            <label
                              htmlFor='checkbox-rect32'
                              className='form-check-label'
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='modal_heading w-100 mb-3'>
                      <h4 className='text-start mb-4'>
                        {' '}
                        Do you have Tyre Protection in previous policy?
                      </h4>
                    </div>
                    <div className='d-flex justify-content-start gap-5 mb-4'>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check'>
                            <input
                              type='checkbox'
                              checked={isTyreChecked1}
                              onChange={handleTyreChange1}
                              id='checkbox-rect41'
                              className='form-check-input'
                            />
                            <label
                              htmlFor='checkbox-rect41'
                              className='form-check-label'
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check'>
                            <input
                              type='checkbox'
                              id='checkbox-rect42'
                              checked={isTyreChecked2}
                              onChange={handleTyreChange2}
                              className='form-check-input'
                            />
                            <label
                              htmlFor='checkbox-rect42'
                              className='form-check-label'
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {' '}
                    <div className='modal_heading w-100 mb-3 mt-4'>
                      <h4 className='text-start  mb-4'>
                        {' '}
                        Do you have Zero Depth in previous policy?
                      </h4>
                    </div>
                    <div className='d-flex justify-content-start gap-5 mb-4 check-input'>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check '>
                            <input
                              type='checkbox'
                              checked={isZeroDepChecked1}
                              onChange={handleZeroDepChange1}
                              id='checkbox-rect11'
                              className='form-check-input'
                            />
                            <label
                              htmlFor='checkbox-rect11'
                              className='form-check-label'
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check'>
                            <input
                              type='checkbox'
                              checked={isZeroDepChecked2}
                              onChange={handleZeroDepChange2}
                              id='checkbox-rect12'
                              className='form-check-input'
                              style={{
                                width: '18px',
                                height: '18px',
                                borderRadius: '3px'
                              }}
                            />
                            <label
                              htmlFor='checkbox-rect12'
                              className='form-check-label'
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            {Object.keys(itemState).length > 0 &&
              itemState.Api_name === 'Future' &&
              itemState?.RoadSideAssistance === 'ZDCET' &&
              apiRequestQQ.NewPolicyType !== 'Bundled' && (
                <>
                  <div>
                    <div className='modal_heading w-100 mb-3'>
                      <h4 className='text-start mb-4'>
                        {' '}
                        Do you have Tyre Protection in previous policy?
                      </h4>
                    </div>
                    <div className='d-flex justify-content-start gap-5 mb-4'>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check'>
                            <input
                              type='checkbox'
                              checked={isTyreChecked1}
                              onChange={handleTyreChange1}
                              id='checkbox-rect41'
                              className='form-check-input'
                            />
                            <label
                              htmlFor='checkbox-rect41'
                              className='form-check-label'
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check'>
                            <input
                              type='checkbox'
                              id='checkbox-rect42'
                              checked={isTyreChecked2}
                              onChange={handleTyreChange2}
                              className='form-check-input'
                            />
                            <label
                              htmlFor='checkbox-rect42'
                              className='form-check-label'
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {' '}
                    <div className='modal_heading w-100 mb-3 mt-4'>
                      <h4 className='text-start  mb-4'>
                        {' '}
                        Do you have Zero Depth in previous policy?
                      </h4>
                    </div>
                    <div className='d-flex justify-content-start gap-5 mb-4 check-input'>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check '>
                            <input
                              type='checkbox'
                              checked={isZeroDepChecked1}
                              onChange={handleZeroDepChange1}
                              id='checkbox-rect11'
                              className='form-check-input'
                            />
                            <label
                              htmlFor='checkbox-rect11'
                              className='form-check-label'
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check'>
                            <input
                              type='checkbox'
                              checked={isZeroDepChecked2}
                              onChange={handleZeroDepChange2}
                              id='checkbox-rect12'
                              className='form-check-input'
                              style={{
                                width: '18px',
                                height: '18px',
                                borderRadius: '3px'
                              }}
                            />
                            <label
                              htmlFor='checkbox-rect12'
                              className='form-check-label'
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='modal_heading w-100 mb-3'>
                      <h4 className='text-start mb-4'>
                        {' '}
                        Do you have Engine Protection in previous policy?
                      </h4>
                    </div>
                    <div className='d-flex justify-content-start gap-5 mb-4'>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check'>
                            <input
                              type='checkbox'
                              checked={isEngineChecked1}
                              onChange={handleEngineChange1}
                              id='checkbox-rect31'
                              className='form-check-input'
                            />
                            <label
                              htmlFor='checkbox-rect31'
                              className='form-check-label'
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check'>
                            <input
                              type='checkbox'
                              id='checkbox-rect32'
                              checked={isEngineChecked2}
                              onChange={handleEngineChange2}
                              className='form-check-input'
                            />
                            <label
                              htmlFor='checkbox-rect32'
                              className='form-check-label'
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            {Object.keys(itemState).length > 0 &&
              itemState.Api_name === 'Future' &&
              itemState?.RoadSideAssistance === 'ZDCNT' &&
              apiRequestQQ.NewPolicyType !== 'Bundled' && (
                <>
                  <div>
                    <div className='modal_heading w-100 mb-3'>
                      <h4 className='text-start mb-4'>
                        {' '}
                        Do you have Tyre Protection in previous policy?
                      </h4>
                    </div>
                    <div className='d-flex justify-content-start gap-5 mb-4'>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check'>
                            <input
                              type='checkbox'
                              checked={isTyreChecked1}
                              onChange={handleTyreChange1}
                              id='checkbox-rect41'
                              className='form-check-input'
                            />
                            <label
                              htmlFor='checkbox-rect41'
                              className='form-check-label'
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check'>
                            <input
                              type='checkbox'
                              id='checkbox-rect42'
                              checked={isTyreChecked2}
                              onChange={handleTyreChange2}
                              className='form-check-input'
                            />
                            <label
                              htmlFor='checkbox-rect42'
                              className='form-check-label'
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {' '}
                    <div className='modal_heading w-100 mb-3 mt-4'>
                      <h4 className='text-start  mb-4'>
                        {' '}
                        Do you have Zero Depth in previous policy?
                      </h4>
                    </div>
                    <div className='d-flex justify-content-start gap-5 mb-4 check-input'>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check '>
                            <input
                              type='checkbox'
                              checked={isZeroDepChecked1}
                              onChange={handleZeroDepChange1}
                              id='checkbox-rect11'
                              className='form-check-input'
                            />
                            <label
                              htmlFor='checkbox-rect11'
                              className='form-check-label'
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check'>
                            <input
                              type='checkbox'
                              checked={isZeroDepChecked2}
                              onChange={handleZeroDepChange2}
                              id='checkbox-rect12'
                              className='form-check-input'
                              style={{
                                width: '18px',
                                height: '18px',
                                borderRadius: '3px'
                              }}
                            />
                            <label
                              htmlFor='checkbox-rect12'
                              className='form-check-label'
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            {Object.keys(itemState).length > 0 &&
              itemState.Api_name === 'Future' &&
              itemState?.RoadSideAssistance === 'ZDCNE' &&
              apiRequestQQ.NewPolicyType !== 'Bundled' && (
                <>
                  <div>
                    <div className='modal_heading w-100 mb-3'>
                      <h4 className='text-start mb-4'>
                        {' '}
                        Do you have Engine Protection in previous policy?
                      </h4>
                    </div>
                    <div className='d-flex justify-content-start gap-5 mb-4'>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check'>
                            <input
                              type='checkbox'
                              checked={isEngineChecked1}
                              onChange={handleEngineChange1}
                              id='checkbox-rect31'
                              className='form-check-input'
                            />
                            <label
                              htmlFor='checkbox-rect31'
                              className='form-check-label'
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check'>
                            <input
                              type='checkbox'
                              id='checkbox-rect32'
                              checked={isEngineChecked2}
                              onChange={handleEngineChange2}
                              className='form-check-input'
                            />
                            <label
                              htmlFor='checkbox-rect32'
                              className='form-check-label'
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {' '}
                    <div className='modal_heading w-100 mb-3 mt-4'>
                      <h4 className='text-start  mb-4'>
                        {' '}
                        Do you have Zero Depth in previous policy?
                      </h4>
                    </div>
                    <div className='d-flex justify-content-start gap-5 mb-4 check-input'>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check '>
                            <input
                              type='checkbox'
                              checked={isZeroDepChecked1}
                              onChange={handleZeroDepChange1}
                              id='checkbox-rect11'
                              className='form-check-input'
                            />
                            <label
                              htmlFor='checkbox-rect11'
                              className='form-check-label'
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='box'>
                        <div class='item'>
                          <div class='form-check'>
                            <input
                              type='checkbox'
                              checked={isZeroDepChecked2}
                              onChange={handleZeroDepChange2}
                              id='checkbox-rect12'
                              className='form-check-input'
                              style={{
                                width: '18px',
                                height: '18px',
                                borderRadius: '3px'
                              }}
                            />
                            <label
                              htmlFor='checkbox-rect12'
                              className='form-check-label'
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            {Object.keys(itemState).length > 0 &&
              itemState.Api_name === 'Future' &&
              apiRequestQQ.AddOns.IsElectricalAccessories === true &&
              apiRequestQQ.NewPolicyType !== 'Bundled' && (
                <div>
                  <div className='modal_heading w-100 mb-3'>
                    <h4 className='text-start mb-4'>
                      {' '}
                      Do you have Electrical Accessories in previous policy?
                    </h4>
                  </div>
                  <div className='d-flex justify-content-start gap-5 mb-4'>
                    <div className='box'>
                      <div class='item'>
                        <div class='form-check'>
                          <input
                            type='checkbox'
                            checked={isElectricalChecked1}
                            onChange={handleElectricalChange1}
                            id='checkbox-rect61'
                            className='form-check-input'
                          />
                          <label
                            htmlFor='checkbox-rect61'
                            className='form-check-label'
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='box'>
                      <div class='item'>
                        <div class='form-check'>
                          <input
                            type='checkbox'
                            id='checkbox-rect62'
                            checked={isElectricalChecked2}
                            onChange={handleElectricalChange2}
                            className='form-check-input'
                          />
                          <label
                            htmlFor='checkbox-rect62'
                            className='form-check-label'
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {Object.keys(itemState).length > 0 &&
              itemState.Api_name === 'Future' &&
              apiRequestQQ.AddOns.IsNonElectricalAccessories === true &&
              apiRequestQQ.NewPolicyType !== 'Bundled' && (
                <div>
                  <div className='modal_heading w-100 mb-3'>
                    <h4 className='text-start mb-4'>
                      {' '}
                      Do you have Non-Electrical Accessories in previous policy?
                    </h4>
                  </div>
                  <div className='d-flex justify-content-start gap-5 mb-4'>
                    <div className='box'>
                      <div class='item'>
                        <div class='form-check'>
                          <input
                            type='checkbox'
                            checked={isNonElectricalChecked1}
                            onChange={handleNonElectricalChange1}
                            id='checkbox-rect71'
                            className='form-check-input'
                          />
                          <label
                            htmlFor='checkbox-rect71'
                            className='form-check-label'
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='box'>
                      <div class='item'>
                        <div class='form-check'>
                          <input
                            type='checkbox'
                            id='checkbox-rect72'
                            checked={isNonElectricalChecked2}
                            onChange={handleNonElectricalChange2}
                            className='form-check-input'
                          />
                          <label
                            htmlFor='checkbox-rect72'
                            className='form-check-label'
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {validationError && (
              <p style={{ color: 'red' }}>{validationError}</p>
            )}
            <div className='d-flex justify-content-center mt-4'>
              <button
                disabled={hidebtn}
                style={{ filter: hidebtn ? 'blur(2px)' : 'none' }}
                onClick={() => magmaSubmitCall()}
                type='button'
                className='btn btn-primary fs-6  d-flex justify-content-center'
              >
                {spinner ? (
                  <div class='spinner-border text-light' role='status'>
                    <span class='sr-only'></span>
                  </div>
                ) : (
                  'Submit'
                )}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        backdrop='static'
        isOpen={modalOpen4}
        toggle={() => setModalOpen4(!modalOpen4)}
        size='md'
        className='modal-dialog-centered'
      >
        <ModalBody>
          <div className='main-modal-sec-mag'>
            <div>
              <div className='modal-logo'>
                <img src={magmaImg}></img>
              </div>
              <div
                onClick={() => setModalOpen4(!modalOpen4)}
                class='v3_close false'
              >
                <img src='/assets/img/close.png' title='Close' alt='close' />
              </div>
            </div>
            <div className='modal_heading w-100 mb-3'>
              <h4 className='text-center  mt-3'>
                {' '}
                Please Remove the following AddOns as you do not have these
                AddOns in your Previous Policy
              </h4>
            </div>
            <Label>
              {apiRequestQQ.AddOns.InvoiceCover &&
                (!isEngineChecked2 || !isTyreChecked2) &&
                (isEngineChecked2 || isTyreChecked2) &&
                'RTI Cover , '}
            </Label>
            <Label>
              {apiRequestQQ.AddOns.ZeroDepriciationCover === true &&
                isZeroDepChecked2 &&
                'Zero Depreciation , '}
            </Label>
            <Label>
              {apiRequestQQ.AddOns.EngineProtection === true &&
                isEngineChecked2 &&
                'Engine Protection , '}
            </Label>
            <Label>
              {apiRequestQQ.AddOns.TyreProtection === true &&
                isTyreChecked2 &&
                'Tyre Protection , '}
            </Label>
            <Label>
              {apiRequestQQ.AddOns.IsCngAccessories === true &&
                'Bi-Fuel Kit , '}
            </Label>
            <Label>
              {apiRequestQQ.AddOns.IsElectricalAccessories === true &&
                'Electrical Accessories , '}
            </Label>
            <Label>
              {apiRequestQQ.AddOns.IsNonElectricalAccessories === true &&
                'Non-Electrical Accessories , '}
            </Label>
            <div className='d-flex justify-content-center mt-4 plan_btn'>
              <button
                type='button'
                onClick={() => setModalOpen4(!modalOpen4)}
                className='fs-6  mt-3 d-flex justify-content-center '
                // onClick={handleSubmit(magmaSubmitCall)}
              >
                Ok
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {ncbModel && <QuotelistModel show={ncbModel} setModel={setNcbModel} />}
      {planModel && (
        <QuotelistModelPlan
          show={planModel}
          setModel={setPlanModel}
          filter={'Plan Model'}
          filteredList={filterLIST}
        />
      )}
      {planDropdown && (
        <QuotelistDropdown show={planDropdown} setModel={setPlanDropdown} />
      )}
      {addCover && <QuotelistAddOns show={addCover} setModel={setAddCover} />}

      {coverModel && (
        <QuotelistModelPlanHealth
          list={coverModelList}
          heading={'Cover'}
          show={coverModel}
          setModel={setCoverModel}
        />
      )}
      {sortModel && (
        <QuotelistModelPlanHealth
          list={sortByList}
          heading={'Sort By'}
          show={sortModel}
          setModel={setSortModel}
        />
      )}
      {insurer && (
        <QuotelistModelPlanHealth
          list={insurerList}
          heading={'Insurer'}
          show={insurer}
          setModel={setInsurerModel}
        />
      )}
      {planTypeModel && (
        <QuotelistModelPlanHealth
          show={planTypeModel}
          list={planTypeList}
          heading={'Plan Type'}
          setModel={setPlanTypeModel}
        />
      )}
      {filterModel && (
        <QuotelistAllFilter show={filterModel} setModel={setFilterModel} />
      )}
      {/* {cashlessHospitalList && (
        <QuotelistCashlessHospitalList
          show={cashlessHospitalList}
          list={cashlessHospitals}
          heading={'Cashless Hospitals'}
          setModel={setCashlessHospitalList}
        />
      )} */}
      {editDetails && (
        <EditDetailsModal
          show={setEditModal}
          setModal={setEditDetails}
          motorQuotation={motorQuotation}
        />
      )}
      {/* 
      {premiumBreakup && (
        <PremiumBreakup
          show={setPremiumBreakupModal}
          setModal={setPremiumBreakup}
          planDetail={planDetail}
        />
      )} */}
    </>
  )
}

export default QuoteBike
