import React, { useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import { Modal } from 'reactstrap'
import { Link } from 'react-router-dom'

function Car () {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  return (
    <>
      {/*   <Navbar />
 
      <div className='main-car-insurance'>
        <div id='car-insurance'>
          <div className='car-insurance-info'>
            <h1>
              Car insurance starting from <br /> <span>₹ 6/day*</span>
            </h1>
            <input
              type='text'
              className=' text-uppercase'
              placeholder='Enter car number'
              maxLength={15}
            />
            <br />
            <Link to="/rto">
            <button
            //  onClick={()=> setModal(true)}
             >
              <a href='#'>Get Details</a>
            </button>
            </Link>
            <div className='text'>Don't know your car number?</div>
          </div>
          <div className='car-insurance-img'>
            <img src='./assets/img/Car-insurance-img-1.png' alt='' />
          </div>
        </div>
      </div> */}
      <div className='about-car-insurance'>
        <div className='car-heading'>
          <h1>
            Know More about <b> Car Insurance</b>
          </h1>
          <div className='car-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Factors influencing premiums: Explore how your driving history,
              vehicle type, location, and coverage limits affect insurance
              costs.
            </p>
          </div>
          <div className='car-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Deductibles and out-of-pocket costs: Understand how choosing
              higher deductibles can lower premiums but increase your financial
              responsibility in case of a claim.Discounts and savings: Identify
              opportunities for discounts, such as safe driver discounts,
              multi-car policies, and bundling with other insurance types.
            </p>
          </div>
          <div className='car-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Policy customization: Delve into the various options available to
              personalize your policy, considering add-ons and endorsements.
              Customize your coverage to precisely match your unique needs and
              circumstances.
            </p>
          </div>
          <div className='car-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Claims process: Familiarize yourself with the steps involved in
              filing a claim and the factors affecting claim settlements.
              Shopping around: Compare quotes from multiple insurers to find the
              best coverage and rates for your situation.
            </p>
          </div>
        </div>
        <div className='car-insurance-img'>
          <img src='./assets/img/CAr-insurance-img-2.png' alt='' />
        </div>
      </div>
      <div className='about-car-insurance-2'>
        <div className='car-insurance-img'>
          <img src='./assets/img/CAr-insurance-img-3.png' alt='' />
        </div>
        <div className='car-heading'>
          <h1>
            Benefits of <b> Car Insurance</b>
          </h1>
          <div className='car-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Car insurance provides financial coverage against damages or
              losses resulting from accidents, theft, vandalism, or natural
              disasters. This protection helps cover repair costs and can
              prevent significant financial burdens
            </p>
          </div>
          <div className='car-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Medical coverage: Covers medical expenses for you and your
              passengers in case of injuries sustained in a covered accident.
            </p>
          </div>
          <div className='car-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Peace of mind: Knowing that you have insurance coverage helps
              alleviate the stress and financial burden associated with
              unexpected accidents or incidents.
            </p>
          </div>
          <div className='car-pera'>
            <img src='./assets/img/Ellipse 56.png' alt='' />
            <p>
              Car insurance is often a legal requirement in many places. Having
              valid insurance helps drivers comply with legal regulations, and
              failure to have insurance may result in fines or other penalties.
            </p>
          </div>
        </div>
      </div>
      {/* <Footer />

      <Modal isOpen={modal} toggle={toggle} size='md'>

<nav className=''>
    <div
      className='nav nav-tabs border-0 justify-content-center px-2 py-2 mt-0'
      id='nav-tab'
      style={{
        backgroundColor: '#354c89',
        width: '100%',
        color: 'white',
        fontWeight: 'bold'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p className='fs-5'>Details Submitted</p>
        
      </div>
    </div>
  </nav>
  <div
    className='px-4 py-5'
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <h2 className='modal-page'>
      Your Details has been Submitted.You will Receive a call Shortly
    </h2>
    <button
      onClick={() => setModal(false)}
      style={{
        width: '50%',
        margin: 20,
        border: 'none',
        borderRadius: '3px',
        color: 'white',
        backgroundColor: '#354c89'
      }}
    >
      Close
    </button>
  </div>
</Modal> */}
    </>
  )
}

export default Car
