import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import {
  dispatchMotorQuote,
  getVehicleMake,
  getVehicleModel,
  getVehicleVariant,
} from "../store/actions/userAction";
import { useDispatch, useSelector } from "react-redux";

import {
  dispatchQuickQuote,
  resetQuickQuoteResults,
} from "../store/actions/userAction";
import {
  FutureGeneralQuickQuote,
  digitAPICall,
  magmaAPICall,
} from "../components/Services/TPApiCall";
import moment from "moment";
import ReactSelect from "./Tags/ReactSelectEditDetails";
import { POLICY_TYPE, VEHICLE_TYPE } from "./utility/constants";

const EditDetailsModal = ({ show, setModal, motorQuotation }) => {
  const toggle = () => setModal(!show);
  const [makeData, setMakeData] = useState([]);
  const [newFilter, setNewFilter] = useState({});
  const [modelData, setModelData] = useState([]);
  const [variantData, setVariantData] = useState([]);
  const [fuelTypes, setFuelTypes] = useState([]);
  const [sortedVariantData, setSortedVariantData] = useState([]);
  const apiRequestQQ = useSelector((state) => state.root.apiRequestQQ);
  const motorRequest = useSelector((state) => state.root.motorRequest);
  const [selectedFuelType, setSelectedFuelType] = useState(
    newFilter.fuelType ? newFilter.fuelType : motorRequest?.fuelType
  );
  const dispatch = useDispatch();
  const vehicleModel =
    apiRequestQQ.VehicleType === "4w"
      ? "Pvt Car"
      : apiRequestQQ.VehicleType === "2w"
      ? "Two Wheeler"
      : apiRequestQQ.VehicleType === "gcv"
      ? "Goods Carrying"
      : apiRequestQQ.VehicleType === "pcv" && "Passenger Carrying";
  useEffect(() => {
    let arr = [];
    getVehicleMake({ Vehicle_Type: vehicleModel }).then((response) => {
      if (response.status === true) {
        let data = response.data;
        let i = 0;
        while (i < data.length) {
          let item = data[i];
          arr.push({
            label: item.Make,
            value: item.Make,
          });
          i++;
        }
        setMakeData(arr);
      }
    });
    getVehicleModel({
      make: apiRequestQQ?.MakeName,
      Vehicle_Type: vehicleModel,
    }).then((response) => {
      if (response.status === true) {
        // handleStepSelection(activeStep + 1);
        let data = response.data;
        let i = 0;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            label: item.Model,
            value: item.Model,
          });
          i++;
        }
        setModelData(arr);
      }
    });
    getVehicleVariant({
      make: apiRequestQQ.MakeName,
      model: apiRequestQQ?.ModelName,
      Vehicle_Type: vehicleModel,
    }).then((response) => {
      if (response.status === true) {
        let data = response.data;
        let fuelTypeArr = data.filter(
          (v, i, a) => a.findIndex((v2) => v2.fuel_type === v.fuel_type) === i
        );

        let j = 0;
        let fuelarr = [];
        while (j < fuelTypeArr.length) {
          fuelarr.push(fuelTypeArr[j].fuel_type);
          j++;
        }
        setFuelTypes(fuelarr);
        let i = 0;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            label: item.Variant,
            value: item.PAS_ID,
            Fuel_Type: item.fuel_type,
            Cubic_Capacity: item.CC,
            Seating_Capacity: item.SEATING_CAPACITY,
            HDFC: item.HDFC,
            Shriram: item.Shriram,
            Kotak: item.KOTAK_FINAL,
            Reliance: item.Reliance,
            Future: item.Future,
            Royal: item.Royal,
            Bajaj: item.Bajaj,
            Magma: item.Magma,
            Sompo: item.Universal_Sompo,
            vehicleId: item.id,
            Liberty: item.Liberty,
          });
          i++;
        }
        setVariantData(arr);
      }
    });
  }, []);

  const geenrateMfgYears = () => {
    var max = new Date().getFullYear();
    var min = max - 25;
    var years = [];
    for (let i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  };

  useEffect(() => {
    console.log("newFilter", newFilter);
  }, [newFilter]);

  const handleSelectMake = (make) => {
    setNewFilter((prevState) => ({ ...prevState, makeName: make }));
    // dispatchQuickQuote("MakeName", make);
    getVehicleModel({ make: make, Vehicle_Type: vehicleModel }).then(
      (response) => {
        if (response.status === true) {
          let data = response.data;
          let i = 0;
          let arr = [];
          while (i < data.length) {
            let item = data[i];
            arr.push({
              label: item.Model,
              value: item.Model,
            });
            i++;
          }
          setModelData(arr);
        }
      }
    );
  };

  const handleSelectModel = (model) => {
    setNewFilter((prevState) => ({ ...prevState, modelName: model }));
    getVehicleVariant({
      make: newFilter.makeName ? newFilter.makeName : motorRequest?.makeName,
      model: model,
      Vehicle_Type: vehicleModel,
    }).then((response) => {
      if (response.status === true) {
        let data = response.data;
        let fuelTypeArr = data.filter(
          (v, i, a) => a.findIndex((v2) => v2.fuel_type === v.fuel_type) === i
        );

        let j = 0;
        let fuelarr = [];
        while (j < fuelTypeArr.length) {
          fuelarr.push(fuelTypeArr[j].fuel_type);
          j++;
        }
        setFuelTypes(fuelarr);
        let i = 0;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            label: item.Variant,
            value: item.PAS_ID,
            Fuel_Type: item.fuel_type,
            Cubic_Capacity: item.CC,
            Seating_Capacity: item.SEATING_CAPACITY,
            HDFC: item.HDFC,
            Shriram: item.Shriram,
            Kotak: item.KOTAK_FINAL,
            Reliance: item.Reliance,
            Future: item.Future,
            Royal: item.Royal,
            Bajaj: item.Bajaj,
            Magma: item.Magma,
            Sompo: item.Universal_Sompo,
            vehicleId: item.id,
            Liberty: item.Liberty,
          });
          i++;
        }
        setVariantData(arr);
      }
    });
  };

  const handleSelectPetrolType = (val) => {
    console.log("handleSelectPetrolType", val);
    setSelectedFuelType(val);
    // dispatchQuickQuote("FuelType", val);
    setNewFilter((prevState) => ({ ...prevState, fuelType: val }));
    const selectedVariantData = variantData.filter(
      (variant) => variant?.Fuel_Type === val
    );
    setSortedVariantData(selectedVariantData);
  };

  const handleSelectVariant = (variant) => {
    console.log("handleSelectVariant", variant);
    // dispatchQuickQuote("VariantName", variant?.label);
    // dispatchQuickQuote("VariantCode.Digit", variant.value);
    // dispatchQuickQuote("VariantCode.HDFC", variant.HDFC);
    // dispatchQuickQuote("VariantCode.Shriram", variant.Shriram);
    setNewFilter((prevState) => ({
      ...prevState,
      variantId: variant.value,
      variantName: variant.label,
    }));

    //     let postdata = {
    //       VehicleType: apiRequestQQ.VehicleType,
    //       Make: apiRequestQQ.MakeName,
    //       Model: apiRequestQQ.ModelName,
    //       Variant: variant.label,
    //       CC: variant.Cubic_Capacity,
    //       fuelType: variant.Fuel_Type,
    //     };
  };

  useEffect(() => {}, [newFilter]);

  useEffect(() => {
    for (let key in newFilter.VariantCode) {
      dispatchQuickQuote(`VariantCode.${key}`, newFilter.VariantCode[key]);
    }
  }, [newFilter.VariantCode]);
  let calculateYearDifference = (date1, date2) => {
    // Convert strings to Date objects if they are not already
    const startDate = new Date(date1);
    const endDate = new Date(date2);

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(startDate - endDate);

    // Convert milliseconds to years (assuming 365.25 days in a year for accuracy)
    const differenceYears = differenceMs / (1000 * 60 * 60 * 24 * 365.25);

    // Round down to the nearest integer to get the whole number of years
    // const yearsDifference = Math.floor(differenceYears);

    return differenceYears;
    // setYearDiff[yearsDifference];
  };
  let yearDiff = calculateYearDifference(
    apiRequestQQ.RegistrationDate,
    new Date().toISOString().slice(0, 10)
  );
  const handleUpdateMMV = () => {
    debugger;
    dispatchQuickQuote("idvRangeSet", false);
    // for (let key in newFilter) {
    //   if (
    //     key == "Digit" ||
    //     key == "Shriram" ||
    //     key == "HDFC" ||
    //     key == "Bajaj"
    //   ) {
    //     dispatchQuickQuote("VariantCode." + key, newFilter[key]);
    //   } else {
    //     dispatchQuickQuote(key, newFilter[key]);
    //   }
    // }

    // const mfgDate = moment(apiRequestQQ?.RegistrationDate)
    //   .subtract(2, "months")
    //   .format("YYYY-MM-DD");
    // dispatchQuickQuote("ManufaturingDate", mfgDate);
    console.log("newFilter", newFilter);
    let newData = { ...motorRequest };

    for (let key in newFilter) {
      newData[key] = newFilter[key];
      dispatchMotorQuote(`${key}`, newFilter[key]);
    }

    if (
      motorRequest.vehicleType === VEHICLE_TYPE.FOUR_WHEELER &&
      yearDiff < 3
    ) {
      dispatchMotorQuote("newPolicyType", POLICY_TYPE.ODONLY);
      newData = { ...newData, newPolicyType: POLICY_TYPE.ODONLY };
    } else if (
      motorRequest.vehicleType === VEHICLE_TYPE.FOUR_WHEELER &&
      yearDiff >= 3
    ) {
      dispatchMotorQuote("newPolicyType", POLICY_TYPE.COMPREHENSIVE);
      newData = { ...newData, newPolicyType: POLICY_TYPE.COMPREHENSIVE };
    }
    if (motorRequest.vehicleType === VEHICLE_TYPE.TWO_WHEELER && yearDiff < 5) {
      dispatchMotorQuote("newPolicyType", POLICY_TYPE.ODONLY);
      newData = { ...newData, newPolicyType: POLICY_TYPE.ODONLY };
    } else if (
      motorRequest.vehicleType === VEHICLE_TYPE.TWO_WHEELER &&
      yearDiff >= 5
    ) {
      dispatchMotorQuote("newPolicyType", POLICY_TYPE.COMPREHENSIVE);
      newData = { ...newData, newPolicyType: POLICY_TYPE.COMPREHENSIVE };
    }

    dispatch(resetQuickQuoteResults());
    // digitAPICall(newData);
    // FutureGeneralQuickQuote(newData);
    // magmaAPICall(newData);
    motorQuotation(newData);
    setModal(!show);
  };

  useEffect(() => {}, [newFilter]);

  const handleMfgDate = (val) => {
    let date = new Date(val);
    date.setDate(1);
    date.setMonth(0);

    const manufacturingDate = date.toISOString().slice(0, 10);
    // dispatchQuickQuote("ManufaturingDate", val);
    setNewFilter((prevState) => ({
      ...prevState,
      manufaturingDate: manufacturingDate,
      manufacturingYear: val,
    }));
  };
  const handleRegDate = (val) => {
    const regYear = new Date(val).getFullYear();
    setNewFilter((prevState) => ({
      ...prevState,
      registrationDate: val,
      registrationYear: regYear,
    }));
    // dispatchQuickQuote("RegistrationYear", regYear);
    // dispatchQuickQuote("RegistrationDate", val);
  };
  return (
    <>
      <Modal
        isOpen={show}
        toggle={toggle}
        centered
        size="md"
        backdrop="static"
        keyboard={false}
      >
        {/* <ModalHeader className="d-flex justify-content-between">
          
        </ModalHeader> */}
        <ModalBody>
          <div className="p-3">
            <div className="modal_heading w-100 mb-5">
              <h4 className="text-center fs-3">Edit Details</h4>
              <div onClick={toggle} className="v2_close false">
                <img
                  src="./assets/img/delete_icon.svg"
                  title="Close"
                  alt="close"
                />
              </div>
            </div>
            <div>
              <div className="row">
                <div className="did-floating-label-content">
                  <ReactSelect
                    value={makeData.filter(
                      (option) => option.label === newFilter.MakeName
                    )}
                    options={makeData}
                    name="make"
                    placeholder="Select Manufacturer"
                    onChange={(val) => handleSelectMake(val.value)}
                    defaultValue={
                      newFilter?.MakeName
                        ? newFilter?.MakeName
                        : apiRequestQQ?.MakeName
                    }
                  />{" "}
                  <label htmlFor="idxx4" className="did-floating-label">
                    Manufacture
                  </label>
                </div>
                <div className="did-floating-label-content">
                  <ReactSelect
                    options={modelData}
                    name="model"
                    value={modelData.filter(
                      (option) => option.label === newFilter.ModelName
                    )}
                    placeholder="Select vehicle modal"
                    onChange={(val) => handleSelectModel(val.value)}
                    defaultValue={
                      newFilter?.ModelName
                        ? newFilter?.ModelName
                        : apiRequestQQ?.ModelName
                    }
                  />
                  <label htmlFor="idxx4" className="did-floating-label">
                    Model
                  </label>
                </div>

                <div className="did-floating-label-content">
                  <select
                    className="did-floating-input"
                    value={selectedFuelType}
                    onClick={() => {
                      dispatchMotorQuote("fuelType", "");
                      setSelectedFuelType("");
                    }}
                    onChange={(e) => handleSelectPetrolType(e.target.value)}
                  >
                    <option value="">Select Fuel Type</option>
                    {fuelTypes.map((fuel) => (
                      <option
                        key={fuel}
                        value={fuel}
                        // selected={
                        //   newFilter?.FuelType === fuel
                        //     ? true
                        //     : motorRequest?.fuelType === fuel
                        //     ? true
                        //     : false
                        // }
                      >
                        {fuel}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="idxx4" className="did-floating-label">
                    Fuel Type
                  </label>
                </div>

                <div className="did-floating-label-content">
                  <ReactSelect
                    options={
                      sortedVariantData ? sortedVariantData : variantData
                    }
                    name="variant"
                    value={variantData.filter(
                      (option) => option.label === newFilter.VariantName
                    )}
                    // options={variantData.filter(
                    //   (item) => item.Fuel_Type == newFilter.FuelType
                    // )}
                    placeholder="Select vehicle variant"
                    onChange={(val) => handleSelectVariant(val)}
                    defaultValue={
                      newFilter?.VariantName
                        ? newFilter?.VariantName
                        : apiRequestQQ?.VariantName
                    }
                  />
                  <label htmlFor="idxx4" className="did-floating-label">
                    Variant
                  </label>
                </div>

                <div className="">
                  <div className="">
                    <div className="did-floating-label-content">
                      <label className="did-floating-label">
                        Manufacture Year
                      </label>
                      {/* <input
                        className='did-floating-input w-100'
                        defaultValue={apiRequestQQ.ManufaturingDate}
                        onChange={e => handleMfgDate(e.target.value)}
                        type='date'
                      />{' '} */}
                      <select
                        id="ManufacturingYear"
                        // {...register2("ManufacturingYear", {
                        //   required: "Year is required",
                        // })}
                        onChange={(e) => handleMfgDate(e.target.value)}
                        defaultValue={
                          newFilter.ManufacturingYear
                            ? newFilter.ManufacturingYear
                            : apiRequestQQ.ManufacturingYear
                        }
                        className="Motor-deatil"
                      >
                        {geenrateMfgYears().map((year, index) => (
                          <option key={index}>{year}</option>
                        ))}
                      </select>
                      {/* {errors2?.ManufacturingYear && (
                        <div>
                          <span className="text-danger error-input">
                            {errors2?.ManufacturingYear?.message}
                          </span>
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div className="">
                    <div className="did-floating-label-content">
                      <label className="did-floating-label">
                        Registration Date{" "}
                      </label>
                      <input
                        defaultValue={
                          newFilter.RegistrationDate
                            ? newFilter.RegistrationDate
                            : apiRequestQQ.RegistrationDate
                        }
                        onChange={(e) => handleRegDate(e.target.value)}
                        min={newFilter?.ManufaturingDate}
                        className=" w-100 did-floating-input"
                        type="date"
                      />
                    </div>
                  </div>

                  <div className="text-center">
                    <button
                      type="button"
                      onClick={() => handleUpdateMMV()}
                      className="btn btn-primary text-center"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditDetailsModal;
