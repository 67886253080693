import moment from "moment";

export const twoWheelerMake = [
  {
    image: "assets/img/logos/bikes/hero.png",
    label: "Hero MotoCorp",
    value: 202
  },
  {
    image: "assets/img/logos/bikes/honda.png",
    label: "HONDA",
    value: 200
  },
  {
    image: "assets/img/logos/bikes/bajaj.png",
    label: "Bajaj Auto",
    value: 206
  },
  {
    image: "assets/img/logos/bikes/tvs.png",
    label: "TVS MOTOR",
    value: 224
  },
  {
    image: "assets/img/logos/bikes/ROYAL_ENFIELD.png",
    label: "ROYAL ENFIELD",
    value: 222
  },
  {
    image: "assets/img/logos/bikes/MAHINDRA.png",
    label: "Mahindra Two Wheelers Limited",
    value: 203
  },
  {
    image: "assets/img/logos/bikes/yamaha.png",
    label: "YAMAHA MOTOR PVT LTD",
    value: 227
  },
  {
    image: "assets/img/logos/bikes/KTM.png",
    label: "KTM",
    value: 207
  },
  {
    image: "assets/img/logos/bikes/jawa.png",
    label: "JAWA MOTORS",
    value: 240
  },
  {
    image: "assets/img/logos/bikes/lml.png",
    label: "LML",
    value: 218
  },
  {
    image: "assets/img/logos/bikes/HYOSUNG.png",
    label: "HYOSUNG",
    value: 204
  },
];

export const fourWheelerModel = [
  {
    image: "assets/img/logos/cars/suzuki.png",
    label: "MARUTI SUZUKI",
    value: 431
  },
  {
    image: "assets/img/logos/cars/tata.png",
    label: "Tata Motors Limited",
    value: 459
  },
  {
    image: "assets/img/logos/cars/honda.png",
    label: "HONDA",
    value: 419
  },
  {
    image: "assets/img/logos/cars/ford.png",
    label: "FORD",
    value: ''
  },
  {
    image: "assets/img/logos/cars/hyundai.png",
    label: "HYUNDAI MOTOR INDIA LTD.",
    value: 417
  },
  {
    image: "assets/img/logos/cars/kia.png",
    label: "KIA MOTORS",
    value: 427
  },
  {
    image: "assets/img/logos/cars/mahindra.png",
    label: "Mahindra AND Mahindra Limited",
    value: 430
  },
  {
    image: "assets/img/logos/cars/mg.png",
    label: "MORRIS GARAGES",
    value: 440
  },
  {
    image: "assets/img/logos/cars/nissan.png",
    label: "NISSAN",
    value: 441
  },
  {
    image: "assets/img/logos/cars/renault.png",
    label: "RENAULT",
    value: 448
  },
  {
    image: "assets/img/logos/cars/skoda.png",
    label: "SKODA",
    value: 453
  },
  {
    image: "assets/img/logos/cars/toyota.png",
    label: "TOYOTA",
    value: 461
  },
  {
    image: "assets/img/logos/cars/wv.png",
    label: "VOLKSWAGEN",
    value: 463
  },
  {
    image: "assets/img/logos/cars/volvo.png",
    label: "VOLVO",
    value: 464
  },
  {
    image: "assets/img/logos/cars/audi.png",
    label: "AUDI",
    value: 402
  },
  {
    image: "assets/img/logos/cars/bmw.png",
    label: "BMW",
    value: 208
  },
];

export function isWithinThreeYears(dateStr) {
  // Parse the given date string into a moment object
  const date = moment(dateStr, "YYYY-MM-DD");

  // Calculate the current date and the date three years ago
  const now = moment();
  const threeYearsAgo = moment().subtract(3, "years");

  // Check if the given date is between three years ago and now
  if (date.isBetween(threeYearsAgo, now)) {
    return true;
  } else {
    return false;
  }
}

export function isWithinFiveYears(dateStr) {
  // Parse the given date string into a moment object
  const date = moment(dateStr, "YYYY-MM-DD");

  // Calculate the current date and the date five years ago
  const now = moment();
  const fiveYearsAgo = moment().subtract(5, "years");

  // Check if the given date is between five years ago and now
  if (date.isBetween(fiveYearsAgo, now)) {
    return true;
  } else {
    return false;
  }
}

export const pvtCarPolicyInfoArr = [
  {
    label: "Select Policy Type",
    name: "PolicyStatus",
    type: "select",
    placeholder: "Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "continue", option: "Continue" },
      { value: "expired within 90 day", option: "Expired Within 90 days" },
      { value: "expired above 90 day", option: "Expired Above 90 days" },
      { value: "false", option: "No Information About Previous Policy" },
    ],
  },
  {
    label: "New policy type?",
    name: "NewPolicyType",
    type: "select",
    placeholder: "New Policy Type",
    validation: {
      required: "Field is required",
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
      { value: "ODOnly", option: "StandAlone OD" },
    ],
  },
  {
    label: "Previous policy type?",
    name: "PreviousPolicyType",
    type: "select",
    placeholder: "Previous Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
    ],
  },
  {
    label: "Previous Policy Insurer",
    name: "PreInsurerCode",
    type: "select",
    placeholder: "Previous Policy Insurer",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Mfg. month of the year",
    name: "ManufaturingDate",
    type: "date",
    validation: {
      require: "Field is required",
    },
  },
  {
    label: "Reg. date of your car",
    name: "RegistrationDate",
    type: "date",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Prev. year policy expiry date",
    name: "PrePolicyEndDate",
    type: "date",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Vehicle Owned By",
    name: "CustomerType",
    type: "select",
    data: [
      { value: "INDIVIDUAL", option: "Individual" },
      { value: "COMPANY", option: "Organization" },
    ],
    validation: {
      required: false,
    },
  },
  {
    label: "Previous No Claim Bonus (NCB)",
    name: "PreviousNoClaimBonus",
    type: "select",
    placeholder: "Previous No Claim Bonus (NCB)",
    validation: {
      required: false,
    },
    data: [
      { value: "0", option: "0%" },
      { value: "20", option: "20%" },
      { value: "25", option: "25%" },
      { value: "35", option: "35%" },
      { value: "45", option: "45%" },
      { value: "50", option: "50%" },
    ],
  },
  {
    label: "Claim made in expiring policy?",
    name: "PreviousInsuranceClaimed",
    type: "select",
    data: [
      { value: true, option: "Yes" },
      { value: false, option: "No" },
    ],
    validation: {
      required: false,
    },
  },
];

export const bikePolicyInfoArr = [
  {
    label: "Select Policy Type",
    name: "PolicyStatus",
    type: "select",
    placeholder: "Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "continue", option: "Continue" },
      { value: "expired within 90 day", option: "Expired Within 90 days" },
      { value: "expired above 90 day", option: "Expired Above 90 days" },
      { value: "false", option: "No Information About Previous Policy" },
    ],
  },
  {
    label: "New policy type?",
    name: "NewPolicyType",
    type: "select",
    placeholder: "New Policy Type",
    validation: {
      required: "Field is required",
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
      { value: "ODOnly", option: "StandAlone OD" },
    ],
  },
  {
    label: "Previous policy type?",
    name: "PreviousPolicyType",
    type: "select",
    placeholder: "Previous Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
    ],
  },
  {
    label: "Previous Policy Insurer",
    name: "PreInsurerCode",
    type: "select",
    placeholder: "Previous Policy Insurer",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Mfg. month of the year",
    name: "ManufaturingDate",
    type: "date",
    validation: {
      require: "Field is required",
    },
  },
  {
    label: "Reg. date of your car",
    name: "RegistrationDate",
    type: "date",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Prev. year policy expiry date",
    name: "PrePolicyEndDate",
    type: "date",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Previous No Claim Bonus (NCB)",
    name: "PreviousNoClaimBonus",
    type: "select",
    placeholder: "Previous No Claim Bonus (NCB)",
    validation: {
      required: false,
    },
    data: [
      { value: "0", option: "0%" },
      { value: "20", option: "20%" },
      { value: "25", option: "25%" },
      { value: "35", option: "35%" },
      { value: "45", option: "45%" },
      { value: "50", option: "50%" },
    ],
  },
  {
    label: "Vehicle Owned By",
    name: "CustomerType",
    type: "select",
    data: [
      { value: "INDIVIDUAL", option: "Individual" },
      { value: "COMPANY", option: "Organization" },
    ],
    validation: {
      required: false,
    },
  },
  {
    label: "Claim made in expiring policy?",
    name: "PreviousInsuranceClaimed",
    type: "select",
    data: [
      { value: true, option: "Yes" },
      { value: false, option: "No" },
    ],
    validation: {
      required: false,
    },
  },
];

export const pcvVehiclePolicyInfoArr = [
  {
    label: "Select Policy Type",
    name: "PolicyStatus",
    type: "select",
    placeholder: "Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "continue", option: "Continue" },
      { value: "expired within 90 day", option: "Expired Within 90 days" },
      { value: "expired above 90 day", option: "Expired Above 90 days" },
      { value: "false", option: "No Information About Previous Policy" },
    ],
  },
  {
    label: "New policy type?",
    name: "NewPolicyType",
    type: "select",
    placeholder: "New Policy Type",
    validation: {
      required: "Field is required",
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
      { value: "ODOnly", option: "StandAlone OD" },
    ],
  },
  {
    label: "Previous policy type?",
    name: "PreviousPolicyType",
    type: "select",
    placeholder: "Previous Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
    ],
  },
  {
    label: "Previous Policy Insurer",
    name: "PreInsurerCode",
    type: "select",
    placeholder: "Previous Policy Insurer",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Mfg. month of the year",
    name: "ManufaturingDate",
    type: "date",
    validation: {
      require: "Field is required",
    },
  },
  {
    label: "Reg. date of your car",
    name: "RegistrationDate",
    type: "date",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Prev. year policy expiry date",
    name: "PrePolicyEndDate",
    type: "date",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Previous No Claim Bonus (NCB)",
    name: "PreviousNoClaimBonus",
    type: "select",
    placeholder: "Previous No Claim Bonus (NCB)",
    validation: {
      required: false,
    },
    data: [
      { value: "0", option: "0%" },
      { value: "20", option: "20%" },
      { value: "25", option: "25%" },
      { value: "35", option: "35%" },
      { value: "45", option: "45%" },
      { value: "50", option: "50%" },
    ],
  },
  {
    label: "Claim made in expiring policy?",
    name: "PreviousInsuranceClaimed",
    type: "radio",
    option1: "Yes",
    option2: "No",
    value1: "Yes",
    value2: "No",
    data: [
      { value: true, option: "Yes" },
      { value: false, option: "No" },
    ],
    validation: {
      required: false,
    },
  },

  {
    label: "Number Of Wheels",
    name: "number_of_wheels",
    type: "number",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Seating Capacity",
    name: "seating_capacity",
    type: "number",
    validation: {
      required: "Field is required",
    },
  },
];

export const gcvVehiclePolicyInfoArr = [
  {
    label: "Select Policy Type",
    name: "PolicyStatus",
    type: "select",
    placeholder: "Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "continue", option: "Continue" },
      { value: "expired within 90 day", option: "Expired Within 90 days" },
      { value: "expired above 90 day", option: "Expired Above 90 days" },
      { value: "false", option: "No Information About Previous Policy" },
    ],
  },
  {
    label: "New policy type?",
    name: "NewPolicyType",
    type: "select",
    placeholder: "New Policy Type",
    validation: {
      required: "Field is required",
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
      { value: "ODOnly", option: "StandAlone OD" },
    ],
  },
  {
    label: "Previous policy type?",
    name: "PreviousPolicyType",
    type: "select",
    placeholder: "Previous Policy Type",
    validation: {
      required: false,
    },
    data: [
      { value: "Comprehensive", option: "Comprehensive" },
      { value: "ThirdParty", option: "Third Party" },
    ],
  },
  {
    label: "Previous Policy Insurer",
    name: "PreInsurerCode",
    type: "select",
    placeholder: "Previous Policy Insurer",
    validation: {
      required: false,
    },
  },
  {
    label: "Mfg. month of the year",
    name: "ManufaturingDate",
    type: "date",
    validation: {
      require: "Field is required",
    },
  },
  {
    label: "Reg. date of your car",
    name: "RegistrationDate",
    type: "date",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Prev. year policy expiry date",
    name: "PrePolicyEndDate",
    type: "date",
    validation: {
      required: false,
    },
  },
  {
    label: "Previous No Claim Bonus (NCB)",
    name: "PreviousNoClaimBonus",
    type: "select",
    placeholder: "Previous No Claim Bonus (NCB)",
    validation: {
      required: false,
    },
    data: [
      { value: "0", option: "0%" },
      { value: "20", option: "20%" },
      { value: "25", option: "25%" },
      { value: "35", option: "35%" },
      { value: "45", option: "45%" },
      { value: "50", option: "50%" },
    ],
  },
  {
    label: "Vehicle Owned By",
    name: "CustomerType",
    type: "select",
    data: [
      { value: "INDIVIDUAL", option: "Individual" },
      { value: "COMPANY", option: "Organization" },
    ],
    validation: {
      required: false,
    },
  },
  {
    label: "Claim made in expiring policy?",
    name: "PreviousInsuranceClaimed",
    type: "select",
    data: [
      { value: true, option: "Yes" },
      { value: false, option: "No" },
    ],
    validation: {
      required: false,
    },
  },

  {
    label: "Number Of Wheels",
    name: "number_of_wheels",
    type: "number",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Gross Weight",
    name: "seating_capacity",
    type: "number",
    validation: {
      required: "Field is required",
    },
  },
  {
    label: "Carrier Type",
    name: "carrier_type",
    type: "select",
    placeholder: "Carrier Type",
    validation: {
      required: "Field is required",
    },
    data: [
      { value: "Public", option: "Puclic" },
      { value: "Private", option: "Private" },
    ],
  },
];

export const Documents = [
  {
    id: 1,
    option: "RC-Front",
    name: "rc_front",
    type: "file",
  },
  {
    id: 2,
    option: "RC-Back",
    name: "rc_back",
    type: "file",
  },
  {
    id: 3,
    option: "Insurance-01",
    name: "insurance_01",
    type: "file",
  },
  {
    id: 4,
    option: "Insurance-02",
    name: "insurance_02",
    type: "file",
  },
  {
    id: 5,
    option: "29/30 Sales Latter",
    name: "sales_letter",
    type: "file",
  },
  {
    id: 6,
    option: "Invoice",
    name: "Invoice",
    type: "pdf",
  },
];

export const policyResultArr = (
  Logo,
  Api_name,
  PolicyId,
  ApiId,
  RegisterNumber,
  Make,
  Model,
  variant,
  BasicODPremium,
  BasicTPPremium,
  PAForOwner,
  NetPremium,
  GST,
  FinalPremium,
  MinIdv,
  MaxIdv,
  idv,
  StartDate,
  EndDate,
  discount,
  insurer,

  policyType,
  RoadSideAssistance,
  EngineProtection,
  TyreProtection,
  RimProtection,
  Consumables,
  IsElectricalAccessories,
  IsNonElectricalAccessories,
  InvoiceCover,
  EngineGearBox,
  NCBProtection,
  VoluntaryDeductive,
  PassengerCover,
  LossOfPersonalBelongings,
  ZeroDepriciationCover,
  KeyReplacement,
  PaCoverAmount,
  PassengerCoverAmount,
  ElectricAmount,
  nonElectricalAmount,
  VoluntaryAmount,
  NCBDiscount,
  CNGValue,
  DriverLiability,
  planId,
  insurerId,
  caseId,
) => {
  let start = moment(StartDate).format("yyyy");
  let end = moment(EndDate).format("yyyy");
  return {
    Logo: Logo,
    Api_name: Api_name,
    ApiId: ApiId,
    PolicyId: PolicyId,
    RegisterNumber: RegisterNumber,
    Make: Make,
    Model: Model,
    Variant: variant,
    BasicODPremium: BasicODPremium,
    BasicTPPremium: BasicTPPremium,
    PersonalAccident: PAForOwner,
    NetPremium: NetPremium,
    GST: GST,
    FinalPremium: FinalPremium,
    Year: end - start === 0 ? "1 Year" : end - start + " Years",
    MinIdv,
    MaxIdv,
    idv: idv,
    insurer: insurer,

    discount: discount,
    StartDate: StartDate,
    EndDate: EndDate,
    policyType: policyType,
    RoadSideAssistance: RoadSideAssistance,
    TyreProtection: TyreProtection,
    RimProtection: RimProtection,
    Consumables: Consumables,
    EngineGearBox: EngineGearBox,
    EngineProtection: EngineProtection,
    IsElectricalAccessories: IsElectricalAccessories,
    IsNonElectricalAccessories: IsNonElectricalAccessories,
    InvoiceCover: InvoiceCover,
    VoluntaryDeductive: VoluntaryDeductive,
    NCBProtection: NCBProtection,
    PassengerCover: PassengerCover,
    LossOfPersonalBelongings: LossOfPersonalBelongings,
    ZeroDepriciationCover: ZeroDepriciationCover,
    KeyReplacement: KeyReplacement,
    PaCoverAmount: PaCoverAmount,
    PassengerCoverAmount: PassengerCoverAmount,
    ElectricAmount: ElectricAmount,
    nonElectricalAmount: nonElectricalAmount,
    VoluntaryAmount: VoluntaryAmount,
    NCBDiscount: NCBDiscount,
    CNGValue: CNGValue,
    DriverLiability: DriverLiability,
    planId: planId,
    insurerId: insurerId,
    caseId: caseId,
  };
};

// export const newPolicyResultArr = (
//   Logo,
//   Api_name,
//   PolicyId,
//   ApiId,
//   RegistrationNumber,
//   Make,
//   Model,
//   variant,
//   BasicODPremium,
//   BasicTPPremium,
//   PAForOwner,
//   NetPremium,
//   GST,
//   FinalPremium,
//   MinIdv,
//   MaxIdv,
//   idv,
//   StartDate,
//   EndDate,
//   discount,
//   insurer,
//   insurerId,
//   policyType,
//   RoadSideAssistance,
//   EngineProtection,
//   TyreProtection,
//   RimProtection,
//   Consumables,
//   IsElectricalAccessories,
//   IsNonElectricalAccessories,
//   InvoiceCover,
//   EngineGearBox,
//   NCBProtection,
//   VoluntaryDeductive,
//   PassengerCover,
//   LossOfPersonalBelongings,
//   ZeroDepriciationCover,
//   KeyReplacement,
//   PaCoverAmount,
//   PassengerCoverAmount,
//   ElectricAmount,
//   nonElectricalAmount,
//   VoluntaryAmount,
//   NCBDiscount,
//   CNGValue,
//   DriverLiability,
//   planId,
//   caseId,
//   kycAdditionalData
// ) => {
//   let start = moment(StartDate).format("yyyy");
//   let end = moment(EndDate).format("yyyy");
//   return {
//     Logo: Logo,
//     Api_name: Api_name,
//     ApiId: ApiId,
//     PolicyId: PolicyId,
//     RegistrationNumber: RegistrationNumber,
//     Make: Make,
//     Model: Model,
//     Variant: variant,
//     BasicODPremium: BasicODPremium,
//     BasicTPPremium: BasicTPPremium,
//     PersonalAccident: PAForOwner,
//     NetPremium: NetPremium,
//     GST: GST,
//     FinalPremium: FinalPremium,
//     // Year: end - start === 0 ? "1 Year" : end - start + " Years",
//     MinIdv,
//     MaxIdv,
//     idv: idv,
//     insurer: insurer,
//     discount: discount,
//     StartDate: StartDate,
//     EndDate: EndDate,
//     policyType: policyType,
//     RoadSideAssistance: RoadSideAssistance,
//     TyreProtection: TyreProtection,
//     RimProtection: RimProtection,
//     Consumables: Consumables,
//     EngineGearBox: EngineGearBox,
//     EngineProtection: EngineProtection,
//     IsElectricalAccessories: IsElectricalAccessories,
//     IsNonElectricalAccessories: IsNonElectricalAccessories,
//     InvoiceCover: InvoiceCover,
//     VoluntaryDeductive: VoluntaryDeductive,
//     NCBProtection: NCBProtection,
//     PassengerCover: PassengerCover,
//     LossOfPersonalBelongings: LossOfPersonalBelongings,
//     ZeroDepriciationCover: ZeroDepriciationCover,
//     KeyReplacement: KeyReplacement,
//     PaCoverAmount: PaCoverAmount,
//     PassengerCoverAmount: PassengerCoverAmount,
//     ElectricAmount: ElectricAmount,
//     nonElectricalAmount: nonElectricalAmount,
//     VoluntaryAmount: VoluntaryAmount,
//     NCBDiscount: NCBDiscount,
//     CNGValue: CNGValue,
//     DriverLiability: DriverLiability,
//     planId: planId,
//     insurerId: insurerId,
//     caseId: caseId,
//     kycAdditionalData: kycAdditionalData
//   };
// };

export const failureCaseArr = (Api_name, logo, displayMessage, insurerId) => {
  return {
    Api_name: Api_name,
    logo: logo,
    displayMessage: displayMessage,
    insurerId: insurerId,
  };
};


export const newPolicyResultArr = (
  Logo,
  Api_name,
  PolicyId,
  ApiId,
  RegisterNumber,
  Make,
  Model,
  variant,
  BasicODPremium,
  BasicTPPremium,
  NetPremium,
  GST,
  FinalPremium,
  MinIdv,
  MaxIdv,
  idv,
  StartDate,
  EndDate,
  discount,
  insurer,
  policyType,
  RoadSideAssistance,
  RoadSideAssistanceAmount,
  EngineProtection,
  EngineProtectionAmount,
  TyreProtection,
  TyreProtectionAmount,
  RimProtection,
  RimProtectionAmount,
  Consumables,
  ConsumablesAmount,
  IsElectricalAccessories,
  ElectricAmount,
  IsNonElectricalAccessories,
  nonElectricalAmount,
  InvoiceCover,
  InvoiceCoverAmount,
  NCBProtection,
  NCBProtectionAmount,
  VoluntaryDeductive,
  VoluntaryAmount,
  PassengerCover,
  PassengerCoverAmount,
  PAForOwner,
  PaCoverAmount,
  LossOfPersonalBelongings,
  LossOfPersonalBelongingsAmount,
  ZeroDepriciationCover,
  ZeroDepriciationCoverAmount,
  KeyReplacement,
  KeyReplacementAmount,
  NCBDiscount,
  BiFuelOd,
  BiFuelOdAmount,
  BiFuelTp,
  BiFuelTpAmount,
  liabilitiesToPaidDriver,
  liabilitiesToPaidDriverAmount,
  caseId,
  insurerId,
  kycAdditionalData,
  planId,
  tppdDiscount,
  loadingPremium,
  isAddonQuesAsk,
  addonQuesArray,
  isAdharRequiredInProposal,
  isPanRequiredInProposal
  // SompoDiscountRate,
  // SompoLoadingRate
) => {
  let start = moment(StartDate).format("yyyy");
  let end = moment(EndDate).format("yyyy");
  // debugger;
  return {
    Logo: Logo,
    Api_name: Api_name,
    PolicyId: PolicyId,
    ApiId: ApiId,
    RegisterNumber: RegisterNumber,
    Make: Make,
    Model: Model,
    variant: variant,
    BasicODPremium: BasicODPremium,
    BasicTPPremium: BasicTPPremium,
    NetPremium: NetPremium,
    GST: GST,
    FinalPremium: FinalPremium,
    MinIdv: MinIdv,
    MaxIdv: MaxIdv,
    idv: idv,
    StartDate: StartDate,
    EndDate: EndDate,
    discount: discount,
    insurer: insurer,
    policyType: policyType,
    RoadSideAssistance: RoadSideAssistance,
    RoadSideAssistanceAmount: RoadSideAssistanceAmount,
    EngineProtection: EngineProtection,
    EngineProtectionAmount: EngineProtectionAmount,
    TyreProtection: TyreProtection,
    TyreProtectionAmount: TyreProtectionAmount,
    RimProtection: RimProtection,
    RimProtectionAmount: RimProtectionAmount,
    Consumables: Consumables,
    ConsumablesAmount: ConsumablesAmount,
    IsElectricalAccessories: IsElectricalAccessories,
    ElectricAmount: ElectricAmount,
    IsNonElectricalAccessories: IsNonElectricalAccessories,
    nonElectricalAmount: nonElectricalAmount,
    InvoiceCover: InvoiceCover,
    InvoiceCoverAmount: InvoiceCoverAmount,
    NCBProtection: NCBProtection,
    NCBProtectionAmount: NCBProtectionAmount,
    VoluntaryDeductive: VoluntaryDeductive,
    VoluntaryAmount: VoluntaryAmount,
    PassengerCover: PassengerCover,
    PassengerCoverAmount: PassengerCoverAmount,
    PersonalAccident: PAForOwner,
    PaCoverAmount: PaCoverAmount,
    LossOfPersonalBelongings: LossOfPersonalBelongings,
    LossOfPersonalBelongingsAmount: LossOfPersonalBelongingsAmount,
    ZeroDepriciationCover: ZeroDepriciationCover,
    ZeroDepriciationCoverAmount: ZeroDepriciationCoverAmount,
    KeyReplacement: KeyReplacement,
    KeyReplacementAmount: KeyReplacementAmount,
    NCBDiscount: NCBDiscount,
    BiFuelOd: BiFuelOd,
    BiFuelOdAmount: BiFuelOdAmount,
    BiFuelTp: BiFuelTp,
    BiFuelTpAmount: BiFuelTpAmount,
    liabilitiesToPaidDriver: liabilitiesToPaidDriver,
    liabilitiesToPaidDriverAmount: liabilitiesToPaidDriverAmount,
    caseId: caseId,
    insurerId: insurerId,
    kycAdditionalData: kycAdditionalData,
    planId: planId,
    tppdDiscount: tppdDiscount,
    loadingPremium: loadingPremium,
    isAddonQuesAsk: isAddonQuesAsk,
    addonQuesArray: addonQuesArray,
    isPanRequiredInProposal: isPanRequiredInProposal,
    isAdharRequiredInProposal: isAdharRequiredInProposal,
  };
};