import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import { Link, useNavigate } from 'react-router-dom'
import Footer from './Footer'
import {
  dispatchMotorQuote,
  dispatchQuickQuote,
  dispatchResetQuickQuote,
  resetKycConfig,
  resetQuickQuoteResults
} from '../store/actions/userAction'
import { useDispatch } from 'react-redux'
import { Modal, TabPane } from 'reactstrap'
function Home () {
  const navigate = useNavigate()
  const [vehicleType, setVehicleType] = useState('')
  const dispatch = useDispatch()
  const handleVehicleType = type => {
    dispatchResetQuickQuote()
    dispatch(resetQuickQuoteResults())
    dispatchQuickQuote('VehicleType', type)
    let vType = {
      vehicleType: '',
      vehicleModel: ''
    }
    if (type === '4w') {
      vType.vehicleType = type
      vType.vehicleModel = 'Pvt Car'
    } else if (type === '2w') {
      vType.vehicleType = type
      vType.vehicleModel = 'Two Wheeler'
    } else if (type === 'gcv') {
      vType.vehicleType = type
      vType.vehicleModel = 'GCV'
    } else if (type === 'gcv') {
      vType.vehicleType = type
      vType.vehicleModel = 'Taxi'
    }
    navigate('/motor-process', { state: vType })
  }
  const [isMoreOn, setIsMoreOn] = useState(false)
  const toggle = () => setIsMoreOn(!isMoreOn)

  useEffect(()=> {
    dispatchMotorQuote('kycData', {})
    dispatchMotorQuote('kycStatus', '')
    dispatchMotorQuote('kycType', '')
    dispatchMotorQuote('dob', '')
    dispatchMotorQuote('gender', '')
    dispatchMotorQuote('docName', '')
    dispatchMotorQuote('docNumber', '')
    dispatch(resetKycConfig())
  },[])
  return (
    <>
      <Navbar />
      <div className='main_hero_section'>
        <div className='container'>
          <div className='hero-info'>
            <div className='hero-left-sec'>
              <div className='hero_img'>
                <img src='/assets/Group.png' alt='' />
              </div>
            </div>
            <div className='hero-right-sec'>
              <div className='hero_heading'>
                <h1>
                  <span>Yours Future</span> is Bright. <br />
                  Lets
                  <span> protect it.</span>
                </h1>
              </div>
              <div className='car-ins'>
                <div
                  className='car-info'
                  style={{
                    textDecoration: 'none',
                    color: '#000',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleVehicleType('4w')}
                >
                  <div className='car-img'>
                    <img src='./assets/img/car.png' alt='' />
                  </div>
                  <div className='icn-info'>
                    <h2>Car</h2>
                  </div>
                </div>
                <div
                  className='car-info'
                  style={{
                    textDecoration: 'none',
                    color: '#000',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleVehicleType('2w')}
                >
                  <div className='car-img'>
                    <img src='./assets/img/bike.png' alt='' />
                  </div>
                  <div className='icn-info'>
                    <h2>Bike</h2>
                  </div>
                </div>
                <div
                  className='car-info'
                  style={{
                    textDecoration: 'none',
                    color: '#000',
                    cursor: 'pointer',
                    paddingBottom: '25px'
                  }}
                  // onClick={() => handleVehicleType('taxi')}
                >
                  <div className='car-img'>
                    <img src='./assets/img/taxi.png' alt='' />
                  </div>
                  <div className='icn-info'>
                    <h2>Taxi</h2>
                  </div>
                  <div className='coming-soon'>
                    <span>Coming Soon</span>
                  </div>
                </div>
                {/* <div > */}
                <div className='car-info' style={{ paddingBottom: '20px' }}>
                  <div className='car-img'>
                    <img src='./assets/img/delivery-truck (1).png' alt='' />
                  </div>
                  <div className='icn-info'>
                    <h2>GCV</h2>
                  </div>
                  <div className='coming-soon'>
                    <span>Coming Soon</span>
                  </div>
                </div>
                <div
                  className='car-info'
                  // onClick={() => navigate('/health')}
                  style={{
                    textDecoration: 'none',
                    color: '#000',
                    cursor: 'pointer',
                    paddingTop: '20px'
                  }}
                >
                  <div className='car-img'>
                    <img src='./assets/img/health-icon.png' alt='' />
                  </div>
                  <div className='icn-info'>
                    <h2>Health</h2>
                  </div>
                  <div className='coming-soon'>
                    <span>Coming Soon</span>
                  </div>
                </div>
                {/* </div> */}
                <div
                  className='car-info'
                  // onClick={() => navigate('/health')}
                  style={{
                    textDecoration: 'none',
                    color: '#000',
                    cursor: 'pointer',
                    paddingTop: '20px'
                  }}
                >
                  <div className='car-img'>
                    <img src='./assets/img/plane.png' alt='' 
                    style={{width:"30px"}}
                    />
                  </div>
                  <div className='icn-info'>
                    <h2>Travel</h2>
                  </div>
                  <div className='coming-soon'>
                    <span>Coming Soon</span>
                  </div>
                </div>
                <div
                  className='car-info'
                  // onClick={() => navigate('/health')}
                  style={{
                    textDecoration: 'none',
                    color: '#000',
                    cursor: 'pointer',
                    padding: '20px 0px 0px'
                  }}
                >
                  <div className='car-img'>
                    <img src='./assets/img/no-car.png' alt=''
                     style={{width:"30px"}}
                    />
                  </div>
                  <div className='icn-info'>
                    <h2 style={{fontSize:"13px"}}>Non Motor</h2>
                  </div>
                  <div className='coming-soon'>
                    <span>Coming Soon</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* WHY RETRO */}
      <div className='why-retro'>
        <div className='why-retro-section-top'>
          <div className='why-retro-text'>
            <h1>
              <b>Why R E T R O Insurance</b>
            </h1>
            <p>
              At R E T R O Insurance, we offer more than just financial
              protection. We provide a promise to simplify the complexities of
              insurance terms and conditions, ensuring you have a clear
              understanding of your coverage. With us, you're not just buying a
              policy, you're gaining peace of mind and clarity in your insurance
              decisions.
            </p>
          </div>
          <div className='why-retro-card'>
            <div
              className='why-retro-card-1'
              //  style={{ height: "200px" }}
            >
              <div className='why-retro-card-img-1'>
                <img src='/assets/img/confetti (1) 1.png' alt='' />
              </div>
              <h1>Wide Customer Satisfaction</h1>
              {/* <p>
                Discover the satisfaction of countless customers. Our commitment
                to excellence provides simplified insurance solutions, ensuring
                your peace of mind and financial security.
              </p> */}
            </div>
            <div className='why-retro-card-2'>
              <div className='why-retro-card-img-2'>
                <img src='./assets/img/medical 1.png' alt='' />
              </div>
              <h1>Diverse Insurer Options</h1>
              {/* <p>
                Explore a network of 10+ insurers at R E T R O Insurance,
                offering you the potential for bundled policies and exclusive
                discounts. Benefit from comprehensive coverage by choosing from
                multiple insurers, tailored to meet your specific needs.
              </p> */}
            </div>
          </div>
        </div>
        <div className='why-retro-section-bottom'>
          <div className='why-retro-section-bottom-img'>
            <img src='./assets/img/card-img.png' alt='' />
          </div>
          <div className='why-retro-section-bottom-card'>
            <div
              className='why-retro-section-bottom-card-1'
              style={{ height: '180px' }}
            >
              <div className='why-retro-section-bottom-img-1'>
                <img src='./assets/img/tag (1) 1.png' alt='' />
              </div>
              <h1>Competitive Pricing</h1>
              {/* <p>
                A tradition that dates back to our inception. Our commitment to
                value, popularized over the years, ensures that you get the best
                coverage without compromising your budget. Join us and enjoy
                cost-effective insurance solutions.
              </p> */}
            </div>
            <div
              className='why-retro-section-bottom-card-2'
              style={{ height: '180px' }}
            >
              <div className='why-retro-section-bottom-img-2'>
                <img src='./assets/img/claim 2.png' alt='' />
              </div>
              <h1>Claims</h1>
              {/* <p>
                It was popularised in the 1960s with the release of Letraset
                sheets containing Lorem Ipsum passages
              </p> */}
            </div>
          </div>
        </div>
      </div>
      {/* Insurance Section */}
      <div className='insurance-section'>
        <div className='insurance'>
          <div className='insurance-card'>
            <div className='insurance-card-img'>
              <img src='./assets/img/Health-img.png' alt='' />
            </div>
            <h1>Health Insurance</h1>
            <p>
              Tailored health insurance plans prioritize your well-being for a
              secure and healthy lifestyle.
            </p>
          </div>
          <div className='insurance-card'>
            <div className='insurance-card-img'>
              <img src='./assets/img/Car-insurance-img.png' alt='' />
            </div>
            <h1>Car Insurance</h1>
            <p>
              Tailored car protection extends coverage beyond repairs, offering
              added benefits for a secure and enjoyable driving experience.
            </p>
          </div>
          <div className='insurance-card'>
            <div className='insurance-card-img'>
              <img src='./assets/img/gcv-insurance-img.png' alt='' />
            </div>
            <h1>GCV Insurance</h1>
            <p>
              Tailored for goods-carrying vehicles, providing protection against
              accidents, theft, and damage to transported goods.
            </p>
          </div>
          <div className='insurance-card'>
            <div className='insurance-card-img'>
              <img src='./assets/img/Texi-insurance-img.png' alt='' />
            </div>
            <h1>Taxi Insurance</h1>
            <p>
              Tailored taxi insurance ensures a protected and smooth driving
              experience.
            </p>
          </div>
        </div>
      </div>
      {/* Advantages */}
      <div className='advantages'>
        <div className='advantages-heading'>
          <div className='advantages-text'>
            <h1>
              <span>R E T R O Insurance </span> <br />
              Advantages
            </h1>
            <p>
              We're dedicated to making insurance simple, providing clear
              coverage that brings peace of mind, not just financial security.
              We want you to understand your policy easily and feel confident
              about your protection. Our goal is to simplify complex insurance
              terms, so you can focus on what matters most without any confusion
              or stress. With straightforward coverage tailored to your needs,
              you can rest assured knowing you're taken care of, no matter what
              life throws your way.
            </p>
          </div>
          <div className='advantages-card'>
            <div className='advantages-card-1'>
              <div className='advantages-img'>
                <img src='./assets/img/Prices-img.png' alt='' />
              </div>
              <h1>One of the Best Prices</h1>
              <p>Guaranteed</p>
              <img src='./assets/img/arro-2.png' alt='' />
            </div>
            <div className='advantages-card-2'>
              <div className='advantages-img-2'>
                <img src='./assets/img/Advice.png' alt='' />
              </div>
              <h1>Unbiased Advice</h1>
              <p>Keeping customers first</p>
              <img src='./assets/img/arro-2.png' alt='' />
            </div>
          </div>
        </div>
      </div>
      <div className='advantages-bottom'>
        <div className='advantages-bottom-group'>
          <div className='group-img'>
            <img src='./assets/img/Main-img-2.png' alt='' />
          </div>
          <div className='advantages-card'>
            <div className='advantages-card-1'>
              <div className='advantages-img'>
                <img src='./assets/img/Reliable-img.png' alt='' />
              </div>
              <h1>100% Reliable</h1>
              <p>Regulated by IRDAI</p>
              <img src='./assets/img/arro-2.png' alt='' />
            </div>
            <div className='advantages-card-2 '>
              <div className='advantages-img-2'>
                <img src='./assets/img/Support.png' alt='' />
              </div>
              <h1>Claims Support</h1>
              <p>Made stress-free</p>
              <img src='./assets/img/arro-2.png' alt='' />
            </div>
            <div className='advantages-card-1'>
              <div className='advantages-img'>
                <img src='./assets/img/Help-img.png' alt='' />
              </div>
              <h1>Happy to Help</h1>
              <p>Every day of the week</p>
              <img src='./assets/img/arro-2.png' alt='' />
            </div>
          </div>
        </div>
      </div>
      {/* Partners */}
      <div className='partners-section'>
      <div className='partners'>
        <h1>
          Our <b>Partners</b>
        </h1>
        <div className='partners-logo-div'>
          <div className='partners-logo'>
            <img src='./assets/img/real-img.jpg' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/img/co-img.png' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/img/Sbi.png' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/img/kotak.png' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/img/digit.png' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/img/zuno_general_insurance.jpg' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/img/royal-img.jpg' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/img/national-img.png' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/img/Ms-img.png' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/img/magma-img.png' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/img/liberty-img.jpg' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/img/iffco-img.jpg' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/retro/care.png' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/retro/general.jpg' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/retro/hdfc.jpg' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/retro/rajega.jpg' alt='' />
          </div>
          <div className='partners-logo'>
            <img
              src='./assets/retro/tata.png'
              style={{ width: '100px' }}
              alt=''
            />
          </div>
          <div className='partners-logo'>
            <img src='./assets/retro/star.jpg' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/retro/Shriram_general.jpg' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/retro/oriental.png' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/retro/niwa.png' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/retro/new_india.png' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/retro/icici.jfif' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/img/sopmo.svg' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/retro/bajaj-img.jpg' alt='' />
          </div>
          <div className='partners-logo'>
            <img src='./assets/img/birla-img.png' alt='' />
          </div>
        </div>
      </div>
      </div>
      {/* Customer Review */}
      <div className='customer'>
        <div className='customer-heading'>
          <h1>
            Our Customers <br /> <b>love us</b>
          </h1>
          <p>
            Experience a unique insurance journey with us, where each policy
            reflects our commitment to quality and care, ensuring your
            satisfaction.
          </p>
        </div>
        <div className='customer-card'>
          <div className='customer-card-1'>
            <img src='./assets/img/quote-down-circle.png' alt='' />
            <p>
              Interacting with R E T R O Insurance's claims team was effortless;
              they handled every aspect diligently, ensuring my claim was
              approved within a month.
            </p>
            <h1>Ravinder Sehrawat</h1>
          </div>
          <div className='customer-card-1'>
            <img src='./assets/img/quote-down-circle.png' alt='' />
            <p>
              When I contacted R E T R O Insurance for assistance, their
              dedicated team efficiently managed the process, resulting in
              prompt approval.
            </p>
            <h1>Brijesh Agarwal</h1>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
export default Home
