import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from './Navbar'
import HowWork from './HowWork'
import Footer from './Footer'

function Plan() {
  return (
   <>
   <Navbar/>
   <div className="main-plans-page">
      <div className='plans-inf'>
      <div className="plans">
            <div className="self-plans">
                 <div className="self-plans-img">
                  <img src="./assets/img/age-img.png" alt=""/>
                 </div>
                 <h1>Self</h1>
            </div>
            <h1>find the right plan</h1>
            

      </div>
      <div className="find-text">
           <p>Do you have an existing illness or medical history?</p>
      </div>
      <div className="plans-details">
            <button><a href="#">Existing illness <img src="Group 47791.png" alt=""/></a></button>
            <button><a href="#">Surgical procedure <img src="Group 47791.png" alt=""/></a></button>
            <button><a href="#">None of these</a></button>
      </div>

     
    
<div className="submit-plans">
      <button><Link to='/existing'>Continue</Link></button>
</div> 
</div>
</div>
{/* <HowWork/> */}
<Footer/>

   </>
  )
}

export default Plan