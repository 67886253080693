import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import { useForm } from 'react-hook-form'
import ReactSelect from '../Tags/ReactSelect'
import { useSelector } from 'react-redux'
import {
  getFiancierBankData,
  getStates,
  getVehiclePincode,
  getVehiclePreviousInsurer,
  getVehicleRto
} from '../Services/masterServices'
import {
  dispatchMotorQuote,
  dispatchQuickQuote
} from '../../store/actions/userAction'
import {
  createCustomer,
  magmaProposalCall,
  submitProposal
} from '../Services/TPApiCall'
import moment from 'moment'
import { GetDataWithToken, PostDataWithToken } from '../../api/apiHelper'
// import { dispatchQuickQuote } from '../../store/actions/userAction'
// import { createCustomer } from '../Services/TPApiCall'
// import moment from 'moment'
// import { GetDataWithToken } from '../../api/apiHelper'
import { useNavigate } from 'react-router-dom'
import { sendSuccessInfo } from '../Services/PosService'
import FutureGeneralKYCRedirect from '../Additional/FutureGeneralKYCRedirect'
import { toast } from 'react-toastify'
import { createQuotePayloadObj } from '../../store/reducers/userReducers'
import KYC from './kyc'

const Proposal = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch
  } = useForm({ mode: 'onBlur' })
  const {
    handleSubmit: handleSubmit1,
    register: register1,
    formState: { errors: errors1 },
    reset: reset1
  } = useForm({ mode: 'onBlur' })
  const {
    handleSubmit: handleSubmit2,
    register: register2,
    watch: watch2,
    formState: { errors: errors2 },
    reset: reset2
  } = useForm({ mode: 'onBlur' })

  const [pincodeData, setPincodeData] = useState([])
  const [cityData, setCityData] = useState([])
  const [insurerData, setInsurerData] = useState([])
  const [rtoData, setRtoData] = useState([])
  const [docType, setDocType] = useState('')
  const [stateData, setStateData] = useState([])
  const [activeStep, setActiveStep] = useState(1)
  const [futureKYC, setFutureKyc] = useState(false)
  const [futureKYCData, setFutureKYCData] = useState({})
  const [magmaAadharKyc, setMagmaAadharKyc] = useState(false)
  const [RegistrationNumber, setRegistrationNumner] = useState('')
  const [rtoCode, setRtoCode] = useState('')
  const [relianceRto, setRelianceRto] = useState([])
  const [hypothecation, setHypothecation] = useState(false)
  const [puc, setPuc] = useState(false)
  const selectedPlan = useSelector(state => state?.root?.selectedPlan)
  const navigate = useNavigate()
  const [disabled, setDisabled] = useState('')
  const [financierData, setFinancierData] = useState([])
  // const [financierCity, setFinancierCity] = useState([])
  const [kycObj, setKycObj] = useState({})
  const [adhar, setAdhar] = useState('')
  const [voterID, setVoterID] = useState('')
  const [drivingLicense, setDrivingLicense] = useState('')
  const [spinner, setSpinner] = useState(false)
  const [hideBtn, setHideBtn] = useState(false)
  const engineNumber = watch2('EngineNumber')
  const chassisNumber = watch2('ChassisNumber')
  const [martial, setMartial] = useState('')
  const [nomineeRelation, setNomineeRelation] = useState('')
  const [relationData, setRelationData] = useState([])
  const [pan, setPan] = useState({
    number: '',
    image: '',
    error: ''
  })
  const [ckyc, setCkyc] = useState({
    number: '',
    error: ''
  })

  const apiRequestQQ = useSelector(state => state?.root?.apiRequestQQ)
  const motorRequest = useSelector(state => state?.root?.motorRequest)
  const handlePreviousStep = () => {
    // if(selectedPlan.Api_name === "Magma"){ setActiveStep(activeStep - 2)}
    // else{setActiveStep(activeStep - 1)}
    setActiveStep(activeStep - 1)
    setHideBtn(false)
  }

  useEffect(() => {
    GetDataWithToken('motor/getRelations').then(res => {
      if (res?.status) {
        setRelationData(res?.data)
      }
    })
  }, [])
  useEffect(() => {
    getVehiclePincode()
      .then(response => {
        if (response.status === true) {
          let i = 0
          let arr = []
          let arr1 = []
          let j = 0
          while (i < response.data.length) {
            arr.push({
              value: response.data[i].Pin_Code,
              label:
                response.data[i].Pin_Code + ' - ' + response.data[i].District,
              District: response.data[i].District,
              City: response.data[i].City,
              CityId: response?.data[i].cityId,
              StateId: response?.data[i]?.stateId
            })

            i++
          }
          while (j < response.data.length) {
            arr1.push({
              value: response.data[j].Pin_Code,
              label: response.data[j].District,
              District: response.data[j].District,
              City: response.data[j].City
            })

            j++
          }
          setPincodeData(arr)
          setCityData(arr1)
        }
      })
      .catch(err => console.log(err))

    getFiancierBankData()
      .then(response => {
        if (response.status === true) {
          let i = 0
          let arr = []
          while (i < response.data.length) {
            arr.push({
              value:
                response.data[i].financerId || response?.data[i]?.bank_name,
              label: response.data[i].financer || response?.data[i]?.bank_name
            })

            i++
          }
          setFinancierData(arr)
        }
      })
      .catch(err => console.log(err))

    getVehiclePreviousInsurer().then(response => {
      if (response.status === true) {
        let i = 0
        let data = response.data
        let arr = []
        while (i < data.length) {
          let item = data[i]
          arr.push({
            option: item.Name,
            value: item.Digit_Code
          })
          i++
        }
        setInsurerData(arr)
      }
    })

    getVehicleRto().then(response => {
      if (response.status === true) {
        let data = response.data
        let i = 0
        let arr = []
        while (i < data.length) {
          let item = data[i]
          arr.push({
            label: item.registered_city_name + ' (' + item.RTO_Code + ')',
            value: item.RTO_Code,
            registered_state_name: item.registered_state_name,
            id: item.id
          })
          i++
        }
        setRtoData(arr)
      }
    })
  }, [])

  useEffect(() => {
    setDisabled('')
    reset2({
      RegistrationNumber: apiRequestQQ.RegistrationNumber,
      PrePolicyNumber: apiRequestQQ.PrePolicyNumber,
      PrePolicyEndDate: apiRequestQQ.PrePolicyEndDate,
      PreviousInsuranceClaimed: apiRequestQQ.PreviousInsuranceClaimed,
      PreInsurerCode: apiRequestQQ.PreInsurerCode,
      PreviousInsurancePolicyNumber: apiRequestQQ.PreviousInsurancePolicyNumber
    })
    setRegistrationNumner(apiRequestQQ.RegistrationNumber)
    let rtoNumber = apiRequestQQ.RegistrationNumber.split('-')
    if (apiRequestQQ.IsVehicleNew) {
      setRtoCode(apiRequestQQ.RtoCode)
    } else {
      setRtoCode(rtoNumber[0] + rtoNumber[1])
    }
    let RTOCode = apiRequestQQ.RtoCode
      ? apiRequestQQ.RtoCode
      : rtoNumber[0] + rtoNumber[1]
  }, [])
  function myFunction () {
    var checkBox = document.getElementById('myCheck')
    var text = document.getElementById('text')
    if (checkBox.checked == true) {
      text.style.display = 'block'
    } else {
      text.style.display = 'none'
    }
  }

  const handleFinancerBank = val => {
    dispatchQuickQuote('HpnBank', val)
    dispatchMotorQuote('hpnBank', val)
  }

  const handleMartialStatus = val => {
    setMartial(val)
  }

  const setFinancierCity = val => {
    dispatchQuickQuote('HpnCity', val)
    dispatchMotorQuote('hpnCity', val)
  }

  const areValuesSame = () => {
    return engineNumber && chassisNumber && engineNumber === chassisNumber
  }

  //magma kyc

  const checkMagmaKycDetails = data => {
    setHideBtn(true)
    setSpinner(true)
    const DOB = new Date(data?.DateofBirth).toLocaleDateString('en-GB')
    setDisabled('disabled')
    let postData = {
      cKYCType: data?.docType,
      cKYCNumber: data?.PanNumber || data.ckycNumber,
      CustomerType: apiRequestQQ?.CustomerType || '',
      DOB: (data?.DateofBirth && DOB) || '',
      GSTNo: (data?.GSTNo && data?.GSTNo.toUpperCase()) || '',
      companyName: (data?.companyName && data?.companyName.toUpperCase()) || '',
      IncorporationDate: data?.incorporationYear || '',
      Gender:
        apiRequestQQ?.CustomerType === 'INDIVIDUAL'
          ? data?.Gender.toUpperCase()
          : '',
      FullName: data?.MiddleName
        ? data?.FirstName + ' ' + data?.MiddleName + ' ' + data?.LastName
        : data?.MiddleName
        ? data?.FirstName + ' ' + data?.LastName
        : '',
      BusinessSourceType: 'A_AGENT',
      ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber?.Magma,
      PolicyIssueDate: new Date().toLocaleDateString('en-GB').toString()
    }

    let aadharPostData = {
      DocumentName: 'AADHAAR',
      DocumentType: 'Image',
      DocumentBase64String: apiRequestQQ?.AdharImage,
      ApiUniqueNumber: apiRequestQQ?.ApiUniqueNumber?.Magma
    }
    {
      data?.docType === 'adhar' || apiRequestQQ.docType === 'adhar'
        ? PostDataWithToken('kyc/magma-aadhar-kyc', aadharPostData).then(
            response => {
              if (
                response?.status === true &&
                response?.data?.ServiceResult === 'Failure'
              ) {
                setMagmaAadharKyc(true)
                dispatchQuickQuote('docType', 'adhar')
                toast.error('KYC Failed')
                setSpinner(false)
                setHideBtn(false)
              } else if (
                response?.status === true &&
                response?.data?.ServiceResult === 'Success'
              ) {
                setSpinner(false)
                setHideBtn(false)
                setKycObj(response?.data?.OutputResult)
                setActiveStep(2)
                setMagmaAadharKyc(true)
                toast.success('KYC Successfull')
              } else if (response?.status === false) {
                dispatchQuickQuote('docType', 'adhar')
                toast.error('KYC Failed')
                setSpinner(false)
                setHideBtn(false)
              }
            }
          )
        : PostDataWithToken('kyc/magma-kyc', postData).then(response => {
            if (
              response?.status === true &&
              response?.data?.ServiceResult === 'Success'
            ) {
              setSpinner(false)
              setHideBtn(false)
              setKycObj(response?.data?.OutputResult)
              toast.success('KYC Successful')
              setMagmaAadharKyc(true)
              // setActiveStep(2)
              if (
                apiRequestQQ.AddOns.PersonalAccident === true &&
                apiRequestQQ?.CustomerType === 'INDIVIDUAL'
              ) {
                setActiveStep(2)
              } else if (apiRequestQQ?.CustomerType === 'ORGANISATION') {
                setActiveStep(3)
              } else {
                setActiveStep(3)
              }
            } else if (
              response?.status === true &&
              response?.data?.ServiceResult === 'Failure'
            ) {
              setSpinner(false)
              setHideBtn(false)
              if (
                response.data.ErrorText.includes(
                  'Error in GetKYCResponse : No record found'
                )
              ) {
                setMagmaAadharKyc(true)
                setDocType('adhar')
                dispatchQuickQuote('docType', 'adhar')
              }
              toast.error('KYC Failed')
            } else if (response?.status === false) {
              setSpinner(false)
              setHideBtn(false)
            }
          })
    }
  }

  const submitOwnerDetails = data => {
    let dataForMagmaKYC = {
      DocumentName: 'AADHAAR',
      DocumentType: 'Image',
      Document64String: apiRequestQQ?.AdharImage
    }

    for (let key in data) {
      dispatchQuickQuote(`${key}`, data[key])
    }

    window.scrollTo(0, scrollY)
    // if (selectedPlan?.Api_name !== 'Magma') {
    //   if (apiRequestQQ.PanImage == '') {
    //     return setPan({ ...pan, error: 'Pan Image is required' })
    //   }
    // }
    dispatchQuickQuote('Gender', data?.Gender)
    dispatchQuickQuote('Dob', data?.DateofBirth)
    dispatchQuickQuote('CompanyName', data?.companyName)
    dispatchQuickQuote('GSTNo', data?.GSTNo)
    dispatchQuickQuote('IncorporationDate', data?.incorporationYear)
    dispatchQuickQuote('Father', data?.Father)
    dispatchQuickQuote('Spouse', data?.Spouse)

    dispatchQuickQuote('adharNumber', data?.adharNumber)
    dispatchQuickQuote('voterIdNumber', data?.voterIdNumber)
    dispatchQuickQuote('drivingLicenseNumber', data?.drivingLicenseNumber)

    let postData = {
      name: apiRequestQQ?.MiddleName
        ? apiRequestQQ?.FirstName +
          ' ' +
          apiRequestQQ?.MiddleName +
          ' ' +
          apiRequestQQ?.LastName
        : apiRequestQQ?.FirstName + ' ' + apiRequestQQ?.LastName || '',
      email: apiRequestQQ?.Email || '',
      phone: apiRequestQQ?.MobileNumber || '',
      dob: data?.DateofBirth || '',
      companyName: (data?.companyName && data?.companyName.toUpperCase()) || '',
      IncorporationDate: data?.incorporationYear || '',
      city: data?.City || '',
      state: data?.State || '',
      address_line1: data?.StreetNumber || '',
      address_line2: data?.Street || '',
      customerId: apiRequestQQ.customerId || '',
      customer_type: apiRequestQQ.CustomerType || '',
      pan_card: (data.PanNumber && data.PanNumber.toUpperCase()) || '',
      ckyc: data.ckyc || '',
      doc_type: docType,
      doc_number: adhar || voterID || drivingLicense,
      GSTNo: (data.GSTNo && data.GSTNo.toUpperCase()) || '',
      pincode: apiRequestQQ.Pincode || ''
    }
    if (selectedPlan.Api_name === 'Magma') {
      checkMagmaKycDetails(data)
      createCustomer(postData)
      // if (
      //   apiRequestQQ.AddOns.PersonalAccident === true &&
      //   apiRequestQQ?.CustomerType === 'INDIVIDUAL'
      // ) {
      //   setActiveStep(3)
      // } else if (
      //   apiRequestQQ.AddOns.PersonalAccident === false &&
      //   apiRequestQQ?.CustomerType === 'ORGANISATION'
      // ) {
      //   setActiveStep(2)
      // } else {
      //   setActiveStep(3)
      // }
    }

    if (selectedPlan.Api_name === 'digit') {
      createCustomer(postData)
      setActiveStep(2)
    }

    if (selectedPlan.Api_name === 'Future') {
      createCustomer(postData)
      FutureGeneralUserKyc(
        data.PanNumber ? data.PanNumber : apiRequestQQ.PanNumber,
        data.DateofBirth,
        postData.name,
        data.Gender,
        apiRequestQQ.PolicyId,
        apiRequestQQ?.FirstName,
        apiRequestQQ?.LastName,
        data?.DateofBirth
      )
    }
  }

  const FutureGeneralUserKycStatus = proposalId => {
    PostDataWithToken('kyc/future-general-kycStatus', {
      proposal_id: proposalId
    }).then(response => {
      if (response.data) {
        if (
          response.data.Final_Status === '3' ||
          response.data.Final_Status === '1'
        ) {
          // sendSuccessInfo('Your Future KYC is successfully generated ')
          toast('Your Future KYC is successfully generated ', {
            type: 'success'
          })
          dispatchQuickQuote(
            'FutureCkycNumber',
            response.data?.result?.ckyc_number
          )
          dispatchQuickQuote(
            'FutureProposalNumber',
            response?.data?.proposal_id
          )
          // toggle(activeTab + 1)
          setActiveStep(2)
          setDisabled('')
        } else {
          setDisabled('')
          alert('KYC is not generated please try again ')
        }
      }
    })
  }

  const FutureGeneralUserKyc = (
    pannumber,
    dob,
    full_name,
    gender,
    policyId,
    FirstName,
    LastName,
    Dob
  ) => {
    setDisabled('disabled')
    let postData = {
      customer_type: apiRequestQQ.CustomerType === 'INDIVIDUAL' ? 'I' : 'C',
      PanNumber: pannumber,
      docType: docType,
      docNumber:
        docType === 'adhar'
          ? adhar
          : docType === 'voterId'
          ? voterID
          : docType === 'DL'
          ? drivingLicense
          : '',
      dob: Dob,
      full_name: full_name,
      gender: gender === 'MALE' ? 'M' : 'F',
      PolicyId: policyId,
      FirstName: FirstName,
      LastName: LastName,
      Dob: moment(Dob).format('DD-MM-YYYY')
    }

    PostDataWithToken('kyc/future-general-kyc', postData).then(response => {
      if (response.data?.success === true) {
        let { result, proposal_id, url, req_id } = response.data
        dispatchQuickQuote('ProposalId', proposal_id)
        if (result?.ckyc_number) {
          setDisabled('')
          toast(
            `Your Future KYC is successfully generated + ${result?.ckyc_number}`
          )
          dispatchQuickQuote('KYC.' + 'Future', result?.ckyc_number)
          setActiveStep(2)
        } else if (isValidHttpUrl(url) === true) {
          setDisabled('')
          setFutureKyc(true)
          setFutureKYCData({
            kycno: req_id,
            proposalNo: proposal_id,
            url: url
          })
        } else if (proposal_id) {
          PostDataWithToken('kyc/future-general-kycStatus', {
            kycProposalID: proposal_id
          }).then(response => {
            if (response.data?.Final_Status !== '0') {
              if (response.data?.Final_Status === '1' || '3') {
                toast('Your Future KYC is successfully generated', {
                  type: 'success'
                })
                dispatchQuickQuote(
                  'KYC.' + 'Future',
                  response.data?.kyc_data?.CKYC?.result?.PERSONAL_DETAILS
                    ?.CKYC_NO
                )
                setActiveStep(2)
                setDisabled('')
              } else {
                setDisabled('')
                alert('KYC is not generated please try again ')
              }
            }
          })
        }
      }
    })
  }

  useEffect(() => {
    if (apiRequestQQ.ProposalId) {
      FutureGeneralUserKycStatus(apiRequestQQ.ProposalId)
    }
  }, [])

  const handleUploadPanFile = async file => {
    const base64Img = await toBase64(file[0])
    setPan({ ...pan, image: base64Img, error: '' })
    dispatchQuickQuote('PanImage', base64Img.split(',')[1])
  }

  const handleUploadAdharFile = async file => {
    const base64Img = await toBase64(file[0])
    setAdhar({ ...adhar, image: base64Img, error: '' })
    dispatchQuickQuote('AdharImage', base64Img.split(',')[1])
  }

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

  const handleSelectPincodeOwner = val => {
    reset({ City: val.City })
    dispatchQuickQuote('Pincode', val.value)

    dispatchQuickQuote('District', val.District)
    dispatchQuickQuote('City', val.City)
    getStates(val.value)
      .then(response => {
        if (response.status === true) {
          let stats = response.data[0]
          setStateData(response.data[0])
          reset({ State: stats.STATEDESC })
          dispatchQuickQuote('State', stats.STATEDESC)
          dispatchQuickQuote('StateCode', stats.Digit_Code)
        }
      })
      .catch(err => console.log(err))
  }

  const handleChangePanValue = value => {
    setPan({ ...pan, number: value, error: '' })
    dispatchQuickQuote('PanNumber', value)
  }

  const handleNomineeDetails = data => {
    dispatchMotorQuote(
      'nomineeName',
      data?.NomineeFirstName + ' ' + data?.NomineeLastName
    )
    dispatchMotorQuote('nomineeDob', data?.NomineeDateOfBirth)
    dispatchMotorQuote('nomineeRelation', data?.NomineeRelationship)
    dispatchMotorQuote('nomineeRelationCode', nomineeRelation)
    for (let key in data) {
      dispatchQuickQuote(key, data[key])
    }
    let postData = {
      name: apiRequestQQ?.FirstName + ' ' + apiRequestQQ?.LastName,
      email: apiRequestQQ.Email,
      phone: apiRequestQQ.MobileNumber,
      dob: apiRequestQQ.Dob,
      city: apiRequestQQ.City,
      state: apiRequestQQ.State,
      address:
        apiRequestQQ.StreetNumber +
        ' ' +
        apiRequestQQ.Street +
        ' ' +
        apiRequestQQ.Area,
      pincode: apiRequestQQ.Pincode,
      gender: apiRequestQQ.Gender,
      nominee_name: data.NomineeFirstName + ' ' + data.NomineeLastName,
      nominee_relation: data.NomineeRelationship,
      nominee_age: moment().diff(data.NomineeDateOfBirth, 'years'),
      nominee_gender: data?.NomineeGender
    }

    if (apiRequestQQ.customerId) {
      postData.customerId = apiRequestQQ.customerId
    }
    createCustomer(postData)

    setActiveStep(3)
  }

  const handleChangeCkycValue = value => {
    setCkyc({ ...ckyc, number: value, error: '' })
  }

  const setRegistrationValue = val => {
    let number = ''
    let stateCode = val.substring(3, 5)
    let rtoCode = val.substring(6, 8)
    let plateNumber = val.substring(9, 13)
    if (val.length <= 2) {
      if (val.match(/^[A-Za-z]+$/)) {
        number = val
        if (val.length === 2) {
          number = number + '-'
        }
      } else {
        number = ''
      }
    } else if (val.length > 3 && val.length <= 5) {
      if (stateCode.match(/^[0-9]+$/)) {
        if (val.length > 3 && val.length < 5) {
          number = RegistrationNumber + stateCode
        }
        if (val.length === 5) {
          number = RegistrationNumber + val.charAt(4) + '-'
        }
      } else {
        stateCode = ''
        number = RegistrationNumber + stateCode
      }
    }
    if (val.length > 6 && val.length <= 8) {
      if (rtoCode.match(/^[A-Za-z]+$/)) {
        number = val
        if (val.length === 8) {
          number = number + '-'
        }
      } else {
        number = ''
      }
    } else if (val.length > 9 && val.length <= 14) {
      if (plateNumber.match(/^[0-9]+$/)) {
        number = val
      }
    }
    setRegistrationNumner(number)
  }

  const handleEngineNumber = val => {
    dispatchQuickQuote('EngineNumber', val)
  }

  const handleChassisNumber = val => {
    dispatchQuickQuote('ChassisNumber', val)
  }

  const handlePrePolicyNumber = val => {
    dispatchQuickQuote('PrePolicyNumber', val)
  }
  const handlePreTPPolicyNumber = val => {
    dispatchQuickQuote('PreTPPolicyNumber', val)
  }
  const handlePreTPPolicyEndDate = val => {
    dispatchQuickQuote('PreTPPolicyEndDate', val)
  }
  const handlePreTPPolicyInsurer = val => {
    console.log('prevTpInsurerCode:- ', val)
    dispatchQuickQuote('PreTPInsurerCode', val)
    dispatchMotorQuote('prevTPInsurerCode', val)
  }

  // useEffect(()=>{
  //   dispatchMotorQuote('prevTPInsurerCode', '')
  //   dispatchMotorQuote('prevTPPolicyNumber', '')
  //   dispatchMotorQuote('prevTPPolicyEndDate', '')
  // },[])
  const submitVehicleDetails = data => {
    console.log('data?.PreTPInsurerCode:- ', data?.PreTPInsurerCode)
    setSpinner(true)
    setHideBtn(true)

    dispatchMotorQuote('prevTPPolicyNumber', data?.PreTPPolicyNumber)
    dispatchMotorQuote('prevTPPolicyEndDate', data?.PreTPPolicyEndDate)

    dispatchMotorQuote('engineNumber', data?.EngineNumber)
    dispatchMotorQuote('chassisNumber', data?.ChassisNumber)
    dispatchMotorQuote('prevPolicyNumber', data?.PrePolicyNumber)

    dispatchMotorQuote('pucNumber', data?.PUCNumber)
    dispatchMotorQuote('pucValidUpto', data?.PUCValidUpto)

    if (data.EngineNumber === data.ChassisNumber) {
      return
    }

    dispatchQuickQuote('RegistrationNumber', RegistrationNumber)
    for (let key in data) {
      dispatchQuickQuote(key, data[key])
    }
    const newData = { ...apiRequestQQ, ...data }
    const newData2 = {
      ...motorRequest,
      chassisNumber: data?.ChassisNumber,
      engineNumber: data?.EngineNumber,
      prevPolicyNumber: data?.PrePolicyNumber,
      prevTPInsurerCode: data?.PreTPInsurerCode,
      prevTPPolicyNumber: data?.PreTPPolicyNumber,
      prevTPPolicyEndDate: data?.PreTPPolicyEndDate,
      pucNumber: data?.PUCNumber,
      pucValidUpto: data?.PUCValidUpto
    }

    setDisabled('disabled')
    if (selectedPlan.Api_name === 'Shriram') {
      const { FatherName, MotherName, AadharNo, PassportPic, AadharBackPic } =
        apiRequestQQ.KYC

      if (
        FatherName == '' ||
        MotherName == '' ||
        AadharNo == '' ||
        PassportPic == '' ||
        AadharBackPic == ''
      ) {
        setDisabled('')
        setModal(true)
      } else {
        setDisabled('disabled')
        generateShriramPolicy(newData)
      }

      ZunoFullQuote(newData).then(response => {
        setDisabled('')

        if (response.status === true) {
          const contractDetails = response.data.contractDetails
          alert(
            parseFloat(
              contractDetails[0].contractPremium.contractPremiumAfterTax
            ) +
              parseFloat(
                contractDetails[1].contractPremium.contractPremiumAfterTax
              ) || 0
          )
          navigate('/checkout')
        }
      })
    } else if (selectedPlan.Api_name == 'Magma') {
      const magmaData = {
        ...newData,
        KYCID: kycObj?.KYCLogID,
        KYCNumber: kycObj?.KYCNumber,
        KYCType: kycObj?.KYCType
      }
      magmaProposalCall(magmaData).then(response => {
        if (response?.data?.ServiceResult === 'Success') {
          dispatchQuickQuote(
            'ProposalId',
            response?.data?.OutputResult?.ProposalNumber
          )
          dispatchQuickQuote(
            'CustomerID',
            response?.data?.OutputResult?.CustomerID
          )
          dispatchQuickQuote(
            'FinalPremium',
            response?.data?.OutputResult?.PremiumBreakUp?.TotalPremium
          )
          navigate('/policyoverview')
          setSpinner(false)
          setHideBtn(false)
        } else {
          setDisabled('')
          setSpinner(false)
          setHideBtn(false)
        }
      })
    } else if (selectedPlan.Api_name === 'Future') {
      PostDataWithToken(
        'motor/futureGenralCreatePolicy',
        createQuotePayloadObj(newData)
      ).then(res => {
        let response =
          res?.data?.CreateProposalResponse?.CreateProposalResult?.Root?.Client
        if (response?.Status && response?.Status['_text'] === 'Successful') {
          navigate('/policyoverview')
        } else {
          toast(
            res?.data?.CreateProposalResponse?.CreateProposalResult?.Root
              ?.ErrorMessage['_text'],
            { type: 'error' }
          )
          setSpinner(false)
          setHideBtn(false)
        }
      })
    } else if (
      selectedPlan.Api_name === 'HDFC' ||
      selectedPlan?.Api_name === 'Bajaj Allianz General Insurance Co. Ltd.'
    ) {
      console.log('newData2:- ', newData2)
      submitProposal(newData2, selectedPlan).then(res => {
        if (res?.data?.caseStatus === 'Proposal Successful') {
          navigate('/policyoverview')
        } else {
          toast('Something Went Wrong', { type: 'error' })
          setSpinner(false)
          setHideBtn(false)
        }
      })
    } else {
      navigate('/policyoverview')
    }
  }

  const handleHypothecation = val => {
    console.log('HPN', val)
    dispatchQuickQuote('IsHypothecation', val)
  }

  return (
    <>
      <div>
        <Navbar />
        <div className='main-proposal-page'>
          <div className='container'>
            <div className='select_policy_heading'>
              <div className='select-model'>
                <div className='det-info-1'>
                  {activeStep === 1 ? (
                    <h1>Owner Details</h1>
                  ) : activeStep === 2 ? (
                    <h1>Nominee Details</h1>
                  ) : (
                    <h1>Vehicle Details</h1>
                  )}
                </div>
              </div>
            </div>
            <div className='proposal-info'>
              <div className='row policy_main_details'>
                <div className='col-md-3 plan-sum'>
                  <div className='left_section'>
                    <div className='invoice_sec'>
                      <div className='row'>
                        <div className='col-12'>
                          <h2 className='sec_heading'>Plan Summary</h2>
                        </div>
                      </div>
                      <div className='invoice_list'>
                        <div className='row'>
                          <div className='col-12 pe-0'>
                            <p className='nat-img'>
                              <img src={selectedPlan.Logo} alt='' />
                            </p>
                          </div>
                        </div>
                        <div className='row plan_sum'>
                          <div className='col-7 pe-0'>
                            <p className=''>IDV</p>
                          </div>
                          <div className='col-5 plan_sum_1'>
                            <span className=''>{selectedPlan.idv}</span>
                          </div>
                        </div>
                        <div className='row plan_sum'>
                          <div className='col-7 pe-0'>
                            <p className=''>Plan Type</p>
                          </div>
                          <div className='col-5 plan_sum_1'>
                            <span className=''>{selectedPlan.policyType}</span>
                          </div>
                        </div>
                        <div className='row plan_sum'>
                          <div className='col-7 pe-0'>
                            <p className=''>Premium</p>
                          </div>
                          <div className='col-5 plan_sum_1'>
                            <span className=''>
                              INR{' '}
                              {Math.round(
                                selectedPlan.FinalPremium.toString().replace(
                                  'INR',
                                  ''
                                )
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {activeStep === 1 && (
                  <div className='col-lg-7'>
                    <div className='right_section'>
                      {futureKYC ? (
                        <FutureGeneralKYCRedirect
                          kycno={futureKYCData?.proposalNo}
                          proposalNo={futureKYCData?.proposalNo}
                          url={futureKYCData?.url}
                        />
                      ) : selectedPlan?.Api_name === 'HDFC' ||
                        selectedPlan?.Api_name ===
                          'Bajaj Allianz General Insurance Co. Ltd.' ||
                        selectedPlan?.Api_name ===
                          'Go Digit General Insurance Limited' ||
                        selectedPlan?.Api_name ===
                          'Future Generali India Insurance Company Limited' ? (
                        <KYC
                          setActiveStep={setActiveStep}
                          setCurrentStep={setActiveStep}
                          // kycStatusCheck={kycStatusCheck}
                        />
                      ) : (
                        <form onSubmit={handleSubmit(submitOwnerDetails)}>
                          <div>
                            <div className='pro-form'>
                              {apiRequestQQ?.CustomerType === 'INDIVIDUAL' && (
                                <>
                                  <div className='Owner-error-div'>
                                    <div className='error-div-box'>
                                      <select
                                        id='country'
                                        name='country'
                                        className='owner-input-box'
                                        {...register('customerSaluation', {
                                          required: 'Field is required',
                                          pattern: {
                                            value: /^[A-Za-z\s]+$/,
                                            message: 'Numbers are not allowed'
                                          }
                                        })}
                                      >
                                        <option value=''>
                                          Select Salutation
                                        </option>
                                        <option value='Mr'>Mr.</option>
                                        <option value='Miss'>Ms</option>
                                        <option value='MRS'>MRS</option>
                                        <option value='M/S'>M/S</option>
                                        <option value='MISS'>MISS</option>
                                      </select>
                                      <span className='text-danger error-input'>
                                        {errors.customerSaluation &&
                                          errors.customerSaluation.message}
                                      </span>
                                    </div>

                                    <div className='error-div-box'>
                                      <div className='Motor-deatil-2'>
                                        <label>Enter First Name</label>
                                        <input
                                          type='text'
                                          placeholder='Enter First Name'
                                          className='owner-input-box'
                                          ma
                                          {...register('FirstName', {
                                            required: 'Field is required',
                                            pattern: {
                                              value: /^[A-Za-z\s]+$/,
                                              message: 'Numbers are not allowed'
                                            }
                                          })}
                                          defaultValue={apiRequestQQ.FirstName}
                                          maxLength={30}
                                          onText={e => {
                                            e.target.Text = e.target.Text.slice(
                                              0,
                                              10
                                            )
                                          }}
                                        />

                                        <span className='text-danger error-input'>
                                          {errors.FirstName &&
                                            errors.FirstName.message}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='Owner-error-div'>
                                    <div className='error-div-box'>
                                      <div className='Motor-deatil-2'>
                                        <label>Enter Middle Name</label>
                                        <input
                                          type='text'
                                          placeholder='Enter Middle Name'
                                          className='owner-input-box'
                                          ma
                                          {...register('MiddleName', {
                                            pattern: {
                                              value: /^[A-Za-z\s]+$/,
                                              message: 'Numbers are not allowed'
                                            }
                                          })}
                                          defaultValue={apiRequestQQ.MiddleName}
                                          maxLength={30}
                                          onText={e => {
                                            e.target.Text = e.target.Text.slice(
                                              0,
                                              10
                                            )
                                          }}
                                        />

                                        <span className='text-danger error-input'>
                                          {errors.MiddleName &&
                                            errors.MiddleName.message}
                                        </span>
                                      </div>
                                    </div>

                                    <div className='error-div-box'>
                                      <div className='Motor-deatil-2'>
                                        <label>Enter Last Name</label>
                                        <input
                                          type='text'
                                          placeholder='Enter Last Name'
                                          className='owner-input-box'
                                          {...register('LastName', {
                                            required: 'Field is required',
                                            pattern: {
                                              value: /^[A-Za-z\s]+$/,
                                              message: 'Numbers are not allowed'
                                            }
                                          })}
                                          defaultValue={apiRequestQQ.LastName}
                                          maxLength={40}
                                          onText={e => {
                                            e.target.Text = e.target.Text.slice(
                                              0,
                                              10
                                            )
                                          }}
                                        />

                                        <span className='text-danger error-input'>
                                          {errors.LastName &&
                                            errors.LastName.message}
                                        </span>
                                      </div>
                                    </div>

                                    <div className='error-div-box'>
                                      <select
                                        {...register('Gender', {
                                          required: 'Field is required'
                                        })}
                                        // value={apiRequestQQ.DateofBirth}
                                        className='owner-input-box'
                                      >
                                        <option value=''>Gender</option>
                                        <option value='MALE'>Male</option>
                                        <option value='FEMALE'>Female</option>
                                        <option value='Other'>Other</option>
                                      </select>
                                      <span className='text-danger error-input'>
                                        {errors.Gender && errors.Gender.message}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              )}

                              {apiRequestQQ?.CustomerType ===
                                'ORGANISATION' && (
                                <div className='Owner-error-div'>
                                  <div className='error-div-box'>
                                    <div className='Motor-deatil-2'>
                                      <label>Enter Company Name</label>
                                      <input
                                        type='text'
                                        placeholder='Enter Company Name'
                                        className='owner-input-box'
                                        {...register('companyName', {
                                          required: 'Field is required'
                                        })}
                                      />

                                      <span className='text-danger error-input'>
                                        {errors.companyName &&
                                          errors.companyName.message}
                                      </span>
                                    </div>
                                  </div>
                                  <div className='error-div-box'>
                                    <div className='Motor-deatil-2'>
                                      <label>Enter GST Number</label>
                                      <input
                                        type='text'
                                        placeholder='Enter GST Number'
                                        className='owner-input-box'
                                        {...register('GSTNo', {
                                          required: 'Field is required'
                                        })}
                                      />

                                      <span className='text-danger error-input'>
                                        {errors.GSTNo && errors.GSTNo.message}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className='Owner-error-div'>
                                {apiRequestQQ?.CustomerType ===
                                  'INDIVIDUAL' && (
                                  <div className='error-div-box'>
                                    <div className='Motor-deatil-2'>
                                      <label>Enter DOB</label>
                                      <input
                                        type='date'
                                        placeholder='Enter Date Of Birth'
                                        className='owner-input-box'
                                        {...register('DateofBirth', {
                                          required: 'Field is required'
                                        })}
                                      />
                                      <span className='text-danger error-input'>
                                        {errors.DateofBirth &&
                                          errors.DateofBirth.message}
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {apiRequestQQ?.CustomerType ===
                                  'ORGANISATION' && (
                                  <div className='error-div-box'>
                                    <div className='Motor-deatil-2'>
                                      <label>Incorporation Year</label>
                                      <input
                                        type='date'
                                        placeholder='Enter Incorporation Year'
                                        className='owner-input-box'
                                        {...register('incorporationYear', {
                                          required: 'Field is required'
                                        })}
                                      />
                                      <span className='text-danger error-input'>
                                        {errors.incorporationYear &&
                                          errors.incorporationYear.message}
                                      </span>
                                    </div>
                                  </div>
                                )}

                                <div className='error-div-box'>
                                  <input
                                    type='number'
                                    placeholder='Enter Mobile Number'
                                    className='owner-input-box'
                                    pattern='[0-9]{10}'
                                    maxLength={10}
                                    defaultValue={apiRequestQQ.MobileNumber}
                                    {...register('MobileNumber', {
                                      required: 'Mobile number is required',
                                      minLength: {
                                        value: 10,

                                        message:
                                          'Incorrect length of mobile number'
                                      }
                                    })}
                                    onInput={e => {
                                      e.target.value = e.target.value.slice(
                                        0,
                                        10
                                      )
                                    }}
                                  />
                                  <span className='text-danger error-input'>
                                    {errors.MobileNumber &&
                                      errors.MobileNumber.message}
                                  </span>
                                </div>
                              </div>
                              <div className='Owner-error-div'>
                                <div className='error-div-box'>
                                  <input
                                    type='email'
                                    placeholder='Enter Email ID'
                                    className='owner-input-box'
                                    defaultValue={apiRequestQQ.Email}
                                    {...register('Email', {
                                      required: 'Field is required'
                                    })}
                                  />
                                  <span className='text-danger error-input'>
                                    {errors.Email && errors.Email.message}
                                  </span>
                                </div>
                                <div className='error-div-box'>
                                  <input
                                    type='text'
                                    placeholder='Address 1'
                                    className='owner-input-box'
                                    maxLength={100}
                                    {...register('StreetNumber', {
                                      required: 'Field is required'
                                    })}
                                  />
                                  <span className='text-danger error-input'>
                                    {errors.StreetNumber &&
                                      errors.StreetNumber.message}
                                  </span>
                                </div>
                              </div>

                              <div className='Owner-error-div'>
                                <div className='error-div-box'>
                                  <input
                                    type='text'
                                    placeholder='Address 2'
                                    className='owner-input-box'
                                    maxLength={100}
                                    {...register('Street', {
                                      required: 'Field is required'
                                    })}
                                  />
                                  <span className='text-danger error-input'>
                                    {errors.Street && errors.Street.message}
                                  </span>
                                </div>
                                <div className='error-div-box' id='pincode-div'>
                                  <ReactSelect
                                    options={pincodeData}
                                    onChange={val =>
                                      handleSelectPincodeOwner(val)
                                    }
                                    placeholder=' pincode'
                                    className='owner-input-box'
                                    id='pincode-input'
                                    defaultValue={apiRequestQQ.Pincode}
                                    // {...register('pincode', {
                                    //   required: 'pincode is required'
                                    // })}
                                  />
                                  <span className='text-danger error-input'>
                                    {errors.pincode && errors.pincode.message}
                                  </span>
                                </div>
                              </div>

                              <div className='Owner-error-div'>
                                <div className='error-div-box'>
                                  <input
                                    type='text'
                                    className='owner-input-box'
                                    placeholder='Enter city'
                                    maxLength={30}
                                    {...register('City', {
                                      required: 'Field is required',
                                      pattern: {
                                        value: /^[A-Za-z\s]+$/,
                                        message: 'Numbers are not allowed'
                                      }
                                    })}
                                    onText={e => {
                                      e.target.Text = e.target.Text.slice(0, 10)
                                    }}
                                  />
                                  <span className='text-danger error-input'>
                                    {errors.City && errors.City.message}
                                  </span>
                                </div>

                                {/* <label for="gender">Gender:</label> */}
                                <div className='error-div-box'>
                                  <input
                                    list='text'
                                    placeholder='Enter State'
                                    name='State'
                                    className='owner-input-box'
                                    maxLength={30}
                                    {...register('State', {
                                      required: 'Field is required',
                                      pattern: {
                                        value: /^[A-Za-z\s]+$/,
                                        message: 'Numbers are not allowed'
                                      }
                                    })}
                                    onText={e => {
                                      e.target.Text = e.target.Text.slice(0, 10)
                                    }}
                                  />
                                  <span className='text-danger error-input'>
                                    {errors.State && errors.State.message}
                                  </span>
                                </div>
                              </div>

                              {docType === 'adhar' &&
                                selectedPlan.Api_name === 'Magma' && (
                                  <div className='error-div-box'>
                                    <select
                                      {...register('Martial', {
                                        required: 'Field is required'
                                      })}
                                      onChange={e =>
                                        handleMartialStatus(e.target.value)
                                      }
                                      className='owner-input-box'
                                    >
                                      <option value=''>
                                        Select Martial Status
                                      </option>
                                      <option value='Married'>Married</option>
                                      <option value='Single'>Single</option>
                                    </select>
                                    <span className='text-danger error-input'>
                                      {errors.Martial && errors.Martial.message}
                                    </span>
                                  </div>
                                )}

                              {martial === 'Married' &&
                                docType === 'adhar' &&
                                selectedPlan.Api_name === 'Magma' && (
                                  <div className='error-div-box'>
                                    <div className='Motor-deatil-2'>
                                      <label>Enter Spouse Name</label>
                                      <input
                                        type='text'
                                        placeholder='Enter Spouse Name'
                                        className='owner-input-box'
                                        {...register('Spouse', {
                                          required: 'Field is required',
                                          pattern: {
                                            value: /^[A-Za-z\s]+$/,
                                            message: 'Numbers are not allowed'
                                          }
                                        })}
                                        // defaultValue={apiRequestQQ.LastName}
                                        maxLength={40}
                                        onText={e => {
                                          e.target.Text = e.target.Text.slice(
                                            0,
                                            10
                                          )
                                        }}
                                      />

                                      <span className='text-danger error-input'>
                                        {errors.Spouse && errors.Spouse.message}
                                      </span>
                                    </div>
                                  </div>
                                )}

                              {martial === 'Single' &&
                                docType === 'adhar' &&
                                selectedPlan.Api_name === 'Magma' && (
                                  <div className='error-div-box'>
                                    <div className='Motor-deatil-2'>
                                      <label>Enter Father's Name</label>
                                      <input
                                        type='text'
                                        placeholder='Enter Father Name'
                                        className='owner-input-box'
                                        {...register('Father', {
                                          required: 'Field is required',
                                          pattern: {
                                            value: /^[A-Za-z\s]+$/,
                                            message: 'Numbers are not allowed'
                                          }
                                        })}
                                        defaultValue={apiRequestQQ.LastName}
                                        maxLength={40}
                                        onText={e => {
                                          e.target.Text = e.target.Text.slice(
                                            0,
                                            10
                                          )
                                        }}
                                      />

                                      <span className='text-danger error-input'>
                                        {errors.Father && errors.Father.message}
                                      </span>
                                    </div>
                                  </div>
                                )}

                              <div className='Owner-error-div'>
                                <>
                                  <div className='error-div-box'>
                                    <select
                                      className='owner-input-box'
                                      {...register('docType', {
                                        required:
                                          'Please Select Document Type to Verify',
                                        onChange: e =>
                                          setDocType(e?.target?.value)
                                      })}
                                    >
                                      {/* {magmaAadharKyc ? (
                                        <option value='adhar'>
                                          Adhar Card
                                        </option>
                                      ) : */}
                                      (
                                      <>
                                        <option value=''>
                                          Select Document Type
                                        </option>
                                        <option value='ckyc'>
                                          CKYC Number
                                        </option>
                                        <option value='pan'>Pan Card</option>
                                        {selectedPlan?.Api_name === 'Magma' && (
                                          <>
                                            <option value='adhar'>
                                              Aadhaar Card
                                            </option>
                                          </>
                                        )}
                                        <option value='voterId'>
                                          Voter ID
                                        </option>
                                        <option value='DL'>
                                          Driving License
                                        </option>
                                      </>
                                      )
                                    </select>
                                    <span className='text-danger error-input'>
                                      {errors.docType && errors.docType.message}
                                    </span>
                                  </div>
                                </>
                                {/* <p className='m-0 f-error'>
                                {errors?.docType?.message}
                              </p> */}

                                {docType === 'ckyc' ? (
                                  <div className='error-div-box'>
                                    <input
                                      type='text'
                                      className='owner-input-box'
                                      {...register('ckycNumber', {
                                        pattern: {
                                          value: /[0-9]$/,
                                          message: 'Invalid CKYC Number'
                                        }
                                      })}
                                      onChange={e =>
                                        handleChangeCkycValue(e.target.value)
                                      }
                                      placeholder='CKYCNumber'
                                    />
                                  </div>
                                ) : docType === 'pan' ? (
                                  <>
                                    <div className='error-div-box'>
                                      <input
                                        type='text'
                                        placeholder='pancard number'
                                        className='owner-input-box text-uppercase'
                                        {...register('PanNumber', {
                                          pattern: {
                                            value:
                                              /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/,
                                            message: 'Invalid Pan Number'
                                          },
                                          required: 'Pan Number is required'
                                        })}
                                        maxLength={10}
                                        onInput={e =>
                                          (e.target.value =
                                            e.target.value.replace(/[^\w]/, ''))
                                        }
                                        onChange={e =>
                                          handleChangePanValue(e.target.value)
                                        }
                                        readOnly={
                                          apiRequestQQ.KYC.RoyalRefNo != '' &&
                                          selectedPlan.Api_name == 'Royal'
                                            ? true
                                            : false
                                        }
                                      />
                                      <p className='m-0 f-error error-input text-danger'>
                                        {errors?.PanNumber?.message}
                                      </p>
                                    </div>
                                  </>
                                ) : docType === 'adhar' ? (
                                  <div className='w-50 mt-3 adhaer-sec'>
                                    <input
                                      type='file'
                                      className='d-none'
                                      style={{ cursor: 'pointer' }}
                                      id={'adharImage'}
                                      name={'adharImage'}
                                      {...register('AdharImage', {
                                        required: true
                                      })}
                                      onChange={e =>
                                        handleUploadAdharFile(e.target.files)
                                      }
                                      accept={'image/*'}
                                    />

                                    <label
                                      htmlFor={'adharImage'}
                                      className='adhar-img'
                                    >
                                      <img
                                        src={
                                          adhar.image
                                            ? adhar.image
                                            : '/assets/img/cameraIcon.svg'
                                        }
                                        className=' d-block mb-3'
                                        style={{
                                          objectFit: 'contain',
                                          width: '100px',
                                          cursor: 'pointer'
                                        }}
                                      />
                                      <span>Please upload AAdhar image.</span>
                                    </label>
                                    {errors.AdharImage && (
                                      <p
                                        className='mt-2 f-error error-input text-danger'
                                        style={{ position: 'relative' }}
                                      >
                                        Please upload AAdhar Card image.
                                      </p>
                                    )}
                                  </div>
                                ) : /* (
                                  <div className='error-div-box'>
                                    <input
                                      type='text'
                                      className='owner-input-box'
                                      {...register('adharNumber', {
                                        required: 'Field is required'
                                      })}
                                      onChange={e => setAdhar(e.target.value)}
                                      placeholder='Adhar Number'
                                    />
                                  </div>
                                )  */
                                docType === 'voterId' ? (
                                  <div className='error-div-box'>
                                    <input
                                      type='text'
                                      className='owner-input-box'
                                      {...register('voterIdNumber', {
                                        required: 'Field is required'
                                      })}
                                      onChange={e => setVoterID(e.target.value)}
                                      placeholder='Voter ID Number'
                                    />
                                  </div>
                                ) : docType === 'DL' ? (
                                  <div className='error-div-box'>
                                    <input
                                      type='text'
                                      className='owner-input-box'
                                      {...register('drivingLicenseNumber', {
                                        required: 'Field is required'
                                      })}
                                      onChange={e =>
                                        setDrivingLicense(e.target.value)
                                      }
                                      placeholder='Driving License Number'
                                    />
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className='field-info'>
                                <div className='submit-model1'>
                                  <button
                                    disabled={hideBtn}
                                    style={{
                                      filter: hideBtn ? 'blur(2px)' : 'none'
                                    }}
                                  >
                                    <a>Previous</a>
                                  </button>
                                </div>
                                <div className='submit-model1'>
                                  <button
                                    type='submit'
                                    disabled={hideBtn}
                                    style={{
                                      filter: hideBtn ? 'blur(2px)' : 'none'
                                    }}
                                  >
                                    {spinner ? (
                                      <div
                                        class='spinner-border text-light'
                                        role='status'
                                      >
                                        <span class='sr-only'></span>
                                      </div>
                                    ) : (
                                      'Continue'
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                )}
                {activeStep === 2 && (
                  <div className='col-lg-7'>
                    <div className='right_section'>
                      <form onSubmit={handleSubmit1(handleNomineeDetails)}>
                        <div>
                          <div className='pro-form'>
                            <div className='Owner-error-div'>
                              <div className='error-div-box'>
                                <input
                                  type='text'
                                  className='owner-input-box'
                                  placeholder='Nominee First Name'
                                  maxLength={30}
                                  {...register1('NomineeFirstName', {
                                    required: 'Field is required',
                                    pattern: {
                                      value: /^[A-Za-z\s]+$/,
                                      message: 'Numbers are not allowed'
                                    }
                                  })}
                                  onText={e => {
                                    e.target.Text = e.target.Text.slice(0, 10)
                                  }}
                                />
                                <span className='text-danger error-input'>
                                  {errors1.NomineeFirstName &&
                                    errors1.NomineeFirstName.message}
                                </span>
                              </div>
                              <div className='error-div-box'>
                                <input
                                  type='text'
                                  placeholder='Nominee Last Name'
                                  className='owner-input-box'
                                  maxLength={30}
                                  {...register1('NomineeLastName', {
                                    required: 'Field is required',
                                    pattern: {
                                      value: /^[A-Za-z\s]+$/,
                                      message: 'Numbers are not allowed'
                                    }
                                  })}
                                  onText={e => {
                                    e.target.Text = e.target.Text.slice(0, 10)
                                  }}
                                />
                                <span className='text-danger error-input'>
                                  {errors1.NomineeLastName &&
                                    errors1.NomineeLastName.message}
                                </span>
                              </div>
                            </div>
                            <div className='Owner-error-div'>
                              {selectedPlan?.Api_name === 'HDFC' ? (
                                <div className='error-div-box'>
                                  <select
                                    name='cars'
                                    id='cars'
                                    className='owner-input-box'
                                    {...register1('NomineeRelationship', {
                                      required: 'Field is required',
                                      onChange: e =>
                                        setNomineeRelation(e?.target?.value)
                                    })}
                                  >
                                    <>
                                      <option>Select Relationship</option>
                                      {relationData?.map(item => {
                                        return (
                                          <option value={item?.relationCode}>
                                            {item?.relation}
                                          </option>
                                        )
                                      })}
                                    </>
                                  </select>
                                  <span className='text-danger error-input'>
                                    {errors1.NomineeRelationship &&
                                      errors1.NomineeRelationship.message}
                                  </span>
                                </div>
                              ) : (
                                <div className='error-div-box'>
                                  <select
                                    name='cars'
                                    id='cars'
                                    className='owner-input-box'
                                    {...register1('NomineeRelationship', {
                                      required: 'Field is required',
                                      onChange: e =>
                                        setNomineeRelation(e?.target)
                                    })}
                                  >
                                    {selectedPlan.Api_name === 'Magma' &&
                                    docType === 'adhar' &&
                                    apiRequestQQ.AddOns.PersonalAccident ===
                                      false ? (
                                      <>
                                        <option>Select Relationship</option>
                                        <option value='Father'>Father</option>
                                        <option value='Spouse'>Spouse</option>
                                      </>
                                    ) : (
                                      <>
                                        <option>Select Relationship</option>
                                        <option key='M' value='Mother'>
                                          Mother
                                        </option>
                                        <option value='Father'>Father</option>
                                        <option value='Husband'>Husband</option>
                                        <option value='Brother'>Brother</option>
                                        <option value='Sister'>Sister</option>
                                        <option value='Son'>Son</option>
                                        <option value='Daughter'>
                                          Daughter
                                        </option>
                                        <option value='Spouse'>Spouse</option>
                                      </>
                                    )}
                                  </select>
                                  <span className='text-danger error-input'>
                                    {errors1.NomineeRelationship &&
                                      errors1.NomineeRelationship.message}
                                  </span>
                                </div>
                              )}
                              <div className='error-div-box'>
                                <select
                                  name='cars'
                                  id='cars'
                                  className='owner-input-box'
                                  {...register1('NomineeGender', {
                                    required: 'Field is required'
                                  })}
                                >
                                  <option value=''>Nominee Gender</option>
                                  <option value='Male'>Male</option>
                                  <option value='Female'>Female</option>
                                  <option value='Other'>Other</option>
                                </select>
                                <span className='text-danger error-input'>
                                  {errors1.NomineeGender &&
                                    errors1.NomineeGender.message}
                                </span>
                              </div>
                            </div>
                            <div className='error-div-box'>
                              <div className='Motor-deatil-2'>
                                <label>Enter DOB</label>
                                <input
                                  type='date'
                                  placeholder='Enter Date of Birth'
                                  className='owner-input-box'
                                  {...register1('NomineeDateOfBirth', {
                                    required: 'Field is required'
                                  })}
                                />
                                <span className='text-danger error-input'>
                                  {errors1.NomineeDateOfBirth &&
                                    errors1.NomineeDateOfBirth.message}
                                </span>
                              </div>
                            </div>
                            <div className='field-info-btn'>
                              <div className='submit-model1'>
                                <button onClick={() => handlePreviousStep()}>
                                  <a>Previous</a>
                                </button>
                              </div>
                              <div className='submit-model1'>
                                <button type='submit'>Continue</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
                {activeStep === 3 && (
                  <div className='col-lg-7'>
                    <div className='right_section'>
                      <div>
                        <div className='pro-form'>
                          <form onSubmit={handleSubmit2(submitVehicleDetails)}>
                            {/* <div className="pro-form"> */}
                            <div className='Owner-error-div'>
                              <div className='error-div-box'>
                                {apiRequestQQ?.IsVehicleNew === false && (
                                  <div className='Motor-deatil-2'>
                                    <label> Registration Number</label>
                                    <input
                                      type='text'
                                      className='owner-input-box'
                                      placeholder='Enter Registration Number'
                                      {...register2('RegistrationNumber')}
                                      value={RegistrationNumber}
                                      onChange={e =>
                                        setRegistrationValue(e.target.value)
                                      }
                                      disabled={true}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className='error-div-box'>
                                {/* {apiRequestQQ.IsVehicleNew === false && ( */}
                                <input
                                  type='text'
                                  className='owner-input-box'
                                  value={rtoCode}
                                  onChange={e =>
                                    setRegistrationValue(e.target.value)
                                  }
                                  placeholder=''
                                  disabled={true}
                                />
                                {/* )} */}
                              </div>
                            </div>
                            <div className='Owner-error-div'>
                              <div className='error-div-box'>
                                {/* {apiRequestQQ?.IsVehicleNew === false && ( */}
                                <div className='Motor-deatil-2'>
                                  <label> Engine Number</label>
                                  <input
                                    type='text'
                                    className='owner-input-box'
                                    placeholder='Enter Engine Number'
                                    maxLength={30}
                                    {...register2('EngineNumber', {
                                      required: 'Field is required',
                                      pattern: {
                                        value: /^[A-Za-z0-9]+$/i,
                                        message: 'Invalid value'
                                      },
                                      minLength: {
                                        value:
                                          apiRequestQQ.IsVehicleNew === true
                                            ? 6
                                            : 6,
                                        message:
                                          apiRequestQQ.IsVehicleNew === true
                                            ? 'Value must be 6 number'
                                            : 'Value must be minumum 6 number'
                                      }
                                    })}
                                    onChange={e =>
                                      handleEngineNumber(e.target.value)
                                    }
                                  />
                                  <span className='text-danger error-input'>
                                    {errors2.EngineNumber &&
                                      errors2.EngineNumber.message}
                                  </span>
                                </div>
                                {/* )} */}
                              </div>
                              <div className='error-div-box'>
                                {/* {apiRequestQQ.IsVehicleNew === false && ( */}
                                <div className='Motor-deatil-2'>
                                  <label>Chassis Number</label>
                                  <input
                                    type='text'
                                    {...register2('ChassisNumber', {
                                      required: 'Field is required',
                                      pattern: {
                                        value: /^[a-z0-9]+$/i,
                                        message: 'Invalid Value'
                                      },
                                      minLength: {
                                        value:
                                          apiRequestQQ.IsVehicleNew === true
                                            ? 17
                                            : 6,
                                        message:
                                          apiRequestQQ.IsVehicleNew === true
                                            ? 'Value must be between 17 to 25 number'
                                            : 'Value must be between 6 to 25 number'
                                      }
                                    })}
                                    placeholder='Enter Chassis Number'
                                    className='owner-input-box'
                                    maxLength='25'
                                    onChange={e =>
                                      handleChassisNumber(e.target.value)
                                    }
                                  />
                                  <span className='text-danger error-input'>
                                    {errors2.ChassisNumber &&
                                      errors2.ChassisNumber.message}
                                  </span>
                                </div>
                                {/* )} */}
                              </div>
                            </div>
                            {areValuesSame() && (
                              <p
                                className='text-danger error-input mb-4'
                                style={{ color: 'red' }}
                              >
                                Engine Number and Chassis Number cannot be the
                                same.
                              </p>
                            )}
                            <div className='prepolicy-info'>
                              <div className='error-div-box'>
                                {apiRequestQQ.IsVehicleNew === false && (
                                  <div className='Motor-deatil-2'>
                                    <label>Previous Policy Number</label>
                                    <input
                                      type='text'
                                      placeholder='Previous Policy Number'
                                      className='owner-input-box'
                                      maxLength={25}
                                      {...register2('PrePolicyNumber', {
                                        required: 'Field is required'
                                      })}
                                      onChange={e =>
                                        handlePrePolicyNumber(e.target.value)
                                      }
                                    />
                                  </div>
                                )}
                                <span className='text-danger error-input'>
                                  {errors2.PrePolicyNumber &&
                                    errors2.PrePolicyNumber.message}
                                </span>
                              </div>

                              <div className='rto-checkbox'>
                                <div className='checkbox-select'>
                                  <input
                                    type='checkbox'
                                    id='vehicle1'
                                    name='vehicle1'
                                    className='input-check'
                                    onClick={e => {
                                      setHypothecation(!hypothecation)
                                      handleHypothecation(e?.target?.checked)
                                    }}
                                  />
                                  <label for='vehicle1'>Hypothecation</label>
                                </div>
                                {selectedPlan?.Api_name !== 'Future' && (
                                  <div className='checkbox-select'>
                                    <input
                                      type='checkbox'
                                      id='vehicle2'
                                      name='vehicle2'
                                      className='input-check hidden'
                                      onClick={() => setPuc(!puc)}
                                    />
                                    <label for='vehicle2'>
                                      {' '}
                                      Is valid PUC available?
                                    </label>
                                  </div>
                                )}
                              </div>
                            </div>
                            {apiRequestQQ.NewPolicyType === 'ODOnly' ? (
                              <div className='previousTPPolicyDetails'>
                                <p>Previous TP Policy Details</p>
                                <div className='pretp'>
                                  <div className='error-div-box'>
                                    <div className='Motor-deatil-2'>
                                      <label>Previous TP Policy Number</label>
                                      <input
                                        type='text'
                                        placeholder='Previous TP Policy Number'
                                        className='owner-input-box'
                                        maxLength={25}
                                        {...register2('PreTPPolicyNumber', {
                                          required: 'Field is required'
                                        })}
                                        onChange={e =>
                                          handlePreTPPolicyNumber(
                                            e.target.value
                                          )
                                        }
                                        onInput={e =>
                                          e.target.value.replace(' ', '')
                                        }
                                      />
                                      <span className='text-danger error-input'>
                                        {errors2.PreTPPolicyNumber &&
                                          errors2.PreTPPolicyNumber.message}
                                      </span>
                                    </div>
                                  </div>
                                  <div className='error-div-box'>
                                    <div className='Motor-deatil-2'>
                                      <label>Previous TP Policy End Date</label>
                                      <input
                                        type='date'
                                        placeholder='Previous TP Policy End Date'
                                        className='owner-input-box'
                                        maxLength={18}
                                        {...register2('PreTPPolicyEndDate', {
                                          required: 'Field is required'
                                        })}
                                        onChange={e =>
                                          handlePreTPPolicyEndDate(
                                            e.target.value
                                          )
                                        }
                                      />
                                      <span className='text-danger error-input'>
                                        {errors2.PreTPPolicyEndDate &&
                                          errors2.PreTPPolicyEndDate.message}
                                      </span>
                                    </div>
                                  </div>
                                  <div className='error-div-box'>
                                    <div className='Motor-deatil-2'>
                                      <label>Previous TP Policy Insurer</label>
                                      <select
                                        {...register2('PreTPInsurerCode', {
                                          required: 'Field is required'
                                        })}
                                        className='owner-input-box'
                                        onChange={e =>
                                          handlePreTPPolicyInsurer(
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value=''>Select</option>
                                        {insurerData &&
                                          insurerData.length > 0 &&
                                          insurerData.map((item, i) => (
                                            <option key={i} value={item.value}>
                                              {item.option}
                                            </option>
                                          ))}
                                      </select>
                                      <span className='text-danger error-input'>
                                        {errors2.PreTPInsurerCode &&
                                          errors2.PreTPInsurerCode.message}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {/* </div> */}
                            {hypothecation && (
                              <div className='Owner-error-div' id='divcheck'>
                                {/* <select
                                    type='text'
                                    className='owner-input-box'
                                    placeholder=''
                                    {...register2('FinancierBank', {
                                      //   required: "Field is required",
                                    })}
                                    onChange={e =>
                                      handleFinancerBank(e.target.value)
                                    }
                                  >
                                    {' '}
                                    <option>Select Financier Bank</option>
                                    {financierData?.map(item => (
                                      <option value={item?.value}>
                                        {item?.label}
                                      </option>
                                    ))}
                                  </select> */}

                                <ReactSelect
                                  options={financierData}
                                  onChange={val =>
                                    handleFinancerBank(val.value)
                                  }
                                  placeholder='Financier Bank'
                                  className='owner-input-box'
                                  id='pincode-input'
                                />

                                <ReactSelect
                                  options={pincodeData}
                                  onChange={val => setFinancierCity(val.value)}
                                  placeholder='Financier City'
                                  className='owner-input-box'
                                  id='pincode-input'
                                />
                              </div>
                            )}

                            {selectedPlan.Api_name === 'Future' &&
                            puc === false ? (
                              <div className='Owner-error-div' id='divcheck'>
                                {apiRequestQQ?.IsVehicleNew === false && (
                                  <div className='error-div-box'>
                                    <div className='Motor-deatil-2'>
                                      <input
                                        type='text'
                                        className='owner-input-box'
                                        placeholder='Enter PUC Number'
                                        {...register2('PUCNumber', {
                                          required: 'Field is required'
                                        })}
                                      />
                                    </div>
                                    <span className='text-danger error-input'>
                                      {errors2.PUCNumber &&
                                        errors2.PUCNumber.message}
                                    </span>
                                  </div>
                                )}

                                {apiRequestQQ.IsVehicleNew === false && (
                                  <div className='error-div-box'>
                                    <div className='Motor-deatil-2'>
                                      <label for='vehicle2'>
                                        PUC Valid Upto
                                      </label>
                                      <input
                                        type='date'
                                        placeholder='PUC Valid Upto'
                                        className='owner-input-box'
                                        {...register2('PUCValidUpto', {
                                          required: 'Field is required'
                                        })}
                                      />
                                    </div>
                                    <span className='text-danger error-input'>
                                      {errors2.PUCValidUpto &&
                                        errors2.PUCValidUpto.message}
                                    </span>
                                  </div>
                                )}
                              </div>
                            ) : (
                              ''
                            )}

                            {puc && (
                              <div className='Owner-error-div' id='divcheck'>
                                <div className='error-div-box'>
                                  <div className='Motor-deatil-2'>
                                    <input
                                      type='text'
                                      className='owner-input-box'
                                      placeholder='Enter PUC Number'
                                      {...register2('PUCNumber', {
                                        required: 'Field is required'
                                      })}
                                    />
                                  </div>
                                  <span className='text-danger error-input'>
                                    {errors2.PUCNumber &&
                                      errors2.PUCNumber.message}
                                  </span>
                                </div>

                                <div className='error-div-box'>
                                  <div className='Motor-deatil-2'>
                                    <label for='vehicle2'>PUC Valid Upto</label>
                                    <input
                                      type='date'
                                      placeholder='PUC Valid Upto'
                                      className='owner-input-box'
                                      {...register2('PUCValidUpto', {
                                        required: 'Field is required'
                                      })}
                                    />
                                  </div>
                                  <span className='text-danger error-input'>
                                    {errors2.PUCValidUpto &&
                                      errors2.PUCValidUpto.message}
                                  </span>
                                </div>
                              </div>
                            )}

                            {/* </div> */}
                            {/* </div> */}
                            {/* </div> */}
                            {/* </div> */}
                            <div className='field-info'>
                              <div className='submit-model1'>
                                <button
                                  disabled={hideBtn}
                                  style={{
                                    filter: hideBtn ? 'blur(2px)' : 'none'
                                  }}
                                  onClick={() => handlePreviousStep()}
                                >
                                  Previous
                                </button>
                              </div>
                              <div className='submit-model1'>
                                <button
                                  disabled={hideBtn}
                                  style={{
                                    filter: hideBtn ? 'blur(2px)' : 'none'
                                  }}
                                >
                                  {spinner ? (
                                    <div
                                      class='spinner-border text-light'
                                      role='status'
                                    >
                                      <span class='sr-only'></span>
                                    </div>
                                  ) : (
                                    'Continue'
                                  )}
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  // </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Proposal

function isValidHttpUrl (string) {
  let url
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}
