import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import HowWork from "./HowWork";

function Personal() {
  return (
    <>
      <Navbar />
      <div className="main-personal-page">
     <div className="personal-inf">
        <div className="personal-details">
          <div className="self-name">
            <div className="self-name-img">
              <img src="./assets/img/age-img.png" alt="" />
            </div>
            <h1>Self</h1>
          </div>
          <h1>Personal Details</h1>
          <div className="loder">
            <p> 80%</p>
          </div>
        </div>
        <div className="details">

          <input
           type="text" 
           placeholder="Full Name" 
           maxLength={30}
           onText={(e) => {
             e.target.Text = e.target.Text.slice(0, 10);
           }}
           
          /> 
           {/* <span className="text-danger">
                    {errors.name && errors.name.message}
                  </span> */}
                  <br />
          <input 
          type="number" 
          placeholder="Mobile Number"
          pattern="[0-9]{10}"
          maxLength={10}
          onInput={(e) => {
            e.target.value = e.target.value.slice(0, 10);
          }}
          />
        </div>
        <div className="submit-name">
          <button>
            <Link to="/plan">Continue</Link>
          </button>
        </div>
        </div>
      </div>
      {/* <HowWork/> */}
      <Footer />
    </>
  );
}

export default Personal;
