import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Bike from '../Bike'
import Car from '../Car'
import { useDispatch, useSelector } from 'react-redux'
import Footer from '../Footer'
import {
  dispatchMotorQuote,
  dispatchQuickQuote,
  dispatchResetQuickQuote,
  generateArrayOfYears,
  generateArrayOfYearsForNew,
  quickQuoteResult,
  resetQuickQuoteResults,
  saveUserDetails,
  apiErrorReponse,
  dispatchProcessFlowMotor
} from '../../store/actions/userAction'
import { useForm } from 'react-hook-form'
import Rto from '../Rto'
import ReactSelect from '../Tags/ReactSelect'
import {
  getVehicleMake,
  getVehicleModel,
  getVehiclePreviousInsurer,
  getVehicleRto,
  getVehicleVariant
} from '../Services/masterServices'
import moment from 'moment'
import {
  fourWheelerModel,
  isWithinFiveYears,
  isWithinThreeYears,
  newPolicyResultArr,
  policyResultArr,
  twoWheelerMake,
  failureCaseArr
} from '../MakeModelArr'
import {
  FutureGeneralQuickQuote,
  digitAPICall,
  magmaAPICall,
  getMotorQuote,
  processFlow
} from '../Services/TPApiCall'
import HowWork from '../HowWork'
import { GetDataWithToken, PostDataWithToken } from '../../api/apiHelper'
import Cookies from 'js-cookie'
import {
  CUSTOMER_TYPE,
  insurers,
  POLICY_STATUS,
  POLICY_TYPE,
  VEHICLE_TYPE
} from '../utility/constants'
import { store } from '../../store'
import { useCookies } from 'react-cookie'

const MotorProcess = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors
  } = useForm({ mode: 'onBlur' })
  const {
    handleSubmit: handleSubmit1,
    watch: watch1,
    register: register1,
    formState: { errors: errors1 },
    clearErrors: clearErrors1
  } = useForm({ mode: 'onBlue' })
  const {
    handleSubmit: handleSubmit2,
    watch: watch2,
    register: register2,
    formState: { errors: errors2 },
    clearErrors: clearErrors2
  } = useForm({ mode: 'onBlue' })
  // const [parsedData, setParsedData] = useState({});

  const userDetails = useSelector(state => state.root.userDetails)
  const [cookies, setCookie, removeCookie] = useCookies(['customerLeadId'])
  const [isBharatSeries, setIsBharatSeries] = useState(false)
  const [RegistrationNumber, setRegistrationNumner] = useState('')
  const [flag, setFlag] = useState(false)
  const toggleFlag = () => setFlag(!flag)
  const [rtoData, setRtoData] = useState([])
  const [activeStep, setActiveStep] = useState(1)
  const [insurerData, setInsurerData] = useState([])
  const apiRequestQQ = useSelector(state => state.root.apiRequestQQ)
  const motorRequest = useSelector(state => state?.root?.motorRequest)
  const processFlowObjMotor = useSelector(
    state => state?.root?.processFlowObjMotor
  )
  const [isNewVehicle, setIsNewVehicle] = useState(false)
  const [makeData, setMakeData] = useState([])
  const [vehicleModal, setVehicleModal] = useState('')
  const [quickPick, setQuickPick] = useState([])
  const [fuelTypes, setFuelTypes] = useState([])
  const [variantData, setVariantData] = useState([])
  const [modelData, setModelData] = useState([])
  const [regYear, setRegYear] = useState('')
  const [currentYear, setCurrentYear] = useState()
  const [policyStatus, setPolicyStatus] = useState('')
  const [newPolicyType, setNewPolicyType] = useState('')
  const [ownerChange, setOwnerChange] = useState(true)
  const [dataMotorQuotation, setDataMotorQuotation] = useState([])
  const [getMotorQuoteResponse, setGetMotorQuoteResponse] = useState()
  const searchParams = new URLSearchParams(window.location.search)

  const userId = searchParams.get('posId')
  const vehicleType = searchParams.get('vehicleType')

  const parsedData = {
    vehicleModel:
      vehicleType === '2w'
        ? 'Two Wheeler'
        : vehicleType === '4w'
        ? 'Pvt Car'
        : null,
    vehicleType: vehicleType
  }

  const location = useLocation()
  const vehicleTYPE = location.state ? location?.state : parsedData

  const navigate = useNavigate()
  const dispatch = useDispatch()

  function getDataFromCookie (cookieName) {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      if (cookie.startsWith(cookieName + '=')) {
        return decodeURIComponent(cookie.substring(cookieName.length + 1))
      }
    }
    return null
  }

  useEffect(() => {
    dispatchQuickQuote('AddOns.RoadSideAssistance', false)
    dispatchQuickQuote('AddOns.EngineProtection', false)
    dispatchQuickQuote('AddOns.TyreProtection', false)
    dispatchQuickQuote('AddOns.RimProtection', false)
    dispatchQuickQuote('AddOns.Consumables', false)
    dispatchQuickQuote('AddOns.IsElectricalAccessories', false)
    dispatchQuickQuote('AddOns.IsNonElectricalAccessories', false)
    dispatchQuickQuote('AddOns.IsCngAccessories', false)
    dispatchQuickQuote('AddOns.PersonalAccident', false)
    dispatchQuickQuote('AddOns.InvoiceCover', false)
    dispatchQuickQuote('AddOns.EngineGearBox', false)
    dispatchQuickQuote('AddOns.NCBProtection', false)
    dispatchQuickQuote('AddOns.VoluntaryDeductive', false)
    dispatchQuickQuote('AddOns.PassengerCover', false)
    dispatchQuickQuote('AddOns.LossOfPersonalBelongings', false)
    dispatchQuickQuote('AddOns.ZeroDepriciationCover', false)
    dispatchQuickQuote('AddOns.LiabilitiesToPaidDriver', false)

    dispatchMotorQuote('roadSideAssistance', false)
    dispatchMotorQuote('engineProtection', false)
    dispatchMotorQuote('tyreProtection', false)
    dispatchMotorQuote('consumables', false)
    dispatchMotorQuote('isElectricalAccessories', false)
    dispatchMotorQuote('isNonElectricalAccessories', false)
    dispatchMotorQuote('isCngAccessories', false)
    dispatchMotorQuote('isTppdDiscount', false)
    dispatchMotorQuote('personalAccident', false)
    dispatchMotorQuote('invoiceCover', false)
    dispatchMotorQuote('ncbProtection', false)
    dispatchMotorQuote('isVoluntaryDeductable', false)
    dispatchMotorQuote('rimProtection', false)
    dispatchMotorQuote('lossOfPersonalBelongings', false)
    dispatchMotorQuote('zeroDepriciationCover', false)
    dispatchMotorQuote('keyReplacement', false)
    dispatchMotorQuote('isPassengerCover', false)
    dispatchMotorQuote('liabilitiesToPaidDriver', false)
    dispatchMotorQuote('isHypothecation', false)
  }, [])

  useEffect(() => {
    if (searchParams?.size > 0) {
      const myData = getDataFromCookie('Data')

      if (myData) {
        let obj = JSON.parse(myData)
        dispatchQuickQuote('VehicleType', obj?.vehicleType)
        dispatchMotorQuote('vehicleType', obj?.vehicleType)
        setVehicleModal(obj?.vehicleModel)
      } else {
        dispatchQuickQuote('VehicleType', vehicleTYPE?.vehicleType)
        dispatchMotorQuote('vehicleType', vehicleTYPE?.vehicleType)
        setVehicleModal(vehicleTYPE?.vehicleModel)
      }
    }
    dispatchMotorQuote('vehicleType', vehicleTYPE?.vehicleType)
  }, [flag])

  let calculateYearDifference = (registrationDate, currentDate) => {
    const date1 = registrationDate
    const date2 = currentDate.getFullYear()

    const yearsDifference = date2 - date1

    return yearsDifference
  }

  let yearDiff = calculateYearDifference(
    apiRequestQQ.RegistrationYear,
    new Date()
  )

  const handleOwnerChange = val => {
    setOwnerChange(val)

    dispatchQuickQuote('IsOwnerChanged', val)
  }

  useEffect(() => {
    getVehicleRto().then(response => {
      if (response.status === true) {
        let data = response?.data
        let i = 0
        let arr = []
        while (i < data?.length) {
          let item = data[i]
          arr.push({
            label: item.registered_city_name + ' (' + item.RTO_Code + ')',
            value: item.RTO_Code,
            registered_state_name: item.registered_state_name,
            id: item.id,
            registered_city_name: item.registered_city_name
          })
          i++
        }
        setRtoData(arr)
      }
    })

    getVehiclePreviousInsurer().then(response => {
      if (response.status === true) {
        let i = 0
        let data = response.data
        let arr = []
        while (i < data.length) {
          let item = data[i]
          arr.push({
            option: item.Name,
            value: item.Digit_Code
          })
          i++
        }
        setInsurerData(arr)
      }
    })
  }, [])

  useEffect(() => {
    setVehicleModal(vehicleTYPE?.vehicleModel)
  }, [vehicleTYPE])

  useEffect(() => {
    const myData1 = getDataFromCookie('Data')
    let obj1 = JSON.parse(myData1)
    let arrMake = []
    getVehicleMake({
      Vehicle_Type: vehicleTYPE?.vehicleModel
        ? vehicleTYPE?.vehicleModel
        : obj1?.vehicleModel
    }).then(response => {
      if (response.status === true) {
        let data = response.data
        let i = 0
        while (i < data.length) {
          let item = data[i]
          arrMake.push({
            label: item.Make,

            value: item.make_id
          })
          i++
        }
        setMakeData(arrMake)
        // vehicleModal == "Passenger Carrying"
        //   ? setQuickPick(arrMake.slice(0, 12))
        //   : vehicleModal == "Goods Carrying" &&
        //     setQuickPick(arrMake.slice(0, 12));
      }
    })
    // debugger
    // dispatchQuickQuote("VehicleType", vehicleType);
    setVehicleModal(
      searchParams?.size > 0 ? obj1?.vehicleModel : vehicleTYPE?.vehicleModel
    )
    vehicleModal === 'Two Wheeler'
      ? setQuickPick(twoWheelerMake)
      : vehicleModal === 'Pvt Car' && setQuickPick(fourWheelerModel)
  }, [vehicleModal])

  useEffect(() => {}, [quickPick, makeData])

  useEffect(() => {
    let vType = {
      vehicleType: '',
      vehicleModel: ''
    }
    if (vehicleType === '4w') {
      vType.vehicleType = vehicleType
      vType.vehicleModel = 'Pvt Car'
    } else if (vehicleType === '2w') {
      vType.vehicleType = vehicleType
      vType.vehicleModel = 'Two Wheeler'
    } else if (vehicleType === 'gcv') {
      vType.vehicleType = vehicleType
      vType.vehicleModel = 'GCV'
    } else if (vehicleType === 'pcv') {
      vType.vehicleType = vehicleType
      vType.vehicleModel = 'Taxi'
    }
    if (userId) {
      GetDataWithToken(`admin/getUserById?userId=${userId}`).then(res => {
        document.cookie = `Data=${encodeURIComponent(
          JSON.stringify(vType)
        )}; domain=.retroinsurance.in`
        document.cookie = `posId=${userId}; domain=.retroinsurance.in`
        document.cookie = `userDetails=${encodeURIComponent(
          JSON.stringify(res?.data)
        )}; domain=.retroinsurance.in`
        dispatch(saveUserDetails(res?.data))
        dispatchQuickQuote('posId', userId)
      })
    }
  }, [])

  function generateRetroNumber () {
    const prefix = 'RETRO'
    const randomDigits = Math.floor(1000000000 + Math.random() * 9000000000)
    return `${prefix}${randomDigits}`
  }

  // useEffect(() => {
  //   const cookieValue = Cookies.get("userDetails");
  //   if (cookieValue) {
  //     // setPosDetails(JSON.parse(cookieValue));
  //     dispatch(saveUserDetails(JSON.parse(cookieValue)));
  //   }

  //   const cookieValue1 = Cookies.get("posId");
  //   // setPosId(cookieValue1);
  //   dispatchQuickQuote("posId", cookieValue1);
  // }, []);

  const formatReg = e => {
    var t = e
    t = t.replace(/-/g, '')
    var o = new Array(4)
    ;(o[0] = t.slice(0, 2)), (o[1] = ''), (o[2] = ''), (o[3] = '')
    try {
      isNaN(t.slice(2, 4))
        ? isNaN(t.slice(3, 4))
          ? (isNaN(t.slice(2, 3)) || (o[1] = t.slice(2, 3)),
            isNaN(t.slice(3, 4)) && (o[2] = t.slice(3, 4)))
          : (o[1] = t.slice(2, 3) + t.slice(3, 4))
        : (o[1] = t.slice(2, 4)),
        isNaN(t.slice(4, 8))
          ? ((o[2] = o[2] + t.slice(4, 5)),
            1 == isNaN(t.slice(5, 6)) && 1 == isNaN(t.slice(6, 7))
              ? (o[2] = o[2] + t.slice(5, 7))
              : (isNaN(t.slice(5, 6))
                  ? (o[2] = o[2] + t.slice(5, 6))
                  : (o[3] = o[3] + t.slice(5, 6)),
                isNaN(t.slice(6, 7)) || (o[3] = o[3] + t.slice(6, 7))),
            isNaN(t.slice(7, 11)) || (o[3] = o[3] + t.slice(7, 11)))
          : (o[3] = o[3] + t.slice(4, 8))
    } catch (e) {}
    return o
      .join('-')
      .replace(/ /g, '')
      .replace(/--/g, '-')
      .replace(/-\s*$/, '')
      .replace(/[^- 0-9 a-z A-z]/g, '')
  }

  const setRegistrationValue = e => {
    var message = document.getElementById('message')
    if (isBharatSeries === true) {
      const regex = /^(0[1-9]|[1-9][0-9])\sBH\s\d{4}\s[A-Z]{2}$/
      if (regex.test(e.target.value)) {
        message.innerHTML = ''
        setRegistrationNumner(e.target.value)
      } else {
        message.innerHTML =
          'The Bharat series number format is XXBH####YY(12BH1234AB)'
      }
    } else {
      if (navigator.userAgent.match(/UCBrowser/)) return !1
      var t = document.querySelector('#vinpuut').value,
        o = e.keyCode
      if (
        -1 != navigator.userAgent.indexOf('MSIE') ||
        1 == !!document.documentMode
      ) {
        if (32 == o || 8 == o) return !1
        t.length >= 3 && (t = formatReg(t)),
          t.length >= 5 && (t = formatReg(t)),
          t.length >= 7 && (t = formatReg(t)),
          (document.querySelector('#vinpuut').value = t.toUpperCase())
      } else
        setTimeout(function () {
          if (32 == o || 8 == o) return !1
          ;(t = document.querySelector('#vinpuut').value).length >= 3 &&
            (t = formatReg(t)),
            t.length >= 5 && (t = formatReg(t)),
            t.length >= 7 && (t = formatReg(t)),
            (document.querySelector('#vinpuut').value = t.toUpperCase())
        }, 100)
      setRegistrationNumner(t)
    }
  }

  const handleNextStep = () => {
    setActiveStep(activeStep + 1)
  }

  const getCustomerLeadId = data => {
    try {
      PostDataWithToken('admin/flow', data).then(res => {
        console.log('Flow Res', res)
        if (res?.data?.success) {
          dispatchMotorQuote('customerLeadId', res?.data?.data?.customerLeadId)
        }
      })
    } catch (err) {
      console.log('Flow Err:- ', err)
    }
  }

  const submitQuoteDetails = data => {
    dispatch(resetQuickQuoteResults())
    toggleFlag()
    const min = 10000000000
    const max = 99999999999
    const randomNumber = Math.floor(Math.random() * (max - min + 1) + min)
    const str = data?.rc_no
    const result = str.replace(/-/g, '')
    const str2 = str.split('-')
    const rtoStr = str2[0] + str2[1]
    if (vehicleTYPE?.vehicleType === '4w') {
      setVehicleModal('Pvt Car')
      dispatchQuickQuote('enquiryId', randomNumber)
      dispatchQuickQuote('RegistrationNumber', data?.rc_no)

      dispatchMotorQuote('registrationNumber', result)
      dispatchMotorQuote('rtoCode', rtoStr)

      dispatchProcessFlowMotor('step1.registerationNumber', result)
      dispatchProcessFlowMotor('step', 'step1')

      const newDataa = {
        ...processFlowObjMotor,
        motorRequest: {
          ...motorRequest,
          vehicleType: VEHICLE_TYPE.FOUR_WHEELER
        },
        step: 'step1',
        quoteType: 'motor',
        processDiscription: 'registrationNumber',
        loggedInUser:
          userDetails?.Phone && userDetails?.CustomerUniqueNumber
            ? true
            : false,
        step1: {
          registrationNumber: result,
          vehicleType: VEHICLE_TYPE.FOUR_WHEELER
        }
      }
      processFlow(newDataa).then(response => {
        console.log('RESPONSE', response)
        if (
          response?.status === 'success' &&
          response?.data?.message === 'process started!!'
        ) {
          dispatchMotorQuote(
            'customerLeadId',
            response?.data?.data?.customerLeadId
          )
          const now = new Date()
          // Set the cookie to expire in 2 hours
          const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000)
          setCookie('customerLeadId', response?.data?.data?.customerLeadId, {
            expires
          })
        }
      })
    } else if (vehicleTYPE?.vehicleType === '2w') {
      setVehicleModal('Two Wheeler')
      dispatchQuickQuote('enquiryId', randomNumber)
      dispatchQuickQuote('RegistrationNumber', data?.rc_no)

      dispatchMotorQuote('registrationNumber', result)
      dispatchMotorQuote('rtoCode', rtoStr)

      dispatchProcessFlowMotor('step1.registerationNumber', result)
      dispatchProcessFlowMotor('step', 'step1')

      const newDataa = {
        ...processFlowObjMotor,
        motorRequest: {
          ...motorRequest,
          vehicleType: VEHICLE_TYPE.TWO_WHEELER
        },
        step: 'step1',
        quoteType: 'motor',
        processDiscription: 'registrationNumber',
        loggedInUser:
          userDetails?.Phone && userDetails?.CustomerUniqueNumber
            ? true
            : false,
        step1: {
          registrationNumber: result,
          vehicleType: VEHICLE_TYPE.TWO_WHEELER
        }
      }
      processFlow(newDataa).then(response => {
        console.log('RESPONSE', response)
        if (
          response?.status === 'success' &&
          response?.data?.message === 'process started!!'
        ) {
          dispatchMotorQuote(
            'customerLeadId',
            response?.data?.data?.customerLeadId
          )
          const now = new Date()
          // Set the cookie to expire in 2 hours
          const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000)
          setCookie('customerLeadId', response?.data?.data?.customerLeadId, {
            expires
          })
        }
      })
    }
    const quotationNumber = generateRetroNumber()
    dispatchQuickQuote('quotationNumber', quotationNumber)
    setActiveStep(3)
  }

  const getPolicyTenuare = () => {
    if (isNewVehicle === true && apiRequestQQ?.VehicleType == '2w') {
      const startDate = moment().format('YYYY-MM-DD')
      const endDate = moment()
        .add(5, 'year')
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
      dispatchQuickQuote('PolicyStartDate', startDate)
      dispatchQuickQuote('PolicyEndDate', endDate)
    } else if (isNewVehicle === true && apiRequestQQ?.VehicleType == '4w') {
      const startDate = moment().format('YYYY-MM-DD')
      const endDate = moment()
        .add(3, 'year')
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
      dispatchQuickQuote('PolicyStartDate', startDate)
      dispatchQuickQuote('PolicyEndDate', endDate)
    } else {
      const startDate = moment().format('YYYY-MM-DD')
      const endDate = moment()
        .add(1, 'year')
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
      dispatchQuickQuote('PolicyStartDate', startDate)
      dispatchQuickQuote('PolicyEndDate', endDate)
    }
  }

  const handleSelectRtoCity = val => {
    dispatchQuickQuote('State', val.registered_state_name)
    getPolicyTenuare()
    dispatchQuickQuote('RtoCode', val.value)
    dispatchMotorQuote('rtoCode', val?.value)
    dispatchMotorQuote('city', val?.registered_city_name)
    dispatchMotorQuote('state', val?.registered_state_name)
    dispatchQuickQuote(
      'RegistrationCity',
      val.registered_city_name.toUpperCase()
    )
    const newData = {
      ...processFlowObjMotor,
      motorRequest: { ...motorRequest },
      step: 'step1',
      step1: { rtoCode: val.value, vehicleType: motorRequest?.vehicleType }
    }
    processFlow(newData).then(response => {
      console.log('RESPONSE', response)
      if (
        response?.status === 'success' &&
        response?.data?.message === 'process started!!'
      ) {
        dispatchMotorQuote(
          'customerLeadId',
          response?.data?.data?.customerLeadId
        )
        const now = new Date()
        // Set the cookie to expire in 2 hours
        const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000)
        setCookie('customerLeadId', response?.data?.data?.customerLeadId, {
          expires
        })
      }
    })
    handleNextStep()
  }

  const handleSelectMake = val => {
    const myData1 = getDataFromCookie('Data')
    let obj1 = JSON.parse(myData1)
    dispatchQuickQuote('MakeName', val?.label)
    dispatchMotorQuote('makeName', val?.label)
    dispatchMotorQuote('makeId', val?.value)
    dispatchQuickQuote('ModelName', '')
    dispatchQuickQuote('VariantName', '')
    dispatchProcessFlowMotor('step2.make', val?.label)
    dispatchProcessFlowMotor('step', 'step2')

    getVehicleModel({
      make: val?.label,
      Vehicle_Type: vehicleTYPE?.vehicleModel
        ? vehicleTYPE?.vehicleModel
        : obj1?.vehicleModel
    }).then(response => {
      if (response.status === true) {
        handleNextStep()
        let data = response.data
        let i = 0
        let arr = []
        while (i < data.length) {
          let item = data[i]
          arr.push({
            label: item.Model,

            value: item.model_id
          })
          i++
        }
        setModelData(arr)
      }
    })

    const newData = {
      ...processFlowObjMotor,
      motorRequest: { ...motorRequest, makeName: val?.label },
      customerLeadId: motorRequest?.customerLeadId,
      step: 'step2',
      processDiscription: 'makeSelection',
      step2: { ...processFlowObjMotor.step2, make: val?.label }
    }

    processFlow(newData).then(response => {
      console.log('RESPONSE', response)
    })
  }

  const handleSelectModel = val => {
    const myData1 = getDataFromCookie('Data')
    let obj1 = JSON.parse(myData1)

    dispatchQuickQuote('ModelName', val?.label)
    dispatchMotorQuote('modelName', val?.label)
    dispatchMotorQuote('modelId', val?.value)
    dispatchQuickQuote('VariantName', '')

    dispatchProcessFlowMotor('step3.model', val?.label)
    dispatchProcessFlowMotor('step', 'step3')

    getVehicleVariant({
      make: apiRequestQQ.MakeName,
      model: val?.label,
      Vehicle_Type: vehicleTYPE?.vehicleModel
        ? vehicleTYPE?.vehicleModel
        : obj1?.vehicleModel
    }).then(response => {
      if (response.status === true) {
        let data = response.data

        let fuelTypeArr = data.filter(
          (v, i, a) => a.findIndex(v2 => v2.fuel_type === v.fuel_type) === i
        )

        let j = 0
        let fuelarr = []
        while (j < fuelTypeArr.length) {
          fuelarr.push(fuelTypeArr[j].fuel_type)
          j++
        }
        setFuelTypes(fuelarr)
        let i = 0
        let arr = []
        while (i < data.length) {
          let item = data[i]
          arr.push({
            label: `${item.Variant}(${item?.CC}cc)` || '',
            Digit: item.PAS_ID || '',
            Fuel_Type: item.fuel_type || '',
            Cubic_Capacity: item.CC || '',
            Seating_Capacity: item.SEATING_CAPACITY || '',
            // HDFC: item.HDFC || '',
            // Shriram: item.Shriram || '',
            Kotak: item.KOTAK_FINAL || '',
            // Reliance: item.Reliance || '',
            Future: item.Future || '',
            // Royal: item.Royal || '',
            // Bajaj: item.Bajaj || '',
            Magma: item.Magma || '',
            // Sompo: item.Universal_Sompo || '',
            vehicleId: item.id || ''
            // Liberty: item.Liberty || '',
          })
          i++
        }

        // const filteredOptions = variantData
        //   .filter(item => item.Fuel_Type === apiRequestQQ.FuelType)
        //   .map(item => ({
        //     value: item.label,
        //     label: `${item.label} (${item.Cubic_Capacity} cc)`
        //   }))
        setVariantData(arr)

        if (apiRequestQQ?.VehicleType === '4w') {
          handleNextStep()
        } else {
          dispatchQuickQuote('FuelType', 'Petrol')
          setActiveStep(5)
        }
      }
    })

    const newData = {
      ...processFlowObjMotor,
      motorRequest: { ...motorRequest, modelName: val?.label },
      customerLeadId: motorRequest?.customerLeadId,
      step: 'step3',
      processDiscription: 'modelSelection',
      step3: { ...processFlowObjMotor.step3, model: val?.label }
    }
    if (!cookies.customerLeadId) {
      const now = new Date()
      // Set the cookie to expire in 2 hours
      const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000)
      setCookie('customerLeadId', motorRequest?.customerLeadId, { expires })
    }
    processFlow(newData).then(response => {
      console.log('RESPONSE', response)
    })
  }

  const handleSelectPetrolType = val => {
    if (val == 'CNG' || val == 'LPG') {
      dispatchQuickQuote('IsInternalCNG', true)
      dispatchMotorQuote('isCngAccessories', true)
    }
    dispatchQuickQuote('FuelType', val)
    dispatchQuickQuote('FuelType', val)
    dispatchMotorQuote('fuelType', val)
    handleNextStep()
  }

  const handleSelectVariant = variant => {
    dispatchQuickQuote('VariantCode.Digit', variant.Digit)
    dispatchQuickQuote('VariantCode.HDFC', variant.HDFC)
    dispatchQuickQuote('VariantCode.Shriram', variant.Shriram)
    dispatchQuickQuote('VariantCode.Kotak', variant.Kotak)
    dispatchQuickQuote('VariantCode.Reliance', variant.Reliance)
    dispatchQuickQuote('VariantCode.Liberty', variant.Liberty)
    dispatchQuickQuote('VariantCode.Future', variant.Future)
    dispatchQuickQuote('VariantCode.Royal', variant.Royal)
    dispatchQuickQuote('VariantCode.Bajaj', variant.Bajaj)
    dispatchQuickQuote('VariantCode.Magma', variant.Magma)
    dispatchQuickQuote('VariantCode.Sompo', variant.Sompo)
    dispatchQuickQuote('VariantCode.vehicleId', variant.vehicleId)
    dispatchQuickQuote('VariantCode.vehicleId', variant.vehicleId)

    dispatchQuickQuote('FuelType', variant.Fuel_Type)
    dispatchQuickQuote('VariantName', variant.label)
    dispatchQuickQuote('CarryingCapacity', variant.Seating_Capacity)
    dispatchQuickQuote('CubicCapacity', variant.Cubic_Capacity)

    dispatchMotorQuote('variantName', variant?.label)
    dispatchMotorQuote('variantId', variant?.Digit)

    dispatchProcessFlowMotor('step4.variantSelection', variant?.vehicleId)
    dispatchProcessFlowMotor('step', 'step4')

    const newData = {
      ...processFlowObjMotor,
      motorRequest: { ...motorRequest },
      customerLeadId: motorRequest?.customerLeadId,
      step: 'step4',
      processDiscription: 'variantSelection',
      step4: {
        ...processFlowObjMotor.step4,
        variantSelection: variant?.vehicleId
      }
    }

    if (!cookies.customerLeadId) {
      const now = new Date()
      // Set the cookie to expire in 2 hours
      const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000)
      setCookie('customerLeadId', motorRequest?.customerLeadId, { expires })
    }
    processFlow(newData).then(response => {
      console.log('RESPONSE', response)
    })

    handleNextStep()
  }

  const selectVehicleYear = year => {
    setRegYear(year)
    dispatchQuickQuote('RegistrationYear', year)
    dispatchQuickQuote('ManufaturingDate', year)
    if (userDetails?.type && userDetails?.type === 'pos') {
      setActiveStep(activeStep + 2)
    } else {
      setActiveStep(activeStep + 1)
    }
    dispatchQuickQuote('CustomerType', 'INDIVIDUAL')
    dispatchQuickQuote('PreviousOwnerChanged', false)
    const today = new Date()
    setCurrentYear(today.getFullYear())
  }

  const submitUserDetails = data => {
    let newData = {
      FirstName: '',
      MiddleName: '',
      LastName: '',
      Email: data.email,
      MobileNumber: data.phone
    }

    // Spliting Name
    const nameParts = data.FullName.split(' ')

    if (nameParts.length > 0) {
      newData.FirstName = nameParts[0]
    }

    if (nameParts.length > 2) {
      newData.MiddleName = nameParts[1]
    }

    if (nameParts.length > 1) {
      newData.LastName = nameParts[nameParts.length - 1]
    }

    // Mapping keys to old structure Redux
    for (let key in newData) {
      dispatchQuickQuote(key, newData[key])
    }

    // Mapping Keys to New Structure Redux
    dispatchMotorQuote('salutation', 'MR.')
    dispatchMotorQuote('firstName', newData?.FirstName)
    dispatchMotorQuote('middleName', newData?.MiddleName)
    dispatchMotorQuote('lastName', newData?.LastName)
    dispatchMotorQuote('email', newData?.Email)
    dispatchMotorQuote('mobileNumber', newData?.MobileNumber)

    dispatchProcessFlowMotor('step', 'step5')
    dispatchProcessFlowMotor('step5.customerDetails', newData)
    dispatchProcessFlowMotor(
      'step5.manufaturingDate',
      motorRequest?.manufaturingDate
    )
    dispatchProcessFlowMotor('step5.customerType', motorRequest?.customerType)
    dispatchProcessFlowMotor(
      'step5.isOwnerChanged',
      motorRequest?.isOwnerChanged
    )
    const newDataaaa = {
      ...processFlowObjMotor,
      motorRequest: { ...motorRequest },
      customerLeadId: motorRequest?.customerLeadId,
      step: "step5",
      processDiscription: "customerDetails",

      step5: {
        ...processFlowObjMotor.step5,
        customerDetails: newData,
        // manufaturingDate: motorRequest?.manufaturingDate,
        customerType: motorRequest?.customerType,
        isOwnerChanged: motorRequest?.isOwnerChanged,
        isPreviousInsuranceClaimed: motorRequest?.isPreviousInsuranceClaimed
      },
    };
    if (!cookies.customerLeadId) {
      const now = new Date();
      // Set the cookie to expire in 2 hours
      const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000);
      setCookie("customerLeadId", motorRequest?.customerLeadId, {
        expires,
      });
    }
    processFlow(newDataaaa).then((response) => {
      console.log("RESPONSE", response);
    });
    submitPolicyDetails()
    handleNextStep()
  }

  const submitPolicyDetails = data => {
    let newData = { ...apiRequestQQ }

    // Setting Reg Date (Old Structure)
    let regDate =
      apiRequestQQ.IsVehicleNew == false
        ? moment()
            .add(1, 'day')
            .format(`${apiRequestQQ.RegistrationYear}-MM-DD`)
        : moment().format(`${apiRequestQQ.RegistrationYear}-MM-DD`)
    dispatchQuickQuote('RegistrationDate', regDate)

    // Setting Previous Policy End Date (Old Structure)
    let prvPolEndDate =
      apiRequestQQ.IsVehicleNew == false &&
      apiRequestQQ.PolicyStatus === 'continue'
        ? moment().add(1, 'day').format(`YYYY-MM-DD`)
        : apiRequestQQ.PolicyStatus === 'expired within 90 day'
        ? moment().subtract(90, 'day').format(`YYYY-MM-DD`)
        : apiRequestQQ.PolicyStatus === 'expired above 90 day'
        ? moment().subtract(180, 'day').format(`YYYY-MM-DD`)
        : ''

    dispatchQuickQuote(
      'ManufaturingDate',
      moment().subtract(2, 'months').format(`${regYear}-MM-DD`)
    )

    let newPolicyType = getNewPolicyType(regDate)
    newData.NewPolicyType = newPolicyType
    newData.PreviousPolicyType =
      newPolicyType == 'ODOnly' ? 'Bundled' : 'Comprehensive'
    newData.PrePolicyEndDate = prvPolEndDate

    dispatchQuickQuote('PrePolicyEndDate', prvPolEndDate)
    if (
      checkIfDateIsIn3Month(prvPolEndDate) &&
      apiRequestQQ.IsVehicleNew === false
    ) {
      const startDate = moment(prvPolEndDate).add(1, 'day').format('YYYY-MM-DD')
      const endDate = moment(startDate, 'YYYY-MM-DD')
        .add(1, 'year')
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
      newData.PolicyStartDate = startDate
      newData.PolicyEndDate = endDate
    }
    for (let key in data) {
      if (Object.keys(apiRequestQQ).includes(key)) {
        if (key === 'PolicyStatus' && data.PolicyStatus == 'false') {
          dispatchQuickQuote('IsPreviousInsurerKnown', false)
          dispatchQuickQuote('PolicyStatus', 'continue')
        }
        newData[key] = data[key]
        dispatchQuickQuote(key, data[key])
      }
    }
  }

  const getNewPolicyType = regDate => {
    if (apiRequestQQ.VehicleType == '2w') {
      if (isWithinFiveYears(regDate) == true) {
        return 'ODOnly'
      } else if (apiRequestQQ.VehicleType == '4w') {
        if (isWithinThreeYears(regDate) == true) {
          return 'ODOnly'
        }
      }
    } else {
      return 'Comprehensive'
    }
  }

  const handlePrePolicyType = val => {
    dispatchQuickQuote('PreviousPolicyType', val)
  }

  const submitPrevInsurernNCB = data => {
    console.log('PYP Data', data)
    // New Structure Start
    let policyStatus =
      data?.PolicyStatus === 'continue'
        ? POLICY_STATUS?.NOTEXPIRED
        : data?.PolicyStatus === 'expired within 90 day'
        ? POLICY_STATUS?.EXPIREDWITHIN90
        : data?.PolicyStatus === 'expired above 90 day'
        ? POLICY_STATUS?.EXPIREDBEFORE90
        : data?.PolicyStatus === 'false'
        ? POLICY_STATUS?.USED
        : ''

    let newPolicyType =
      data?.NewPolicyType === 'Comprehensive'
        ? POLICY_TYPE?.COMPREHENSIVE
        : data?.NewPolicyType === 'ThirdParty'
        ? POLICY_TYPE?.THIRDPARTY
        : data?.NewPolicyType === 'ODOnly'
        ? POLICY_TYPE?.ODONLY
        : data?.NewPolicyType === 'Bundled'
        ? POLICY_TYPE?.COMPREHENSIVE
        : ''

    let prevPolicyType =
      data?.PreviousPolicyType === 'Comprehensive'
        ? POLICY_TYPE?.COMPREHENSIVE
        : data?.PreviousPolicyType === 'ThirdParty'
        ? POLICY_TYPE?.THIRDPARTY
        : data?.PreviousPolicyType === 'ODOnly'
        ? POLICY_TYPE?.ODONLY
        : data?.PreviousPolicyType === 'Bundled'
        ? POLICY_TYPE?.BUNDLED
        : ''

    let businessType =
      data?.PolicyStatus === 'continue'
        ? POLICY_STATUS?.NOTEXPIRED
        : data?.PolicyStatus === 'expired within 90 day'
        ? POLICY_STATUS?.EXPIREDWITHIN90
        : data?.PolicyStatus === 'expired above 90 day'
        ? POLICY_STATUS?.EXPIREDBEFORE90
        : data?.PolicyStatus === 'false'
        ? POLICY_STATUS?.USED
        : POLICY_STATUS?.NEW

    dispatchMotorQuote('registrationDate', data?.RegistrationDate)
    dispatchMotorQuote('prevPolicyType', prevPolicyType)
    dispatchMotorQuote('newPolicyType', newPolicyType)
    dispatchMotorQuote('prevPolicyEndDate', data?.PreviousPolicyEndDate)
    dispatchMotorQuote('prevPolicyExpiryStatus', policyStatus)
    dispatchMotorQuote('insurerCode', data?.PreInsurerCode)
    dispatchMotorQuote('businessType', businessType)

    // New Structure End

    for (let key in data) {
      dispatchQuickQuote(`${key}`, data[key])
    }
    const mfgDate = moment(data?.RegistrationDate)
      .subtract(2, 'months')
      .format('YYYY-MM-DD')
    dispatchQuickQuote('ManufaturingDate', mfgDate)
    dispatchMotorQuote('manufacturingDate', mfgDate)

    let newStructureData = {
      ...motorRequest,
      registrationDate: data?.RegistrationDate,
      prevPolicyType: prevPolicyType,
      newPolicyType: newPolicyType,
      prevPolicyEndDate: data?.PreviousPolicyEndDate,
      policyStatus: policyStatus,
      insurerCode: data?.PreInsurerCode,
      businessType: businessType,
      manufacturingDate: mfgDate
    }
    if (
      apiRequestQQ?.PreviousInsuranceClaimed === true ||
      apiRequestQQ?.PreviousOwnerChanged === true
    ) {
      dispatchQuickQuote('PreviousNoClaimBonus', 0)
    }

    if (
      motorRequest?.isPreviousInsuranceClaimed === true ||
      motorRequest?.isOwnerChanged
    ) {
      dispatchMotorQuote('prevNoClaimBonus', 0)
    } else {
      dispatchMotorQuote('prevNoClaimBonus', data?.PreviousNoClaimBonus)
    }

    dispatchQuickQuote('PrePolicyEndDate', data?.PreviousPolicyEndDate)

    const startDate = moment(data?.PreviousPolicyEndDate)
      .add(1, 'day')
      .format('YYYY-MM-DD')
    const endDate = moment(startDate, 'YYYY-MM-DD')
      .add(1, 'year')
      .subtract(1, 'day')
      .format('YYYY-MM-DD')
    dispatchQuickQuote('PolicyStartDate', startDate)
    dispatchQuickQuote('PolicyEndDate', endDate)

    let PreviousNoClaimBonus = data.PreviousNoClaimBonus
      ? data.PreviousNoClaimBonus
      : 0
    let PreInsurerCode = data.PreInsurerCode
    let NewPolicyType = data?.NewPolicyType
    // if (
    //   apiRequestQQ?.PreviousInsuranceClaimed === true ||
    //   apiRequestQQ?.PreviousOwnerChanged === true
    // ) {
    //   dispatchQuickQuote("PreviousNoClaimBonus", "0");
    //   PreviousNoClaimBonus = 0;
    // }
    let newData = {
      ...apiRequestQQ,
      PreviousNoClaimBonus,
      PreInsurerCode,
      NewPolicyType,
      userDetails: userDetails,
      PrePolicyEndDate: data?.PreviousPolicyEndDate,
      PolicyStartDate: startDate,
      PolicyEndDate: endDate
    }

    // digitAPICall(newData)
    newData = {
      ...newData,

      AddOns: { ...newData.AddOns, PersonalAccident: true }
    }
    // magmaAPICall(newData)
    // FutureGeneralQuickQuote(newData)

    newStructureData = {
      ...newStructureData,
      idv: 0,
      prevNoClaimBonus: data?.PreviousNoClaimBonus
    }

    

    dispatchProcessFlowMotor("step6.pypSelection", data);
    dispatchProcessFlowMotor("step", "step6");

    const postData = {
      ...processFlowObjMotor,
      motorRequest: { ...motorRequest },
      customerLeadId: motorRequest?.customerLeadId,
      step: "step6",
      processDiscription: "pypSelection",
      step6: { ...processFlowObjMotor.step6, pypSelection: {
        registrationDate: data?.RegistrationDate,
        prevPolicyType: prevPolicyType,
        newPolicyType: newPolicyType,
        prevPolicyEndDate: data?.PreviousPolicyEndDate,
        prevPolicyExpiryStatus: policyStatus,
        insurerCode: data?.PreInsurerCode,
        businessType: businessType,
        manufacturingDate: mfgDate,
        prevNoClaimBonus: data?.PreviousNoClaimBonus
      },
    motorRequest: {...motorRequest} },
    };
    if (!cookies.customerLeadId) {
      const now = new Date();
      // Set the cookie to expire in 2 hours
      const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000);
      setCookie("customerLeadId", motorRequest?.customerLeadId, { expires });
    }
    processFlow(postData);
    if (
      motorRequest?.isPreviousInsuranceClaimed === true ||
      motorRequest?.isOwnerChanged === true
    ) {
      dispatchMotorQuote("prevNoClaimBonus", 0);
    }
    motorQuotation(newStructureData)
    // navigate(`/quotelist`)
    navigate(`/quotelist/${motorRequest?.customerLeadId}`)
    
    // }
  }

  const handlePreviousStep = () => {
    setActiveStep(activeStep - 1)
  }

  const geenrateMfgYears = () => {
    var max = regYear
    var min = regYear - 18
    var years = []
    for (let i = max; i >= min; i--) {
      years.push(i)
    }
    return years
  }

  function getMaxDate () {
    const today = new Date()
    const maxDate = new Date(today)

    maxDate.setDate(maxDate.getDate() + 45)

    // Format the max date to match the input's required format (YYYY-MM-DD)
    const formattedMaxDate = maxDate.toISOString().split('T')[0]

    return formattedMaxDate
  }

  function getMinDate () {
    const today = new Date()
    const maxDate = new Date(today)

    maxDate.setDate(maxDate.getDate())

    const formattedMinDate = maxDate.toISOString().split('T')[0]

    return formattedMinDate
  }

  const handleNewVhicle = () => {
    const quotationNumber = generateRetroNumber()
    dispatchQuickQuote('quotationNumber', quotationNumber)
    dispatchQuickQuote('IsVehicleNew', true)
    getCustomerLeadId()
    setActiveStep(activeStep + 1)
  }

  // New Structure Get Quote Start

  const motorQuotation = async data => {
    dispatch(resetQuickQuoteResults())
    if (data) {
      let response
      let isEqual
      let newData = { ...data }
      for (let key in data) {
        if (data[key] !== dataMotorQuotation[key]) {
          isEqual = false
          break
        } else {
          isEqual = true
        }
      }
      if (!isEqual) {
        dispatchMotorQuote('rId', '')
        newData = { ...newData, rId: '' }
      }
      setDataMotorQuotation(newData)
      let updatedData = { ...newData }
      let executionCount = 0
      const fetchQuotesData = async () => {
        executionCount++
        try {
          if (
            updatedData?.rId &&
            response?.caseStatus === 'Quotes Generated' &&
            response?.isAllQuotesDone
          ) {
            response = await getMotorQuote(updatedData)
            setGetMotorQuoteResponse(response)
            dispatchMotorQuote('sessionId', response?.sessionId)
            if (executionCount >= 5) {
              handleQuotesData(response)
              clearInterval(interval)
            }

            if (response?.errors.length > 0) {
              response?.errors.map(error =>
                store.dispatch(
                  apiErrorReponse(
                    failureCaseArr(
                      error?.insurerName,
                      error?.insurerId,
                      error?.displayMessage,
                      error?.logo
                    )
                  )
                )
              )
              clearInterval(interval)
            }
          } else if (
            updatedData?.rId &&
            response?.caseStatus === 'Quotes Requested'
          ) {
            response = await getMotorQuote(updatedData)
            setGetMotorQuoteResponse(response)
            dispatchMotorQuote('sessionId', response?.sessionId)
          } else {
            response = await getMotorQuote(updatedData)
            if (
              response?.status === 'failure' &&
              response?.errors?.length > 0
            ) {
              response?.errors.map(error =>
                store.dispatch(
                  apiErrorReponse(
                    failureCaseArr(
                      error?.insurerName,
                      error?.insurerId,
                      error?.displayMessage,
                      error?.logo
                    )
                  )
                )
              )
              clearInterval(interval)
              throw new Error('API Error occurred')
            }
            setGetMotorQuoteResponse(response)
            updatedData = { ...updatedData, rId: response?.rId }
            dispatchMotorQuote('rId', response?.rId)
            dispatchMotorQuote('sessionId', response?.sessionId)
          }
        } catch (error) {
          clearInterval(interval)
        }
      }

      const interval = setInterval(fetchQuotesData, 1000)
      handleQuotesData(response)
    }
  }

  const handleQuotesData = data => {
    console.log('ResultArrData', data)
    data?.quotes?.map(item =>
      store.dispatch(
        quickQuoteResult(
          newPolicyResultArr(
            item?.insurerId === insurers.Digit.insurerId
            ? insurers.Digit.logo
            : item?.insurerId === insurers.Future.insurerId
            ? insurers.Future.logo
            : item?.insurerId === insurers.Bajaj.insurerId
            ? insurers.Bajaj.logo
            : item?.insurerId === insurers.HDFC.insurerId
            ? insurers.HDFC.logo
            : null,
          item?.insurer,
          "",
          "",
          motorRequest?.registrationNumber,
          item?.make,
          item?.model,
          item?.variant,
          item?.basicODPremium,
          item?.basicTPPremium,
          item?.netPremium,
          item?.gst,
          item?.finalPremium,
          item?.minIdv,
          item?.maxIdv,
          item?.idv,
          item?.policyStartDate,
          item?.policyEndDate,
          item?.discount,
          item?.insurer,
          item?.policyType,
          item?.roadSideAssistance?.isOpted,
          item?.roadSideAssistance?.isOpted
            ? item?.roadSideAssistance?.premium
            : item?.roadSideAssistance?.isOpted,
          item?.engineProtection?.isOpted,
          item?.engineProtection?.isOpted
            ? item?.engineProtection?.premium
            : item?.engineProtection?.isOpted,
          item?.tyreProtection?.isOpted,
          item?.tyreProtection?.isOpted
            ? item?.tyreProtection?.premium
            : item?.tyreProtection?.isOpted,
          item?.rimProtection?.isOpted,
          item?.rimProtection?.isOpted
            ? item?.rimProtection?.premium
            : item?.rimProtection?.isOpted,
          item?.consumables?.isOpted,
          item?.consumables?.isOpted
            ? item?.consumables?.premium
            : item?.consumables?.isOpted,
          item?.electricalAccessories?.isOpted,
          item?.electricalAccessories?.isOpted
            ? item?.electricalAccessories?.premium
            : item?.electricalAccessories?.isOpted,
          item?.nonElectricalAccessories?.isOpted,
          item?.nonElectricalAccessories?.isOpted
            ? item?.nonElectricalAccessories?.premium
            : item?.nonElectricalAccessories?.isOpted,
          item?.invoiceCover?.isOpted,
          item?.invoiceCover?.isOpted
            ? item?.invoiceCover?.premium
            : item?.invoiceCover?.isOpted,
          item?.ncbProtection?.isOpted,
          item?.ncbProtection?.isOpted
            ? item?.ncbProtection?.premium
            : item?.ncbProtection?.isOpted,
          item?.voluntaryDeductive?.isOpted,
          item?.voluntaryDeductive?.isOpted
            ? item?.voluntaryDeductive?.premium
            : item?.voluntaryDeductive?.isOpted,
          item?.passengerCover?.isOpted,
          item?.passengerCover?.isOpted
            ? item?.passengerCover?.premium
            : item?.passengerCover?.isOpted,
          item?.paCoverAmount?.isOpted,
          item?.paCoverAmount?.isOpted
            ? item?.paCoverAmount?.premium
            : item?.paCoverAmount?.isOpted,
          item?.lossOfPersonalBelongings?.isOpted,
          item?.lossOfPersonalBelongings?.isOpted
            ? item?.lossOfPersonalBelongings?.premium
            : item?.lossOfPersonalBelongings?.isOpted,
          item?.zeroDepriciationCover?.isOpted,
          item?.zeroDepriciationCover?.isOpted
            ? item?.zeroDepriciationCover?.premium
            : item?.zeroDepriciationCover?.isOpted,
          item?.keyReplacement?.isOpted,
          item?.keyReplacement?.isOpted
            ? item?.keyReplacement?.premium
            : item?.keyReplacement?.isOpted,

          item?.ncbDiscountAmount,
          item?.biFuelOd?.isOpted,
          item?.biFuelOd?.isOpted
            ? item?.biFuelOd?.premium
            : item?.biFuelOd?.isOpted,
          item?.biFuelTp?.isOpted,
          item?.biFuelTp?.isOpted
            ? item?.biFuelTp?.premium
            : item?.biFuelTp?.isOpted,
          item?.llToPaidDriver?.isOpted,
          item?.llToPaidDriver?.isOpted
            ? item?.llToPaidDriver?.premium
            : item?.llToPaidDriver?.isOpted,
          item?.caseId,
          item?.insurerId,
          item?.kycAdditionalData,
          item?.planId,
          item?.tppdDiscount ? item?.tppdDiscount : false,
          item?.loadingPremium ? item?.loadingPremium : false,
          item?.isAddonQuesAsk,
          item?.addonQuesArray,
          item?.isPanRequiredInProposal,
          item?.isAdharRequiredInProposal
          )
        )
      )
    )
  }
  // New Structure Get Quote End
  return (
    <>
      <Navbar />

      {/* Main Section */}
      <div className='main-page-bick-insurance'>
        <div className='bike-insurance-1'>
          {activeStep === 1 && (
            <>
              <div className='bike-insureance-info'>
                <h1>
                  {vehicleTYPE?.vehicleType === '4w'
                    ? 'Car'
                    : vehicleTYPE?.vehicleType === '2w'
                    ? 'Bike'
                    : ''}{' '}
                  insurance starting from <br /> <span>₹ 6/day*</span>
                </h1>
                <form onSubmit={handleSubmit(submitQuoteDetails)}>
                  <input
                    type='text'
                    className='text-uppercase '
                    id='vinpuut'
                    {...register('rc_no', {
                      required: 'Field is required',
                      minLength: {
                        value: 6,
                        message: 'Enter a Valid Number'
                      }
                    })}
                    //   maxLength={15}
                    placeholder={`${
                      vehicleTYPE?.vehicleType === '4w'
                        ? 'Car number:'
                        : vehicleTYPE?.vehicleType === '2w'
                        ? 'Bike Number'
                        : ''
                    } (rj-31-cc-1986)`}
                    onChange={e => {
                      setRegistrationValue(e)
                      clearErrors('rc_no')
                    }}
                  />{' '}
                  {errors?.rc_no && (
                    <div>
                      <span className='text-danger error-input'>
                        {errors?.rc_no?.message}
                      </span>
                    </div>
                  )}
                  <br />
                  <div>
                    <button type='submit'>
                      <a className='get-btn'> Get Details</a>
                    </button>
                  </div>
                </form>
                <div
                  class='text'
                  onClick={() => {
                    setActiveStep(2)
                    const quotationNumber = generateRetroNumber()
                    dispatchQuickQuote('quotationNumber', quotationNumber)
                  }}
                >
                  Don't know your{' '}
                  {vehicleTYPE?.vehicleType === '4w' ? 'Car' : 'Bike'} number?
                </div>
                <div
                  class='text'
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleNewVhicle()}
                >
                  Bought a new{' '}
                  {vehicleTYPE?.vehicleType === '4w' ? 'Car' : 'Bike'}
                </div>
              </div>
              <div className='bike-insurance-img'>
                <img
                  src={
                    vehicleTYPE?.vehicleType === '4w'
                      ? './assets/img/Car-insurance-img-1.png'
                      : vehicleTYPE?.vehicleType === '2w'
                      ? '/assets/img/Bike-img.png'
                      : './assets/img/Car-insurance-img-1.png'
                  }
                  alt=''
                />
              </div>
            </>
          )}
          {activeStep === 2 && (
            <>
              <div className='main-rto-page'>
                <div className='rto-inf'>
                  <div className='select-rto'>
                    <div className='new-rto'>
                      <div className='new-rto-img'>
                        <img src='./assets/img/new-product-img.png' alt='' />
                      </div>
                      <h1>New</h1>
                    </div>
                    <h1>Select City / RTO</h1>
                    <div className='loder'>
                      <p> 30%</p>
                    </div>
                  </div>
                  <div className='select_moter'>
                    <ReactSelect
                      options={rtoData}
                      name='rto'
                      placeholder='Select RTO Location'
                      onChange={val => handleSelectRtoCity(val)}
                    />
                    {errors?.rc_no && (
                      <div>
                        <span className='text-danger error-input'>
                          {errors?.rc_no?.message}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className='btn-rto-2'>
                    {rtoData &&
                      rtoData.length > 0 &&
                      rtoData
                        .filter(
                          item => item.registered_state_name === 'RAJASTHAN'
                        )
                        .slice(0, 12)
                        .map((item, index) => (
                          <>
                            <li
                              className='active'
                              style={{ listStyleType: 'none' }}
                              id={'rto' + item.id}
                              name='regcitycode'
                              onClick={() => handleSelectRtoCity(item)}
                            >
                              <button htmlFor={'rto' + item.id}>
                                {item.value}
                              </button>
                            </li>
                          </>
                        ))}
                  </div>

                  <div className='submit-rto'>
                    <button>Continue</button>
                  </div>
                </div>
              </div>
            </>
          )}
          {activeStep === 3 && (
            <>
              <div className='main-wheeler-page'>
                <div className='wheeler-inf'>
                  <div className='select-wheeler'>
                    <div className='new-wheeler'>
                      <div className='new-wheeler-img'>
                        <img src='./assets/img/new-product-img.png' alt='' />
                      </div>
                      <p>New</p>
                    </div>

                    <h1>Select Vehicle Manufacturer</h1>
                    <div className='loder'>
                      <p> 40%</p>
                    </div>
                  </div>
                  <div className='select_moter'>
                    <ReactSelect
                      options={makeData}
                      name='make'
                      placeholder='Select Manufacturer'
                      onChange={val => handleSelectMake(val)}
                    />
                  </div>
                  <div className='wheeler-name'>
                    <div className='wheeler-logo'>
                      <ul>
                        {apiRequestQQ?.VehicleType == '2w' ||
                        apiRequestQQ?.VehicleType == '4w' ||
                        apiRequestQQ?.VehicleType == 'pcv'
                          ? quickPick.map((item, i) => (
                              <li
                                value={item.value}
                                id={'idx' + i}
                                name={'make'}
                                onClick={() => handleSelectMake(item)}
                              >
                                <div className='wheeler-box'>
                                  <img
                                    src={item.image}
                                    className='img-fluid '
                                    alt=''
                                  />
                                </div>
                              </li>
                            ))
                          : makeData &&
                            makeData.length > 0 &&
                            makeData.slice(0, 12).map((item, index) => (
                              <li
                                className='company-btn'
                                id={'make11' + index}
                                name='carComany'
                              >
                                <button
                                  onClick={() => handleSelectMake(item.value)}
                                  htmlFor={'make11' + index}
                                >
                                  {item.value}
                                </button>
                              </li>
                            ))}
                      </ul>
                    </div>
                  </div>

                  {/* <div className="more-wheeler">
                    <select name="" id="">
                      <option value=""> View More</option>
                    </select>
                  </div> */}

                  <div className='field-info-3'>
                    <div className='submit-model'>
                      <button onClick={() => handlePreviousStep()}>
                        Previous
                      </button>
                    </div>
                    <div className='submit-model'>
                      <button type='submit'>Continue</button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {activeStep === 4 && (
            <div className='main-model-company-page'>
              <div className='modal-inf'>
                <div className='select-company'>
                  <div className='new-company'>
                    <div className='new-model'>
                      <div className='new-model-img'>
                        <img src='./assets/img/new-product-img.png' alt='' />
                      </div>
                      <h1>New</h1>
                    </div>
                    {/* <h1>New</h1> */}
                  </div>
                  {apiRequestQQ?.VehicleType == '4w' ? (
                    <h1>Select Four Wheeler Model</h1>
                  ) : apiRequestQQ?.VehicleType == '2w' ? (
                    <h1>Select Two Wheeler Model</h1>
                  ) : apiRequestQQ?.VehicleType == 'pcv' ? (
                    <h1>Select Two Wheeler Model</h1>
                  ) : (
                    apiRequestQQ?.VehicleType == 'gcv' && 'GCV'
                  )}
                  <div className='loder'>
                    <p> 50%</p>
                  </div>
                </div>
                <div className='select_moter'>
                  <ReactSelect
                    options={modelData}
                    name='make'
                    placeholder='Select Model'
                    onChange={val => handleSelectModel(val)}
                  />
                </div>

                {/* <div class="model-company"> */}
                <div className='model-company'>
                  {modelData &&
                    modelData.length > 0 &&
                    modelData.slice(0, 15).map((item, index) => (
                      <li
                        style={{ listStyleType: 'none' }}
                        onClick={() => handleSelectModel(item)}
                        className='company-btn'
                        id={'rto11' + index}
                        name='carComanY'
                      >
                        <button htmlFor={'rto11' + index}>
                          {item.label}

                          {/* <input type="radio" name="radio" /> */}
                          {/* <span className="illcheckmark"></span> */}
                        </button>
                      </li>
                    ))}
                </div>
                {/* </div> */}

                {/* <div className="more-company">
                  <select name="" id="">
                    <option value=""> View More</option>
                  </select>
                </div> */}
                <div className='field-info-3'>
                  <div className='submit-model'>
                    <button onClick={() => handlePreviousStep()}>
                      Previous
                    </button>
                  </div>
                  <div className='submit-model'>
                    <button type='submit'>Continue</button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeStep === 5 && (
            <div class='bike-brand-name-1 wheeler-inf'>
              <div class='select-model  previous-heading'>
                <div className='new-model'>
                  <div className='new-model-img'>
                    <img src='./assets/img/new-product-img.png' alt='' />
                  </div>
                  <h1>New</h1>
                </div>
                <h1>Select Fuel Type</h1>
                <div className='loder'>
                  <p> 60%</p>
                </div>
              </div>
              <div className='allcitybike fuel-btn-btn '>
                <ul className='fuelTypeList d-flex  btn-btn-1'>
                  {fuelTypes.includes('Petrol') && (
                    <>
                      <li
                        onClick={() => handleSelectPetrolType('Petrol')}
                        className='active '
                        id={'rto11petrol'}
                        name='carComany'
                      >
                        <button htmlFor={'rto11petrol'} className='fuel-'>
                          Petrol
                        </button>
                      </li>
                    </>
                  )}
                  {fuelTypes.includes('Diesel') && (
                    <>
                      <li
                        onClick={() => handleSelectPetrolType('Diesel')}
                        className='active'
                        id={'rto11diesel'}
                        name='carComany'
                      >
                        <button htmlFor={'rto11diesel'} className='fuelTypeBtn'>
                          Diesel
                        </button>
                      </li>
                    </>
                  )}
                  {fuelTypes.includes('Electric') && (
                    <>
                      <li
                        onClick={() => handleSelectPetrolType('Electric')}
                        className='active'
                        id={'rto11ele'}
                        name='carComany'
                      >
                        <button htmlFor={'rto11ele'} className='fuelTypeBtn'>
                          Electric
                        </button>
                      </li>
                    </>
                  )}
                  {fuelTypes.includes('CNG') && (
                    <>
                      <li
                        onClick={() => handleSelectPetrolType('CNG')}
                        className='active'
                        id={'rto11cng'}
                        name='carComany'
                      >
                        <button htmlFor={'rto11cng'} className='fuelTypeBtn'>
                          CNG
                        </button>
                      </li>
                    </>
                  )}
                </ul>
              </div>
              <div className='field-info-3'>
                <div className='motor-submit-btn'>
                  <button
                    className='motor-btn'
                    onClick={() => handlePreviousStep()}
                  >
                    Previous
                  </button>
                </div>
                <div className='motor-submit-btn'>
                  <button type='submit' className='motor-btn'>
                    Continue
                  </button>
                </div>
              </div>
            </div>
          )}
          {activeStep === 6 && (
            <div className='main-variant-page'>
              <div className='modal-inf'>
                <div className='select-variant'>
                  <div className='new-variant'>
                    <div className='new-variant-img'>
                      <img src='./assets/img/new-product-img.png' alt='' />
                    </div>
                    <h1>New</h1>
                  </div>
                  <h1>Model Variant</h1>
                  <div className='loder'>
                    <p> 70%</p>
                  </div>
                </div>
                <div className='model-variant'>
                  <div className='variant-btn'>
                    {/* <Link to="#">
                      <button>CB Unicorn</button>
                    </Link> */}
                  </div>
                </div>
                <div class='bike-brand-name'>
                  {/* <div class="company-name-heading">
                    {apiRequestQQ?.VehicleType == "4w" ? (
                      <h1>Select Four Wheeler Model</h1>
                    ) : apiRequestQQ?.VehicleType == "2w" ? (
                      <h1>Select Two Wheeler Model</h1>
                    ) : apiRequestQQ?.VehicleType == "pcv" ? (
                      <h1>Select Two Wheeler Model</h1>
                    ) : (
                      apiRequestQQ?.VehicleType == "gcv" && "GCV"
                    )}
                  </div> */}
                  <div className='select_moter '>
                    <ReactSelect
                      options={variantData.filter(
                        item => item.Fuel_Type == apiRequestQQ.FuelType
                      )}
                      // options={variantData}
                      name='model'
                      placeholder='Select vehicle variant'
                      onChange={val => handleSelectVariant(val)}
                    />
                  </div>
                  <div class='model_yer mt-4'>
                    <ul className='model-year'>
                      {variantData &&
                        variantData.length > 0 &&
                        variantData
                          .filter(
                            item => item.Fuel_Type == apiRequestQQ.FuelType
                          )
                          .slice(0, 12)
                          .map((item, index) => (
                            <li
                              id={'rot12' + index}
                              name='carComanY11'
                              onClick={() => handleSelectVariant(item)}
                              className='cursor-pointer'
                            >
                              <label
                                className='year_info'
                                htmlFor={'rot12' + index}
                                value={item.value}
                              >
                                {item.label}
                                <input type='radio' name='radio' />
                                {/* <span className="illcheckmark"></span> */}
                              </label>
                            </li>
                          ))}
                    </ul>
                  </div>
                </div>
                <div className='field-info-3'>
                  <div className='submit-model'>
                    <button onClick={() => handlePreviousStep()}>
                      Previous
                    </button>
                  </div>
                  <div className='submit-model'>
                    <button type='submit'>Continue</button>
                  </div>
                </div>
                {/* <div className="submit-variant">
                  <button>
           Continue
                  </button>
                </div> */}
              </div>
            </div>
          )}
          {activeStep === 7 && (
            <div className='main-model-page'>
              <div className='modal-inf'>
                <div className='select-model'>
                  <div className='new-model'>
                    <div className='new-model-img'>
                      <img src='./assets/img/new-product-img.png' alt='' />
                    </div>
                    <h1>New</h1>
                  </div>

                  <h1>Select Manufacturing Year </h1>
                  <div className='loder'>
                    <p> 80%</p>
                  </div>
                </div>
                <div className='model-year'>
                  {/* <div className="model-btn">
                    <Link>
                      <button>2023</button>
                    </Link>
                  </div> */}
                  <div class='model_yer'>
                    <ul className='model-year'>
                      {apiRequestQQ?.IsVehicleNew === true
                        ? generateArrayOfYearsForNew().map((item, index) => (
                            <>
                              <li
                                onClick={() => selectVehicleYear(item)}
                                value={item}
                                id={'yrr' + index}
                                name='CarYear'
                              >
                                <label
                                  className='year-select'
                                  htmlFor={'yrr' + index}
                                >
                                  {item}
                                  <input type='radio' name='radio' />
                                  {/* <span className="illcheckmark"></span> */}
                                </label>
                              </li>
                            </>
                          ))
                        : generateArrayOfYears().map((item, index) => (
                            <>
                              <li
                                onClick={() => selectVehicleYear(item)}
                                value={item}
                                id={'yrr' + index}
                                name='CarYear'
                              >
                                <label
                                  className='year-select'
                                  htmlFor={'yrr' + index}
                                >
                                  {item}
                                  <input type='radio' name='radio' />
                                  {/* <span className="illcheckmark"></span> */}
                                </label>
                              </li>
                            </>
                          ))}
                    </ul>
                  </div>
                </div>

                {/* <div className="more-model">
                  <select name="" id="">
                    <option value=""> View More</option>
                  </select>
                </div> */}

                <div className='field-info-3'>
                  <div className='submit-model'>
                    <button onClick={() => handlePreviousStep()}>
                      Previous
                    </button>
                  </div>
                  <div className='submit-model'>
                    {' '}
                    <button>Continue</button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeStep === 8 && (
            <div className='main-model-page'>
              <div className='previous-Policy-div'>
                <div className='select-model  previous-heading'>
                  <div className='det-info'>
                    <div className='new-model'>
                      <div className='new-model-img'>
                        <img src='./assets/img/new-product-img.png' alt='' />
                      </div>
                      <h1>New</h1>
                    </div>
                    <h1>Personal Details</h1>
                    <div className='loder'>
                      <p> 90%</p>
                    </div>
                  </div>
                </div>
                <div className='PreviousPolicyDetails-cantainer'>
                  <div className='policy-img-1'>
                    <img
                      src={
                        vehicleTYPE?.vehicleType === '4w'
                          ? './assets/img/Car-insurance-img-1.png'
                          : vehicleTYPE?.vehicleType === '2w'
                          ? './assets/img/bike-img-2.png'
                          : './assets/img/Car-insurance-img-1.png'
                      }
                      alt=''
                    />
                  </div>

                  <div className='PreviousPolicyDetails'>
                    <div>
                      {apiRequestQQ?.IsVehicleNew === false && (
                        <div>
                          <h6 className='mb-3'>Last Owner Change ?</h6>
                          <div className='btn-previousPolicy'>
                            <div className='previousPolicyBtn'>
                              <div className='btn-policy-btn '>
                                <input
                                  type='radio'
                                  id='owner1'
                                  name='ownerChange'
                                  {...register1('owner', {
                                    required: 'Field is required'
                                  })}
                                  value={true}
                                  onChange={e => {
                                    const value = e?.target?.value
                                    setOwnerChange(value)
                                    dispatchMotorQuote('isOwnerChanged', true)
                                    dispatchQuickQuote('IsOwnerChanged', value)
                                  }}
                                />
                                <label for='owner1'>Yes</label>
                              </div>
                              <span className='text-danger error-input-perviousPage'>
                                {errors1.owner && errors1.owner.message}
                              </span>
                            </div>
                            <div className='previousPolicyBtn'>
                              <div className='btn-policy-btn'>
                                <input
                                  type='radio'
                                  id='owner2'
                                  name='ownerChange'
                                  {...register1('owner', {
                                    required: 'Field is required'
                                  })}
                                  value={false}
                                  onChange={e => {
                                    const value = e?.target?.value
                                    setOwnerChange(value)
                                    dispatchMotorQuote('isOwnerChanged', false)
                                    dispatchQuickQuote('IsOwnerChanged', value)
                                  }}
                                />
                                  <label for='owner2'>No</label>
                              </div>
                              <span className='text-danger error-input-perviousPage'>
                                {errors1.owner && errors1.owner.message}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}

                      {apiRequestQQ?.IsVehicleNew === false &&
                        ownerChange === 'false' && (
                          <div>
                            <h6 className='mb-3'>
                              Have You made a claim in the previous year
                            </h6>
                            <div className='btn-previousPolicy'>
                              <div className='previousPolicyBtn'>
                                <div className='btn-policy-btn '>
                                  <input
                                    type='radio'
                                    id='btn1'
                                    name='claim'
                                    {...register1('claim', {
                                      required: 'Field is required'
                                    })}
                                    onChange={e => {
                                      dispatchQuickQuote(
                                        'PreviousInsuranceClaimed',
                                        e.target.value
                                      )
                                      dispatchMotorQuote(
                                        'isPreviousInsuranceClaimed',
                                        true
                                      )
                                    }}
                                    value={true}
                                  />
                                  <label for='btn1'>Yes</label>
                                </div>
                                <span className='text-danger error-input-perviousPage'>
                                  {errors1.claim && errors1.claim.message}
                                </span>
                              </div>
                              <div className='previousPolicyBtn'>
                                <div className='btn-policy-btn'>
                                  <input
                                    type='radio'
                                    id='policy1'
                                    name='claim'
                                    {...register1('claim', {
                                      required: 'Field is required'
                                    })}
                                    onChange={e => {
                                      dispatchQuickQuote(
                                        'PreviousInsuranceClaimed',
                                        e.target.value
                                      )
                                      dispatchMotorQuote(
                                        'isPreviousInsuranceClaimed',
                                        false
                                      )
                                    }}
                                    value={false}
                                  />
                                   {' '}
                                  <label for='policy1' name='claim'>
                                    No
                                  </label>
                                </div>
                                <span className='text-danger error-input-perviousPage'>
                                  {errors1.claim && errors1.claim.message}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      <div className='mt-3'>
                        <h6 className='mb-3'>Ownership of the Vehicle ?</h6>
                        <div className='btn-previousPolicy'>
                          <div className='previousPolicyBtn'>
                            <div className='btn-policy-btn'>
                              <input
                                type='radio'
                                id='html'
                                name='ownership'
                                {...register1('ownership', {
                                  required: 'Field is required'
                                })}
                                onChange={e => {
                                  dispatchQuickQuote(
                                    'CustomerType',
                                    e.target.value
                                  )
                                  dispatchMotorQuote(
                                    'customerType',
                                    CUSTOMER_TYPE?.INDIVIDUAL
                                  )
                                }}
                                value={'INDIVIDUAL'}
                              />
                                <label for='html'>Individual</label>
                            </div>
                            <span className='text-danger error-input-perviousPage'>
                              {errors1.ownership && errors1.ownership.message}
                            </span>
                          </div>
                          <div className='previousPolicyBtn'>
                            <div className='btn-policy-btn'>
                              <input
                                type='radio'
                                id='Organization'
                                name='ownership'
                                {...register1('ownership', {
                                  required: 'Field is required'
                                })}
                                onChange={e => {
                                  dispatchQuickQuote(
                                    'CustomerType',
                                    e.target.value
                                  )

                                  dispatchMotorQuote(
                                    'customerType',
                                    CUSTOMER_TYPE?.ORGANISATION
                                  )
                                }}
                                value={'ORGANISATION'}
                              />
                                <label for='Organization'>Organization</label>
                            </div>
                            <span className='text-danger error-input-perviousPage'>
                              {errors1.ownership && errors1.ownership.message}
                            </span>
                          </div>
                        </div>
                      </div>

                      <form onSubmit={handleSubmit1(submitUserDetails)}>
                        <div className='UserDetails material-textfield'>
                          <input
                            type='text'
                            placeholder=''
                            {...register1('FullName', {
                              required: 'Field is required',
                              pattern: {
                                value: /^[A-Za-z\s]+$/,
                                message: 'Numbers are not allowed'
                              }
                            })}
                            onChange={e => {
                              e.target.value = e.target.value.replace(
                                /[^a-zA-Z\s]/g,
                                ''
                              )
                              clearErrors1('FullName')
                            }}
                            className='input-policy-type'
                          />
                          <label className='label-policy-type'>Full Name</label>
                          {errors1?.FullName && (
                            <div>
                              <span className='text-danger error-input'>
                                {errors1?.FullName?.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className='UserDetails material-textfield'>
                          <input
                            type='text'
                            placeholder=''
                            className='input-policy-type'
                            {...register1('email', {
                              required: 'Email is required',
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: 'Invalid email address'
                              }
                            })}
                            onInput={e => {
                              let inputValue = e.target.value
                              if (inputValue.includes('com')) {
                                inputValue = inputValue.replace(
                                  /\.com.*/,
                                  '.com'
                                )
                              } else if (inputValue.includes('.in')) {
                                inputValue = inputValue.replace(/\.in.*/, '.in')
                              }
                              e.target.value = inputValue
                            }}
                          />
                          <label className='label-policy-type'>Email</label>
                          {errors1?.email && (
                            <div>
                              <span className='text-danger error-input'>
                                {errors1?.email?.message}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className='UserDetails material-textfield'>
                          <input
                            type='number'
                            id='MobileNumber'
                            placeholder=''
                            className='input-policy-type'
                            pattern='[0-9]{10}'
                            maxLength={10}
                            {...register1('phone', {
                              required: 'Mobile number is required',
                              minLength: {
                                value: 10,

                                message: 'Incorrect length of mobile number'
                              }
                            })}
                            onInput={e => {
                              e.target.value = e.target.value.slice(0, 10)
                            }}
                            onChange={e => {
                              let value = e.target.value.trim()
                              if (
                                value.startsWith('0') ||
                                value.startsWith('1') ||
                                value.startsWith('2') ||
                                value.startsWith('3') ||
                                value.startsWith('4') ||
                                value.startsWith('5')
                              ) {
                                value = value.substring('1')
                              }
                              e.target.value = value
                            }}
                          />

                          <label className='label-policy-type'>Mobile No</label>
                          {errors1?.phone && (
                            <div>
                              <span className='text-danger error-input'>
                                {errors1?.phone?.message}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className='field-info2'>
                          <div className='submit-model'>
                            <button onClick={() => handlePreviousStep()}>
                              Previous
                            </button>
                          </div>
                          <div className='submit-model'>
                            <button type='submit'>Continue</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeStep === 9 && (
            <div className='main-proposal-page-2'>
              <div className='container motor-general-details wheeler-inf'>
                <div className='select-model previous-heading'>
                  <div className='det-info'>
                    <div className='new-model'>
                      <div className='new-model-img'>
                        <img src='./assets/img/new-product-img.png' alt='' />
                      </div>
                      <h1>New</h1>
                    </div>
                    <h1>Fill Policy Details</h1>
                    <div className='loder'>
                      <p> 100%</p>
                    </div>
                  </div>
                </div>
                <div className='general-deatils'>
                  <form onSubmit={handleSubmit2(submitPrevInsurernNCB)}>
                    <div className='main-required-sec'>
                      <div className='left-required-sec'>
                        {/* Policy Status */}
                        {apiRequestQQ?.IsVehicleNew === false && (
                          <div className='Motor-deatil-1'>
                            <label>Select Policy Status</label>
                            <select
                              name='Not Expired'
                              id='PolicyStatus'
                              {...register2('PolicyStatus', {
                                required: 'Field is required',
                                onChange: e => setPolicyStatus(e?.target?.value)
                              })}
                              className='Motor-deatil'
                            >
                              <option value=''>Select</option>
                              <option value='continue'>Not Expired</option>
                              <option value='expired within 90 day'>
                                Expired Within 90 days
                              </option>
                              <option value='expired above 90 day'>
                                Expired Above 90 days
                              </option>
                              <option value='false'>
                                No Information About Previous Policy
                              </option>
                            </select>
                            <p className='f-error fs-6 text-danger mx-2'>
                              {errors2?.PolicyStatus?.message}
                            </p>
                          </div>
                        )}

                        {/* New Policy Type */}
                        <div className='Motor-deatil-1'>
                          <label>New Policy Type</label>
                          <select
                            id='NewPolicyType'
                            name='NewPolicyType'
                            {...register2('NewPolicyType', {
                              required: 'Field is required',
                              onChange: e => setNewPolicyType(e?.target?.value)
                            })}
                            className='Motor-deatil'
                          >
                            <option value=''>Select</option>
                            {apiRequestQQ.IsVehicleNew == true ? (
                              <option selected value='Bundled'>
                                Bundled
                              </option>
                            ) : apiRequestQQ.VehicleType === '4w' ? (
                              yearDiff < 3 ? (
                                <>
                                  <option value='ODOnly'>ODOnly</option>
                                </>
                              ) : (
                                <>
                                  <option value='Comprehensive'>
                                    Comprehensive
                                  </option>
                                  <option value='ThirdParty'>ThirdParty</option>
                                </>
                              )
                            ) : apiRequestQQ.VehicleType === '2w' ? (
                              yearDiff < 5 ? (
                                <>
                                  <option value='ODOnly'>ODOnly</option>
                                </>
                              ) : (
                                <>
                                  <option value='Comprehensive'>
                                    Comprehensive
                                  </option>
                                  <option value='ThirdParty'>ThirdParty</option>
                                </>
                              )
                            ) : (
                              <>
                                <option value='Comprehensive'>
                                  Comprehensive
                                </option>
                                <option value='ThirdParty'>ThirdParty</option>
                                <option value='ODOnly'>ODOnly</option>
                              </>
                            )}
                          </select>
                          {errors2?.NewPolicyType && (
                            <div>
                              <span className='text-danger error-input'>
                                {errors2?.NewPolicyType?.message}
                              </span>
                            </div>
                          )}
                        </div>

                        {/* Previous Policy Type */}
                        {policyStatus !== 'false' &&
                        apiRequestQQ?.IsVehicleNew === false ? (
                          <>
                            <div className='Motor-deatil-1'>
                              <label>Previous Policy Type</label>
                              <select
                                id='PreviousPolicyType'
                                name='PreviousPolicyType'
                                {...register2('PreviousPolicyType', {
                                  required: 'Field is required'
                                })}
                                className='Motor-deatil'
                              >
                                <option value=''>Select</option>
                                {apiRequestQQ.VehicleType === '2w' ? (
                                  yearDiff === 1 ? (
                                    <>
                                      <option value='Bundled'>Bundled</option>
                                    </>
                                  ) : yearDiff > 1 && yearDiff < 5 ? (
                                    <>
                                      <option value='ODOnly'>ODOnly</option>
                                    </>
                                  ) : (
                                    <>
                                      <option value='Comprehensive'>
                                        Comprehensive
                                      </option>
                                      <option value='ThirdParty'>
                                        ThirdParty
                                      </option>
                                    </>
                                  )
                                ) : apiRequestQQ.VehicleType === '4w' ? (
                                  yearDiff === 1 ? (
                                    <>
                                      <option value='Bundled'>Bundled</option>
                                    </>
                                  ) : yearDiff > 1 && yearDiff < 4 ? (
                                    <>
                                      <option value='ODOnly'>ODOnly</option>
                                    </>
                                  ) : yearDiff >= 3 ? (
                                    <>
                                      <option value='Comprehensive'>
                                        Comprehensive
                                      </option>
                                      <option value='ThirdParty'>
                                        ThirdParty
                                      </option>
                                    </>
                                  ) : (
                                    ''
                                  )
                                ) : (
                                  <>
                                    <option value='Comprehensive'>
                                      Comprehensive
                                    </option>
                                    <option value='ThirdParty'>
                                      ThirdParty
                                    </option>
                                    <option value='Bundled'>Bundled</option>
                                    <option value='ODOnly'>ODOnly</option>
                                  </>
                                )}
                              </select>
                              {errors2?.PreviousPolicyType && (
                                <div>
                                  <span className='text-danger error-input'>
                                    {errors2?.PreviousPolicyType?.message}
                                  </span>
                                </div>
                              )}
                            </div>

                            <div className='Motor-deatil-1'>
                              <label>Previous Policy Insurer</label>
                              <select
                                {...register2('PreInsurerCode', {
                                  required: 'Field is required'
                                })}
                                name='PreInsurerCode'
                                className='Motor-deatil'
                              >
                                {/* <option value="australia">New Policy Type</option> */}
                                {/* <option selected>Previous Policy Insurer</option> */}
                                <option value=''>Select</option>
                                {insurerData &&
                                  insurerData.length > 0 &&
                                  insurerData.map((item, i) => (
                                    <option key={i} value={item.value}>
                                      {item.option}
                                    </option>
                                  ))}
                              </select>
                              {errors2?.PreInsurerCode && (
                                <div>
                                  <span className='text-danger error-input'>
                                    {errors2?.PreInsurerCode?.message}
                                  </span>
                                </div>
                              )}
                            </div>

                            <div className='Motor-deatil-1'>
                              <label>Previous Policy End Date</label>
                              <input
                                type='date'
                                placeholder='Previous Policy End Date'
                                className='Motor-deatil'
                                {...register2('PreviousPolicyEndDate', {
                                  required: 'Field is required'
                                })}
                                max={getMaxDate()}
                                min={
                                  policyStatus === 'continue' && getMinDate()
                                }
                              />
                              {errors2?.PreviousPolicyEndDate && (
                                <div>
                                  <span className='text-danger error-input'>
                                    {errors2?.PreviousPolicyEndDate?.message}
                                  </span>
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                      </div>

                      {/* Manufacturing Year */}
                      <div className='right-required-sec'>
                        <div className='Motor-deatil-1'>
                          <label>Manufacturing Year</label>
                          <select
                            id='ManufacturingYear'
                            {...register2('ManufacturingYear', {
                              required: 'Year is required'
                            })}
                            defaultValue={new Date().getFullYear()} // Set default year to current year
                            className='Motor-deatil'
                          >
                            {geenrateMfgYears().map((year, index) => (
                              <option key={index}>{year}</option>
                            ))}
                          </select>
                          {errors2?.ManufacturingYear && (
                            <div>
                              <span className='text-danger error-input'>
                                {errors2?.ManufacturingYear?.message}
                              </span>
                            </div>
                          )}
                        </div>

                        {/* Registration Date */}
                        <div className='Motor-deatil-1'>
                          <label>Registration Date</label>
                          <input
                            type='date'
                            placeholder='Registration Date'
                            className='Motor-deatil'
                            {...register2('RegistrationDate', {
                              required: 'Field is required'
                            })}
                            defaultValue={moment()
                              .startOf('month')
                              .format(`${regYear}-MM-DD`)}
                            min={moment()
                              .startOf('month')
                              .format(`${regYear}-01-01`)}
                          />
                          {errors2?.RegistrationDate && (
                            <div>
                              <span className='text-danger error-input'>
                                {errors2?.RegistrationDate?.message}
                              </span>
                            </div>
                          )}
                        </div>

                        {/* Previous NCB */}
                        {ownerChange === 'false' &&
                          apiRequestQQ?.PreviousInsuranceClaimed === 'false' &&
                          !apiRequestQQ?.IsVehicleNew &&
                          policyStatus !== 'false' &&
                          policyStatus !== 'expired above 90 day' &&
                          newPolicyType !== 'ThirdParty' && (
                            <div className='Motor-deatil-1'>
                              <label>Previous Claim Bonus</label>
                              <select
                                id='PreviousNoClaimBonus'
                                name='PreviousNoClaimBonus'
                                {...register2('PreviousNoClaimBonus', {
                                  required: 'Field is required'
                                })}
                                className='Motor-deatil'
                              >
                                <option className='d-none' selected value=''>
                                  Previous No Claim Bonus (NCB)
                                </option>
                                <option value={0}>0%</option>
                                <option value={20}>20%</option>
                                <option value={25}>25%</option>
                                <option value={35}>35%</option>
                                <option value={45}>45%</option>
                                <option value={50}>50%</option>
                              </select>
                              {errors2?.PreviousNoClaimBonus && (
                                <div>
                                  <span className='text-danger error-input'>
                                    {errors2?.PreviousNoClaimBonus?.message}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className='motor-steps-btn'></div>
                    <div className='motor-steps-btn'>
                      <div className='motor-submit-btn'>
                        <button
                          className='motor-btn'
                          onClick={() => handlePreviousStep()}
                        >
                          Previous
                        </button>
                      </div>
                      <div className='motor-submit-btn'>
                        <button type='submit' className='motor-btn'>
                          Continue
                        </button>
                      </div>
                    </div>
                  </form>

                  {/* <div className='motor-img-div'>
                  <img src='./assets/img/bike-img-3.png' alt='' />
                </div> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <HowWork />
      {/* Details Section */}

      {apiRequestQQ?.VehicleType === '2w' ? (
        <Bike />
      ) : apiRequestQQ?.VehicleType === '4w' ? (
        <Car />
      ) : (
        ''
      )}

      <Footer />
    </>
  )
}

export default MotorProcess

const checkIfDateIsIn3Month = dateToCheck => {
  return (
    moment().diff(dateToCheck, 'days') > -90 &&
    moment().diff(dateToCheck, 'days') <= 0
  )
}
