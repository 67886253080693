import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import HowWork from "./HowWork";

function City() {
  return (
    <>
      <Navbar />
      <div id="main-city-page">
        <div className="sele-city">
        <div className="select-city">
          <div className="self-city">
            <div className="self-city-img">
              <img src="./assets/img/age-img.png" alt="" />
            </div>
            <h1>Self</h1>
          </div>
          <h1>select City</h1>
          <div className="loder">
            <p> 65%</p>
          </div>
        </div>
        <div className="city">
          <select>
            <option>Select City</option>
            <option value="">Chennai</option>
            <option value="">Mumbai</option>
            <option value="">Bangalore</option>
            <option value="">Hyderabad</option>
            <option value="">Kolkata</option>
          </select>
        </div>
        <div className="city-img">
          <div className="Chennai">
            <div className="img-1">
              <img src="./assets/img/Chennai (1).png" alt="" />
            </div>
            <p>Chennai</p>
          </div>
          <div className="Chennai">
            <div className="img-1">
              <img src="./assets/img/Mumbai (1).png" alt="" />
            </div>
            <p>Mumbai</p>
          </div>
          <div className="Chennai">
            <div className="img-1">
              <img src="./assets/img/Bangalore (1).png" alt="" />
            </div>
            <p>Bangalore</p>
          </div>
          <div className="Chennai">
            <div className="img-1">
              <img src="./assets/img/Hyderabad.png" alt="" />
            </div>
            <p>Hyderabad</p>
          </div>
          <div className="Chennai">
            <div className="img-1">
              <img src="./assets/img/Kolkata (1).png" alt="" />
            </div>
            <p>Kolkata</p>
          </div>
        </div>
        <div className="other-city">
          <select name="" id="">
            <option value="">View More</option>
          </select>
        </div>

        <div className="submit-city">
          <button>
            Continue
          </button>
        </div>
        </div>
      </div>
      {/* <HowWork/> */}
      <Footer />
    </>
  );
}
export default City;
