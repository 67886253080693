import React from 'react'

function HowWork() {
  return (
<>
<div className="works">
        <h1>How it Works</h1>
      </div>
      <div className="works-info">
        <div className="works-icon-img">
          <img src="./assets/img/money-bag-img.png" alt="" />
        </div>
        <h1>Affordable options</h1>
        <p>
          It was popularised in the 1960s with the release of Letraset sheets
          containing
        </p>
      </div>
      <div className="it-works">
        <div className="main-works-div">
          <div className="works-div-1">
            <div className="works-icon-img-2">
              <img src="./assets/img/customer-service11.png" alt="" />
            </div>
            <h1>Platinum Premium Insurance</h1>
            <p>
              It was popularised in the 1960s with the release of Letraset
              sheets containing
            </p>
          </div>
          <div className="works-div-2">
            <img src="./assets/img/work-img.png" alt="" />
          </div>
          <div className="works-div-3">
            <div className="works-icon-img-3">
              <img src="./assets/img/customer-service-img.png" alt="" />
            </div>
            <h1>30 minute claim support</h1>
            <p>
              It was popularised in the 1960s with the release of Letraset
              sheets containing
            </p>
          </div>
        </div>
      </div>
</>
  )
}

export default HowWork