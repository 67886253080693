export const VEHICLE_TYPE = {
    FOUR_WHEELER: "4w",
    TWO_WHEELER: "2w",
    GCV: "gcv",
    PCV: "pcv",
  };
  
  export const CUSTOMER_TYPE = {
    INDIVIDUAL: "individual",
    ORGANISATION: "organisation",
  };
  
  export const POLICY_TYPE = {
    COMPREHENSIVE: "comprehensive",
    THIRDPARTY: "thirdParty",
    ODONLY: "odOnly",
    BUNDLED: "bundled",
  };
  
  export const POLICY_STATUS = {
    NOTEXPIRED: "rollover",
    EXPIREDWITHIN90: "breakInWithin90Days",
    EXPIREDBEFORE90: "breakInBefore90Days",
    NEW: "new",
    USED: "used",
  };
  
  export const kycSteps = {
    before_proposal: "before_proposal",
    in_proposal: "in_proposal",
    before_payment: "before_payment",
    before_policy_doc: "before_policy_doc",
  };
  
  export const kycStepPriority = {
    before_proposal: 1,
    in_proposal: 2,
    before_payment: 3,
    before_policy_doc: 4,
  };
  
  export const caseStatus = {
    quotesRequested: "Quotes Requested",
    quotesInProcess: "Quotes In Process",
    quotesGenerated: "Quotes Generated",
    proposalRequest: "Proposal Requested",
    proposalPremiumMismatch: "Proposal Premium Mismatch",
    proposalSuccess: "Proposal Successful",
    proposalFailure: "Proposal Failure",
    paymentRequested: "Payment Requested",
    paymentSuccess: "Payment Success",
    paymentPending: "Payment Pending",
    paymentFailure: "Payment Failed",
    policyDocRequested: "Policy Document Requested",
    policyDocSuccess: "Policy Document Requested",
    policyDocPending: "Policy Document Pending",
    policyDocFailure: "Policy Document Fail",
  };
  
  export const kycStatus = {
    success: "success",
    failure: "failure",
    pending: "pending",
  };
  
  export const kycDoc = {
    passport: "passport",
    voterId: "voterId",
    pan: "pan",
    dl: "dl",
    adhaar: "adhaar",
    nregaCard: "nregaCard",
    gstin: "gstin",
    ckycNumber: "ckycNumber",
  };
  
  export const kycType = {
    ckyc: "ckyc",
    ovd: "ovd",
  };
  
  export const kycDocName = {
    passport: "Passport",
    voterId: "Voter Id",
    pan: "Pan",
    dl: "Driving License",
    adhaar: "Aadhaar Card",
    nregaCard: "Nrega Card",
    gstin: "GSTIN",
    ckycNumber: "Ckyc Number",
    cin: 'CIN'
  };
  
  export const documentType = {
    kyc: "kyc",
    motorDocuments: "motorDocuments",
  };

  export const insurers = {
    Digit: {
      name: "Go Digit General Insurance Limited",
      insurerId: 13,
      logo: "/assets/img/logos/partners/digit.png",
    },
    Bajaj: {
      name: "Bajaj Allianz General Insurance Co. Ltd.",
      insurerId: 5,
      logo: "/assets/img/logos/partners/bajaj.png",
    },
    Future: {
      name: "Future Generali India Insurance Company Limited",
      insurerId: 12,
      logo: "/assets/img/logos/partners/fg.png",
    },
    HDFC: {
      name: 'HDFC ERGO General Insurance Co. Ltd.',
      insurerId: 14,
      logo: '/assets/img/hdfc-img.jpg'
    }
  };

  export const extractCustomerLeadId = (url) => {
    // Use regex to match the pattern after "quotelist/"
    const match = url.match(/\/([^\/]+)\/?$/);
  
    // Return the first capture group if match is found
    return match ? match[1] : null;
  };