import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { useSelector } from "react-redux";
import {
  digitCreateQuoteApiCall,
  digitPaymentApi,
  hdfcPayment,
  magmaPaymentcall,
  savePolicyToDatabase,
} from "./Services/TPApiCall";
import { sendErrorMessage, sendSuccessInfo } from "./Services/PosService";
import { dispatchQuickQuote } from "../store/actions/userAction";
import { useForm } from "react-hook-form";
import Footer from "./Footer";
import FutureGeneralPayment from "./Additional/FutureGeneralPayment";
import { toast } from "react-toastify";
import { PostImageDataWithToken } from "../api/apiHelper";
import { useSearchParams } from "react-router-dom";

function PolicyOverview() {
  const selectedPlan = useSelector((state) => state?.root?.selectedPlan);
  const apiRequestQQ = useSelector((state) => state?.root?.apiRequestQQ);
  const QuickQouteResult = useSelector((state) => state.root.QuickQouteResult);
  const [disabled, setDisabled] = useState("");
  const [proceedSpinner, setProceedSpinner] = useState(false);
  const [buyButton, setBuyButton] = useState(false);
  const [magmaPaymentURL, setMagmaPaymentURL] = useState("");
  const [spinnner, setSpinner] = useState(false);
  const [images, setImages] = useState({
    rcFront: null,
    rcBack: null,
    insurance1: null,
    insurance2: null,
    salesLetter: null,
    invoice: null,
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    if (selectedPlan.Api_name === "Magma") {
      // setMagmaSpinner(true);
      magmaPaymentcall(apiRequestQQ).then((response) => {
        if (response?.data?.ServiceResult === "Success") {
          setSpinner(false);
          // setMagmaSpinner(false);
          setMagmaPaymentURL(response?.data?.OutputResult?.PaymentURL);
        } else if (response?.data?.ServiceResult === "Failure") {
          alert("Your case is going for approval !!");
        }
      });
    }
  }, [apiRequestQQ?.ProposalId]);

  const isValidate = () => {};

  const createQuote = (e) => {
    setSpinner(true);
    // if (images.rcFront === null && images.rcBack === null) {
    //   toast.error('Please Upload RC Photo')
    // } else if (images.rcFront === null) {
    //   toast.error('Please Upload RC-Front Photo')
    // } else if (images.rcBack === null) {
    //   toast.error('Please Upload RC-Back Photo')
    // } else {
    // if (apiRequestQQ.PolicyStatus === 'continue') {
    if (selectedPlan.Api_name === "digit") {
      setProceedSpinner(true);
      digitCreateQuoteApiCall(apiRequestQQ)
        .then((response) => {
          if (
            response.status === true &&
            response.data.error.httpCode === 200
          ) {
            dispatchQuickQuote("applicationId", response.data.applicationId);
            dispatchQuickQuote("ApiId", response.data.policyNumber);
            savePolicy(response.data.policyNumber);
            if (
              response.data.policyStatus === "INCOMPLETE" &&
              (apiRequestQQ.PolicyStatus === "continue" ||
                apiRequestQQ.PolicyStatus === "expired within 90 day" ||
                apiRequestQQ.PolicyStatus === "expired above 90 day" ||
                apiRequestQQ.PolicyStatus === "false")
            ) {
              digitPaymentApi(
                response.data.applicationId,
                apiRequestQQ?.ApiUniqueNumber?.Digit
              );
            } else if (
              response.data.policyStatus !== "INCOMPLETE" &&
              apiRequestQQ.PolicyStatus !== "continue"
            ) {
              sendSuccessInfo(
                "Complete your vehicle incepection once insurer send you verification link via SMS, Find inscpection status in my inspections"
              );
              setTimeout(() => {
                navigate("/");
              }, 2000);
            }
          } else {
            sendErrorMessage(response);
            setProceedSpinner(false);
            setDisabled("");
          }
          setDisabled("");
        })
        .catch((err) => {
          setProceedSpinner(false);
          setDisabled("");
        });
    } else if (selectedPlan.Api_name === "Magma") {
      window.location.href = magmaPaymentURL;
    } else if(selectedPlan?.Api_name === 'HDFC' || selectedPlan?.Api_name === 'Bajaj Allianz General Insurance Co. Ltd.'){
      // hdfcPayment(selectedPlan?.caseId)
      window.location.href = `https://uatapi.retroinsurance.in/api/v1/motor/payment/${selectedPlan.caseId}`
    }
    // } else if (
    //   apiRequestQQ.PolicyStatus === 'continue' &&
    //   images.rcFront === null
    // ) {
    //   toast.error('Please Upload RC-Front')
    // }
    // }
  };

  const handleUploadFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (
        e.target.files[0].type.includes("image") === true ||
        e.target.files[0].type.includes("pdf") === true
      ) {
        setImages((prevImages) => ({
          ...prevImages,
          [e.target.name]: e.target.files[0],
        }));
      } else {
        alert("Error in uploading images");
      }
    } else {
    }
  };

  const savePolicy = (apiId, inspection_no) => {
    let data = { ...apiRequestQQ, ...selectedPlan };
    if (apiId) {
      data.ApiId = apiId;
    }
    if (inspection_no) {
      data.inspection_no = inspection_no;
    }
    savePolicyToDatabase(data);
  };

  const handleUploadDoc = () => {
    let apiUNum =
      selectedPlan.Api_name === "digit"
        ? apiRequestQQ?.ApiUniqueNumber?.Digit
        : selectedPlan?.Api_name === "Magma"
        ? apiRequestQQ?.ApiUniqueNumber?.Magma
        : selectedPlan?.Api_name === "Future"
        ? apiRequestQQ?.ApiUniqueNumber?.Future
        : "";
    let formData = new FormData();

    formData.append("rc_front", images?.rcFront);
    formData.append("rc_back", images?.rcBack);
    formData.append("insurance1", images?.insurance1);
    formData.append("insuranceLetter", images?.insurance2);
    formData.append("salesLetter", images?.salesLetter);
    formData.append("invoice", images?.invoice);
    formData.append("ApiUniqueNumber", apiUNum);

    if (formData.size === 0) {
      toast("Images are empty");
      setBuyButton(false);
    } else {
      PostImageDataWithToken("admin/upload_doc", formData).then((res) => {
        if (res?.success) {
          toast(res?.message, { type: "success" });
          setBuyButton(true);
        } else {
          toast.error("Please Upload Document");
          // toast(res?.message, { type: 'error' })
          setBuyButton(false);
        }
      });
    }
  };

  const searchParams = new URLSearchParams(window.location.search);
  // const partner = searchParams.get('partner')

  return (
    <>
      <Navbar />
      <div className="policyoverview-main-page">
        <div className="container">
          <div className="select_policy_heading">
            <div className="select-model ">
              <div className="det-info-1 d-flex justify-content-between px-2 px-md-4 flex-wrap">
                <h1>PolicyOverview</h1>
                <h1 style={{ whiteSpace: "nowrap" }}>
                  Case No: {apiRequestQQ?.quotationNumber}
                </h1>
              </div>
            </div>
          </div>
          <div className="row policy_main_details">
            <div className="col-md-4  left_main_div">
              <div className="left-over-page">
                <div className="accordion main-over-div" id="accordionExample">
                  <div className="accordion-item over-div-info">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Policy Details
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="over-main-sec">
                          <div className="over-hed">
                            <h2>Insurance Company:</h2>
                            <img src={selectedPlan?.Logo} alt="" />
                          </div>
                          <div className="over-hed">
                            <h2>Policy Type:</h2>
                            <p>{selectedPlan?.policyType}</p>
                          </div>
                          <div className="over-hed">
                            <h2>Policy Start Date:</h2>
                            <p>{selectedPlan?.StartDate}</p>
                          </div>
                          <div className="over-hed">
                            <h2>Policy End Date:</h2>
                            <p>{selectedPlan?.EndDate}</p>
                          </div>
                          <div className="over-hed">
                            <h2>IDV:</h2>
                            <p>{selectedPlan?.idv}</p>
                          </div>
                          <div className="over-hed">
                            <h2>Net Premium:</h2>
                            <p>
                              INR{" "}
                              {Math.round(
                                selectedPlan?.NetPremium.toString().replace(
                                  "INR",
                                  ""
                                )
                              )}
                            </p>
                          </div>
                          <div className="over-hed">
                            <h2>GST:</h2>
                            <p>
                              INR{" "}
                              {Math.round(
                                selectedPlan?.GST.toString().replace("INR", "")
                              )}
                            </p>
                          </div>
                          <div className="over-hed">
                            <h2>Final Premium:</h2>
                            <p>
                              INR{" "}
                              {Math.round(
                                selectedPlan?.FinalPremium.toString().replace(
                                  "INR",
                                  ""
                                )
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item over-div-info">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Policy Holder Details
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="over-main-sec">
                          <div className="over-hed">
                            <h2>Name:</h2>
                            <p>
                              {apiRequestQQ?.MiddleName
                                ? apiRequestQQ?.FirstName +
                                  " " +
                                  apiRequestQQ?.MiddleName +
                                  " " +
                                  apiRequestQQ?.LastName
                                : apiRequestQQ?.FirstName +
                                  " " +
                                  apiRequestQQ?.LastName}
                            </p>
                          </div>
                          <div className="over-hed">
                            <h2>Email:</h2>
                            <p>{apiRequestQQ?.Email}</p>
                          </div>
                          <div className="over-hed">
                            <h2>Mobile:</h2>
                            <p>{apiRequestQQ?.MobileNumber}</p>
                          </div>
                          <div className="over-hed">
                            <h2>Date of Birth:</h2>
                            <p>
                              {new Date(apiRequestQQ?.Dob).toLocaleDateString(
                                "en-GB"
                              )}
                            </p>
                          </div>
                          <div className="over-hed">
                            <h2>City:</h2>
                            <p>{apiRequestQQ?.City}</p>
                          </div>
                          <div className="over-hed">
                            <h2>State:</h2>
                            <p>{apiRequestQQ?.State}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item over-div-info">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Vehicle Details
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="over-main-sec">
                          {apiRequestQQ?.IsVehicleNew === true ? (
                            <div className="over-hed">
                              <h2>RTO:</h2>
                              <p>{apiRequestQQ?.RtoCode}</p>
                            </div>
                          ) : (
                            <div className="over-hed">
                              <h2>Registration Number:</h2>
                              <p>{selectedPlan?.RegisterNumber}</p>
                            </div>
                          )}
                          <div className="over-hed">
                            <h2>Manufacturer:</h2>
                            <p>{selectedPlan?.Make}</p>
                          </div>
                          <div className="over-hed">
                            <h2>Model:</h2>
                            <p>{selectedPlan?.Model}</p>
                          </div>
                          <div className="over-hed">
                            <h2>Variant:</h2>
                            <p>{apiRequestQQ?.VariantName}</p>
                          </div>
                          <div className="over-hed">
                            <h2>Cubic Capacity:</h2>
                            <p>{apiRequestQQ?.CubicCapacity}</p>
                          </div>
                          <div className="over-hed">
                            <h2>Engine Number:</h2>
                            <p>{apiRequestQQ?.EngineNumber}</p>
                          </div>
                          <div className="over-hed">
                            <h2>Chassis Number:</h2>
                            <p>{apiRequestQQ?.ChassisNumber}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 right_doc_div">
              <div className="right-over-page">
                <div
                  className="accordion accordion-flush main-over-div"
                  id="accordionFlushExample"
                >
                  {apiRequestQQ?.IsVehicleNew === false && (
                    <>
                      {" "}
                      <div className="accordion-item over-div-info">
                        <h2 className="accordion-header" id="flush-headingOne">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            Upload RC Photo
                          </button>
                        </h2>
                        <div
                          id="flush-collapseOne"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <div className="po_left_container_inner_div_selected">
                              <div className="po_left_container_inner_div_selected_left">
                                <input
                                  type="file"
                                  className="d-none"
                                  style={{ cursor: "pointer" }}
                                  id={"rcFront"}
                                  name={"back"}
                                  accept={"image/*"}
                                  {...register("rcFront", {
                                    required: "Front RC is required",
                                    onChange: (e) => handleUploadFile(e),
                                  })}
                                />
                                <span className="text-danger error-input">
                                  {errors.rcFront && errors.rcFront.message}
                                </span>
                                <label
                                  htmlFor="rcFront"
                                  className="d-flex flex-column justify-content-center align-items-center"
                                >
                                  {images.rcFront ? (
                                    <img
                                      src={URL.createObjectURL(images.rcFront)}
                                      className=" d-block"
                                      style={{
                                        objectFit: "contain",
                                        width: "3.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={"/assets/img/cameraIcon.svg"}
                                      className=" d-block"
                                      style={{
                                        objectFit: "contain",
                                        width: "3.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                                </label>
                                <span>Front</span>
                              </div>
                              <div className="po_left_container_inner_div_selected_left">
                                <input
                                  type="file"
                                  className="d-none"
                                  style={{ cursor: "pointer" }}
                                  id={"rcBack"}
                                  name={"back"}
                                  accept={"image/*"}
                                  {...register("rcBack", {
                                    required: "Back RC is required",
                                    onChange: (e) => handleUploadFile(e),
                                  })}
                                />
                                <span className="text-danger error-input">
                                  {errors.rcBack && errors.rcBack.message}
                                </span>
                                <label
                                  htmlFor="rcBack"
                                  className="d-flex flex-column justify-content-center align-items-center"
                                >
                                  {images.rcBack ? (
                                    <img
                                      src={URL.createObjectURL(images.rcBack)}
                                      className=" d-block"
                                      style={{
                                        objectFit: "contain",
                                        width: "3.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={"/assets/img/cameraIcon.svg"}
                                      className=" d-block"
                                      style={{
                                        objectFit: "contain",
                                        width: "3.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                                </label>
                                <span>Back</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item over-div-info">
                        <h2 className="accordion-header" id="flush-headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                          >
                            Upload Insurance 1
                          </button>
                        </h2>
                        <div
                          id="flush-collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingTwo"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <div className="po_left_container_inner_div_selected">
                              <div className="po_left_container_inner_div_selected_left">
                                <input
                                  type="file"
                                  className="d-none"
                                  style={{ cursor: "pointer" }}
                                  id={"insurance1"}
                                  name={"insurance 1"}
                                  accept={"image/*"}
                                  {...register("insurance1", {
                                    required: "Insurance 1 is required",
                                    onChange: (e) => handleUploadFile(e),
                                  })}
                                />
                                <span className="text-danger error-input">
                                  {errors.insurance1 &&
                                    errors.insurance1.message}
                                </span>
                                <label
                                  htmlFor={"insurance1"}
                                  className="d-flex flex-column justify-content-center align-items-center"
                                >
                                  {images.insurance1 ? (
                                    <img
                                      src={URL.createObjectURL(
                                        images.insurance1
                                      )}
                                      className=" d-block"
                                      style={{
                                        objectFit: "contain",
                                        width: "3.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={"/assets/img/cameraIcon.svg"}
                                      className=" d-block"
                                      style={{
                                        objectFit: "contain",
                                        width: "3.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                                </label>
                                <span style={{ fontSize: "14px" }}>
                                  Insurance 1
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item over-div-info">
                        <h2
                          className="accordion-header"
                          id="flush-headingThree"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                            aria-expanded="false"
                            aria-controls="flush-collapseThree"
                          >
                            Upload Insurance 2
                          </button>
                        </h2>
                        <div
                          id="flush-collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingThree"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <div className="po_left_container_inner_div_selected">
                              <div className="po_left_container_inner_div_selected_left">
                                <input
                                  type="file"
                                  className="d-none"
                                  style={{ cursor: "pointer" }}
                                  id={"insurance2"}
                                  name={"insurance 2"}
                                  accept={"image/*"}
                                  {...register("insurance2", {
                                    required: "Insurance 2 is required",
                                    onChange: (e) => handleUploadFile(e),
                                  })}
                                />
                                <span className="text-danger error-input">
                                  {errors.insurance2 &&
                                    errors.insurance2.message}
                                </span>
                                <label
                                  htmlFor="insurance2"
                                  className="d-flex flex-column justify-content-center align-items-center"
                                >
                                  {images.insurance2 ? (
                                    <img
                                      src={URL.createObjectURL(
                                        images.insurance2
                                      )}
                                      className=" d-block"
                                      style={{
                                        objectFit: "contain",
                                        width: "3.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={"/assets/img/cameraIcon.svg"}
                                      className=" d-block"
                                      style={{
                                        objectFit: "contain",
                                        width: "3.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                                </label>
                                <span style={{ fontSize: "14px" }}>
                                  Upload Insurance 2
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item over-div-info">
                        <h2 className="accordion-header" id="flush-headingFour">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFour"
                            aria-expanded="false"
                            aria-controls="flush-collapseFour"
                          >
                            Sales Letter
                          </button>
                        </h2>
                        <div
                          id="flush-collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingFour"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <div className="po_left_container_inner_div_selected">
                              <div className="po_left_container_inner_div_selected_left">
                                <input
                                  type="file"
                                  className="d-none"
                                  style={{ cursor: "pointer" }}
                                  id={"salesLetter"}
                                  name={"salesletter"}
                                  accept={"image/*"}
                                  {...register("salesLetter", {
                                    required: "Sales Letter is required",
                                    onChange: (e) => handleUploadFile(e),
                                  })}
                                />
                                <span className="text-danger error-input">
                                  {errors.salesLetter &&
                                    errors.salesLetter.message}
                                </span>
                                <label
                                  htmlFor="salesLetter"
                                  className="d-flex flex-column justify-content-center align-items-center"
                                >
                                  {images.salesLetter ? (
                                    <img
                                      src={URL.createObjectURL(
                                        images.salesLetter
                                      )}
                                      className=" d-block"
                                      style={{
                                        objectFit: "contain",
                                        width: "3.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={"/assets/img/cameraIcon.svg"}
                                      className=" d-block"
                                      style={{
                                        objectFit: "contain",
                                        width: "3.5rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                                </label>
                                <span style={{ fontSize: "14px" }}>
                                  Sales Letter
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          className="btn btn-primary fs-6 mt-4"
                          type="submit"
                          onClick={() => handleUploadDoc()}
                        >
                          Upload Documents
                        </button>
                      </div>
                    </>
                  )}
                  {apiRequestQQ?.IsVehicleNew && (
                    <div className="accordion-item over-div-info">
                      <h2 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          Invoice
                        </button>
                      </h2>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <div className="po_left_container_inner_div_selected">
                            <div className="po_left_container_inner_div_selected_left">
                              <input
                                type="file"
                                className="d-none"
                                style={{ cursor: "pointer" }}
                                id={"invoice"}
                                name={"invoice"}
                                accept={"image/*"}
                                {...register("invoice", {
                                  required: "Invoice is required",
                                  onChange: (e) => handleUploadFile(e),
                                })}
                              />
                              <span className="text-danger error-input">
                                {errors.invoice && errors.invoice.message}
                              </span>
                              <label
                                htmlFor="invoice"
                                className="d-flex flex-column justify-content-center align-items-center"
                              >
                                {images.invoice ? (
                                  <img
                                    src={URL.createObjectURL(images.invoice)}
                                    className=" d-block"
                                    style={{
                                      objectFit: "contain",
                                      width: "3.5rem",
                                      cursor: "pointer",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={"/assets/img/cameraIcon.svg"}
                                    className=" d-block"
                                    style={{
                                      objectFit: "contain",
                                      width: "3.5rem",
                                      cursor: "pointer",
                                    }}
                                  />
                                )}
                              </label>
                              <span style={{ fontSize: "14px" }}>Invoice</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        className="btn btn-primary fs-6 mt-4"
                        type="submit"
                        onClick={() => handleUploadDoc()}
                      >
                        Upload Documents
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {buyButton && selectedPlan?.Api_name !== 'HDFC' ? (
          <>
            {" "}
            <div className="col-sm-4 col-6 mx-auto ms-auto mr-lg-0">
              <div className="text-sm-end text-center mb-sm-0 mb-5 w-100">
                {(apiRequestQQ.PolicyStatus === "continue" ||
                  apiRequestQQ.NewPolicyType === "ThirdParty") &&
                  selectedPlan.Api_name === "Future" && (
                    <FutureGeneralPayment />
                  )}
              </div>
            </div>
            {proceedSpinner ? (
              <div className="inr-btn">
                <button className="sub-btn" onClick={(e) => createQuote(e)}>
                  <div
                    className="spinner-border text-light"
                    role="status"
                  ></div>
                </button>
              </div>
            ) : (
              <div className="inr-btn">
                <button className="sub-btn" onClick={(e) => createQuote(e)}>
                  {selectedPlan.Api_name === "digit" ? (
                    <>
                      INR{" "}
                      {Math.round(
                        selectedPlan?.FinalPremium.toString().replace("INR", "")
                      )}
                    </>
                  ) : selectedPlan.Api_name === "Magma" &&
                    magmaPaymentURL !== "" ? (
                    <>
                      {spinnner ? (
                        <div class="spinner-border text-light" role="status">
                          <span class="sr-only"></span>
                        </div>
                      ) : (
                        "Buy" + selectedPlan.FinalPremium
                      )}
                    </>
                  ) : selectedPlan?.Api_name === 'HDFC' ? (
                    "Buy" + selectedPlan.FinalPremium
                  ) : ''}
                </button>
              </div>
            )}
          </>
        ) : <div className="inr-btn">
        <button className="sub-btn" onClick={(e) => createQuote(e)}>
        {"Buy" + ' ' + selectedPlan.FinalPremium}
        </button>
      </div>}
      </div>
      <Footer />
    </>
  );
}

export default PolicyOverview;
