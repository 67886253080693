import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import HowWork from "./HowWork";

function Model() {
  return (
    <>
      <Navbar />
      <div className="main-model-company-page">
        <div className="modal-inf">
          <div className="select-company">
            <div className="new-company">
              <div className="new-company-img">
                <img src="./assets/img/new-product-img.png" alt="" />
              </div>
              <h1>New</h1>
            </div>
            <h1>Model</h1>
            <div className="loder">
              <p> 60%</p>
            </div>
          </div>
          <div className="model-company">
            <div className="company-btn">
              <button>Activa</button>
              <button>CB Shine</button>
              <button>CB Unicorn</button>
              <button>CB Twiste</button>
              <button>CB 350 RS</button>
              <button>Dio</button>
              <button>Aviator</button>
              <button>Dream Yuga</button>
              <button>Unicorn</button>
              <button>Activa-1</button>
              <button>Hero</button>
              <button>CB</button>
              <button>Shine</button>
              <button>KTM</button>
              <button>Duke</button>
            </div>
          </div>

          <div className="more-company">
            <select name="" id="">
              <option value=""> View More</option>
            </select>
          </div>
          <div className="submit-company">
            <button>
      Continue
            </button>
          </div>
        </div>
      </div>
      <HowWork />
      <Footer />
    </>
  );
}

export default Model;
