import React from 'react'
import './Skeleton.css'

const Skeleton = () => {
  return (
    <div className='skeleton-outer'>
      {[1].map(index => (
        <div key={index} className='skeleton'>
          <div className='skeleton-inner'></div>
        </div>
      ))}
    </div>
  )
}

export default Skeleton
