import React, { useState } from 'react'
import Navbar from './Navbar'
import { Link } from 'react-router-dom'
import Footer from './Footer'
import HowWork from './HowWork'
import { Modal } from 'reactstrap'

function Health () {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  return (
    <>
      <div className='select-main-container'>
        <Navbar />
        <div className='select-who-like'>
          <h1>
            Select Who would you <b>like to insure?</b>
          </h1>
        </div>

        <div className='health-ins'>
          {/* <div className='select-insure'> */}
            {/* <div className='health-select-insure1'> */}
              <div className='select-insure'>
                <Link to='#' onClick={() => setModal(true)}>
                  <div className='select-insure-like-img'>
                    <img src='./assets/img/self-img.png' alt='' />
                    <h1>Self</h1>
                  </div>
                </Link>
                <Link href='#' onClick={() => setModal(true)}>
                  <div className='select-insure-like-img'>
                    <img src='./assets/img/wife-img.png' alt='' />
                    <h1>Wife</h1>
                  </div>
                </Link>
                <Link href='#' onClick={() => setModal(true)}>
                  <div className='select-insure-like-img'>
                    <img src='./assets/img/son-img.png' alt='' />
                    <h1>Son</h1>
                  </div>
                </Link>
                <Link href='#' onClick={() => setModal(true)}>
                  <div className='select-insure-like-img'>
                    <img src='./assets/img/daughter-img.png' alt='' />

                    <h1 style={{ marginLeft: '-5px' }}>Daughter</h1>
                  </div>
                </Link>

                <Link href='#' onClick={() => setModal(true)}>
                  <div className='select-insure-like-img'>
                    <img src='./assets/img/father-img.png' alt='' />
                    <h1>Father</h1>
                  </div>
                </Link>
                <Link href='#' onClick={() => setModal(true)}>
                  <div className='select-insure-like-img'>
                    <img src='./assets/img/mother-img.png' alt='' />
                    <h1>Mother</h1>
                  </div>
                </Link>
                <Link href='#' onClick={() => setModal(true)}>
                  <div className='select-insure-like-img'>
                    <img src='./assets/img/grand-father-img.png' alt='' />
                    <h1>
                      Grand <br />
                      Father
                    </h1>
                  </div>
                </Link>
                <Link href='#' onClick={() => setModal(true)}>
                  <div className='select-insure-like-img'>
                    <img src='./assets/img/grand-mother-img.png' alt='' />
                    <h1>
                      Grand <br />
                      Mother
                    </h1>
                  </div>
                </Link>
                <Link href='#' onClick={() => setModal(true)}>
                  <div className='select-insure-like-img'>
                    <img src='./assets/img/father-in-law-img.png' alt='' />
                    <h1>
                      Father <br />
                      In-Law
                    </h1>
                  </div>
                </Link>
                <Link href='#' onClick={() => setModal(true)}>
                  <div className='select-insure-like-img'>
                    <img src='./assets/img/mother-in-law-img.png' alt='' />
                    <h1>
                      Mother <br />
                      In-Low
                    </h1>
                  </div>
                </Link>
              </div>
            {/* </div> */}
          {/* </div> */}
        </div>
        {/* <HowWork/> */}
      </div>
      <Footer />

      <Modal isOpen={modal} toggle={toggle} size='md' 
      // style={{ width: '35%' }}
      >
        <nav className=''>
          <div
            className='nav nav-tabs border-0 justify-content-center px-2 py-2 mt-0'
            id='nav-tab'
            style={{
              backgroundColor: '#354c89',
              width: '100%',
              color: 'white',
              fontWeight: 'bold'
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p className='fs-5'>Details Submitted</p>
            </div>
          </div>
        </nav>
        <div
          className='px-4 py-5'
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <h2 className='modal-page'>
            Your Details has been Submitted.You will Recieve a call Shortly
          </h2>
          <button
            onClick={() => setModal(false)}
            style={{
              width: '50%',
              margin: 20,
              border: 'none',
              borderRadius: '3px',
              color: 'white',
              backgroundColor: '#354c89'
            }}
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  )
}

export default Health
