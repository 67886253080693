import React, { Component } from 'react'
import Select from 'react-select'
import { FixedSizeList as List } from 'react-window'
const ReactSelect = ({
  options,
  name,
  placeholder,
  defaultValue,
  onChange = () => {}
}) => {
  return (
    <div className='select_infor' id='pincode-input'>
      <Select
        className='select-inf'
        styles={{
          control: base => ({
            ...base
            // width: "25rem",
            // height: "3rem",
            // borderRadius: "7px",
          })
          // control: (baseStyles, state) => ({
          //   ...baseStyles,
          //   width: state ? "100%" : "",
          //   border: state ? "0px" : "0px",
          //   border: state.isFocused ? "0px" : "0px",
          // }),
        }}
        // className={`form-select fs-3`}
        isSearchable
        options={options}
        components={{ MenuList, IndicatorSeparator: () => null }}
        onChange={val => onChange(val)}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
    </div>
  )
}

export default ReactSelect

const height = 35

class MenuList extends Component {
  render () {
    const { options, children, maxHeight, getValue } = this.props
    const [value] = getValue()
    const initialOffset = options.indexOf(value) * height

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    )
  }
}
