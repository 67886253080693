import React from "react";

const Footer = () => {

const date = new Date().getFullYear()

  return (
    <>
      <footer>
        <div className="footer-info">
          <div className="footer-logo">
            <img src="/assets/logo-1.png" alt="" />
          </div>
          <div className="footer-location">
            <div className="location-1">
              <div className="footer-icon-1">
                <img src="/assets/img/Location-img.png" alt="" />
              </div>
              <div className="location-info">
                <p>
                  Location <br />
                  <span>
                    F-53, 1st Floor, Raheja Square Mall, Sec-2, IMT <br />
                    Manesar, Gurgaon, Haryana - 122051
                  </span>
                </p>
              </div>
            </div>
            <div className="location-1">
              <div className="footer-icon-1">
                <img src="/assets/img/Mail-img.png" alt="" />
              </div>
              <div className="location-info">
                <p>
                  Email Address
                  <br />
                  <span>help@retroinsurance.in</span>
                </p>
              </div>
            </div>
            <div className="location-1">
              <div className="footer-icon-1">
                <img src="/assets/img/phone-img.png" alt="" />
              </div>
              <div className="location-info">
                <p>
                  Phone
                  <br />
                  <span>+91(97173 71800)</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-links">
          <ul style = {{justifyContent : "center"}}>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/about"> About Us</a>
            </li>
            {/* <li>
              <a href="/Blog">Blog</a>
            </li> */}
            {/* <li>
              <a href="#">Sitemap</a>
            </li>
            <li>
              <a href="#">Careers</a>
            </li>
            <li>
              <a href="#">ISNP</a>
            </li>
            <li>
              <a href="#">Insurance Companies</a>
            </li>
            <li>
              <a href="#">Articles</a>
            </li> */}
            <li>
              <a href="/ContactUs">Contact Us</a>
            </li>
            {/* <li>
              <a href="#">Know your advisor</a>
            </li>
            <li>
              <a href="#">Newsroom</a>
            </li>
            <li>
              <a href="#">Security</a>
            </li> */}
          </ul>
        </div>
        <div className="footer-social">
          <div className="social-img">
            <img src="/assets/img/footer-img.png" alt="" />
          </div>
          <div className="social-icon">
            <div className="icon-1">
              <img src="/assets/img/facebook.png" alt="" />
            </div>
            <div className="icon-2">
              <img src="/assets/img/tweter.png" alt="" />
            </div>
            <div className="icon-3">
              <img src="/assets/img/linkedin.png" alt="" />
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright">
        <h1>© {date} R E T R O Insurance Broker. All Rights Reserved.</h1>
      </div>
    </>
  );
};

export default Footer;
