import logo from './logo.svg'
import './App.css'
import Navbar from './components/Navbar'
import Home from './components/Home'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Taxi from './components/Taxi'
import Bike from './components/Bike'
import Car from './components/Car'
import BecomePos from './components/pages/POS/BecomePos'
import Health from './components/Health'
import Age from './components/Age'
import City from './components/City'
import Personal from './components/Personal'
import Existing from './components/Existing'
import Rto from './components/Rto'
import Wheeler from './components/Wheeler'
import Model from './components/Model'
import Variant from './components/Variant'
import ModelYear from './components/ModelYear'
import QuoteList from './components/QuoteList'
import Plan from './components/Plan'
import ModelBox from './components/ModelBox'
import About from './components/About'
import ContactUs from './components/ContactUs'
import Blog from './components/Blog'
import BlogDetai from './components/BlogDetails'
import Login from './components/Login'
import ResetPassword from './components/ResetPassword'
import ForgetPassword from './components/ForgetPassword'
import ChangePassword from './components/ChangePassword'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import HowWork from './components/HowWork'
import FindAdvisor from './components/FindAdvisor'

import QuoteBike from './components/QuoteBike'

import MotorProcess from './components/common/MotorProcess'
import Proposal from './components/Proposal/Proposal'
import PolicyOverview from './components/PolicyOverview'
import SuccessMessage from './components/Proposal/SuccessMessage'
import ErrorMessage from './components/Proposal/ErrorMessage'

function App () {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/car' element={<Car />} />
          <Route path='/bike' element={<Bike />} />
          <Route path='/taxi' element={<Taxi />} />
          <Route path='/Health' element={<Health />} />
          <Route path='/becomepos' element={<BecomePos />} />
          <Route path='/motor-process' element={<MotorProcess />} />
          <Route path='/Age' element={<Age />}></Route>
          <Route path='/city' element={<City />}></Route>
          <Route path='/personal' element={<Personal />}></Route>
          <Route path='/plan' element={<Plan />}></Route>
          <Route path='/existing' element={<Existing />}></Route>
          <Route path='/rto' element={<Rto />}></Route>
          <Route path='/wheeler' element={<Wheeler />}></Route>
          <Route path='/model' element={<Model />}></Route>
          <Route path='/varient' element={<Variant />}></Route>
          <Route path='/modelyear' element={<ModelYear />}></Route>
          <Route path='/quotelist/:id' element={<QuoteBike />}></Route>
          {/* <Route path="/modelbox" element={<ModelBox />}></Route> */}
          <Route path='/About' element={<About />}></Route>
          <Route path='/ContactUs' element={<ContactUs />}></Route>
          <Route path='/Blog' element={<Blog />}></Route>
          <Route path='/BlogDetails' element={<BlogDetai />}></Route>
          {/* <Route path="/Login" element={<Login />}></Route> */}
          {/* <Route path="/ResetPassword" element={<ResetPassword />}></Route> */}
          {/* <Route path="/ForgetPassword" element={<ForgetPassword />}></Route> */}
          {/* <Route path="/ChangePassword" element={<ChangePassword />}></Route> */}
          <Route path='/howwork' element={<HowWork />}></Route>
          <Route path='/proposal' element={<Proposal />} />
          <Route path='/policyoverview' element={<PolicyOverview />} />
          <Route path='/success' element={<SuccessMessage />}></Route>
          <Route path='/error-page' element={<ErrorMessage />}></Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  )
}

export default App
